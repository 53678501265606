import React, {useState, useRef, useEffect} from "react";
import {  faChevronDown, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 *
 * @param {showDropdown} showDropdown - The dropdown to show
 * @param {setShowDropdown} setShowDropdown - The function to set the dropdown to show
 * @param {availableManagers} availableManagers - The available managers
 * @param {selectedManagers} selectedManagers - The selected managers
 * @param {setSelectedManagers} setSelectedManagers - The function to set the selected managers
 * @returns {JSX.Element} - The manager multi-select component
 */
const ManagerMultiSelect = ({showDropdown, setShowDropdown, availableManagers, selectedManagers, setSelectedManagers}) => {
    const [managerMultiSelectView, setManagerMultiSelectView] = useState(false)
    const [managerSearchQuery, setManagerSearchQuery] = useState('')
    const [managers, setManagers] = useState([])
    const [filteredManagers, setFilteredManagers] = useState([])

    const dropdownRef = useRef(null)
    const boxRef = useRef(null)

    const handleSearchInputChange = (event) => {
        setManagerSearchQuery(event.target.value);
    };

    //To search in the dropdown
    const filteredManagerSelect = filteredManagers.filter((manager) => {
        return manager.name.toLowerCase().includes(managerSearchQuery.toLowerCase()) || manager.surname.toLowerCase().includes(managerSearchQuery.toLowerCase())
    });

    const handleManagerSelect = (e, manager) => {
        e.stopPropagation();
        if (selectedManagers.includes(manager)) {
            setSelectedManagers(selectedManagers.filter((item) => item.id !== manager.id));
        } else {
            setSelectedManagers([...selectedManagers, manager]);
        }
    }

    //This is to prevent the employeeMultiSelect and ManagerMultiSelect to be open at the same time
    const handleManagerMultiSelectToggle = (e) =>{
        e.stopPropagation();
        if(managerMultiSelectView){
            setManagerMultiSelectView(false)
        }else{
            setManagerMultiSelectView(true)
            setShowDropdown('manager')
        }
    }
    //This is to prevent the employeeMultiSelect and ManagerMultiSelect to be open at the same time
    useEffect(()=>{
        if (showDropdown == 'employee') {
            setManagerMultiSelectView(false)
        }
    },[showDropdown])

    useEffect(()=>{
        setManagers(availableManagers)
    }, [availableManagers])

    //Remove selected managers from manager list
    useEffect(() => {
        const filteredManagers = managers.filter((manager) => !selectedManagers.includes(manager));
        setFilteredManagers(filteredManagers);
    }, [managers, selectedManagers]);

    useEffect(() => {
        // Function to handle the click outside the component
        const handleClickOutside = (event) => {
          if (dropdownRef.current && boxRef.current && !dropdownRef.current.contains(event.target) && !boxRef.current.contains(event.target)) {
            setManagerMultiSelectView(false);
          }
        };

        // Add the event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return(
        <>
            <div className="managerSelect w-full bg-gray-200 rounded-lg">
                {/* No managers available to add to team */}
                {
                    availableManagers?.length === 0 && (
                        <div className="w-full bg-gray-200 rounded-lg flex items-center justify-between focus:outline-none relative p-1">
                        <span className="text-gray-600 text-sm">No managers available to add to team.</span>
                        </div>
                    )
                }

                {availableManagers.length !== 0 && (
                    <div
                    className="w-full bg-gray-200 rounded-lg flex items-center justify-between focus:outline-none relative p-1"
                    onClick={(e)=>{handleManagerMultiSelectToggle(e)}}
                    style={{userSelect: 'none'}}
                    ref={boxRef}
                >
                    <span className="ml-1 text-gray-600" style={{ fontSize: "14px" }}>
                    {filteredManagerSelect?.length > 0
                        ? availableManagers.length > 0
                        ? `${availableManagers.length} managers available.`
                        : "Manager Multi-Select"
                        : "No managers to select"}
                    </span>
                    <FontAwesomeIcon icon={faChevronDown} size="xs" className="text-gray-600 mr-1" />
                    {managerMultiSelectView && filteredManagerSelect?.length > 0 && (
                        <div className="absolute top-full left-0 bg-gray-200 rounded shadow-sm w-full overflow-hidden p-1"  style={{ zIndex: 10, top: 'calc(100%)' }} ref={dropdownRef}>
                            <input
                                    type="text"
                                    className="px-2 py-1 text-sm bg-gray-200 text-gray-600 w-full "
                                    placeholder="Search..."
                                    value={managerSearchQuery}
                                    onChange={handleSearchInputChange}
                                    onClick={(e) => {e.stopPropagation()}}
                                    autoFocus={true}
                                    style={{userSelect: 'none'}}
                                />
                            <div className="pb-1 bg-gray-200" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                {filteredManagerSelect.map((manager) => (
                                <div
                                    key={manager.id}
                                    className="block px-2 py-1 text-xs text-gray-600 ml-2 bg-gray-200 hover:bg-purple-200 hover:text-gray-900 cursor-pointer"
                                    onClick={(e) => handleManagerSelect(e,manager)}
                                >
                                    {manager.name} {manager.surname}

                                </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                )}

            </div>

        </>
    )

}

export default ManagerMultiSelect;
