import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const DownloadCsv = ({
  selectedTimeslot,
  outboundTimeslot,
  inboundTimeslot,
  selectedEmployees,
  selectedWeek,
  selectedCompanyAddress,
  tripType,
  selectedCompany,
}) => {
  const extractIds = (employees) => {
    return employees.map((employee) => employee.id);
  };

  const employees = extractIds(selectedEmployees);

  return (
    <div className="my-2">
      {(inboundTimeslot === null || outboundTimeslot === null) ||
        selectedEmployees?.length === 0 ||
        (selectedCompanyAddress &&
          selectedCompanyAddress.name == "Select an Address") ||
        selectedWeek === undefined ? (
        <button className="bookingsDownload bg-gray-300 text-sm text-gray-500 cursor-not-allowed mt-2 py-2 px-4 rounded-full">
          Download CSV
        </button>
      ) : (
        <div className="mt-4">
          <a
            href={`/csv_bookings/download_csv.csv?company_id=${selectedCompany.id
              }&employees=${employees
                .map((employee) => employee)
                .join(
                  "|"
                )}&timeslot_inbound=${inboundTimeslot}&timeslot_outbound=${outboundTimeslot}&company_address=${selectedCompanyAddress.name
              }&week=${selectedWeek.value}`}
            className="text-sm text-white py-2 px-4 rounded-full bg-purple-500 hover:bg-purple-700"
          >
            Download CSV
          </a>
        </div>
      )}
    </div>
  );
};

export default DownloadCsv;
