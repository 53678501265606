import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTimesCircle, faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import ResponseMessage from "./Message";
import ConfirmationModal from "../Modals/Confirmation";
import { showToast } from "../../../toasts";

/**
 *
 * @param {handleCloseDialogue} - function to close the dialogue
 * @param {reloadTeams} - state to trigger a reload of the teams
 * @param {setReloadTeams} - function to set the reload teams state
 * @returns {JSX.Element} - the create team dialogue
 */
const EditTeamDialogue = ({
  selectedCompany,
  handleCloseDialogue,
  reloadTeams,
  teams,
  setTeams,
  setReloadTeams,
  role,
  handleTabClick,
}) => {
  const [selectedTeam, setSelectedTeam] = useState(teams[0]?.team_id);
  const currentTeam = teams.find((team) => team.team_id === selectedTeam);
  const [newTeamName, setNewTeamName] = useState("");
  const [availableEmployees, setAvailableEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const [availableManagers, setAvailableManagers] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteTeamModalOpen, setIsDeleteTeamModalOpen] = useState(false);
  const [employeesActive, setEmployeesActive] = useState([]);

  const [showMessage, setShowMessage] = useState(false);
  const [responseTitle, setResponseTitle] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [isRemoveMembersModalOpen, setIsRemoveMembersModalOpen] = useState(false);

  const [viewOptions, setViewOptions] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const dropdownRef = useRef(null);
  const boxRef = useRef(null);

  const toggleView = () => {
    setViewOptions(!viewOptions);
  };

  // Function to get the employees that are not in the team
  function getAvailableEmployees(array1, array2) {
    const uniqueObjects = [];

    // Create a Set of ids from each array for comparison
    const idSet1 = new Set(array1?.map((obj) => obj.id));
    const idSet2 = new Set(array2?.map((obj) => obj.id));

    for (const obj of array1) {
      if (!idSet2.has(obj.id)) {
        uniqueObjects.push(obj);
      }
    }

    for (const obj of array2) {
      if (!idSet1.has(obj.id)) {
        uniqueObjects.push(obj);
      }
    }

    function findObjectsInArrayById(arr1, arr2) {
      // Create a Map of objects from arr2 using their 'id' as the key
      const arr2Map = new Map();
      arr2.forEach((obj) => arr2Map.set(obj.id, obj));

      const result = arr1.filter((obj1) => arr2Map.has(obj1.id));

      return result;
    }

    const resultArray = findObjectsInArrayById(
      availableEmployees,
      uniqueObjects
    );

    return resultArray;
  }

  const teamToUpdateMembers = teams.find(
    (team) => team.team_id === selectedTeam
  );

  const handleTeamChange = (event) => {

    setSelectedTeam(event);
    setSelectedEmployees([]);
    setSelectedTeamMembers([]);
    setNewTeamName("");
    setViewOptions(false);

    setEmployeesActive(
      getAvailableEmployees(availableEmployees, teamToUpdateMembers?.employees)
    );
  };

  const handleEmployeeChecked = (employeeId) => {
    const isSelected = selectedTeamMembers.includes(employeeId);
    if (isSelected) {
      setSelectedTeamMembers(
        selectedTeamMembers.filter((id) => id !== employeeId)
      );
    } else {
      setSelectedTeamMembers([...selectedTeamMembers, employeeId]);
    }
  };
  const filterEmployeesByRole = (teamData) => {
    const team_employees = teamData.employees;

    const filtered_Employees = team_employees.filter(employee => {
      return employee.role === "employee";
    });

    return filtered_Employees;
  }

  const handleRemoveMembers = () => {
    if (selectedTeamMembers && selectedTeamMembers.length > 0 && selectedTeam) {
      const teamToUpdateMembers = teams.find(
        (team) => team.team_id === selectedTeam
      );
      setIsLoading(true);

      const requestData = {
        user_ids_to_remove: selectedTeamMembers,
        team_id: teamToUpdateMembers.team_id,
      };

      Axios.post(`/team_manager/delete_users_from_team`, requestData)
        .then((response) => {
          setReloadTeams(reloadTeams + 1);
          setSelectedTeamMembers([]);
          setIsLoading(false);
          setIsDeleteTeamModalOpen(false);
          showToast("success", "Members removed successfully");
          setIsRemoveMembersModalOpen(false)
        })
        .catch((error) => {
          console.error("Error removing team members:", error);
          showToast("error", "Error removing team members");
          setReloadTeams(reloadTeams + 1);
          setSelectedTeamMembers([]);
          setIsLoading(false);
          setIsRemoveMembersModalOpen(false)
        });
    }
  };

  const handleRemoveTeamMember = (memberId) => {
    const teamToUpdateMembers = teams.find(
      (team) => team.team_id === selectedTeam
    );
    if (memberId && teamToUpdateMembers?.team_id) {
      setIsLoading(true);

      const requestData = {
        user_id_to_remove: memberId,
        team_id: teamToUpdateMembers.team_id,
      };

      const team = filterEmployeesByRole(currentTeam);

      if (team.length === 1) {
        handleDeleteTeam();
        return;
      }

      Axios.post(`/team_manager/delete_team_member`, requestData)
        .then((response) => {
          showToast("success", "Member removed successfully");
          setReloadTeams(reloadTeams + 1);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error removing team member:", error);
          showToast("error", "Error removing team member");
          setReloadTeams(reloadTeams + 1);
          setIsLoading(false);
        });
    }
  };

  const handleChangeTeamName = (event) => {
    setNewTeamName(event.target.value);
  };

  const handleRemoveManager = (manager) => {
    setIsLoading(true);


    Axios.post(`/team_manager/remove_manager`, {
      manager_id: manager.id,
      team_id: currentTeam.team_id,
    })
      .then((response) => {
        showToast("success", "Manager removed successfully");
        setReloadTeams(reloadTeams + 1);
        setSelectedManagers(
          selectedManagers.filter((item) => item.id !== manager.id)
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error removing manager", error);
        showToast("error", "Error removing manager");
        setReloadTeams(reloadTeams + 1);
        setIsLoading(false);
      });
  };

  const handleUpdateTeamName = () => {
    if (newTeamName.trim() !== "") {
      const teamToUpdate = teams.find((team) => team.team_id === selectedTeam);
      if (teamToUpdate) {
        const updatedTeam = {
          new_team_name: newTeamName,
          team_id: teamToUpdate.team_id,
        };

        setIsLoading(true);

        Axios.put(`/team_manager/update_team_name`, updatedTeam)
          .then((response) => {
            setReloadTeams(reloadTeams + 1);
            setIsLoading(false);
            showToast("success", "Team name updated successfully");
            setNewTeamName("");
          })
          .catch((error) => {
            console.error("Error updating team name:", error);
            showToast("error", "Error updating team name");
            setReloadTeams(reloadTeams + 1);
            setIsLoading(false);
          });
      }
    }
  };

  const handleDeleteTeam = () => {
    if (selectedTeam) {
      const teamToDelete = teams?.find((team) => team.team_id === selectedTeam);
      if (teamToDelete) {
        const deletedTeam = { team_id: teamToDelete.team_id };

        setIsLoading(true);

        setIsDeleteTeamModalOpen(false);

        Axios.post(`/team_manager/delete_team`, deletedTeam)
          .then((response) => {
            setIsLoading(false);
            showToast("success", "Team deleted successfully");
            handleTabClick("createTeamTab");
            setReloadTeams(reloadTeams + 1);
          })
          .catch((error) => {
            console.error("Error Deleting team:", error);
            showToast("error", "Error deleting team");
            setReloadTeams(reloadTeams + 1);
            setIsLoading(false);
          });
      }
    }
  };

  const openDeleteTeamModal = () => {
    setIsDeleteTeamModalOpen(true);
  };

  const fetchManagers = () => {
    setIsLoading(true);
    Axios({
      method: "get",
      url: "/team_manager/get_managers",
      headers: { Accept: "application/json" },
      params: {
        company_id: selectedCompany?.id,
      },
    })
      .then((res) => {
        setAvailableManagers(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching managers:", error);
        setIsLoading(false);
      });
  };
  const fetchEmployees = () => {
    setIsLoading(true);
    Axios({
      method: "get",
      url: "/team_manager/get_employees",
      params: {
        company_id: selectedCompany?.id,
      },
      headers: { Accept: "application/json" },
    })
      .then((res) => {
        setAvailableEmployees(res.data);
        setEmployeesActive(
          getAvailableEmployees(
            availableEmployees,
            teamToUpdateMembers?.employees
          )
        );

        fetchManagers();
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching employees:", error);
        setIsLoading(false);
      });
  };

  const handleClickOutside = (event) => {
    if (
      boxRef.current &&
      dropdownRef.current &&
      !boxRef.current.contains(event.target) &&
      !dropdownRef.current.contains(event.target)
    ) {
      setViewOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    fetchManagers();
    fetchEmployees();
  }, []);

  useEffect(() => {
    setEmployeesActive(
      getAvailableEmployees(availableEmployees, teamToUpdateMembers?.employees)
    );
  }, [availableEmployees, teamToUpdateMembers?.employees]);

  const filteredTeams = teams.filter((team) =>
    team.team_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Find the team name that has the selected team id
  const selectedTeamName = teams.find((team) => team.team_id === selectedTeam);


  return (
    <div>
      <div className="bg-white rounded-lg py-4">
        <div>
          <section className="flex justify-between items-center gap-4" >
            <div className="flex justify-between items-center w-1/2">

              <div
                className="flex justify-between items-center bg-gray-200 rounded-lg w-full text-sm px-2 py-1 relative"
                onClick={() => toggleView()}
                style={{ userSelect: "none" }}
                ref={boxRef}
              >
                <div className="text-gray-800 font-small">
                  {selectedTeam ? (
                    selectedTeamName.team_name
                  ) : (
                    <p className="flex justify-between text-gray-400 ml-1">
                      {teams?.length > 0
                        ? `Select a team from ${teams?.length} team(s)`
                        : "No teams available"}
                    </p>
                  )}
                </div>
                <FontAwesomeIcon icon={faPeopleGroup} className="text-gray-600" />
                {viewOptions && (
                  <div
                    ref={dropdownRef}
                    className="absolute top-full left-0 bg-white rounded-lg shadow-md border dark-gray-scrollbar border-gray-300 w-full overflow-y-auto z-40"
                    style={{ top: "calc(100%)", maxHeight: "200px", overflowY: "auto" }}
                  >
                    <div className="p-2">
                      <input
                        type="text"
                        placeholder="Search teams"
                        autoFocus
                        className="w-full border rounded-md px-2 py-1"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>

                    <div className="px-4 text-gray-500 font-semibold">
                      Employees with no teams: {availableEmployees?.length}
                    </div>
                    {teams &&
                      filteredTeams.map((team, index) => (
                        <div
                          key={index}
                          className="px-4 py-1 text-gray-600 font-normal cursor-pointer hover:bg-gray-100"
                          onClick={() => handleTeamChange(team.team_id)}
                        >
                          <h2 className="flex justify-between">
                            {team.team_name}{" "}
                            <span className="font-semibold">
                              ({team?.employees?.length})
                            </span>
                          </h2>
                        </div>
                      ))}
                  </div>
                )}
              </div>



            </div>
            <div className="flex  w-1/2 ">
              <input
                type="text"
                value={newTeamName}
                placeholder="Enter new team name"
                onChange={handleChangeTeamName}
                className="border text-sm py-1 rounded-l-lg w-full border-gray-400 text-gray-900 px-2"
              />
              <button
                className="px-3 py-1 cursor-pointer w-48 bg-purple-500 text-white text-sm align-center justify-center rounded-r-lg"
                onClick={handleUpdateTeamName}
              >
                Update Name
              </button>
            </div>
          </section>

          {/* Add other errors below - pass as props - you could set the error and success messages then pass the data as
          an array or object with message and title */}
          {showMessage && (
            <ResponseMessage title={responseTitle} message={responseMessage} />
          )}
          <div className="flex w-full gap-4">
            <div className="w-1/2">

              <EmployeeSelectDropdown
                setReloadTeams={setReloadTeams}
                reloadTeams={reloadTeams}
                availableEmployees={employeesActive}
                setSelectedEmployees={setSelectedEmployees}
                selectedEmployees={selectedEmployees}
                setIsLoading={setIsLoading}
                currentTeam={currentTeam}
              />

              <div
                className="w-full overflow-y-scroll dark-gray-scrollbar  mt-2 bg-gray-300 rounded-md"
                style={{
                  maxHeight: "250px",
                }}
              >
                {currentTeam?.employees
                  ?.filter(
                    (employee) =>
                      !currentTeam?.managers?.find(
                        ({ id }) => employee.id === id
                      )
                  )
                  .map((employee) => (
                    <div
                      key={employee.id}
                      className="border hover:border-purple-500 rounded-md  m-2 border-gray-200 bg-white flex justify-between"
                    >
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          className="p-2 m-2"
                          id={`employee-${employee.id}`}
                          checked={selectedTeamMembers.includes(employee.id)}
                          onChange={() => handleEmployeeChecked(employee.id)}
                        />
                        <label
                          className="text-left text-sm"
                          htmlFor={`employee-${employee.id}`}
                        >
                          {employee.name} {employee.surname}
                        </label>
                      </div>

                      <ConfirmationModal
                        handleYesClick={() =>
                          handleRemoveTeamMember(employee.id)
                        }
                      >
                        <p className="border rounded-r-md border-red-600 bg-red-600 hover:bg-red-400 py-1 px-2 m-0 ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 text-white "
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                            />
                          </svg>
                        </p>
                      </ConfirmationModal>
                    </div>
                  ))}
              </div>
            </div>

            {role !== "manager" && (
              <div className="w-1/2">
                <ManagerSelectDropdown
                  setReloadTeams={setReloadTeams}
                  reloadTeams={reloadTeams}
                  availableManagers={availableManagers}
                  setSelectedManagers={setSelectedManagers}
                  selectedManagers={selectedManagers}
                  currentTeam={currentTeam}
                />

                <div
                  className="w-full overflow-y-scroll dark-gray-scrollbar  mt-2 bg-gray-300 rounded-md"
                  style={{
                    maxHeight: "250px",
                  }}
                >
                  {availableManagers?.length > 0 &&
                    currentTeam?.managers.map((manager) => (
                      <div
                        key={manager.id}
                        className="border hover:border-purple-500 rounded-md  m-2 border-gray-200 bg-white flex justify-between"
                      >
                        <div className="flex items-center px-2">
                          <label
                            className="text-left text-sm"
                            htmlFor={`employee-${manager.id}`}
                          >
                            {manager.name} {manager.surname}
                          </label>
                        </div>

                        <div className="flex justify-between items-center gap-4">
                          <ConfirmationModal
                            handleYesClick={() => handleRemoveManager(manager)}
                          >
                            <p className="border rounded-r-md border-red-600 bg-red-600 hover:bg-red-400 py-1 px-2 m-0 ">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 text-white "
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                />
                              </svg>
                            </p>
                          </ConfirmationModal>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Manager + Employee MultiSelect */}

        <div className=" flex justify-start mt-4 gap-4">
          <button
            className="px-3 py-2 cursor-pointer bg-red-500 text-white text-sm align-center justify-center rounded-lg"
            onClick={openDeleteTeamModal}
          >
            Delete Team
          </button>

          {selectedTeamMembers.length > 0 && (
            <button
              className="px-3 py-2 cursor-pointer bg-red-500 text-white text-sm align-center justify-center rounded-lg"
              onClick={() => setIsRemoveMembersModalOpen(true)}
            >
              Remove Members
            </button>
          )}
        </div>

        {/* Modal for removing team members */}
        {isRemoveMembersModalOpen && (
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-50 z-10">
            <div className="bg-white rounded-lg p-4 shadow-lg" style={{ width: "350px", maxWidth: "350px", position: "relative" }}>
              <h2 className="text-gray-600" style={{ fontSize: "20px" }}>
                Remove team members
              </h2>
              <div className="text-gray-400" style={{ fontSize: "14px" }}>
                <p>Are you sure you want to remove these members?</p>
              </div>
              <div className="flex justify-end mt-4 gap-4">
                <button
                  className="px-3 py-2 cursor-pointer bg-red-500 text-white text-sm align-center justify-center rounded-lg"
                  onClick={handleRemoveMembers}
                >
                  Confirm Delete
                </button>
                <button
                  className="px-3 py-2 cursor-pointer bg-gray-300 text-white text-sm align-center justify-center rounded-lg"
                  onClick={() => setIsRemoveMembersModalOpen(false)} // Close the modal
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}


        {isDeleteTeamModalOpen && (
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-50 z-10">
            <div className="bg-white rounded-lg p-4 shadow-lg" style={{ width: "350px", maxWidth: "350px", position: "relative" }}>
              <h2 className="text-gray-600" style={{ fontSize: "20px" }}>
                Delete Team
              </h2>
              <div className="text-gray-400" style={{ fontSize: "14px" }}>
                Are you sure you want to delete this team?
              </div>
              <div className="flex justify-end mt-4 gap-4">
                <button
                  className="px-3 py-2 cursor-pointer bg-red-500 text-white text-sm align-center justify-center rounded-lg"
                  onClick={handleDeleteTeam}
                >
                  Confirm Delete
                </button>
                <button
                  className="px-3 py-2 cursor-pointer bg-gray-300 text-white text-sm align-center justify-center rounded-lg"
                  onClick={() => setIsDeleteTeamModalOpen(false)} // Close the modal
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>

        )}
        {isLoading && (
          <>
            <div className="absolute top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 z-50 flex items-center justify-center rounded-lg">
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8 my-1"></div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EditTeamDialogue;

const ManagerSelectDropdown = ({
  setReloadTeams,
  reloadTeams,
  availableManagers,
  setSelectedManagers,
  selectedManagers,
  currentTeam,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");

  const allManagers = availableManagers.filter(
    (elem) => !currentTeam?.managers.find(({ id }) => elem.id === id)
  );

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const addManagerToTeam = async (manager) => {
    try {
      const response = await Axios.post("/team_manager/add_manager", {
        team_id: currentTeam.team_id,
        manager_id: manager.id,
      });

      setReloadTeams(reloadTeams + 1);
      setIsOpen(!isOpen);
    } catch (error) {
      console.error("Error adding manager to the team:", error);
    }
  };

  // Filter managers based on the search query
  const filteredManagers = allManagers.filter(
    (item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.surname.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="relative mt-2" ref={dropdownRef}>
      <h3 className="font-small text-gray-600 py-2 text-sm">Select Managers:</h3>
      <button
        onClick={toggleDropdown}
        className="managerSelect border text-sm border-gray-300 flex justify-between rounded-md py-2 px-4 text-gray-700 bg-white w-full text-left focus:outline-none focus:border-blue-500"
      >
        {selectedManagers?.length === 0 && currentTeam?.managers?.length === 0
          ? "Select Managers"
          : ` ${allManagers?.length} Available Manager(s)`}

        {isOpen ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 15.75l7.5-7.5 7.5 7.5"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        )}
      </button>
      {isOpen && (
        <div
          className="absolute top-12 left-0 w-full border border-gray-300 bg-white mt-2 rounded-md shadow-lg z-30"
          style={{
            maxHeight: "300px",
            overflowY: "auto",
            scrollbarWidth: "thin",
          }}
        >
          <div className="p-2">
            <input
              type="text"
              placeholder="Search by name, surname, or email"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full h-10 border-b-2 border-gray-300 px-3 mb-2 text-sm focus:outline-none"
            />
          </div>
          {filteredManagers.length === 0 ? (
            <div>
              <p className="text-center text-gray-400 p-2 text-sm">
                No managers available
              </p>
            </div>
          ) : (
            <div
              style={{
                maxHeight: "200px",
                overflowY: "auto",
                scrollbarWidth: "thin",
              }}
            >
              {filteredManagers.map((item) => (
                <button
                  key={item.id}
                  onClick={() => addManagerToTeam(item)}
                  className="flex justify-start py-2 px-4 w-full hover:border-r-0 text-sm hover:bg-purple-500 hover:text-white hover:cursor-pointer"
                >
                  {item.name} {item.surname}
                </button>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const EmployeeSelectDropdown = ({
  setReloadTeams,
  reloadTeams,
  availableEmployees,
  setSelectedEmployees,
  selectedEmployees,
  currentTeam,
  setIsLoading,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);

  const filteredEmployees = availableEmployees.filter((employee) => {
    const { name, surname, email } = employee;
    const query = searchQuery.toLowerCase();
    return (
      name.toLowerCase().includes(query) ||
      surname.toLowerCase().includes(query) ||
      email.toLowerCase().includes(query)
    );
  });

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const addMemberToTeam = async (employee) => {
    setIsLoading(true);

    const requestData = {
      user_id_to_add: employee.id,
      team_id: currentTeam.team_id,
    };

    Axios.post(`/team_manager/add_member_to_team`, requestData)
      .then((response) => {
        setReloadTeams(reloadTeams + 1);
        setSelectedEmployees([]);
        setIsLoading(false);
        showToast("success", "Member added successfully");
      })
      .catch((error) => {
        console.error("Error adding team member:", error.response.data.error);
        showToast("error", "Error adding team member");
        setReloadTeams(reloadTeams + 1);
        setSelectedEmployees([]);
        setIsLoading(false);
      });
  };

  return (
    <div className="relative mt-2" ref={dropdownRef}>
      <h3 className="text-sm text-gray-600 py-2">Select Employees:</h3>
      <button
        onClick={toggleDropdown}
        className="employeeSelect border border-gray-300 text-sm flex justify-between rounded-md py-2 px-4 text-gray-700 bg-white w-full text-left focus:outline-none focus:border-blue-500"
      >
        {filteredEmployees.length === 0
          ? "No Employees to add"
          : `${filteredEmployees.length} Available Employee(s)`}
        {isOpen ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 15.75l7.5-7.5 7.5 7.5"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        )}
      </button>
      {isOpen && (
        <div
          className="absolute top-12 z-30 left-0 w-full border border-gray-300 bg-white mt-2 rounded-md shadow-lg"
          style={{ maxHeight: "300px", overflowY: "auto" }}
        >
          <div className="p-2">
            <input
              type="text"
              placeholder="Search by name, surname, or email"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full h-10 border-b-2 border-gray-300 px-3 mb-2 focus:outline-none"
            />
          </div>
          {filteredEmployees.length === 0 ? (
            <div>
              <p className="text-center text-gray-400 p-2">
                No Employees available
              </p>
            </div>
          ) : (
            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
              {filteredEmployees.map((item) => (
                <button
                  key={item.id}
                  onClick={() => addMemberToTeam(item)}
                  className="flex justify-start py-2 px-4 w-full hover:border-r-0 text-sm  hover:bg-purple-500 hover:text-white hover:cursor-pointer"
                >
                  {item.name} {item.surname}
                </button>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
