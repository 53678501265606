import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

const WEEKDAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];
const TIMESLOTS = [
  '00:00AM',
  '00:30AM', 
  '01:00AM',
  '01:30AM',
  '02:00AM',
  '02:30AM',
  '03:00AM',
  '03:30AM',
  '04:00AM',
  '04:30AM',
  '05:00AM',
  '05:30AM',
  '06:00AM',
  '06:30AM',
  '07:00AM',
  '07:30AM',
  '08:00AM',
  '08:30AM',
  '09:00AM',
  '09:30AM',
  '10:00AM',
  '10:30AM',
  '11:00AM',
  '11:30AM',
  '12:00PM',
  '12:30PM',
  '13:00PM',
  '13:30PM',
  '14:00PM',
  '14:30PM',
  '15:00PM',
  '15:30PM',
  '16:00PM',
  '16:30PM',
  '17:00PM',
  '17:30PM',
  '18:00PM',
  '18:30PM',
  '19:00PM',
  '19:30PM',
  '20:00PM',
  '20:30PM',
  '21:00PM',
  '21:30PM',
  '22:00PM',
  '22:30PM',
  '23:00PM',
  '23:30PM',
];

/**
 *
 * @param {string} view The current view (day / week)
 * @param {array} summaryAdhocData The weekly summary data
 * @param {array} summaryDailyData The daily summary data
 * @param {string} selectedDay The selected day for the date selection component (summary view)
 * @param {string} selectedWeek The selected week for the date selection component(summary view)
 * @returns {JSX.Element} The bookings card at the top of the summary view, shows the bookings for this week / day
 */
const AdhocCard = ({
  view,
  viewAdhoc,
  summaryAdhocData,
  summaryDailyData,
  selectedDay,
  selectedWeek,
}) => {
  const [weeklyRecords, setWeeklyRecords] = useState([]);
  const [dailyRecords, setDailyRecords] = useState([]);
  const [filteredDailyRecords, setFilteredDailyBookings] = useState([]);

  const expandWeekday = (weekday) => {
    setWeeklyRecords((prevState) => {
      return prevState.map((item, index) => {
        if (item.weekday === weekday) {
          return { ...item, expanded: !item.expanded };
        }
        return item;
      });
    });
  };

  const expandDaily = (timeslot) => {
    setDailyRecords((prevState) => {
      return prevState.map((item, index) => {
        if (item.timeslot === timeslot) {
          return { ...item, expanded: !item.expanded };
        }
        return item;
      });
    });
  };

  //This should always run before any other useEffect, here we map the structure of the weekly and daily records so that we can access multiple things when looping through them
  useEffect(() => {
    let initialWeeklyRecords = [];
    initialWeeklyRecords = WEEKDAYS.map((weekday) => ({
      weekday: weekday, //The day of the week that bookings are grouped by
      current_count_total: 0, //The total number of bookings for this week
      current_count_in: 0, //The total number of inbound bookings for this week
      current_count_out: 0, //The total number of outbound bookings for this week
      prev_count_total: 0, //The total number of bookings for last week
      prev_count_in: 0, //The total number of inbound bookings for last week
      prev_count_out: 0, //The total number of outbound bookings for last week
      expanded: false, //Expanding the weekday to show more information
    }));
    setWeeklyRecords(initialWeeklyRecords);
    let initialDailyRecords = [];
    initialDailyRecords = TIMESLOTS.map((timeslot) => ({
      timeslot: timeslot, //Timeslots that bookings are grouped by
      current_count_total: 0,
      current_count_in: 0,
      current_count_out: 0,
      prev_count_total: 0,
      prev_count_in: 0,
      prev_count_out: 0,
      expanded: false,
    }));
    setDailyRecords(initialDailyRecords);
  }, []);

  //Use effect to map the backend data to the frontend structure after retrieving new data for weekly view
  useEffect(() => {
    let initialWeeklyRecords = [];
    initialWeeklyRecords = WEEKDAYS.map((weekday) => ({
      weekday: weekday,
      current_count_total: 0,
      current_count_in: 0,
      current_count_out: 0,
      prev_count_total: 0,
      prev_count_in: 0,
      prev_count_out: 0,
      expanded: false,
    }));
    setWeeklyRecords(initialWeeklyRecords);
    if (summaryAdhocData && view === 'ad-hoc') {
      const currentBookings = summaryAdhocData['this_week'];
      const previousBookings = summaryAdhocData['past_week'];

      weeklyRecords.forEach((record, index) => {
        const currentBooking = currentBookings[record.weekday];
        if (currentBooking) {
          const inboundTrips = currentBooking['inbound'];
          const outboundTrips = currentBooking['outbound'];
          let inboundCount = inboundTrips ? inboundTrips.length : 0;
          let outboundCount = outboundTrips ? outboundTrips.length : 0;
          setWeeklyRecords((prevState) => {
            return prevState.map((item, index) => {
              if (item.weekday === record.weekday) {
                return {
                  ...item,
                  current_count_in: inboundCount,
                  current_count_out: outboundCount,
                  current_count_total: inboundCount + outboundCount,
                };
              }
              return item;
            });
          });
        }
        const previousBooking = previousBookings[record.weekday];
        if (previousBooking) {
          const inboundTrips = previousBooking['inbound'];
          const outboundTrips = previousBooking['outbound'];
          let inboundCount = inboundTrips ? inboundTrips.length : 0;
          let outboundCount = outboundTrips ? outboundTrips.length : 0;
          setWeeklyRecords((prevState) => {
            return prevState.map((item, index) => {
              if (item.weekday === record.weekday) {
                return {
                  ...item,
                  prev_count_in: inboundCount,
                  prev_count_out: outboundCount,
                  prev_count_total: inboundCount + outboundCount,
                };
              }
              return item;
            });
          });
        }
      });
    }
  }, [summaryAdhocData, selectedWeek]);

  //Use effect to map the backend data to the frontend structure after retrieving new data for daily viw
  useEffect(() => {
    let initialDailyRecords = [];
    initialDailyRecords = TIMESLOTS.map((timeslot) => ({
      timeslot: timeslot,
      current_count_total: 0,
      current_count_in: 0,
      current_count_out: 0,
      prev_count_total: 0,
      prev_count_in: 0,
      prev_count_out: 0,
      expanded: false,
    }));
    setDailyRecords(initialDailyRecords);
    if (summaryDailyData && view === 'day') {
      const currentBookings = summaryDailyData['this_week'];
      const previousBookings = summaryDailyData['past_week'];

      if (currentBookings) {
        const selectedDayBookings = currentBookings[selectedDay];
        if (selectedDayBookings) {
          dailyRecords.forEach((record, index) => {
            const currentBooking = selectedDayBookings[record.timeslot];
            if (currentBooking) {
              const inboundTrips = currentBooking['inbound'];
              const outboundTrips = currentBooking['outbound'];
              let inboundCount = inboundTrips ? inboundTrips.length : 0;
              let outboundCount = outboundTrips ? outboundTrips.length : 0;
              setDailyRecords((prevState) => {
                return prevState.map((item, index) => {
                  if (item.timeslot === record.timeslot) {
                    return {
                      ...item,
                      current_count_in: inboundCount,
                      current_count_out: outboundCount,
                      current_count_total: inboundCount + outboundCount,
                    };
                  }
                  return item;
                });
              });
            } else {
              setDailyRecords((prevState) => {
                return prevState.map((item, index) => {
                  if (item.timeslot === record.timeslot) {
                    return {
                      ...item,
                      current_count_in: 0,
                      current_count_out: 0,
                      current_count_total: 0,
                    };
                  }
                  return item;
                });
              });
            }
          });
        }
      }
      if (previousBookings) {
        //use moment.js to get selectedDay (date) minus 7 days:
        let lastWeekSelectedDay = moment(selectedDay, 'DD-MM-YYYY');
        lastWeekSelectedDay = lastWeekSelectedDay.subtract(7, 'days');
        const selectedDayBookings =
          previousBookings[lastWeekSelectedDay.format('DD-MM-YYYY')];
        if (selectedDayBookings) {
          dailyRecords.forEach((record, index) => {
            const previousBooking = selectedDayBookings[record.timeslot];
            if (previousBooking) {
              const inboundTrips = previousBooking['inbound'];
              const outboundTrips = previousBooking['outbound'];
              let inboundCount = inboundTrips ? inboundTrips.length : 0;
              let outboundCount = outboundTrips ? outboundTrips.length : 0;
              setDailyRecords((prevState) => {
                return prevState.map((item, index) => {
                  if (item.timeslot === record.timeslot) {
                    return {
                      ...item,
                      prev_count_in: inboundCount,
                      prev_count_out: outboundCount,
                      prev_count_total: inboundCount + outboundCount,
                    };
                  }
                  return item;
                });
              });
            } else {
              setDailyRecords((prevState) => {
                return prevState.map((item, index) => {
                  if (item.timeslot === record.timeslot) {
                    return {
                      ...item,
                      prev_count_in: 0,
                      prev_count_out: 0,
                      prev_count_total: 0,
                    };
                  }
                  return item;
                });
              });
            }
          });
        }
      }
    }
  }, [summaryDailyData, selectedDay]);

  //Here we filter out any timeslots that didn't have bookings to keep the card small
  useEffect(() => {
    const filteredRecords = [];
    if (dailyRecords && selectedDay) {
      dailyRecords.forEach((record, index) => {
        if (record.current_count_total > 0 || record.prev_count_total > 0) {
          filteredRecords.push(record);
        }
      });
      setFilteredDailyBookings(filteredRecords);
    }
  }, [dailyRecords]);

  //Method for rendering weekly view
  const renderWeeklyBookingBlocks = () => {
    return weeklyRecords.map((record, index) => (
      <div
        key={index}
        className="bg-white rounded p-1 py-2 flex flex-row justify-between "
        style={{
          userSelect: 'none',
        }}>
        <div className="flex flex-col w-full ">
          <div className=" w-full text-xs  text-gray-600 flex justify-between gap-1">
            <div className="text-center w-1/5">
              <div className="bg-gray-200 h-full py-1 px-2 my-1 rounded-md">
                <h2>{record.weekday.slice(0, 3)}</h2>

                <p>
                  {moment()
                    .isoWeekday(WEEKDAYS.indexOf(record.weekday) + 1)
                    .format('D')}
                </p>
              </div>
            </div>

            <div className="bg-gray-200 text-center w-2/5 h-full py-1 px-1 m-1 rounded-md">
              <h2>This week</h2>
              <h2>{record.current_count_total}</h2>
              {record.expanded && (
                <div className="border-t border-black pb-1">
                  <h3>Inbound</h3>
                  <p>{record.current_count_in}</p>
                  <h3>Outbound</h3>
                  <p>{record.current_count_out}</p>
                </div>
              )}
            </div>

            <div className="bg-gray-200 text-center w-2/5 h-full py-1 px-1 m-1 rounded-md mr-2">
              <h2>Last week</h2>
              <h2>{record.prev_count_total}</h2>
              {record.expanded && (
                <div className="border-t border-black pb-1">
                  <h3>Inbound</h3>
                  <p>{record.prev_count_in}</p>
                  <h3>Outbound</h3>
                  <p>{record.current_count_out}</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <button
          onClick={() => expandWeekday(record.weekday)}
          className="py-4 px-2 bg-purple-500 rounded-lg flex justify-center items-center">
          <FontAwesomeIcon
            icon={record.expanded ? faMinusCircle : faPlusCircle}
            size={'xs'}
            className="text-white my-1"
          />
        </button>
      </div>
    ));
  };

  //Method for rendering daily view
  const renderDailyBookingBlocks = () => {
    return filteredDailyRecords.map((record, index) => (
      <div
        key={index}
        className="bg-white rounded px-2 flex flex-row mt-1 justify-between"
        style={{
          paddingTop: '0.1rem',
          paddingBottom: '0.1rem',
          userSelect: 'none',
        }}>
        <div className="flex flex-col">
          <div className=" w-full text-xs text-gray-600 flex justify-between">
            <span>
              <span className="font-bold">{record.timeslot}</span> | This week:{' '}
              {record.current_count_total}{' '}
              <span className="text-gray-400">
                {' '}
                Last week: {record.prev_count_total}
              </span>
            </span>
          </div>
          {record.expanded && (
            <>
              <div className="w-full text-xs text-gray-600 mt-1 flex justify-between">
                <span className="font-semibold mr-1">Inbound</span>{' '}
                <span>
                  {' '}
                  This week: {record.current_count_in}{' '}
                  <span className="text-gray-400">
                    {' '}
                    Last week: {record.prev_count_in}
                  </span>{' '}
                </span>
              </div>
              <div className="w-full text-xs text-gray-600 mt-1  flex justify-between">
                <span className="font-semibold mr-1">Outbound</span>{' '}
                <span>
                  {' '}
                  This week: {record.current_count_out}{' '}
                  <span className="text-gray-400">
                    {' '}
                    Last week: {record.prev_count_out}
                  </span>
                </span>
              </div>
            </>
          )}
        </div>
        <FontAwesomeIcon
          icon={record.expanded ? faMinusCircle : faPlusCircle}
          size={'xs'}
          className="text-gray-500 my-1"
          onClick={() => expandDaily(record.timeslot)}
        />
      </div>
    ));
  };

  return (
    <>
      {viewAdhoc === true && (
        <div className="bg-gray-200 rounded-lg w-full py-1 mt-4 px-2">
          <h1 className="font-small text-gray-600 px-2">Adhocs</h1>
          <div className="w-full mb-2">{renderDailyBookingBlocks()}</div>
          <div className="w-full mb-2">{renderWeeklyBookingBlocks()}</div>
        </div>
      )}
    </>
  );
};

export default AdhocCard;
