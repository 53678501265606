/**
 * @module DropdownMenu Component
 * @description
 * Utilised by `Dropdown` to display a menu.
 */
import React from 'react';
import {
    arrayOf,
    shape,
    func,
    string,
    bool,
} from 'prop-types';
import './dropdownMenu.scss';
import {getClassNames} from '../../tools/helpers';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

/**
 * Renders each menu item. Uses a button instead of links for more general use.
 *  (links have to contain hrefs).
 *
 * @param {Object[]} menuItems - list of {label:, onClick:} pairs
 */
const renderMenuItems = (menuItems) => menuItems.map(({label, onClick, selected, icon}, index) => {
    const itemClassNames = getClassNames('dropdown-menu__item', {selected});

    return (
        <li key={index} className={itemClassNames}>
            {icon &&
            <div className="dropdown-menu__item-icon">
                <FontAwesomeIcon icon={icon}/>
            </div>
            }
            <div className="dropdown-menu__item-content">
                <button type="button" onClick={onClick}>{label}</button>
            </div>
        </li>
    );
});


const DropdownMenu = ({menuItems, minimal, select}) => (
    <ul className={getClassNames('dropdown-menu', {select, minimal})}>
        {renderMenuItems(menuItems)}
    </ul>
);

DropdownMenu.defaultProps = {
    minimal: false,
    select: false,
};

DropdownMenu.propTypes = {
    menuItems: arrayOf(shape({
        label: string.isRequired,
        onClick: func,
    })).isRequired,
    minimal: bool,
    select: bool,
};

export default DropdownMenu;
