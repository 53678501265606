import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {  faClock } from '@fortawesome/free-solid-svg-icons'
import TimePickerDropDown from "./TimePickerDropDown";
import { getFormattedTimeSlot24 } from '../../../tools/date.util';

const TimePicker = ({timeSlot, setTimeSlot, trips}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => setIsOpen(true);
    const handleCloseModal = () => setIsOpen(false);


    const toggleDropdown = () => {
      if(isOpen){
        handleCloseModal()
      }else{
        handleOpenModal()  
      }
    }
    return (
      <>
        <div className="bg-gray-200 rounded-lg flex-1 px-2 py-1 flex items-center justify-between">
          <div className="bg-gray-200 text-gray-600 font-medium inline-block">{timeSlot ? getFormattedTimeSlot24(timeSlot) : "Time"}</div>
          <FontAwesomeIcon
            data-testid={'clock-icon'}
            icon={faClock}
            className="text-gray-600 cursor-pointer"
            onClick={toggleDropdown}
          />
        </div>
        {isOpen && (
          <div className="mt-1" style={{ position: 'absolute', top: 'calc(100%-1.5rem)', left: "216px", zIndex: 20 }}>
            <TimePickerDropDown
              handleCloseModal = {handleCloseModal} 
              setTimeSlot = {setTimeSlot}
              timeSlot = {timeSlot}
              trips={trips}
            />
            </div>
        
        )}
    </>
    );
};

export default TimePicker;