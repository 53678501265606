import React, { useState, useEffect, useRef } from 'react';
import TripMap from './groupedTripsComponents/tripBookingMap/TripMap';
import DateColumn from './groupedTripsComponents/DateColumn';
import TimeslotColumn from './groupedTripsComponents/TimeslotColumn';
import { isEmpty } from '../../tools/helpers';
import Axios from 'axios';
import Assign from './groupedTripsComponents/Assign';
import Bookings from './groupedTripsComponents/Bookings';
import { getFormattedDate } from '../../tools/date.util';

const MONTH_NAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const TIME_SLOTS = [
  '00:00AM',
  '00:30AM', 
  '01:00AM',
  '01:30AM',
  '02:00AM',
  '02:30AM',
  '03:00AM',
  '03:30AM',
  '04:00AM',
  '04:30AM',
  '05:00AM',
  '05:30AM',
  '06:00AM',
  '06:30AM',
  '07:00AM',
  '07:30AM',
  '08:00AM',
  '08:30AM',
  '09:00AM',
  '09:30AM',
  '10:00AM',
  '10:30AM',
  '11:00AM',
  '11:30AM',
  '12:00PM',
  '12:30PM',
  '13:00PM',
  '13:30PM',
  '14:00PM',
  '14:30PM',
  '15:00PM',
  '15:30PM',
  '16:00PM',
  '16:30PM',
  '17:00PM',
  '17:30PM',
  '18:00PM',
  '18:30PM',
  '19:00PM',
  '19:30PM',
  '20:00PM',
  '20:30PM',
  '21:00PM',
  '21:30PM',
  '22:00PM',
  '22:30PM',
  '23:00PM',
  '23:30PM',
];

const getDays = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};

const renderComapanyOptions = (companies) =>
  !isEmpty(companies) &&
  companies.map((company) => (
    <option value={company.id} key={company.id}>
      {company.name || ''}
    </option>
  ));

const renderFilterSelect = (filter, handleFilterClick, setFilters) => {
  const FILTERS = setFilters();
  return FILTERS.map((item) => (
    <h3
      key={item}
      className={
        (filter === 'ungrouped' && item === 'Bookings') ||
        (filter === 'unassigned' && item === 'Map') ||
        (filter === 'assigned' && item === 'Groupings')
          ? 'self-center text-xs font-medium mr-3 cursor-pointer text-gray-800 border-b-2 border-gray-800'
          : 'self-center text-xs font-medium mr-3 cursor-pointer text-gray-700'
      }
      onClick={handleFilterClick}>
      {item}
    </h3>
  ));
};

const renderSwitchGroupSection = (
  filter,
  inboundTrips,
  outboundTrips,
  handleMarkerClick,
  handleGroupMarkerClick,
  selectedGroup,
  selectedTrips,
  inboundGroupedTrips,
  outboundGroupedTrips,
  handleCreateGroup,
  handleReGroup,
  bound,
  fullDate,
  selectedTimeslot,
  selectedCompany,
  dateAll,
  allSlot,
  handleUngroup,
  selectedAddress,
  trained
) => {
  switch (filter) {
    case 'ungrouped':
      return (
        <Bookings
          fullDate={fullDate}
          selectedTimeslot={selectedTimeslot}
          selectedCompany={selectedCompany}
          filter={filter}
          dateAll={dateAll}
          allSlot={allSlot}
          trained={trained}
        />
      );
    case 'unassigned':
      return (
        <TripMap
          trips={bound === 'Inbound' ? inboundTrips : outboundTrips}
          handleMarkerClick={handleMarkerClick}
          handleGroupMarkerClick={handleGroupMarkerClick}
          selectedGroup={selectedGroup}
          selected={selectedTrips}
          groupedTrips={
            bound === 'Inbound' ? inboundGroupedTrips : outboundGroupedTrips
          }
          handleCreateGroup={handleCreateGroup}
          handleReGroup={handleReGroup}
          bound={bound}
          handleUngroup={handleUngroup}
          selectedAddress={selectedAddress}
          trained={trained}
        />
      );
    case 'assigned':
      return (
        <Assign
          fullDate={fullDate}
          selectedTimeslot={selectedTimeslot}
          selectedCompany={selectedCompany}
          filter={filter}
          dateAll={dateAll}
          allSlot={allSlot}
          trained={trained}
        />
      );
    default:
      return <Bookings />;
  }
};

const renderCompanyAddresses = (companyAddresses, allTrips, allGroups) => {
  if (!isEmpty(companyAddresses)) {
    return companyAddresses.map((address, index) => {
      let inboundCount = 0;
      let outboundCount = 0;
      let inboundGroupCount = 0;
      let outboundGroupCount = 0;
      if (
        allTrips.hasOwnProperty(address) &&
        !isEmpty(allTrips[address].inbound)
      ) {
        inboundCount = allTrips[address].inbound.length;
      }
      if (
        allTrips.hasOwnProperty(address) &&
        !isEmpty(allTrips[address].outbound)
      ) {
        outboundCount = allTrips[address].outbound.length;
      }
      if (
        allGroups.hasOwnProperty(address) &&
        !isEmpty(Object.keys(allGroups[address].inbound))
      ) {
        inboundGroupCount = Object.keys(allGroups[address].inbound).length;
      }
      if (
        allGroups.hasOwnProperty(address) &&
        !isEmpty(Object.keys(allGroups[address].outbound))
      ) {
        outboundGroupCount = Object.keys(allGroups[address].outbound).length;
      }
      if (
        inboundCount !== 0 ||
        outboundCount !== 0 ||
        inboundGroupCount !== 0 ||
        outboundGroupCount !== 0
      ) {
        return (
          <option value={address} key={index}>
            {`(${inboundCount + outboundCount}) ${address}`}
          </option>
        );
      }
    });
  }
};

const GroupedTrips = ({ role, trained }) => {
  const [userRole, setUserRole] = useState(role);
  const [filter, setFilter] = useState(() => {
    if (role === 'admin' || role === 'logistics_specialist') {
      return 'ungrouped';
    } else {
      return 'assigned';
    }
  });
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('All Companies');
  const [allTrips, setAllTrips] = useState([]);
  const [inboundTrips, setInboundTrips] = useState([]);
  const [outboundTrips, setOutboundTrips] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [inboundGroupedTrips, setInboundGroupedTrips] = useState([]);
  const [outboundGroupedTrips, setOutboundGroupedTrips] = useState([]);
  const [companyAddresses, setCompanyAddresses] = useState([]);
  const [dateAll, setDateAll] = useState(true);
  const [allSlot, setAllSlot] = useState(true);
  const selectedCompanyRef = useRef();

  // set filters
  const setFilters = () => {
    if (userRole === 'admin' || userRole === 'logistics_specialist') {
      return ['Bookings', 'Map', 'Groupings'];
    } else {
      return [''];
    }
  };

  // inbound/outbound

  const [bound, setBound] = useState('Inbound');

  // For Timeslot Column
  const [selectedTimeslot, setSelectedTimeslot] = useState(TIME_SLOTS[0]);

  const handleTimeslotSelect = (timeslot) => {
    setAllSlot(false);
    setSelectedTimeslot(timeslot);
  };

  // For Date Column
  let today = new Date();
  const [selectedMonth, setSelectedMonth] = useState(
    MONTH_NAMES[today.getMonth()]
  );
  const [selectedYear, setSelectedYear] = useState(today.getFullYear());
  const [days, setDays] = useState(
    getDays(today.getFullYear(), today.getMonth())
  );
  const [fullDate, setFullDate] = useState(today);

  const handleMonthDecrease = () => {
    let index = MONTH_NAMES.indexOf(selectedMonth);
    index--;
    if (index < 0) {
      index = 11;
      setSelectedMonth(MONTH_NAMES[index]);
      setSelectedYear(selectedYear - 1);
      setDays(getDays(selectedYear, index));
      setFullDate(new Date(`1 ${selectedMonth} ${selectedYear}`));
    } else {
      setSelectedMonth(MONTH_NAMES[index]);
      setDays(getDays(selectedYear, index));
      setFullDate(new Date(`1 ${selectedMonth} ${selectedYear}`));
    }
  };

  const handleMonthIncrease = () => {
    let index = MONTH_NAMES.indexOf(selectedMonth);
    index++;
    if (index > 11) {
      index = 0;
      setSelectedMonth(MONTH_NAMES[index]);
      setSelectedYear(selectedYear + 1);
      setDays(getDays(selectedYear, index));
      setFullDate(new Date(`1 ${selectedMonth} ${selectedYear}`));
    } else {
      setSelectedMonth(MONTH_NAMES[index]);
      setDays(getDays(selectedYear, index));
      setFullDate(new Date(`1 ${selectedMonth} ${selectedYear}`));
    }
  };

  const handleDateFilter = (day) => {
    setDateAll(false);
    setFullDate(new Date(`${day} ${selectedMonth} ${selectedYear}`));
  };

  const handleFilterClick = (e) => {
    const selectedFilter = e.target.innerHTML;

    e.target.classList.add('text-gray-800', 'border-b-2', 'border-gray-800');
    if (selectedFilter === 'Bookings') {
      setFilter('ungrouped');
    } else if (selectedFilter === 'Map') {
      setFilter('unassigned');
    } else {
      setFilter('assigned');
    }
  };

  // For TripMap

  const [selectedTrips, setSelectedTrips] = useState([]);

  const handleMarkerClick = (_e, item) => {
    const index = selectedTrips.findIndex(
      (loc) => loc.full_name === item.full_name
    );
    if (index === -1) {
      setSelectedTrips([...selectedTrips, item]);
    } else {
      setSelectedTrips(
        selectedTrips.filter((loc) => loc.full_name !== item.full_name)
      );
    }
  };

  // get companies on load
  useEffect(() => {
    Axios({
      method: 'get',
      url: '/active_companies',
      headers: { Accept: 'application/json' },
    }).then((res) => {
      setCompanies(res.data.companies);
      let demoCompany = res.data.companies.filter(
        (company) => company.name == 'Demo Company'
      )[0];
      if ((trained == null || trained == false) && demoCompany != null) {
        selectedCompanyRef.current.value = demoCompany.id;
        setSelectedCompany(demoCompany.id);
      }
    });
  }, []);

  // handle selected company and addresses
  const [selectedAddress, setSelectedAddress] = useState('None');

  const prevAddresses = useRef(companyAddresses);

  useEffect(() => {
    const tripAdd = Object.keys(allTrips);
    const groupAdd = Object.keys(allGroups);
    const totalAdd = [...tripAdd, ...groupAdd];
    const unique = totalAdd.filter((item, i, ar) => ar.indexOf(item) === i);
    if (
      isEmpty(companyAddresses) ||
      prevAddresses.current.length < unique.length
    ) {
      setCompanyAddresses(unique);
      prevAddresses.current = unique;
    } else {
      setCompanyAddresses(prevAddresses.current);
    }
  }, [allTrips, allGroups]);

  const handleAddressChange = (e) => {
    const address = e.target.value;
    if (address === 'None') {
      setSelectedTrips([]);
      setSelectedGroup([]);
      setInboundTrips([]);
      setOutboundTrips([]);
      setInboundGroupedTrips([]);
      setInboundGroupedTrips([]);
      setSelectedAddress(address);
      setBound('Inbound');
      return;
    } else {
      setSelectedAddress(address);
      if (allTrips.hasOwnProperty(address)) {
        setInboundTrips(allTrips[address].inbound);
        setOutboundTrips(allTrips[address].outbound);
      } else {
        setInboundTrips([]);
        setOutboundTrips([]);
      }
      if (allGroups.hasOwnProperty(address)) {
        setInboundGroupedTrips(allGroups[address].inbound);
        setOutboundGroupedTrips(allGroups[address].outbound);
      } else {
        setInboundGroupedTrips([]);
        setOutboundGroupedTrips([]);
      }
    }
  };

  const handleCompanySelect = (e) => {
    const companyID = e.target.value;
    setSelectedCompany(companyID);
  };

  // get Grouped Trips

  const [selectedGroup, setSelectedGroup] = useState([]);

  useEffect(() => {
    if (
      fullDate &&
      selectedTimeslot &&
      selectedCompany !== 'All Companies' &&
      filter
    ) {
      let url = '/grouped_trips/grouped_trips_bounded';
      url += `?date=${getFormattedDate(fullDate)}`;
      url += `&timeslot=${selectedTimeslot}`;
      url += `&company=${selectedCompany}`;
      url += '&filter=unassigned';
      url += `&all=false`;
      Axios({
        method: 'get',
        url: url,
        headers: { Accept: 'application/json' },
      })
        .then((res) => {
          const groupedTrips = res.data.grouped_with_trips_bounded;
          for (const [add, bounds] of Object.entries(groupedTrips)) {
            for (const [bound, ids] of Object.entries(bounds)) {
              for (const [id, trips] of Object.entries(ids))
                if (!isEmpty(trips)) {
                  trips.forEach((trip) => {
                    trip.location_latitude += Math.random() / 2500;
                    trip.location_longitude += Math.random() / 2500;
                    trip.destination_latitude += Math.random() / 2500;
                    trip.destination_longitude += Math.random() / 2500;
                  });
                }
            }
          }
          setAllGroups(groupedTrips);
        })
        .catch((err) => console.log(err));
    }
  }, [
    inboundGroupedTrips,
    outboundGroupedTrips,
    fullDate,
    selectedTimeslot,
    selectedCompany,
    filter,
  ]);

  const handleGroupMarkerClick = (_e, trip, _index) => {
    if (
      !isEmpty(selectedGroup) &&
      trip.grouped_trip_id === selectedGroup[0].grouped_trip_id
    ) {
      setSelectedGroup([]);
      return;
    }
    const group_id = trip.grouped_trip_id;
    if (bound === 'Inbound') {
      setSelectedGroup(inboundGroupedTrips[group_id]);
    } else if (bound == 'Outbound') {
      setSelectedGroup(outboundGroupedTrips[group_id]);
    }
  };

  // get trips
  useEffect(() => {
    if (
      fullDate &&
      selectedTimeslot &&
      selectedCompany !== 'All Companies' &&
      filter === 'unassigned'
    ) {
      let url = '/grouped_trips/ungrouped_trips';
      url += `?date=${getFormattedDate(fullDate)}`;
      url += `&timeslot=${selectedTimeslot}`;
      url += `&company=${selectedCompany}`;
      url += `&all=${dateAll}`;
      Axios({
        method: 'get',
        url: url,
        headers: { Accept: 'application/json' },
      })
        .then((res) => {
          const trips = res.data.trips_bounded;
          for (const [add, bounds] of Object.entries(trips)) {
            for (const [bound, trips] of Object.entries(bounds)) {
              if (!isEmpty(trips)) {
                trips.forEach((trip) => {
                  trip.location_latitude += Math.random() / 2500;
                  trip.location_longitude += Math.random() / 2500;
                  trip.destination_latitude += Math.random() / 2500;
                  trip.destination_longitude += Math.random() / 2500;
                });
              }
            }
          }
          setAllTrips(trips);
        })
        .catch((err) => console.log(err));
    }
  }, [
    inboundGroupedTrips,
    outboundGroupedTrips,
    fullDate,
    selectedTimeslot,
    selectedCompany,
    filter,
    dateAll,
  ]);

  // Post request to create action of grouped_trips controller
  const handleCreateGroup = () => {
    if (bound === 'Inbound') {
      const newInboundTrips = [].concat(
        inboundTrips.filter((obj1) =>
          selectedTrips.every((obj2) => obj1.id !== obj2.id)
        ),
        selectedTrips.filter((obj2) =>
          inboundTrips.every((obj1) => obj2.id !== obj1.id)
        )
      );
      setInboundTrips(newInboundTrips);
    } else if (bound === 'Outbound') {
      const newOutboundTrips = [].concat(
        outboundTrips.filter((obj1) =>
          selectedTrips.every((obj2) => obj1.id !== obj2.id)
        ),
        selectedTrips.filter((obj2) =>
          outboundTrips.every((obj1) => obj2.id !== obj1.id)
        )
      );
      setOutboundTrips(newOutboundTrips);
    }
    setSelectedTrips([]);
    const data = {
      trips: selectedTrips,
      timeslot: selectedTimeslot,
      company: selectedCompany,
      date: getFormattedDate(fullDate),
    };
    Axios({
      method: 'post',
      url: '/grouped_trips',
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        const data = res.data.trips;
        const group_id = data[0].grouped_trip_id;
        if (bound === 'Inbound') {
          data.forEach((trip) => {
            trip.location_latitude += Math.random() / 2500;
            trip.location_longitude += Math.random() / 2500;
          });
          let newInboundGroupedTrips = Object.assign({}, inboundGroupedTrips);
          newInboundGroupedTrips[group_id] = data;
          setInboundGroupedTrips(newInboundGroupedTrips);
          setSelectedGroup(newInboundGroupedTrips[group_id]);
        } else if (bound === 'Outbound') {
          data.forEach((trip) => {
            trip.destination_latitude += Math.random() / 2500;
            trip.destination_longitude += Math.random() / 2500;
          });
          let newOutboundGroupedTrips = Object.assign({}, outboundGroupedTrips);
          newOutboundGroupedTrips[group_id] = data;
          setOutboundGroupedTrips(newOutboundGroupedTrips);
          setSelectedGroup(newOutboundGroupedTrips[group_id]);
        }
      })
      .catch((err) => console.log(err));
  };

  // Patch request to update action of grouped_trips controller for re-grouping

  const handleReGroup = () => {
    if (bound === 'Inbound') {
      const newInboundTrips = [].concat(
        inboundTrips.filter((obj1) =>
          selectedTrips.every((obj2) => obj1.id !== obj2.id)
        ),
        selectedTrips.filter((obj2) =>
          inboundTrips.every((obj1) => obj2.id !== obj1.id)
        )
      );
      setInboundTrips(newInboundTrips);
    } else if (bound === 'Outbound') {
      const newOutboundTrips = [].concat(
        outboundTrips.filter((obj1) =>
          selectedTrips.every((obj2) => obj1.id !== obj2.id)
        ),
        selectedTrips.filter((obj2) =>
          outboundTrips.every((obj1) => obj2.id !== obj1.id)
        )
      );
      setOutboundTrips(newOutboundTrips);
    }
    setSelectedTrips([]);
    const group_id = selectedGroup[0].grouped_trip_id;
    const data = {
      trips: selectedTrips,
      group_id: group_id,
    };
    Axios({
      method: 'patch',
      url: `/grouped_trips/${group_id}?context=re_grouping`,
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        const data = res.data.trips;
        if (bound === 'Inbound') {
          data.forEach((trip) => {
            trip.location_latitude += Math.random() / 2500;
            trip.location_longitude += Math.random() / 2500;
          });
          let newInboundGroupedTrips = Object.assign({}, inboundGroupedTrips);
          newInboundGroupedTrips[group_id] = data;
          setInboundGroupedTrips(newInboundGroupedTrips);
          setSelectedGroup(newInboundGroupedTrips[group_id]);
        } else if (bound === 'Outbound') {
          data.forEach((trip) => {
            trip.destination_latitude += Math.random() / 2500;
            trip.destination_longitude += Math.random() / 2500;
          });
          let newOutboundGroupedTrips = Object.assign({}, outboundGroupedTrips);
          newOutboundGroupedTrips[group_id] = data;
          setOutboundGroupedTrips(newOutboundGroupedTrips);
          setSelectedGroup(newOutboundGroupedTrips[group_id]);
        }
      })
      .catch((err) => console.log(err));
  };

  // Patch request to update action of grouped_trips controller for un-grouping of trips

  const handleUngroup = (e, loc) => {
    if (bound === 'Inbound') {
      const newInboundTrips = inboundTrips;
      newInboundTrips.push(loc);
      setInboundTrips(newInboundTrips);
    } else if (bound === 'Outbound') {
      const newOutboundTrips = outboundTrips;
      newOutboundTrips.push(loc);
      setOutboundTrips(newOutboundTrips);
    }
    const group_id = selectedGroup[0].grouped_trip_id;
    const data = {
      trip: loc,
      group_id: group_id,
    };
    Axios({
      method: 'patch',
      url: `/grouped_trips/${group_id}?context=ungrouping`,
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        const data = res.data.trips;
        let tripType;
        if (bound === 'Inbound') {
          let newInboundGroupedTrips = Object.assign({}, inboundGroupedTrips);
          newInboundGroupedTrips[group_id] = data;
          if (isEmpty(data)) {
            delete newInboundGroupedTrips[group_id];
            setSelectedGroup([]);
          } else {
            data.forEach((trip) => {
              trip.location_latitude += Math.random() / 2500;
              trip.location_longitude += Math.random() / 2500;
            });
            setSelectedGroup(newInboundGroupedTrips[group_id]);
          }
          setInboundGroupedTrips(newInboundGroupedTrips);
        } else if (bound === 'Outbound') {
          let newOutboundGroupedTrips = Object.assign({}, outboundGroupedTrips);
          newOutboundGroupedTrips[group_id] = data;
          if (isEmpty(data)) {
            delete newOutboundGroupedTrips[group_id];
            setSelectedGroup([]);
          } else {
            data.forEach((trip) => {
              trip.destination_latitude += Math.random() / 2500;
              trip.destination_longitude += Math.random() / 2500;
            });
            setSelectedGroup(newOutboundGroupedTrips[group_id]);
          }
          setOutboundGroupedTrips(newOutboundGroupedTrips);
        }
      })
      .catch((err) => console.log(err));
  };

  // Handle dateAll state Change
  const handleAllStateChange = () => {
    if (dateAll === true) {
      setDateAll(false);
    } else {
      setDateAll(true);
      setAllSlot(true);
    }
  };
  // Handle All timeslot change
  const handleAllSlotChange = () => {
    if (allSlot === true) {
      setAllSlot(false);
      setDateAll(false);
    } else {
      setAllSlot(true);
    }
  };

  // Clear selection on date, time, filter and bound change

  useEffect(() => {
    setSelectedGroup([]);
    setSelectedTrips([]);
  }, [filter, fullDate, selectedTimeslot, bound]);

  useEffect(() => {
    setCompanyAddresses([]);
    prevAddresses.current = [];
    setInboundTrips([]);
    setOutboundTrips([]);
    setInboundGroupedTrips([]);
    setOutboundGroupedTrips([]);
    setSelectedAddress('None');
    setBound('Inbound');
  }, [selectedCompany, filter, fullDate, selectedTimeslot]);

  return (
    <div className="w-full inline-flex groupTripsIntro">
      <div className="dateTimeIntro inline-flex">
        <DateColumn
          handleDateFilter={handleDateFilter}
          handleMonthIncrease={handleMonthIncrease}
          handleMonthDecrease={handleMonthDecrease}
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
          days={days}
          fullDate={fullDate}
          filter={filter}
          handleAllStateChange={handleAllStateChange}
          dateAll={dateAll}
        />
        <TimeslotColumn
          handleTimeslotSelect={handleTimeslotSelect}
          selectedTimeslot={selectedTimeslot}
          filter={filter}
          handleAllSlotChange={handleAllSlotChange}
          allSlot={allSlot}
        />
      </div>
      <div className="w-full mr-2 ">
        <div className="flex h-8 justify-between flex-wrap-reverse">
          <div className="groupingIntro">
            <div className="inline-block relative w-32 h-6 text-xs pr-2 selectCompany">
              <select
                className="block appearance-none w-full bg-gray-300 px-2 py-1 rounded-lg leading-tight"
                onChange={handleCompanySelect}
                ref={selectedCompanyRef}>
                <option value="All Companies">Select Company</option>
                {renderComapanyOptions(companies)}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
            {filter === 'unassigned' && (
              <div className="inline-block relative w-48 h-6 text-xs pr-2">
                <select
                  onChange={handleAddressChange}
                  className="block appearance-none w-full bg-gray-300 px-2 py-1 rounded-lg leading-tight"
                  value={selectedAddress}>
                  <option value="None">Select Address</option>
                  {renderCompanyAddresses(
                    companyAddresses,
                    allTrips,
                    allGroups
                  )}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            )}
            {filter === 'unassigned' && (
              <div className="inline-block relative w-32 h-6 text-xs">
                <select
                  onChange={(e) => setBound(e.target.value)}
                  className="block appearance-none w-full bg-gray-300 px-2 py-1 rounded-lg leading-tight"
                  value={bound}>
                  <option value="Inbound">
                    ({inboundTrips.length}) Inbound
                  </option>
                  <option value="Outbound">
                    ({outboundTrips.length}) Outbound
                  </option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            )}
          </div>
          <div className="flex">
            {renderFilterSelect(filter, handleFilterClick, setFilters)}
          </div>
        </div>
        {renderSwitchGroupSection(
          filter,
          inboundTrips,
          outboundTrips,
          handleMarkerClick,
          handleGroupMarkerClick,
          selectedGroup,
          selectedTrips,
          inboundGroupedTrips,
          outboundGroupedTrips,
          handleCreateGroup,
          handleReGroup,
          bound,
          fullDate,
          selectedTimeslot,
          selectedCompany,
          dateAll,
          allSlot,
          handleUngroup,
          selectedAddress,
          trained
        )}
      </div>
    </div>
  );
};

export default GroupedTrips;
