import { faTrashCan, faSortAlphaAsc, faSortAlphaDesc } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import './tooltip.css'

/**
 * 
 * @param {teamEmployees} teamEmployees - The employees of the team
 * @param {selectedDay} selectedDay - The selected day
 * @param {view} view - The view (inbound or outbound)
 * @param {selectedEmployees} selectedEmployees - The selected employees
 * @param {setSelectedEmployees} setSelectedEmployees - The function to set the selected employees
 * @param {selectedTimeslot} selectedTimeslot - The selected timeslot
 * @param {selectedCompanyAddress} selectedCompanyAddress - The selected company address
 * @param {searchQuery} searchQuery - The search query
 * @param {bookings} bookings - The bookings (front-end)
 * @param {setBookings} setBookings - The function to set the bookings (front-end)
 * @param {bookedTrips} bookedTrips - The booked trips (back-end)
 * @param {timesUp} timesUp - The boolean to check if the times are up
 * @param {pageView} pageView - The page view (daily or weekly)
 * @param {setShowPopup} setShowPopup - The function to set the show popup
 * @param {setBookingToDelete} setBookingToDelete - The function to set the booking to delete
 * @param {setBookingToEdit} setBookingToEdit - The function to set the booking to edit
 * @param {updateState} updateState - Used to update the useEffects inside the DailyTable component, that updates the cells when the selectedCompanyAddress changes
 * @param {setDailyChangesMade} setDailyChangesMade - The function to set that daily changes were made (for warning when navigating away without submitting changes)
 * @returns {JSX.Element} - The daily table component
 */
const DailyTable = ({
  teamEmployees, 
  selectedDay, 
  view,
  selectedEmployees, 
  setSelectedEmployees, 
  selectedTimeslot, 
  selectedCompanyAddress,
  searchQuery,
  bookings, 
  setBookings,
  bookedTrips,
  timesUp,
  pageView,
  setShowPopup,
  setBookingToDelete,
  setBookingToEdit,
  updateState,
  setDailyChangesMade
}) => {
  const [filteredBookings, setFilteredBookings] = useState([]) //filtered bookings based on search query (or more can be added)
  const [incompleteBookings, setIncompleteBookings] = useState([]) //array of incomplete bookings
  const [tooltipVisible, setTooltipVisible] = useState(false); //Manages tooltip visibility
  const [sortOrder, setSortOrder] = useState('asc'); // or 'desc' if you want to default to descending
  const [sortedBy, setSortedBy] = useState('employee'); //booking attribute to sort

  //toggles tooltip on Confirm Button
  const toggleTooltip = () => {
    setTooltipVisible(!tooltipVisible);
  };

  // Function to update a specific booking row based on employee ID
  const updateBooking = (employeeId, field, value, bound) => {
    setBookings((prevBookings) =>
      prevBookings.map((booking) =>
        booking.employee.id === employeeId && booking.trip_type.toLowerCase() === bound
          ? { ...booking, [field]: value }
          : booking
      )
    );
  };

  //Functions to sync checkboxes and selection from dropdown
  const handleRemoveEmployee = (employee) => {
    setSelectedEmployees(selectedEmployees.filter((item) => item.id !== employee.id));
  };

  const handleAddEmployee = (employee) => {
    setSelectedEmployees((prevSelectedEmployees) => [...prevSelectedEmployees, employee]);
  };

  const handleCheckbox = (e, booking) =>{
    if (booking.trip_type.toLowerCase() == view.toLowerCase()){
      updateBooking(booking.employee.id, 'selection', e.target.checked, view.toLowerCase())
    }
    if(e.target.checked == true){
      handleAddEmployee(booking.employee)
    }else if(e.target.checked == false){
      handleRemoveEmployee(booking.employee)
    }
  }

  const handleConfirmBookingDelete = (booking) => {
    setShowPopup('delete') //In booking view, this will open the delete popup
    setBookingToDelete(booking.trip_id) //Single booking to delete
  }

  const handleConfirmBookings = () =>{
    if (!timesUp){
      // Filter out bookings with null values for timeslot, drop_off, or pick_up
      const filteredBookings = bookings.filter(
        (booking) => booking.timeslot !== null && (booking.drop_off !== null || booking.pick_up !== null)
      );


      const newTrips = filteredBookings.filter((booking) => booking.status == 'unbooked' || booking.status == 'edited');
      setBookingToEdit(newTrips)
      setBookingToDelete(null)
      setShowPopup('confirm')
    }
  }

  const checkIncompleteBookings = () =>{
    return incompleteBookings.length == 0
  }

  const changesAllowed = () => {
    if (checkIncompleteBookings() == false){ 
      return false
    }
    if (timesUp == true){
      return false
    } 
    return true
  }

  //If there are incomplete bookings, show tooltip on Confirm Button
  const onHoverCheck = () => {
    if (checkIncompleteBookings() == false){
      const formattedBookings = incompleteBookings.map((booking) => {
        return `${booking.employee?.name} ${booking.employee?.surname}`;
      });
      return (
        <>
          You have incomplete bookings for:
          <br />
          {
            [...new Set(formattedBookings)].map((booking, index) => (
              <div key={index}>
                {booking}
              </div>
            ))
          }
        </>
      );
    }else if (timesUp){
      return "You can no longer schedule trips for this day, please request an adhoc trip"
    }else{
      setTooltipVisible(false)
    }
  }

  //Check for incomplete bookings: 
  useEffect(()=>{
    let incompleteBookings = []
    if (view.toLowerCase() == "inbound"){
      bookings.forEach((booking) => {
        if((booking.trip_id) && (!booking.drop_off || !booking.timeslot || !booking.pick_up)){
          incompleteBookings.push(booking)
        }
        if(booking.trip_type.toLowerCase() == "inbound" && ((!booking.drop_off && booking.timeslot)  || (booking.drop_off && !booking.timeslot))){
          incompleteBookings.push(booking)
        }
      })
    }else if (view.toLowerCase() == "outbound"){
      bookings.forEach((booking) => {
        if(booking.trip_type.toLowerCase() == "outbound" && ((!booking.pick_up && booking.timeslot) || (booking.pick_up && !booking.timeslot))){
          incompleteBookings.push(booking)
        }
      })
    }
    setIncompleteBookings(incompleteBookings)
  },[bookings])

  //Filter via Search Box
  useEffect(() => { 
    const searchedBookings = bookings.filter((booking) => {
      const full_name = `${booking.employee.name} ${booking.employee.surname}`.toLowerCase();
      return full_name.includes(searchQuery.toLowerCase()) && booking.trip_type.toLowerCase() == view.toLowerCase();
    });
    setFilteredBookings(searchedBookings)
  
  },[searchQuery])

  //Set initial values for bookings
  useEffect(() => {

    if (teamEmployees.length <= 0) return;

    let initialOutboundBookings = []
    let initialInboundBookings = []
    // Generate the initial booking rows from teamEmployees when the component mounts
    initialInboundBookings = teamEmployees.map((employee) => ({
      selection: false,
      employee: employee,
      day: selectedDay,
      timeslot: null,
      pick_up: employee.address,
      drop_off: null,
      trip_type: 'Inbound',
      status: 'unbooked',
      trip_id: null
    }));

    initialOutboundBookings = teamEmployees.map((employee) => ({
      selection: false,
      employee: employee,
      day: selectedDay,
      timeslot: null,
      pick_up: null,
      drop_off: employee.address,
      trip_type: 'Outbound',
      status: 'unbooked',
      trip_id: null
    }));
    const allBookings = [...initialInboundBookings, ...initialOutboundBookings];
    setBookings(allBookings);

    if (bookedTrips.length <= 0 || allBookings.length <=0) return;
    //MAPPING FROM THE BACKEND
      bookedTrips.forEach((trip) => {
        allBookings.forEach((booking) => {
          if(booking.trip_type.toLowerCase() == "inbound" && booking.trip_type.toLowerCase() == trip.trip_type && trip.user_id == booking.employee.id){
            updateBooking(trip.user_id,'timeslot', trip.timeslot, trip.trip_type)
            updateBooking(trip.user_id,'selection', false, trip.trip_type)
            updateBooking(trip.user_id,'drop_off', trip.destination, trip.trip_type)
            updateBooking(trip.user_id,'status', 'booked', trip.trip_type)
            updateBooking(trip.user_id,'trip_id', trip.id, trip.trip_type)
          }else if(booking.trip_type.toLowerCase() == "outbound" && booking.trip_type.toLowerCase() == trip.trip_type &&  trip.user_id == booking.employee.id){
            updateBooking(trip.user_id,'timeslot', trip.timeslot, trip.trip_type)
            updateBooking(trip.user_id,'pick_up', trip.location, trip.trip_type)
            updateBooking(trip.user_id,'status', trip.is_adhoc ? 'adhoc' : 'booked', trip.trip_type)
            updateBooking(trip.user_id,'trip_id', trip.id, trip.trip_type)
            updateBooking(trip.user_id,'selection', false, trip.trip_type)


          }
        });
      });

  }, [teamEmployees, selectedDay, pageView, bookedTrips]); 

  useEffect(() => {
    // Update bookings.selection based on selectedEmployees
    if(!timesUp){
      const updatedBookings = bookings.map((booking) => ({
        ...booking,
        selection: selectedEmployees.some((selectedEmployee) => selectedEmployee.id === booking.employee.id),
      }));
      setBookings(updatedBookings);
    }
  }, [selectedEmployees]);

  useEffect(() => {
    // Update bookings.timeslot based on selectedEmployees and selectedTimeslot
    setBookings((prevBookings) =>
      prevBookings.map((booking) => {
        // Check if the trip_type matches the view before updating timeslot
        if (booking.selection) {

          if (
            booking.selection &&
            selectedTimeslot !== null &&
            booking.trip_type.toLowerCase() === view.toLowerCase()
          ) {
            setDailyChangesMade(true)
            return { 
              ...booking, 
              timeslot: selectedTimeslot,
              status: booking.status === 'booked' ? 'edited' : booking.status, 
            };
          }
        }
        return booking;
      }
    ));
  }, [selectedTimeslot]);

  useEffect(() => {
    // Update bookings.pick_up based on selectedEmployees, selectedCompanyAddress, and bookings.trip_type
    setBookings((prevBookings) =>
      prevBookings.map((booking) => {
        if (booking.selection) {
          if (booking.trip_type?.toLowerCase() === "inbound" && view.toLowerCase() == booking.trip_type.toLowerCase()) {
            // Set drop_off to selectedCompanyAddress for inbound trips
            setDailyChangesMade(true)
            return { 
              ...booking, 
              pick_up: booking.employee.address,
              drop_off: selectedCompanyAddress ? selectedCompanyAddress.name : null, 
              status: booking.status === 'booked' ? 'edited' : booking.status, 
            };
          } else if (booking.trip_type?.toLowerCase() === "outbound"  && view.toLowerCase() == booking.trip_type.toLowerCase()) {
            // Set pick_up to selectedCompanyAddress for outbound trips
            setDailyChangesMade(true)
            return { 
              ...booking, 
              pick_up: selectedCompanyAddress ? selectedCompanyAddress.name : null, 
              drop_off: booking.employee.address,
              status: booking.status === 'booked' ? 'edited' : booking.status,
             };
          }
        }
        return booking;
      })
    );
  }, [selectedCompanyAddress, updateState]);

  const toggleSort = (columnName) => {
    if (sortedBy === columnName) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortOrder('asc');
      setSortedBy(columnName);
    }
  };

  const sortedBookings = [...filteredBookings].sort((a, b) => {
    const nameA = a.employee.name.toLowerCase();
    const nameB = b.employee.name.toLowerCase();
  
    if (nameA < nameB) return sortOrder === 'asc' ? -1 : 1;
    if (nameA > nameB) return sortOrder === 'asc' ? 1 : -1;
    return 0;
  });

  useEffect(() => {
    const filteredBookings = bookings.filter(
      (booking) => booking.trip_type.toLowerCase() === view.toLowerCase()
    );
    setFilteredBookings(filteredBookings);
  }, [bookings, view]);

  return (
    <>
      <div className='w-full mt-4 overflow-hidden border border-gray-200' style={{ borderRadius: '0.5rem' }}>
        <table className='w-full' >
            <thead>
                <tr>
                    <th style={{height: '36px', width: '36px', minWidth: '36px', borderTopLeftRadius: '0.5rem'}}></th>
                    <th className='border border-t-0 border-gray-200 w-1/5'  onClick={() => toggleSort('employee')}>
                      <div className="flex justify-between items-center mx-2">
                        <span>Employee</span>
                        {sortedBy === 'employee' && (
                          <FontAwesomeIcon
                            icon={sortOrder === 'asc' ? faSortAlphaAsc : faSortAlphaDesc}
                            className='ml-2 cursor-pointer'
                          />
                        )}
                      </div> 
                    </th>
                    <th className='border border-t-0 border-gray-200 w-1/5' >
                        <div className="flex justify-between items-center mx-2">
                          <span>Timeslot</span>

                        </div> 
                    </th>
                    <th className='border border-t-0 border-gray-200 w-1/5' >
                        <div className="flex justify-between items-center mx-2">
                          <span>Pick Up</span>

                        </div> 
                    </th>
                    <th className='border border-t-0 border-gray-200 w-1/5'>
                        <div className="flex justify-between items-center mx-2">
                          <span>Drop Off</span>
                        </div> 
                    </th>
                    <th className='border border-t-0 border-gray-200 w-1/5'>
                        <div className="flex justify-between items-center mx-2">
                          <span>Trip Type</span>
                        </div> 
                    </th>
                    <th style={{height: '36px', width: '36px', minWidth: '36px', borderTopRightRadius: '0.5rem'}}></th>
                </tr>
            </thead>
            <tbody>
                {/* Render table rows */}
                {
                sortedBookings
                .map((booking, index) => (
                  <tr key={index} className={`${ timesUp ? "bg-gray-100" :
                    booking.status === 'booked' ? 'bg-green-100' :
                    booking.status === 'edited' ? 'bg-orange-100' :
                    booking.status === 'adhoc' ? 'bg-yellow-100' :
                    'bg-white'
                  }`}>
                    <td className='border border-l-0 border-gray-200 ' style={{ height: '36px', width: '36px' }}>
                      {/* Checkbox */}
                      <div className='w-full h-full flex justify-center items-center'>
                        <input
                          type='checkbox'
                          className='rounded-lg'
                          checked={booking.selection}
                          disabled={timesUp}
                          onChange={(e) => handleCheckbox(e, booking)}
                        />
                      </div>
                    </td>
                    <td className='border border-gray-200 w-1/5'>{booking.employee.name} {booking.employee.surname}</td>
                     {/* Input fields */}
                    <td className='border border-gray-200 w-1/5'>
                      <div>{booking.timeslot || ''}</div>
                    </td>
                    <td className='border border-gray-200 w-1/5'>
                      <div>{(booking.pick_up?.name) ? booking.pick_up.name : (booking.pick_up) ? booking.pick_up : ''}</div>
                    </td>
                    <td className='border border-gray-200 w-1/5'>
                      <div>{(booking.drop_off?.name) ? booking.drop_off.name : (booking.drop_off) ? booking.drop_off : ''}</div>
                    </td>
                    <td className='border border-gray-200 w-1/5'>
                      <div>{booking.trip_type || ''}</div>
                    </td>
                    <td className='border border-r-0 border-gray-200' style={{ height: '36px', width: '36px' }}>
                      <div  
                        onClick={() => (!timesUp && booking.trip_id ? handleConfirmBookingDelete(booking) : null)} 
                        className={`w-full h-full flex justify-center items-center ${timesUp ? "cursor-not-allowed" : "cursor-pointer"}`}
                      >
                        <FontAwesomeIcon icon={faTrashCan} className='bg-gray-500 text-white p-1 rounded'   />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
        </table>
      </div>
      { (teamEmployees.length > 0) &&
        <>
          <div className='w-full justify-start justify-center text-gray-400 ml-2 mt-1' style={{fontSize: "14px"}}>
            {teamEmployees.length} team member(s)
          </div>
          <div className='w-full flex justify-end'>
            <div className={`p-2 text-white rounded-lg flex align-center justify-center relative ${(!changesAllowed()) ? "cursor-not-allowed bg-gray-300" : "cursor-pointer bg-green-500 hover:bg-green-600" } `}
                 style={{width: '156px', userSelect: 'none'}} onClick={() =>{ !changesAllowed() ? onHoverCheck() : handleConfirmBookings()}}
                 onMouseEnter={toggleTooltip}
                 onMouseLeave={toggleTooltip}
                >
                Confirm Bookings
                {
                  tooltipVisible && (
                    <div className='tooltip-weekly w-full'>{onHoverCheck()}</div>
                  )
                }
            </div>
          </div>
        </>
        }
    </>
  );
};

export default DailyTable;
