import React, { useState } from "react";
import { faHistory, faCircleQuestion, faUsers, faInfo } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const SchedularInfo = () => {
  const [isSchedulerKeyOpen, setIsSchedulerKeyOpen] = useState(false);

  const toggleDropdown = () => {
    setIsSchedulerKeyOpen(!isSchedulerKeyOpen);
  };

  return (
    <div className="relative">
      <button
        className="text-white rounded-full focus:outline-none flex justify-center items-center"
        onMouseEnter={toggleDropdown}
        onMouseLeave={toggleDropdown}
      >
        <FontAwesomeIcon
          icon={faInfo}
          className={`text-white p-2 px-3 text-sm bg-gray-300 rounded-full hover:bg-gray-400 hover:text-white`}
        />
      </button>
      {isSchedulerKeyOpen && (
        <div className="absolute left-0 mt-2 w-64 bg-white rounded-lg shadow-lg z-40">
          <h1 className="text-gray-700 space-x-2 p-2" >Cell Colour Key </h1>
          <ul>
            <li className="flex items-center space-x-2 p-2">
              <div className=" p-4 h-4 rounded-md bg-gray-200" />
              <p className="text-gray-600 text-sm">Time has passed</p>
            </li>
            <li className="flex items-center space-x-2 p-2">
              <div className=" p-4 h-4 rounded-md bg-green-200" />
              <p className="text-gray-600 text-sm">An existing booking</p>
            </li>
            <li className="flex items-center space-x-2 p-2">
              <div className=" p-4 h-4 rounded-md bg-red-200" />
              <p className="text-gray-600 text-sm">An incomplete booking</p>
            </li>
            <li className="flex items-center space-x-2 p-2">
              <div className=" p-4 h-4 rounded-md bg-orange-200" />
              <p className="text-gray-600 text-sm">An updated booking</p>
            </li>
            <li className="flex items-center space-x-2 p-2">
              <div className=" p-4 h-4 rounded-md bg-red-300" />
              <p className="text-gray-600 text-sm">A booking that will be deleted</p>
            </li>
            <li className="flex items-center space-x-2 p-2">
              <div className=" p-4 h-4 rounded-md bg-green-600" />
              <p className="text-gray-600 text-sm">New booking</p>
            </li>
            <li className="flex items-center space-x-2 p-2 mb-2">
              <FontAwesomeIcon icon={faUsers} className="px-2 text-purple-500 m-0 " />
              <p className="text-gray-600 text-sm">A manager</p>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default SchedularInfo;
