import React from 'react';

const renderDays = (month, days, handleDateFilter, fullDate, dateAll) => {
  const day = fullDate.getDate();
  let dates = [];
  for (let i = 1; i <= days; i++) {
    dates.push(
      <div
        className={
          day === i && !dateAll
            ? 'flex w-full bg-gray-100 border border-gray-200 rounded p-2 mt-1 cursor-pointer bg-gray-800 text-white'
            : 'flex w-full bg-gray-100 border border-gray-200 rounded p-2 mt-1 cursor-pointer text-gray-700 hover:bg-gray-300'
        }
        key={i}
        onClick={() => handleDateFilter(i)}
      >
        <div className="self-center w-full text-center font-medium leading-none">
          <p>
            <span className="text-md font-medium">{i}</span>{' '}
            <span className="text-xs mt-1">{month}</span>
          </p>
        </div>
      </div>,
    );
  }
  return dates;
};

const DateColumn = ({
  handleDateFilter,
  handleMonthIncrease,
  handleMonthDecrease,
  selectedMonth,
  selectedYear,
  days,
  fullDate,
  filter,
  handleAllStateChange,
  dateAll,
}) => {
  return (
    <div className="w-48 mr-5 dateColumn">
      <div className="w-full">
        <div className="relative">
          <div className="flex justify-between items-center h-8">
            <div className="flex w-full justify-between">
              <button
                type="button"
                className="transition ease-in-out duration-100 inline-flex cursor-pointer bg-gray-200 hover:bg-gray-300 p-1 rounded-full"
                onClick={handleMonthDecrease}
              >
                <svg
                  className="h-4 w-4 text-gray-500 inline-flex"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
              <div className="self-center inline-flex text-sm">
                <span className="font-bold text-gray-800">{selectedMonth}</span>{' '}
                <span className="ml-1 text-gray-600 font-normal">
                  {selectedYear}
                </span>
              </div>
              <button
                type="button"
                className="transition ease-in-out duration-100 inline-flex cursor-pointer bg-gray-200 hover:bg-gray-300 p-1 rounded-full"
                onClick={handleMonthIncrease}
              >
                <svg
                  className="h-4 w-4 text-gray-500 inline-flex"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            </div>
          </div>
          {filter !== 'unassigned' && (
            <button
              className={
                dateAll
                  ? 'w-full bg-gray-800 text-xs text-white font-medium py-1 px-2 rounded-lg mt-2'
                  : 'w-full bg-gray-300 text-xs text-gray-700 font-medium py-1 px-2 rounded-lg mt-2'
              }
              type="button"
              onClick={handleAllStateChange}
            >
              All
            </button>
          )}

          <div
            className="flex flex-col mt-2 bg-white overflow-scroll bg-white border border-gray-200 rounded-lg p-2"
            style={{ height: '70vh' }}
          >
            {renderDays(
              selectedMonth,
              days,
              handleDateFilter,
              fullDate,
              dateAll,
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateColumn;
