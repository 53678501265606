import React, { useState } from 'react';

const Dropdown = ({ options, onSelect }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  return (
    <div className="relative inline-block text-left">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="text-gray-800 b-0 px-2 -mr-2 pt-0 outline-none cursor-pointer text-center items-center hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
        &#8230;
      </button>

      {isOpen && (
        <div className="absolute py-1 right-0 mt-2 min-w-40 rounded-md shadow-xl bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1 px-1 w-56 no-underline flex flex-col justify-between items-center align-middle">
            {options.map(
              (option, index) =>
                option.value && (
                  <button
                    key={`${index}: ${option.id}`}
                    onClick={() => handleSelect(option)}
                    className={`pl-2 pr-6 py-2 w-full text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 whitespace-normal ${
                      selectedOption === option.id && 'bg-gray-700'
                    }`}>
                    <i className={option.icon} aria-hidden="true"></i>
                    {option.label}
                  </button>
                )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
