import React, { useEffect, forwardRef } from 'react';
import Chart from 'chart.js';

const ChartComponent = forwardRef(
  ({ id, type, labels, datasets, options, width, height }, ref) => {
    useEffect(() => {
      const ctx = ref.current.getContext('2d');

      if (datasets && datasets.length > 0) {
        new Chart(ctx, {
          type: type,
          data: {
            labels: labels,
            datasets: datasets.map((dataset) => ({
              label: dataset.label,
              data: dataset.data,
              backgroundColor: dataset.backgroundColor,
              borderColor: dataset.borderColor,
              borderWidth: dataset.borderWidth,
            })),
          },
          options: options,
        });
      }
    }, [id, type, datasets, ref]);

    return (
      <div>
        <canvas ref={ref} width={width} height={height} id={id}></canvas>
      </div>
    );
  }
);

export default ChartComponent;
