import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Define custom styles for wrapping text
const customStyle = {
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
};

export const showToast = (type, message) => {
    const options = {
        style: customStyle,
    };

    switch (type) {
        case 'success':
            toast.success(message, options);
            break;
        case 'error':
            toast.error(message, options);
            break;
        case 'warning':
            toast.warning(message, options);
            break;
        case 'info':
            toast.info(message, options);
            break;
        default:
            toast(message, options);
            break;
    }
};
