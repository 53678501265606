import React, { useState } from 'react';

const DateDropdown = ({
  date_ranges,
  selectedDateRange,
  setSelectedDateRange,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDateDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleDateRangeChange = (selectedDateRange) => {
    setSelectedDateRange(selectedDateRange);
    toggleDateDropdown();
  };

  return (
    <div className="relative w-full">
      <button
        className="block text-left font-thin appearance-none w-full bg-white text-gray-700 py-3 px-4 rounded-lg cursor-pointer leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        onClick={toggleDateDropdown}>
        {selectedDateRange?.labelPart}
        <div
          key={'1:' + selectedDateRange?.labelPart}
          className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 text-gray-700">
          <i
            key={'2:' + selectedDateRange?.labelPart}
            className={`fas fa-angle-${
              isDropdownOpen ? 'up' : 'down'
            } text-gray-500`}></i>
        </div>
      </button>
      <ul
        className={`absolute left-0 mt-2 w-full bg-white shadow-lg rounded-md dataSelectMenuUl border-b border-gray-300 z-50 text-sm ${
          !isDropdownOpen && 'hidden'
        }`}>
        {date_ranges.map(([label, dates], index) => {
          const [labelPart, datePart] = label.split(', ');
          return (
            <>
              <li
                key={index + labelPart}
                className="pt-2 px-4 flex justify-between cursor-pointer hover:bg-gray-200"
                onClick={() =>
                  handleDateRangeChange({
                    label,
                    labelPart,
                    datePart,
                    dates,
                  })
                }>
                <div
                  key={labelPart + index}
                  className="order-first font-normal">
                  {labelPart}
                </div>
                <div key={datePart + index} className="order-last font-thin">
                  {datePart}
                </div>
              </li>
              {index == 3 && <hr />}
            </>
          );
        })}
      </ul>
    </div>
  );
};

export default DateDropdown;
