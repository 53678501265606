import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock,
  faMap,
  faUserClock,
  faUsers,
  faCarAlt,
  faBullseye,
  faRetweet,
  faBullhorn,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import CancelConfirm from './CancelConfirm';
import GroupedTripCard from './GroupedTripCard';
import Axios from 'axios';
import './notification_pulse.css';

const socketUrl = process.env.REACT_APP_SECURE_ACTION_CABLE_URL;
const AssignedTripCard = ({
  group_trip_id,
  setTripsToUngroupAssigned,
  tripsToUngroupAssigned,
  tripDetails,
  trips,
  viewAllPassengers,
  handleUngroup,
  drivers,
  updateDriver,
  focusPin,
  companies,
  assignAllDrivers,
  getGroupLabel,
  labelIndex,
  handleMarkerHighlight,
  handleGroupMarkerHighlight,
  setIsLoadingPage,
  setRefresh,
  setFocusPins,
  bound,
  role,
  selectedCompany,
  assignAllFleets,
  updateFleet,
  merchantFleets,
  fleets,
  openInGoogleMaps,
  pageType,
  groupedTrips,
  assignType,
  handleUngroupAllTrips
}) => {
  const [viewPassengers, setViewPassengers] = useState(viewAllPassengers);
  const [confirmCommunications, setConfirmCommunications] = useState(false);
  const [loadingCard, setLoadingCard] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [singleTripAlert, SetSingleTripAlert] = useState(false);
  const [tripReason, setTripReason] = useState(null);

  const handleCheckboxChange = () => {
    let filteredTrips = tripsToUngroupAssigned.filter(
      (trip) => trip.grouped_trip_id == group_trip_id
    );
    if (filteredTrips.length > 0) {
      setTripsToUngroupAssigned(
        tripsToUngroupAssigned.filter(
          (trip) => trip.grouped_trip_id != group_trip_id
        )
      );
    } else {
      setTripsToUngroupAssigned([...tripsToUngroupAssigned, ...trips]);
    }
  };

  useEffect(() => {
    setViewPassengers(viewAllPassengers);
  }, [viewAllPassengers]);

  useEffect(() => {
    let filteredTrips = tripsToUngroupAssigned.filter(
      (trip) => trip.grouped_trip_id == group_trip_id
    );
    if (filteredTrips.length > 0) {
      if (isChecked == false) setIsChecked(true);
    } else {
      if (isChecked == true) setIsChecked(false);
    }
  }, [tripsToUngroupAssigned.length]);

  //Action Cable to get the updated assigned trips
  useEffect(() => {
    const cable = ActionCable.createConsumer(socketUrl);

    const channel = cable.subscriptions.create('RouteFinishedChannel', {
      received: (data) => {
        if (data.message === 'updated_trip' && data.id == group_trip_id) {
          setLoadingCard(false);
          setRefresh();
        }
      },
    });

    return () => {
      channel.unsubscribe();
    };
  }, []);

  const toggleCardView = (bool) => {
    if (bool) {
      setViewPassengers(bool);
    } else {
      setViewPassengers(!viewPassengers);
    }
  };
  const getDriverName = () => {
    const savedDriver = drivers.vehicles.find(
      (driver) => driver.user_id === tripDetails.user_id
    );
    if (savedDriver) {
      return savedDriver.username + ' ' + savedDriver.usersurname;
    } else {
      return `Error: ${tripDetails.user_id}`;
    }
  };
  const matchedGroup = groupedTrips.find(
    (trip) => trip.group_id === group_trip_id
  );

  const handleComms = () => {
    setIsLoadingPage(true);
    Axios({
      method: 'post',
      url: '/grouped_trips/group_updated_comms', //pass data and run check here
      headers: { Accept: 'application/json' },
      data: {
        id: group_trip_id,
        trip_reason: tripReason,
      },
    })
      .then((response) => {
        // Handle the response
        setConfirmCommunications(false);
        setIsLoadingPage(false);
        setLoadingCard(true);
        SetSingleTripAlert(false);
        if (tripReason !== null || matchedGroup.trips.length > 1) {
          cardUpdated();
        }
      })
      .catch((error) => {
        // Handle the error
        setConfirmCommunications(false);
        setIsLoadingPage(false);
        SetSingleTripAlert(false);
        if (tripReason !== null) {
          cardUpdated();
        }
        console.error('Failed to create adhoc trips', error);
      });
  };

  const cardUpdated = () => {
    if (!tripDetails.grouped_at || !tripDetails.communicated_at) {
      return false;
    }

    const groupedAt = new Date(tripDetails.grouped_at);
    const timeCommunicatedAt = new Date(tripDetails.communicated_at);
    const timeDifferenceInSeconds = (groupedAt - timeCommunicatedAt) / 1000; // Convert to seconds

    if (timeDifferenceInSeconds < 3) {
      return false;
    } else {
      return true;
    }
  };

  const handleRecommunicate = () => {
    if (matchedGroup) {
      if (matchedGroup.trips.length === 1 && confirmCommunications === false) {
        SetSingleTripAlert(true);
      } else {
        setConfirmCommunications(true);
        setTripReason(null);
      }
    } else {
      setConfirmCommunications(true);
    }
  };

  const reasons = [
    { id: 1, title: 'Adhoc Passenger', value: 'Adhoc Passenger' },
    { id: 2, title: 'Adhoc Trip', value: 'Adhoc trip' },
    { id: 3, title: 'Late Booking', value: 'late booking' },
    { id: 4, title: 'Only Trip in Timeslot', value: 'only trip in timeslot' },
    {
      id: 5,
      title: 'Other Passenger Cancelled',
      value: 'other passenger cancelled',
    },
    { id: 6, title: 'Outlier', value: 'outlier' },
  ];

  const handleTripReasonChange = (event) => {
    setTripReason(event.target.value);
  };

  if (viewPassengers) {
    return (
      <div>
        <GroupedTripCard
          setTripsToUngroup={setTripsToUngroupAssigned}
          tripsToUngroup={tripsToUngroupAssigned}
          label={getGroupLabel(labelIndex)}
          trips={trips}
          groupId={group_trip_id}
          handleUngroup={handleUngroup}
          drivers={drivers}
          updateDriver={updateDriver}
          groupDetails={tripDetails}
          toggleCardView={toggleCardView}
          focusPin={focusPin}
          assignAllDrivers={assignAllDrivers}
          handleMarkerHighlight={handleMarkerHighlight}
          handleGroupMarkerHighlight={handleGroupMarkerHighlight}
          cardUpdated={cardUpdated()}
          setFocusPins={setFocusPins}
          setRefresh={setRefresh}
          bound={bound}
          role={role}
          companies={companies}
          selectedCompany={selectedCompany}
          assignAllFleets={assignAllFleets}
          updateFleet={updateFleet}
          merchantFleets={merchantFleets}
          fleets={fleets}
          openInGoogleMaps={openInGoogleMaps}
          pageType={pageType}
          assignType={assignType}
          handleUngroupAllTrips={handleUngroupAllTrips}
        />
      </div>
    );
  } else {
    const handleFocusClick = () => {
      // Get the average latitude and longitude
      let sumLat = 0;
      let sumLong = 0;
      let count = 0;

      trips.forEach((trip) => {
        let lat, long;

        if (trip.trip_type === 'inbound') {
          lat = trip.location_latitude;
          long = trip.location_longitude;
        } else if (trip.trip_type === 'outbound') {
          lat = trip.destination_latitude;
          long = trip.destination_longitude;
        }

        if (lat && long) {
          sumLat += lat;
          sumLong += long;
          count++;
        }
      });

      if (count > 0) {
        const avgLat = sumLat / count;
        const avgLong = sumLong / count;
        focusPin(avgLat, avgLong);
        handleGroupMarkerHighlight(group_trip_id);
      }
    };

    return (
      <div
        className={`w-full bg-gray-100 rounded-xl overflow-hidden mb-4 relative  ${
          cardUpdated()
            ? 'border-2 border-red-700 pulse'
            : ' border border-gray-200  shadow-md'
        } `}>
        <div className="grid grid-cols-2 p-2 ">
          <div className="col-span-2 w-70 flex items-center justify-between">
            <h2 className="mx-2 text-sm text-gray-700 font-semibold">
              {getGroupLabel(labelIndex)}- {tripDetails.id}
            </h2>
            <h2 className="mx-2 text-sm text-gray-700 font-semibold">
              {tripDetails.status == 'complete'
                ? tripDetails.tag + ' - Completed'
                : tripDetails.tag}{' '}
            </h2>
            <div className="flex flex-row">
              {role != 'fleetmanager' && (
                <button
                  onClick={handleFocusClick}
                  className="w-5 min-w-5 max-h-20 w-30 px-4 py-0 ml-2  bg-gray-100 hover:bg-gray-200 text-gray-700 font-bold rounded-xl border border-gray-700 flex items-center justify-center focus:outline-none">
                  <FontAwesomeIcon
                    icon={faBullseye}
                    className="h-4 w-3 font-bold"
                    size={'xs'}
                  />
                </button>
              )}
              <button
                onClick={handleRecommunicate}
                disabled={!cardUpdated()}
                className={`max-w-30 h-5 max-h-20 w-30 px-2 py-1 ml-2 font-bold rounded-full border flex items-center justify-center focus:outline-none
                                                ${
                                                  cardUpdated()
                                                    ? 'bg-red-200 hover:bg-red-300 text-red-700 border-red-700 pulse'
                                                    : 'bg-gray-100 hover:bg-gray-200 text-gray-700 border-gray-700 cursor-not-allowed'
                                                }`}>
                <FontAwesomeIcon icon={faBullhorn} size={'xs'} />
              </button>
              <button
                onClick={toggleCardView}
                className="max-w-30 h-5 max-h-20 w-30 px-2 py-1 ml-2  bg-gray-100 hover:bg-gray-200 text-gray-700 font-bold rounded-full border border-gray-700 flex items-center justify-center focus:outline-none">
                <FontAwesomeIcon icon={faRetweet} size={'xs'} />
              </button>
              {role != 'fleetmanager' && (
                <input
                  type="checkbox"
                  className="ml-2 mr-1"
                  disabled={
                    tripDetails ? tripDetails.status == 'complete' : false
                  }
                  checked={isChecked}
                  onChange={() => handleCheckboxChange()}
                />
              )}
            </div>
          </div>
          <hr className="my-2 mx-1 col-span-2 " />
          <div className="col-span-1 text-sm">
            <div>
              <div className="text-gray-700 700 w-full">
                <div className="w-4 min-w-4 items-center justify-center inline-block mx-1">
                  <FontAwesomeIcon icon={faMap} />
                </div>
                <div className="w-30 inline-block">{tripDetails.region}</div>
              </div>
              <div className="text-gray-700 w-full">
                <div className="w-4 min-w-4 items-center justify-center inline-block mx-1">
                  <FontAwesomeIcon icon={faUserClock} />
                </div>
                <div className="w-30 inline-block">
                  {tripDetails.start_time}
                </div>
              </div>
              <div className="text-gray-700 w-full">
                <div className="w-4 min-w-4 items-center justify-center inline-block mx-1">
                  <FontAwesomeIcon icon={faClock} />
                </div>
                <div className="w-30 inline-block">{tripDetails.timeslot}</div>
              </div>
            </div>
          </div>
          <div className="col-span-1 text-sm">
            <div>
              <div className="text-gray-700 text-sm 700 w-full">
                <div className="w-4 min-w-4 items-center justify-center inline-block mx-1">
                  <FontAwesomeIcon icon={faUserTie} />
                </div>
                <div className="w-30 inline-block">{getDriverName()}</div>
              </div>
              <div className="text-gray-700 w-full">
                <div className="w-4 min-w-4 items-center justify-center inline-block mx-1">
                  <FontAwesomeIcon icon={faUsers} />
                </div>
                <div className="w-30 inline-block">
                  {tripDetails.trip_count}
                </div>
              </div>
              <div className="text-gray-700 w-full">
                <div className="w-4 min-w-4 items-center justify-center inline-block mx-1">
                  <FontAwesomeIcon icon={faCarAlt} />
                </div>
                <div className="w-30 inline-block">
                  {tripDetails.make} - {tripDetails.reg_number}
                </div>
              </div>
            </div>
          </div>
        </div>
        {confirmCommunications && (
          <>
            <div
              style={{ zIndex: 10 }}
              className="absolute top-0 left-0 w-full h-full bg-black opacity-50"
              onClick={() => setConfirmCommunications(false)}></div>
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <CancelConfirm
                title={'Update Trip'}
                body={
                  'This will communicate changes to the driver and passengers. Are you sure?'
                }
                onNoClick={() => {
                  setConfirmCommunications(false);
                }}
                onYesClick={() => {
                  handleComms();
                }}
              />
            </div>
          </>
        )}
        {loadingCard && (
          <>
            <div
              className="absolute top-0 left-0 w-full h-full bg-black opacity-50 p-4 flex flex-col items-center justify-center"
              style={{ zIndex: 10 }}>
              <div
                className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"
                style={{
                  borderRadius: '50%',
                  width: '12px',
                  height: '12px',
                }}></div>
              <h2 className="text-center text-white text-xl font-semibold">
                Loading...
              </h2>
            </div>
          </>
        )}

        {singleTripAlert && (
          <div className="relative Z-40 top-0 left-0 w-full h-full flex items-center justify-center">
            <div className="relative bg-white rounded-md shadow-lg p-6">
              <h2 className="text-xl text-gray-700 font-semibold mb-4">
                Select Trip Reason:
              </h2>
              <select
                value={tripReason}
                onChange={handleTripReasonChange}
                className="w-full p-2 border rounded-md mb-4">
                <option value="">Select Trip Reason</option>
                {reasons.map((reason) => (
                  <option key={reason.id} value={reason.value}>
                    {reason.title}
                  </option>
                ))}
              </select>
              <button
                disabled={!singleTripAlert}
                onClick={() => setConfirmCommunications(true)}
                className="px-4 py-2 bg-purple-500 text-white rounded-md hover:bg-purple-600">
                Submit
              </button>
              <button
                disabled={!singleTripAlert}
                onClick={() => SetSingleTripAlert(false)}
                className="ml-2 px-4 py-2 bg-gray-700 text-white rounded-md hover:bg-gray-600">
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default AssignedTripCard;
