import React, { Component, createRef, useState, useEffect } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ThreeDots } from 'react-loader-spinner';
import { generateId, isNull, isUndefined } from '../../tools/helpers';
import {
  getNextWeekDateRangeOption,
  getThisWeekDateRangeOption,
} from '../../tools/date.util';
import Select from 'react-select';

import './bookings.scss';
import axios from 'axios';
import BasicSelect from '../select/BasicSelect';
import '../button/button.scss';
import Button from '../button/Button';
export const DownloadCsvBookings = () => {
  const taskTypes = [
    { label: 'Upload Bookings', value: 'upload' },
    { label: 'Download Booking Template', value: 'download' },
  ];

  const [employees, setEmployees] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [timeslots, setTimeslots] = useState([]);
  const [companyAddresses, setCompanyAddresses] = useState([]);
  const [task, setTask] = useState(taskTypes[0].value);

  const [selectCompany, setSelectCompany] = useState(undefined);
  const [selectTimeslotInbound, setSelectTimeslotInbound] = useState(undefined);
  const [selectTimeslotOutbound, setSelectTimeslotOutbound] =
    useState(undefined);

  const [selectEmployees, setSelectEmployees] = useState([]);
  const [selectCompanyAddress, setSelectCompanyAddress] = useState(undefined);
  const [selectWeek, setSelectWeek] = useState(undefined);

  const [success, setShowSuccess] = useState(false);
  const [failure, setShowFailure] = useState(false);

  const [weeks, setWeeks] = useState([]);

  const companyRef = createRef();
  const weekRef = createRef();
  const timeslotInboundRef = createRef();
  const timeslotOutboundRef = createRef();

  const employeesRef = createRef();
  const companyAddressRef = createRef();

  useEffect(() => {
    void fetchCompanies();
    void fetchTimeslots();
    void fetchWeeks();
  }, []);

  useEffect(() => {
    if (selectCompany) void fetchEmployees();
  }, [selectCompany]);

  const downloadCsv = async (e) => {
    /*const response = await axios('/csv_bookings/download_csv', {
            headers: { Accept: 'application/json' },
            data: { 
                company_id: companyRef.current.value,
                timeslot: timeslotRef.current.value, 
                employees: selectEmployees,
            },
            method: 'POST'
        });*/
  };

  const fetchWeeks = async () => {
    let currentWeek = await getThisWeekDateRangeOption();
    let nextWeek = await getNextWeekDateRangeOption();
    setWeeks([currentWeek, nextWeek]);
  };

  const fetchEmployees = async () => {
    const response = await axios(
      `/csv_bookings/employees?company_id=${selectCompany}`,
      {
        headers: { Accept: 'application/json' },
      }
    );

    const { data } = response;
    const employees = data.map((item) => ({
      label: `${item.name} ${item.surname}`,
      value: item.id,
      item,
    }));

    setEmployees(employees);
  };

  const fetchCompanies = async () => {
    const response = await axios('/companies', {
      headers: { Accept: 'application/json' },
    });

    const { data } = response;
    const companies = data.companies.map((item) => ({
      label: item.name,
      value: item.id,
      item,
    }));

    setCompanies(companies);
  };

  const fetchTimeslots = async () => {
    const response = await axios('/csv_bookings/time_slots', {
      headers: { Accept: 'application/json' },
    });

    const { data } = response;
    const timeslots = Object.entries(data).map(([key, value]) => ({
      label: key,
      value: value,
    }));
    setTimeslots(timeslots);
  };

  const onChangedSelectedWeek = (selectedWeek) => {
    setSelectWeek(weekRef.current.value);
  };

  const onChangedSelectedCompany = (selectedCompany) => {
    //reset employees
    setEmployees([]);

    // reset selected employees
    setSelectEmployees([]);

    // reset selected company adddress
    setSelectCompanyAddress(undefined);

    //reset selected timeslot
    setSelectTimeslotInbound(undefined);
    setSelectTimeslotOutbound(undefined);

    // reset selected week
    setSelectWeek(undefined);

    // filter employees
    setSelectCompany(companyRef.current.value);

    let foundCompany = companies.find(
      (company) => company.item.id == companyRef.current.value
    );
    let companyAddresses = [];

    foundCompany?.item.company_addresses?.forEach((address) => {
      companyAddresses.push({
        label: `${address.name}`,
        value: `${address.name}`,
      });
    });
    setCompanyAddresses(companyAddresses);
  };

  const onChangedSelectedTimeslotInbound = (selectedTimeslot) => {
    setSelectTimeslotInbound(timeslotInboundRef.current.value);
  };

  const onChangedSelectedTimeslotOutbound = (selectedTimeslot) => {
    setSelectTimeslotOutbound(timeslotOutboundRef.current.value);
  };

  const onChangeSelectedEmployees = (selectedEmployees) => {
    setSelectEmployees(selectedEmployees);
  };

  const onChangedSelectedCompanyAddress = (selectedCompanyAddress) => {
    setSelectCompanyAddress(companyAddressRef.current.value);
  };

  return (
    <div className="bookings">
      <h1 className="bookings__title">Download Bookings</h1>
      {companies.length == 0 ? (
        <div className="flex justify-center mt-10">
          <ThreeDots
            type="ThreeDots"
            color="#9B999E"
            height="100"
            width="100"
          />
        </div>
      ) : (
        <>
          {success && (
            <h3
              style={{
                color: 'green',
                textAlign: 'center',
                marginBottom: '30px',
              }}>
              Trip(s) were created successfully!
            </h3>
          )}
          {failure && (
            <h3
              style={{
                color: 'red',
                textAlign: 'center',
                marginBottom: '30px',
              }}>
              Failed to action your request!
            </h3>
          )}
          {
            <>
              <div className="bookings__filters">
                <BasicSelect
                  placeholder="Select Company"
                  items={companies}
                  onChange={onChangedSelectedCompany}
                  dropdownRef={companyRef}
                />

                <BasicSelect
                  placeholder="Select Select an Address"
                  onChange={onChangedSelectedCompanyAddress}
                  items={[...companyAddresses]}
                  dropdownRef={companyAddressRef}
                />
              </div>

              {selectCompanyAddress != undefined && (
                <div className="bookings__filters_sub">
                  <BasicSelect
                    placeholder="Select Week"
                    onChange={onChangedSelectedWeek}
                    items={[...weeks]}
                    dropdownRef={weekRef}
                  />

                  <BasicSelect
                    placeholder="Select Inbound Timeslot"
                    onChange={onChangedSelectedTimeslotInbound}
                    items={[...timeslots]}
                    dropdownRef={timeslotInboundRef}
                  />

                  <BasicSelect
                    placeholder="Select Outbound Timeslot"
                    onChange={onChangedSelectedTimeslotOutbound}
                    items={[...timeslots]}
                    dropdownRef={timeslotOutboundRef}
                  />
                </div>
              )}
              {selectCompanyAddress != undefined && (
                <>
                  <div className="bookings__filters_button">
                    <a
                      href={`/csv_bookings/download_csv.csv?company_id=${selectCompany}&employees=${selectEmployees
                        .map((employee) => employee.item.id)
                        .join(
                          '|'
                        )}&timeslot_inbound=${selectTimeslotInbound}&timeslot_outbound=${selectTimeslotOutbound}&company_address=${selectCompanyAddress}&week=${selectWeek}`}>
                      <Button onClick={() => {}}>Download CSV</Button>
                    </a>
                  </div>
                  <Select
                    ref={employeesRef}
                    placeholder={'Select Employees'}
                    isMulti
                    isSearchable
                    options={[
                      {
                        label: 'All Employees',
                        value: 'all',
                        item: { id: 'all' },
                      },
                      ...employees,
                    ]}
                    onChange={onChangeSelectedEmployees}
                    value={selectEmployees}
                  />
                </>
              )}
            </>
          }
        </>
      )}
    </div>
  );
};
export default DownloadCsvBookings;
