import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRetweet,
  faBullseye,
  faMinus,
} from '@fortawesome/free-solid-svg-icons';
import OptionsButton from './OptionsButton';
import CancelConfirm from './CancelConfirm';
import './notification_pulse.css';
import RelevantAssignDropdown from './RelevantAssignDropdown';
const socketUrl = process.env.REACT_APP_SECURE_ACTION_CABLE_URL



const GroupedTripCard = ({
  label,
  trips,
  setTripsToUngroup,
  tripsToUngroup,
  groupId,
  handleUngroup,
  drivers,
  updateDriver,
  groupDetails,
  toggleCardView,
  focusPin,
  assignAllDrivers,
  handleMarkerHighlight,
  handleGroupMarkerHighlight,
  cardUpdated,
  setFocusPins,
  setRefresh,
  dateTime,
  bound,
  role,
  companies,
  selectedCompany,
  updateFleet,
  assignAllFleets,
  merchantFleets,
  fleets,
  openInGoogleMaps,
  pageType,
  assignType,
  handleUngroupAllTrips,
}) => {
  const [cancelTrip, setCancelTrip] = useState(false);
  const [ungroupTrip, setUngroupTrip] = useState(false);
  const [ungroupPassenger, setUngroupPassenger] = useState(false);
  const [tripToRemove, setTripToRemove] = useState(null);
  const [loadingCard, setLoadingCard] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const cable = ActionCable.createConsumer(socketUrl);

    const channel = cable.subscriptions.create('RouteFinishedChannel', {
      received: (data) => {
        if (data.message === 'assigned_trip' && data.id == groupId) {
          setLoadingCard(false);
          setRefresh();
        }
      },
    });

    return () => {
      channel.unsubscribe();
    };
  }, []);

  useEffect(() => {
    let filteredTrips = tripsToUngroup.filter(
      (trip) => trip.grouped_trip_id == groupId
    );
    if (filteredTrips.length > 0) {
      if (isChecked == false) setIsChecked(true);
    } else {
      if (isChecked == true) setIsChecked(false);
    }
  }, [tripsToUngroup.length]);

  const handleTrashClick = (e, trip) => {
    if (toggleCardView) {
      setUngroupPassenger(true);
      setTripToRemove(trip);
    }
  };

  const handleUngroupAll = (e) => {
    setUngroupTrip(false);
    handleUngroupAllTrips(trips);
  };

  const handleClick = () => {
    if (toggleCardView) {
      toggleCardView();
    }
  };

  const handleFocusClick = () => {
    // Get the average latitude and longitude
    let sumLat = 0;
    let sumLong = 0;
    let count = 0;

    trips.forEach((trip) => {
      let lat, long;

      if (trip.trip_type === 'inbound') {
        lat = trip.location_latitude;
        long = trip.location_longitude;
      } else if (trip.trip_type === 'outbound') {
        lat = trip.destination_latitude;
        long = trip.destination_longitude;
      }

      if (lat && long) {
        sumLat += lat;
        sumLong += long;
        count++;
      }
    });

    if (count > 0) {
      const avgLat = sumLat / count;
      const avgLong = sumLong / count;
      focusPin(avgLat, avgLong);
      handleGroupMarkerHighlight(groupId);
    }
  };

  const handleFocusTripClick = (e, trip) => {
    if (trip.trip_type == 'inbound') {
      const lat = trip.location_latitude;
      const long = trip.location_longitude;
      focusPin(lat, long);
      handleMarkerHighlight(trip.id);
    } else if (trip.trip_type == 'outbound') {
      const lat = trip.destination_latitude;
      const long = trip.destination_longitude;
      focusPin(lat, long);
      handleMarkerHighlight(trip.id);
    }
  };

  const handleUngroupPassenger = (e) => {
    handleUngroup(e, tripToRemove);
    setUngroupPassenger(false);
  };

  const handleCheckboxChange = () => {
    let filteredTrips = tripsToUngroup.filter(
      (trip) => trip.grouped_trip_id == groupId
    );
    if (filteredTrips.length > 0) {
      const updatedItems = tripsToUngroup?.filter(
        (trip) => trip.grouped_trip_id != groupId
      );
      setTripsToUngroup(updatedItems);
    } else {
      setTripsToUngroup([...tripsToUngroup, ...trips]);
    }
  };

  const getRelevantDropDown = () => {
    if (merchantFleets == true && role != 'fleetmanager') {
      return (
        <FleetDropDown
          fleets={fleets}
          groupId={groupId}
          updateFleet={updateFleet}
          groupDetails={groupDetails}
          assignAllFleets={assignAllFleets}
          setLoadingCard={setLoadingCard}
          trips={trips}
        />
      );
    }

    return (
      <DriverDropDown
        drivers={drivers}
        groupId={groupId}
        updateDriver={updateDriver}
        groupDetails={groupDetails}
        assignAllDrivers={assignAllDrivers}
        setLoadingCard={setLoadingCard}
        dateTime={dateTime}
        bound={bound}
        trips={trips}
        tripsLength={trips.length}
      />
    );
  };

  return (

    <div
      className={`w-full bg-gray-100 rounded-xl mb-4 relative  ${cardUpdated
        ? ' border-2 border-red-700 pulse'
        : ' border border-gray-200 shadow-md '}  `}
      style={{ minHeight: '128px' }}>

      <div className="grid grid-cols-[1fr, 2fr] p-2 ">
        <div className="col-span-1">
          <h2 className="text-lg text-gray-700 font-semibold">{label + '-' + groupId}</h2>
        </div>
        <div className="flex items-center justify-end col-span-1">
          <RelevantAssignDropdown
            merchantFleets={merchantFleets}
            role={role}
            fleets={fleets}
            groupId={groupId}
            updateFleet={updateFleet}
            groupDetails={groupDetails}
            assignAllFleets={assignAllFleets}
            setLoadingCard={setLoadingCard}
            drivers={drivers}
            updateDriver={updateDriver}
            assignAllDrivers={assignAllDrivers}
            dateTime={dateTime}
            bound={bound}
            trips={trips}
            assignType={assignType}
          />

          {(role != "fleetmanager") &&
            <button onClick={handleFocusClick}
              className="max-w-30 h-5 max-h-20 w-30 px-2 py-1 ml-2  bg-gray-100 hover:bg-gray-200 text-gray-700 font-bold rounded-full border border-gray-700 flex items-center justify-center focus:outline-none">
              <FontAwesomeIcon icon={faBullseye} size={'xs'} />
            </button>
          }

          <OptionsButton
            type={'grouped'}
            setCancelTrip={setCancelTrip}
            setUngroupTrip={setUngroupTrip}
            groupId={groupId}
            tripStatus={groupDetails ? groupDetails.status : null}
            role={role}
            openInGoogleMaps={openInGoogleMaps}
            pageType={pageType}
          />

          {toggleCardView &&
            <button onClick={toggleCardView ? handleClick : null}
              className={`max-w-30 h-5 max-h-20 w-30 px-2 py-1 ml-2 font-bold rounded-full border ${(cardUpdated) ? "bg-red-200 hover:bg-red-300 text-red-700 border-red-700 pulse" : " bg-gray-100 hover:bg-gray-200 text-gray-700 border-gray-700"} flex items-center justify-center focus:outline-none `}>
              <FontAwesomeIcon icon={faRetweet} size={'xs'} />
            </button>
          }

          {(role != "fleetmanager") &&
            <input
              type="checkbox"
              className='p-2 ml-2'
              checked={isChecked}
              disabled={(groupDetails?.status == 'complete') ? true : false}
              onChange={() => handleCheckboxChange()}
            />
          }
        </div>

        <hr className="my-2 col-span-2 " />

        <div className='col-span-2'>
          {trips.map((trip) => (
            <div key={trip.id} className='w-full flex items-center mb-1'>
              {(role != "fleetmanager") &&
                <button
                  disabled={(groupDetails?.status == 'complete') ? true : false}
                  onClick={(e) => { (toggleCardView) ? handleTrashClick(e, trip) : handleUngroup(e, trip); }}
                  className={`w-5 min-w-5 items-center flex justify-center rounded border py-1  px-2  focus:outline-none ${(groupDetails?.status == 'complete' || role == 'fleetmanager') ? "text-gray-500 border-gray-500 hover:bg-gray-200" : "text-red-500 border-red-500 hover:bg-red-200"} `}
                >
                  <FontAwesomeIcon icon={faMinus} className="h-4 w-3" size={'lg'} />
                </button>}
              {(role != "fleetmanager") &&
                <button
                  onClick={(e) => { handleFocusTripClick(e, trip); }}
                  className="w-5 min-w-5 max-h-20 px-2 py-1 ml-2  bg-gray-100 hover:bg-gray-200 text-gray-700 font-bold rounded border border-gray-700 flex items-center justify-center focus:outline-none"
                >
                  <FontAwesomeIcon icon={faBullseye} className="h-4 w-3 font-bold" size={'xs'} />
                </button>}
              <div className='flex flex-grow space-between'>
                <div className='w-48 inline-block text-gray-700 ml-1 overflow-hidden whitespace-nowrap'>{trip.full_name}</div>
                <div className="w-12"></div>
                <div className='text-gray-700 ml-2 overflow-hidden whitespace-nowrap'>{trip.region}</div>
              </div>
            </div>
          ))}
        </div>

        {ungroupTrip &&
          <>
            <div style={{ zIndex: 10 }}
              className="absolute top-0 left-0 w-full h-full bg-black opacity-50"
              onClick={() => setUngroupTrip(false)}
            ></div>
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center  overflow-visible">
              <CancelConfirm
                title={"Ungroup All Passengers"}
                body={"This will ungroup all passengers and remove this group. Are you sure?"}
                onNoClick={() => { setUngroupTrip(false); }}
                onYesClick={() => { handleUngroupAll(); }} />
            </div>
          </>}

        {cancelTrip &&
          <>
            <div style={{ zIndex: 10 }}
              className="absolute top-0 left-0 w-full h-full bg-black opacity-50 p-4"
              onClick={() => setCancelTrip(false)}
            ></div>
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center p-4">
              <CancelConfirm
                title={"Ungroup & Cancel Trip"}
                body={"This will ungroup these passengers and cancel the trip. Are you sure?"}
                onNoClick={() => { setCancelTrip(false); }}
                onYesClick={() => { }} />
            </div>
          </>
        }
        {ungroupPassenger &&
          <>
            <div style={{ zIndex: 10 }}
              className="absolute top-0 left-0 w-full h-full bg-black opacity-50 p-4"
              onClick={() => setUngroupPassenger(false)}
            ></div>
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center p-4">
              <CancelConfirm
                title={"Ungroup Passenger"}
                body={"This will ungroup this passengers from the assigned trip. Are you sure?"}
                onNoClick={() => { setUngroupPassenger(false); }}
                onYesClick={(e) => { handleUngroupPassenger(e); }} />
            </div>
          </>
        }

        {loadingCard &&
          <>
            <div className="absolute top-0 left-0 w-full h-full bg-black rounded-xl opacity-50 p-4 flex flex-col items-center justify-center" style={{ zIndex: 10 }}>
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
              <h2 className="text-center text-white text-xl font-semibold">Loading...</h2>
            </div>
          </>
        }

      </div>

    </div>

  );
};

export default GroupedTripCard;
