import React from "react";

import './basic-select.scss';

const renderDropdownItems = (items) => items.map(({label, value}) => <option key={value}
                                                                             value={value}>{label}</option>);

const BasicSelect = ({dropdownRef, onChange, items, name, disabled, placeholder, defaultValue}) => (
    <div className="basic-select">
        <div className="basic-select__dropdown-wrapper">
            <select name={name} disabled={disabled} ref={dropdownRef} onChange={onChange} defaultValue={defaultValue}>
                <option value="">{placeholder}</option>
                {renderDropdownItems(items)}
            </select>
        </div>
    </div>
);

export default BasicSelect;
