import React, { useState, useEffect, useRef } from "react";
import { timeIsUp } from "./Countdown.jsx";
import moment from "moment";
import "./custom_scrollbar.css";
import "./marquee.css";
import {
  faSortAlphaAsc,
  faSortAlphaDesc,
  faCrown,
  faHistory, faUsers
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./tooltip.css";
import Countdown from "./Countdown.jsx";
import { getLastWeekDateRangeOption } from "../../../tools/date.util.js";
import Axios from "axios";
import { showToast } from "../../toasts/index.jsx";

import companyTimeslots from "./utils/companyOpeningTimeslots.jsx";
import companyClosingTime from "./utils/companyClosingTime.jsx";

//Default options for timeslots
const OPTIONS = [
  "Off",
  "00:00AM",
  "00:30AM",
  "01:00AM",
  "01:30AM",
  "02:00AM",
  "02:30AM",
  "03:00AM",
  "03:30AM",
  "04:00AM",
  "04:30AM",
  "05:00AM",
  "05:30AM",
  "06:00AM",
  "06:30AM",
  "07:00AM",
  "07:30AM",
  "08:00AM",
  "08:30AM",
  "09:00AM",
  "09:30AM",
  "10:00AM",
  "10:30AM",
  "11:00AM",
  "11:30AM",
  "12:00PM",
  "12:30PM",
  "13:00PM",
  "13:30PM",
  "14:00PM",
  "14:30PM",
  "15:00PM",
  "15:30PM",
  "16:00PM",
  "16:30PM",
  "17:00PM",
  "17:30PM",
  "18:00PM",
  "18:30PM",
  "19:00PM",
  "19:30PM",
  "20:00PM",
  "20:30PM",
  "21:00PM",
  "21:30PM",
  "22:00PM",
  "22:30PM",
  "23:00PM",
  "23:30PM",
];

/**
 *
 * @param {Array} bookings - Array of bookings (json objects used in the table)
 * @param {Function} setBookings - Function to update the bookings state (managed in the Booking View component)
 * @param {Array} bookedTrips - Array of trips booked in the backend
 * @param {String} view - The current view (inbound/outbound)
 * @param {Array} teamEmployees - Array of employees in the team
 * @param {Object} selectedWeek - The selected week (object with value and label)
 * @param {Array} companyAddresses - Array of addresses for the company
 * @param {String} searchQuery - The search query
 * @param {String} pageView - The current page view (weekly/daily)
 * @param {Function} setShowPopup - Function to update the showPopup state (managed in the Booking View component)
 * @param {Array} changedBookings - Array of changed bookings (json objects used in the table)
 * @param {Array} toDeleteBookings - Array of deleted bookings (json objects used in the table)
 * @param {Array} newBookings - Array of new bookings (json objects used in the table)
 * @param {Function} setChangedBookings - Function to update the changedBookings state (managed in the Booking View component)
 * @param {Function} setNewBookings - Function to update the newBookings state (managed in the Booking View component)
 * @param {Function} setToDeleteBookings - Function to update the toDeleteBookings state (managed in the Booking View component)
 * @param {Boolean} cleanUpBookings - Boolean to trigger the clean up of bookings (managed in the Booking View component)
 * @param {Function} setShowAdhoc - Function to update the showAdhoc state (managed in the Booking View component)
 * @param {String} companyCutOffTime - The company cut off time
 * @param {Function} setWeeklyChangesMade - Function to update the weeklyChangesMade state (managed in the Booking View component)
 * @returns {JSX} - Weekly table component
 */
const WeeklyTable = ({
  bookings,
  setBookings,
  setWeek,
  setWeekToEdit,
  setDailyChangesMade,
  setShowWarning,
  weekToEdit,
  bookedTrips,
  view,
  setTimesUp,
  timesUp,
  teamEmployees,
  selectedWeek,
  companyAddresses,
  refresh,
  setRefresh,
  setCleanUpBookings,
  searchQuery,
  pageView,
  setShowPopup,
  changedBookings,
  toDeleteBookings,
  newBookings,
  setChangedBookings,
  setNewBookings,
  setToDeleteBookings,
  cleanUpBookings,
  setShowAdhoc,
  companyCutOffTime,
  bookingTimeRange,
  openingTime,
  closingTime,
  setWeeklyChangesMade,
  reloadTeams,
  setReloadTeams,
  weeklyChangesMade,
  setView,
  role,
  selectedTimeslot,
  selectedEmployees,
  selectedCompanyAddress,
  setSelectedEmployees,
  setSelectedTimeslot,
  setSelectedCompanyAddress,
  daySelected,
  tripType,
  preLoadBookings,
  teamSelected,
  isAllowedToEditCsb,
  weekRanges
}) => {
  const [filteredBookings, setFilteredBookings] = useState([]); // - Bookings after search query is applied but before sorting is applied
  const [timeslotDropdownCell, setTimeslotDropDownCell] = useState({
    index: null,
    day: null,
  }); // Holds the specific timeslot cell that gets clicked via index and day (x, y)
  const [addressDropdownCell, setAddressDropDownCell] = useState({
    index: null,
    day: null,
  }); // Holds the specific address cell that gets clicked via index and day (x, y)
  const [sortingOrder, setSortingOrder] = useState("asc");
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const dropdownRef = useRef(null); //These refs are for clicking outside the dropdown to make it disapear
  const boxRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [lastWeekTrips, setLastWeekTrips] = useState([]);
  const [lastWeekDay, setLastWeekDay] = useState(null);
  const [fetchingTrips, setFetchingTrips] = useState(false);

  const [isLastWeekModalOpen, setIsLastWeekModalOpen] = useState(false);

  const openModalForLastWeekTrips = () => {
    setIsLastWeekModalOpen(true);
  };

  const closeLastWeekModal = () => {
    setIsLastWeekModalOpen(false);
  };

  const handleApplyTrips = () => {
    handleApplyLastWeekTrips(lastWeekDay);
    closeLastWeekModal();
  };

  const lastWeekRange = getLastWeekDateRangeOption();


  function getEmployees(arrayOfObjects) {
    return arrayOfObjects.map((obj) => obj.employee);
  }

  function getEmployeesById(employeesArray, id) {
    return employeesArray.filter((obj) => obj.id === id);
  }

  function getLastWeekDate(dateString) {
    const [day, month, year] = dateString.split("-").map(Number);
    const currentDate = new Date(year, month - 1, day);

    const lastWeekDate = new Date(currentDate);
    lastWeekDate.setDate(lastWeekDate.getDate() - 7);

    const formattedDate = `${lastWeekDate
      .getDate()
      .toString()
      .padStart(2, "0")}-${(lastWeekDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${lastWeekDate.getFullYear()}`;

    return formattedDate;
  }

  function getTripsByTypeAndDay(type, day, data) {
    let lastWeekDay = getLastWeekDate(day);

    const weekDay = moment(lastWeekDay, "DD-MM-YYYY").format("dddd").toLowerCase();

    if (data.hasOwnProperty(type)) {
      if (data[type].hasOwnProperty(lastWeekDay)) {
        setLastWeekTrips(data[type][lastWeekDay]);

        showToast("success", "Trips fetched successfully");

        return data[type][lastWeekDay];
      } else {
        showToast("success", `No ${type} trips found for last week ${weekDay}`);
        return [];
      }
    } else {
      showToast("error", "No trips found for the selected type");
      return [];
    }
  }

  const handleApplyLastWeekTrips = (selectedDate) => {
    let sortedWeekRanges = weekRanges;
    sortedWeekRanges.sort((a, b) => {
      const _startDateA = a.value.split('|')[0].split('-').reverse().join('-');
      const _startDateB = b.value.split('|')[0].split('-').reverse().join('-');
      return new Date(_startDateA) - new Date(_startDateB);
    });

    function getWeek(value) {
      const targetStartDate = new Date(value.split('|')[0].split('-').reverse().join('-'));

      for (let i = 1; i < sortedWeekRanges.length; i++) {
        const currentStartDate = new Date(sortedWeekRanges[i].value.split('|')[0].split('-').reverse().join('-'));

        if (currentStartDate.getTime() === targetStartDate.getTime()) {
          return sortedWeekRanges[i - 1].value;
        }
      }

      return null; // Week is not found
    }

    const dateRange = getWeek(selectedWeek.value)
    const team_id = teamSelected?.team_id;
    setFetchingTrips(true);

    let url = "/team_manager/get_team_trips_weekly";
    url += `?date_range=${dateRange}`;
    url += `&team_id=${team_id}`;
    Axios({
      method: "get",
      url: url,
      headers: { Accept: "application/json" },
    })
      .then((response) => {
        getTripsByTypeAndDay(tripType, selectedDate, response.data);
        setFetchingTrips(false);
      })
      .catch((error) => {
        console.error("Error fetching teams:", error);
        showToast("error", "Fetching trips failed");
        setFetchingTrips(false);
      });
  };



  var dayOfWeek = moment().format("dddd");

  function getDateFromWeek(weekRange) {
    const [startStr, endStr] = weekRange.split("|");

    const startDate = parseDate(startStr);
    const endDate = parseDate(endStr);

    const dayNames = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    const result = [];

    for (let i = 0; i < dayNames.length; i++) {
      const dayName = dayNames[i];

      const targetDayIndex = dayNames.indexOf(dayName);

      const daysToAdd = targetDayIndex;

      const targetDate = new Date(startDate);
      targetDate.setDate(startDate.getDate() + daysToAdd);

      if (targetDate >= startDate && targetDate <= endDate) {
        result.push({
          day: dayName,
          date: formatDate(targetDate),
        });
      }
    }

    return result;
  }

  const schedulerDays = getDateFromWeek(selectedWeek.value);

  function isTripPassed(arr, targetDay, trip) {
    const currentDate = moment().format("DD-MM-YYYY");

    const present = arr.some(
      (item) => item.day === targetDay && item.date === currentDate
    );

    if (present) {
      if (role === 'admin') {
        setShowModal(true);
      }
    } else if (trip.id) {
      window.open(`/trips/${trip.id}`, "_blank");
    }
  }

  //toggles tooltip on Confirm Button
  const toggleTooltip = () => {
    setTooltipVisible(!tooltipVisible);
  };

  const handleYesClick = () => {
    handleAdhocClick();
    setShowModal(false);
    // setWeeklyChangesMade(false);
  };

  const handleNoClick = () => {
    setShowModal(false);
  };

  const resetNewBookings = () => {

    function setStatusToDeleteForNullId(employee) {
      const daysOfWeek = [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ];

      daysOfWeek.forEach((day) => {
        const trip = employee[day];
        if (trip.id === null) {
          trip.status = "unbooked";
          trip.timeslot = "";
          trip.destination = (trip.trip_type == "inbound") ? "" : trip.destination;
          trip.location = (trip.trip_type == "outbound") ? "" : trip.location;
        }
      });
    }

    // Iterate through the list and update the status for entries with id === null
    bookings.forEach((employee) => {
      setStatusToDeleteForNullId(employee);
    });

    // Log the updated data
    setNewBookings([]);
    // setWeeklyChangesMade(false);
    setChangedBookings([]);
    setToDeleteBookings([]);
    setCleanUpBookings(!cleanUpBookings);
    setRefresh(!refresh);
  };

  // Method to toggle sorting order
  const toggleSortingOrder = () => {
    const newSortingOrder = sortingOrder === "asc" ? "desc" : "asc";
    setSortingOrder(newSortingOrder);
  };

  // Function to update a specific booking row based on employee ID
  const updateBooking = (employeeId, field, value, bound) => {
    setBookings((prevBookings) =>
      prevBookings.map((booking) =>
        booking.employee.id === employeeId &&
          booking?.trip_type.toLowerCase() === bound
          ? { ...booking, [field]: value }
          : booking
      )
    );
  };

  //ADHOC CLICK AFTER TIMEOUT
  const handleAdhocClick = () => {
    setView("ad-hoc");
  };

  //ADDRESS SELECTION PORTION
  const handleAddressCellClick = (e, index, day) => {
    e.stopPropagation(); // Prevent the click event from propagating to the table row

    // Update the ref with the reference of the clicked cell
    boxRef.current = e.currentTarget;

    // Toggle the visibility of the dropdown for the clicked cell
    if (
      addressDropdownCell.index === index &&
      addressDropdownCell.day === day
    ) {
      setAddressDropDownCell({
        index: null,
        day: null,
      });
    } else {
      setAddressDropDownCell({
        index: index,
        day: day,
      });
    }
  };

  const renderAddressDropdown = (booking, day) => {
    return (
      <>
        <div
          className="absolute top-full left-0 pt-1 bg-gray-200 shadow-md border border-gray-200 w-full overflow-y-auto scrollable-dropdown flex flex-col items-center"
          style={{ zIndex: 10, top: "calc(100%)", maxHeight: "250px" }}
          ref={dropdownRef}
        >
          {companyAddresses.map((address, index) => (
            <div
              key={index}
              className="p-1 w-full text-gray-600 font-normal cursor-pointer hover:bg-gray-300 truncate marquee"
              style={{ fontSize: "11px", textAlign: "center" }}
              onClick={() => {
                // Update the ref with the reference of the clicked cell
                boxRef.current = null;

                // Toggle the visibility of the dropdown for the clicked cell
                setAddressDropDownCell((prevDropDownCell) => ({
                  ...prevDropDownCell,
                  index: null,
                  day: null,
                }));
                handleSelectAddress(address, booking, day);
              }}
            >
              {address == "Clear" && (
                <div className="flex" style={{ alignSelf: "center" }}>
                  Clear
                </div>
              )}
              {address != "Clear" && <span>{address.name}</span>}
            </div>
          ))}
        </div>
      </>
    );
  };

  const handleSelectAddress = (address, booking, day) => {
    const trip = booking[day];
    const tripAddress = trip.trip_type == "inbound" ? trip?.destination : trip?.location;
    const addressName = address == "Clear" ? "" : address?.name;
    //const addressName = address?.name;

    if (tripAddress == addressName) return;

    trip.status = trip.id
      ? address == "Clear"
        ? "to_delete"
        : "updated"
      : "new";


    trip.destination = trip.trip_type == "inbound" ? addressName : trip.destination;
    trip.location = trip.trip_type == "outbound" ? addressName : trip.location;

    if (trip.status == "to_delete") trip.timeslot = "";

    if (
      (trip.timeslot == "" && ((trip.trip_type == "outbound" && trip.location !== "") || (trip.trip_type == "inbound" && trip.destination !== ""))) ||
      (trip.timeslot !== "" && ((trip.trip_type == "outbound" && trip.location == "") || (trip.trip_type == "inbound" && trip.destination == "")))
    ) {
      trip.status = "incomplete";
    }

    updateBooking(
      booking.employee.id,
      day,
      trip,
      booking.trip_type.toLowerCase()
    );
    // address == "Clear" ? setWeeklyChangesMade(false) : setWeeklyChangesMade(true);
    checkBackendBookings(trip);
  };
  //TIMESLOT SELECTION PORTION
  const handleTimeslotCellClick = (e, index, day) => {
    e.stopPropagation(); // Prevent the click event from propagating to the table row

    // Update the ref with the reference of the clicked cell
    boxRef.current = e.currentTarget;

    // Toggle the visibility of the dropdown for the clicked cell
    if (
      timeslotDropdownCell.index === index &&
      timeslotDropdownCell.day === day
    ) {
      setTimeslotDropDownCell({
        index: null,
        day: null,
      });
    } else {
      setTimeslotDropDownCell({
        index: index,
        day: day,
      });
    }
  };

  const renderTimeslotDropdown = (booking, day) => {
    const currentTimeslot = moment().format("HH:mm");
    const hasCompanyClosed = companyClosingTime(currentTimeslot, closingTime.split(" ").join(""));
    // remove timeslots that are before the company opening time
    const selected_week = selectedWeek.value;

    const selectedWeekStart = moment(selected_week.split("|")[0], "DD-MM-YYYY");
    const selectedWeekEnd = moment(selected_week.split("|")[1], "DD-MM-YYYY");

    const currentWeekStart = moment().startOf("week");
    const currentWeekEnd = moment().endOf("week");

    const isSelectedWeekCurrentWeek = (selectedWeekStart.isBefore(currentWeekEnd) && selectedWeekEnd.isAfter(currentWeekStart));

    const tomorrow = moment().add(1, "days");
    const companyAvailableTimeslots = tomorrow.format("dddd").toLowerCase() == day && hasCompanyClosed && isSelectedWeekCurrentWeek ? companyTimeslots(OPTIONS, openingTime) : OPTIONS;
    const currentHour = moment().hour();

    const twentyTwoHoursAgo = moment().subtract(22, "hours");
    let filteredOptions = companyAvailableTimeslots;
    if (day == tomorrow.format("dddd").toLowerCase()) {
      // Check if it's tomorrow
      filteredOptions = companyAvailableTimeslots.filter((option) => {
        const optionTime = moment(option, "hh:mmA");
        return optionTime >= twentyTwoHoursAgo || option === "Off";
      });
    } else if (moment().format("dddd").toLowerCase() == day) {
      // if the selected week is the current week and the day is today then filter out the timeslots that have passed else show all timeslots
      if (selectedWeek.value == moment().format("DD-MM-YYYY") + "|" + moment().add(6, "days").format("DD-MM-YYYY")) {
        filteredOptions = companyAvailableTimeslots.filter((option) => {
          const optionTime = moment(option, "hh:mmA");
          return (optionTime.hour() >= currentHour + bookingTimeRange) || option == "Off";
        });
      } else {


        filteredOptions = !isSelectedWeekCurrentWeek ? companyAvailableTimeslots : companyAvailableTimeslots.filter((option) => {
          const optionTime = moment(option, "hh:mmA");
          return (optionTime.hour() >= currentHour + bookingTimeRange) || option == "Off";
        });
      }
    }


    return (
      <>
        <div
          className="absolute top-full left-0 pt-1 bg-gray-200 shadow-md border border-gray-200 w-full overflow-y-auto scrollable-dropdown flex flex-col items-center"
          style={{ zIndex: 10, top: "calc(100%)", maxHeight: "200px" }}
          ref={dropdownRef}
        >
          {filteredOptions.map((option, index) => (
            <div
              key={index}
              className="p-1 w-full text-gray-600 font-normal cursor-pointer hover:bg-gray-300"
              style={{ fontSize: "11px", textAlign: "center" }}
              onClick={() => {
                // Update the ref with the reference of the clicked cell
                boxRef.current = null;

                // Toggle the visibility of the dropdown for the clicked cell
                setTimeslotDropDownCell((prevDropDownCell) => ({
                  ...prevDropDownCell,
                  index: null,
                  day: null,
                }));

                handleSelect(option, booking, day);
              }}
            >
              {option}
            </div>
          ))}
        </div>
      </>
    );
  };

  const handleSelect = (option, booking, day) => {
    const trip = booking[day];

    const newTimeslot = option == "Off" ? "" : option;
    if (trip.timeslot == newTimeslot) return;
    if (trip.id) {
      trip.status = option == "Off" ? "to_delete" : "updated";
    } else {
      trip.status = option == "Off" ? "unbooked" : "new";
    }
    trip.timeslot = option == "Off" ? "" : option;

    if (
      (trip.timeslot == "Off" && ((trip.trip_type == "outbound" && trip.location !== "") || (trip.trip_type == "inbound" && trip.destination !== ""))) ||
      (trip.timeslot !== "Off" && ((trip.trip_type == "outbound" && trip.location == "") || (trip.trip_type == "inbound" && trip.destination == "")))
    ) {
      trip.status = "incomplete";
    }

    if (trip.status == "to_delete" || trip.status == "unbooked") {
      if (trip.trip_type == "inbound") {
        trip["destination"] = "";
      } else {
        trip["location"] = "";
      }
    }

    // option == "Off" ? setWeeklyChangesMade(false) : setWeeklyChangesMade(true);
    updateBooking(trip.user_id, day, trip, booking.trip_type.toLowerCase());
    checkBackendBookings(trip);
  };

  const changesAllowed = () => {
    if (checkIncompleteBookings()) return false

    return (
      changedBookings.length > 0 ||
      newBookings.length > 0 ||
      toDeleteBookings.length > 0
    );
  };

  const handleConfirmBooking = () => {
    setShowPopup("weekly");
  };

  const checkBackendBookings = (trip) => {
    if (trip.status == "to_delete" && trip.id == null) {
      //a trip without an id is trying to get deleted. Say the user selected a timeslot, and then changed their mind and selected 'Off',
      if (
        (trip.trip_type == "inbound" && trip.destination == "") ||
        (trip.trip_type == "outbound" && trip.location == "")
      ) {
        //The trip doesn't have a destination, so it's not a new trip
        trip.status = "unbooked";
      } else {
        //The trip has a destination, so it's a new trip, but incomplete
        trip.status = "new";
      }
      updateBooking(
        trip.employee.id,
        trip.day,
        trip,
        trip.trip_type.toLowerCase()
      );
    }
    if (trip.id == null) return;
    Object.keys(bookedTrips[trip.trip_type]).forEach((date) => {
      const tripsForDay = bookedTrips[trip.trip_type][date];

      if (tripsForDay) {
        // Use moment.js to determine the day of the week
        const dayOfWeek = moment(date, "DD-MM-YYYY")
          .format("dddd")
          .toLowerCase();

        // Iterate over each trip in the current day for the current trip type
        // return if dayOfWeek is not the same as the trip day
        if (dayOfWeek != trip.day) return;
        tripsForDay.forEach((trip) => {
          const { user_id } = trip;

          // Find the corresponding booking in bookings based on trip ID and trip type
          const booking = bookings.find(
            (booking) =>
              booking.employee.id === user_id &&
              booking.trip_type.toLowerCase() === trip.trip_type
          );
          // return if dayOfWeek is not the same as the trip day
          if (booking[dayOfWeek] === undefined) return;
          const tempTrip = booking[dayOfWeek];
          if (
            tempTrip.id == trip.id &&
            trip.timeslot == tempTrip.timeslot &&
            trip.location == tempTrip.location &&
            trip.destination == tempTrip.destination
          ) {
            tempTrip.status = "booked";
            updateBooking(
              booking.employee.id,
              dayOfWeek,
              tempTrip,
              booking.trip_type.toLowerCase()
            );
          }
        });
      }
    });
  };

  const checkIncompleteBookings = () => {
    let hasIncompleteBookings = newBookings.some((trip) => trip.status == "incomplete")
    return hasIncompleteBookings;
  };

  const onHoverCheck = () => {
    if (checkIncompleteBookings() == false) {
      return "Please complete all fields before confirming bookings."
    } else if (!changesAllowed()) {
      return "No changes have been made."
    } else {
      setTooltipVisible(false)
    }
  }

  // Helper function to get the clear booking state for deleted bookings
  const handleDeleteBookings = () => {
    toDeleteBookings.forEach((trip) => {
      const booking = bookings.find(
        (booking) =>
          booking.employee.id === trip.user_id &&
          booking.trip_type.toLowerCase() === trip.trip_type
      );
      let tempTrip = booking[trip.day];
      tempTrip.id = null;
      tempTrip.status = "unbooked";
      tempTrip.timeslot = "";
      if (trip.trip_type == "inbound") {
        tempTrip.destination = "";
      } else if (trip.trip_type == "outbound") {
        tempTrip.location = "";
      }

      updateBooking(
        trip.user_id,
        trip.day,
        tempTrip,
        trip.trip_type.toLowerCase()
      );
    });
    setToDeleteBookings([]);
  };

  //Sorting method
  const sortBookings = (bookings, sortOrder) => {
    const sortedBookings = [...bookings];

    sortedBookings.sort((bookingA, bookingB) => {
      const nameA = bookingA.employee.name.toLowerCase();
      const nameB = bookingB.employee.name.toLowerCase();

      if (sortOrder === "asc") {
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
      } else if (sortOrder === "desc") {
        if (nameA > nameB) return -1;
        if (nameA < nameB) return 1;
      }

      return 0;
    });

    return sortedBookings;
  };

  const getCompanyTimeOut = () => {
    const time = moment(companyCutOffTime, "hh:mmA");
    const hour = time.hour();
    const minute = time.minute();
    return { hour: hour, minute: minute, second: 0 };
  };

  // Sort bookings based on the current sorting order
  const sortedBookings = sortBookings(filteredBookings, sortingOrder);

  //Clean up frontend bookings after deletions
  useEffect(() => {
    handleDeleteBookings();
  }, [cleanUpBookings]);

  //Filter via Search Box
  useEffect(() => {
    const searchedBookings = bookings.filter((booking) => {
      const full_name =
        `${booking.employee.name} ${booking.employee.surname}`.toLowerCase();
      return (
        full_name.includes(searchQuery?.toLowerCase()) &&
        booking.trip_type.toLowerCase() == view.toLowerCase()
      );
    });
    setFilteredBookings(searchedBookings);
  }, [searchQuery]);

  function generateWeekdayTrips(employees, selectedWeek) {
    const weekdays = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];

    const filteredEmployees = employees.filter((entry) => {
      const employeeId = entry.employee.id;
      return selectedEmployees.some((employee) => employee.id === employeeId);
    });

    const dateObject = moment(daySelected, "DD-MM-YYYY");
    const dayOfWeek = dateObject.format("dddd").toLowerCase();

    // only choose the selected day of the week using daOfWeek variable and select the day from weekdays array
    const selectedDay = weekdays.filter((day) => day == dayOfWeek);

    const trips = [];

    for (const employee of filteredEmployees) {
      const [startDate, endDate] = selectedWeek.split("|");
      const tripObj = {
        employee,
        trip_type: tripType,
      };

      const address =
        selectedCompanyAddress.name == "Select an Address"
          ? "Clear"
          : selectedCompanyAddress;

      for (const day of weekdays) {

        handleSelect(selectedTimeslot, tripObj.employee, selectedDay);
        handleSelectAddress(address, tripObj.employee, selectedDay);

        tripObj[day] = {
          id: null,
          timeslot: selectedTimeslot,
          destination:
            tripType === "inbound"
              ? selectedCompanyAddress.name == "Select an Address"
                ? "Clear"
                : selectedCompanyAddress.name
              : "",
          location:
            tripType === "outbound"
              ? employee.employee?.address
              : selectedCompanyAddress.name == "Select an Address"
                ? ""
                : selectedCompanyAddress.name,
          trip_type: tripType,
          status: "new",
          user_id: employee.employee.id,
          day: day,
          selected_week: `${startDate}|${endDate}`,
          company_id: employee.employee.company_id,
        };
      }

      trips.push(tripObj);
    }

    // spread the trips into the new bookings array
    setNewBookings([...newBookings, ...trips]);
    setSelectedEmployees([]);
    setSelectedTimeslot(null);
    setSelectedCompanyAddress({ name: "Select an Address" });

    trips.length > 0 && showToast("success", `Inserted ${trips.length} trips successfully`)

    return trips;
  }

  function generateDayTrips() {
    const trips = [];


    for (const _trip of lastWeekTrips) {

      const date = moment(_trip.date);

      // Get the weekday name
      const weekdayName = date.format("dddd").toLocaleLowerCase();
      const _existingCurrentWeekBooking = filteredBookings.find((booking) => (
        booking.employee.id === _trip.user_id &&
        booking.trip_type.toLowerCase() === _trip.trip_type.toLowerCase() &&
        booking[weekdayName]?.status !== "unbooked" &&
        booking[weekdayName]?.status !== "incomplete"
      ))

      const tripObj = {
        employee: _trip,
        trip_type: _trip.trip_type,
        [weekdayName]: {
          company_id: _trip.company_id,
          day: weekdayName,
          destination: _trip.destination,
          id: _existingCurrentWeekBooking ? _existingCurrentWeekBooking[weekdayName]?.id : null,
          location: _trip.location,
          selected_week: selectedWeek.value,
          status: _existingCurrentWeekBooking
            ?
            _existingCurrentWeekBooking[weekdayName]?.status == "booked" || _existingCurrentWeekBooking[weekdayName]?.status == "to_delete"
              ?
              "updated"
              :
              _existingCurrentWeekBooking[weekdayName]?.status
            :
            "new",
          timeslot: _trip.timeslot,
          trip_type: _trip.trip_type,
          user_id: _trip.user_id,
          employee_id: _trip.user_id,
        },
      };

      if (_existingCurrentWeekBooking) {
        if (_existingCurrentWeekBooking[weekdayName]?.timeslot == _trip.timeslot && _existingCurrentWeekBooking[weekdayName]?.location == _trip.location && _existingCurrentWeekBooking[weekdayName]?.destination == _trip.destination) {
          tripObj[weekdayName].status = "booked";
          return;
        }
      }

      updateBooking(
        tripObj.employee.user_id,
        weekdayName,
        tripObj[weekdayName],
        tripObj.trip_type.toLowerCase()
      );


      trips.push(tripObj[weekdayName]);
    }

    // Remove duplicate trips
    const uniqueTrips = Array.from(new Set(trips.map((a) => a.user_id))).map(
      (user_id) => {
        return trips.find((a) => a.user_id === user_id);
      }
    );

    setNewBookings([...uniqueTrips]);

    return trips;
  }


  useEffect(() => {
    generateWeekdayTrips(sortedBookings, selectedWeek.value);


  }, [preLoadBookings]);

  useEffect(() => {
    generateDayTrips();
  }, [lastWeekTrips]);

  //Extract new,changed and to be deleted trips for submission to backend
  useEffect(() => {
    const changedTrips = [];
    const newTrips = [];
    const deleteTrips = [];
    bookings.forEach((booking) => {
      Object.keys(booking).forEach((day) => {
        const trip = booking[day];
        if (trip.status == "updated") {
          changedTrips.push(trip);
        } else if (trip.status == "new") {
          newTrips.push(trip);
        } else if (trip.status == "to_delete") {
          deleteTrips.push(trip);
        } else if (trip.status == "incomplete") {
          newTrips.push(trip);
        }
      });
    });
    // Remove trips with status 'unbooked' from the newTrips array
    const filteredNewTrips = newTrips.filter(
      (trip) => trip.status !== "unbooked"
    );
    setChangedBookings(changedTrips);
    setNewBookings(filteredNewTrips);
    setToDeleteBookings(deleteTrips);
  }, [bookings]);

  // Function to handle the click outside the component
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        boxRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !boxRef.current.contains(event.target)
      ) {
        setTimeslotDropDownCell({
          index: null,
          day: null,
        });
        setAddressDropDownCell({
          index: null,
          day: null,
        });
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Clear changes when the selected week or view (daily/weekly) changes
  useEffect(() => {
    setChangedBookings([]);
    setNewBookings([]);
    setToDeleteBookings([]);
  }, [selectedWeek, pageView]);

  // Filter bookings based on the selected view (inbound/outbound)
  useEffect(() => {
    const filteredBookings = bookings.filter(
      (booking) => booking.trip_type?.toLowerCase() == view?.toLowerCase()
    );
    setFilteredBookings(filteredBookings);
  }, [bookings, view]);

  // Map backebd trips to bookings
  useEffect(() => {
    if (bookedTrips.length <= 0) return;
    try {
      // Iterate over each trip type (inbound/outbound)
      Object.keys(bookedTrips).forEach((tripType) => {
        // Iterate over each day (date) in the selected week
        Object.keys(bookedTrips[tripType]).forEach((date) => {
          const tripsForDay = bookedTrips[tripType][date];

          if (tripsForDay) {
            // Use moment.js to determine the day of the week
            const dayOfWeek = moment(date, "DD-MM-YYYY")
              .format("dddd")
              .toLowerCase();

            // Iterate over each trip in the current day for the current trip type
            tripsForDay.forEach((trip) => {
              const { user_id } = trip;

              // Find the corresponding booking in bookings based on user ID and trip type
              const booking = bookings.find(
                (booking) =>
                  booking.employee.id === user_id &&
                  booking.trip_type.toLowerCase() === tripType.toLowerCase()
              );

              if (booking) {
                const tempTrip = booking[dayOfWeek];
                tempTrip.id = trip.id;
                tempTrip.timeslot = trip.timeslot;
                tempTrip.destination = trip.destination;
                tempTrip.location = trip.location;
                tempTrip.status = "booked";

                updateBooking(
                  booking.employee.id,
                  dayOfWeek,
                  tempTrip,
                  tripType.toLowerCase()
                );
              }
            });
          }
        });
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }, [bookedTrips]);

  //Set Initial Bookings
  useEffect(() => {
    // Generate the initial booking rows from teamEmployees when the component mounts
    if (teamEmployees.length <= 0) return;
    let initialOutboundBookings = [];
    let initialInboundBookings = [];
    initialInboundBookings = teamEmployees.map((employee) => ({
      employee: employee,
      monday: {
        id: null,
        timeslot: "",
        destination: "",
        location: employee.address,
        trip_type: "inbound",
        status: "unbooked",
        user_id: employee.id,
        day: "monday",
        selected_week: selectedWeek.value,
        company_id: employee.company_id,
      },
      tuesday: {
        id: null,
        timeslot: "",
        destination: "",
        location: employee.address,
        trip_type: "inbound",
        status: "unbooked",
        user_id: employee.id,
        day: "tuesday",
        selected_week: selectedWeek.value,
        company_id: employee.company_id,
      },
      wednesday: {
        id: null,
        timeslot: "",
        destination: "",
        location: employee.address,
        trip_type: "inbound",
        status: "unbooked",
        user_id: employee.id,
        day: "wednesday",
        selected_week: selectedWeek.value,
        company_id: employee.company_id,
      },
      thursday: {
        id: null,
        timeslot: "",
        destination: "",
        location: employee.address,
        trip_type: "inbound",
        status: "unbooked",
        user_id: employee.id,
        day: "thursday",
        selected_week: selectedWeek.value,
        company_id: employee.company_id,
      },
      friday: {
        id: null,
        timeslot: "",
        destination: "",
        location: employee.address,
        trip_type: "inbound",
        status: "unbooked",
        user_id: employee.id,
        day: "friday",
        selected_week: selectedWeek.value,
        company_id: employee.company_id,
      },
      saturday: {
        id: null,
        timeslot: "",
        destination: "",
        location: employee.address,
        trip_type: "inbound",
        status: "unbooked",
        user_id: employee.id,
        day: "saturday",
        selected_week: selectedWeek.value,
        company_id: employee.company_id,
      },
      sunday: {
        id: null,
        timeslot: "",
        destination: "",
        location: employee.address,
        trip_type: "inbound",
        status: "unbooked",
        user_id: employee.id,
        day: "sunday",
        selected_week: selectedWeek.value,
        company_id: employee.company_id,
      },
      trip_type: "inbound",
    }));

    initialOutboundBookings = teamEmployees.map((employee) => ({
      employee: employee,
      monday: {
        id: null,
        timeslot: "",
        destination: employee.address,
        location: "",
        trip_type: "outbound",
        status: "unbooked",
        user_id: employee.id,
        day: "monday",
        selected_week: selectedWeek.value,
        company_id: employee.company_id,
      },
      tuesday: {
        id: null,
        timeslot: "",
        destination: employee.address,
        location: "",
        trip_type: "outbound",
        status: "unbooked",
        user_id: employee.id,
        day: "tuesday",
        selected_week: selectedWeek.value,
        company_id: employee.company_id,
      },
      wednesday: {
        id: null,
        timeslot: "",
        destination: employee.address,
        location: "",
        trip_type: "outbound",
        status: "unbooked",
        user_id: employee.id,
        day: "wednesday",
        selected_week: selectedWeek.value,
        company_id: employee.company_id,
      },
      thursday: {
        id: null,
        timeslot: "",
        destination: employee.address,
        location: "",
        trip_type: "outbound",
        status: "unbooked",
        user_id: employee.id,
        day: "thursday",
        selected_week: selectedWeek.value,
        company_id: employee.company_id,
      },
      friday: {
        id: null,
        timeslot: "",
        destination: employee.address,
        location: "",
        trip_type: "outbound",
        status: "unbooked",
        user_id: employee.id,
        day: "friday",
        selected_week: selectedWeek.value,
        company_id: employee.company_id,
      },
      saturday: {
        id: null,
        timeslot: "",
        destination: employee.address,
        location: "",
        trip_type: "outbound",
        status: "unbooked",
        user_id: employee.id,
        day: "saturday",
        selected_week: selectedWeek.value,
        company_id: employee.company_id,
      },
      sunday: {
        id: null,
        timeslot: "",
        destination: employee.address,
        location: "",
        trip_type: "outbound",
        status: "unbooked",
        user_id: employee.id,
        day: "sunday",
        selected_week: selectedWeek.value,
        company_id: employee.company_id,
      },
      trip_type: "outbound",
    }));

    const allBookings = [...initialInboundBookings, ...initialOutboundBookings];
    setBookings(allBookings);
  }, [teamEmployees, selectedWeek, pageView]);

  function parseDate(dateStr) {
    const [day, month, year] = dateStr.split("-").map(Number);
    return new Date(year, month - 1, day); // Month is 0-indexed
  }

  function formatDate(date) {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  return (
    <>
      {isAllowedToEditCsb ? (
        <div
          className={`${view === "inbound"
            ? "text-green-500 border-green-500"
            : "text-gray-500 border-orange-500"
            } grid grid-cols-8 border-2 overflow-visible rounded pb-24 overflow-y-auto dark-gray-scrollbar`}
          style={{ zIndex: 0, maxHeight: "800px" }}
        >
          {/* Headings */}

          <div
            style={{ borderTopLeftRadius: "0.5rem" }}
            className="p-2 text-sm text-gray-600 flex items-center justify-between border border-gray-200 font-semibold sticky top-0 z-10 bg-white "
          >
            Employee
            <button
              onClick={toggleSortingOrder}
              className="ml-1 mr-1 focus:outline-none font-thin text-gray-500 hover:text-gray-700"
            >
              <FontAwesomeIcon
                icon={sortingOrder === "asc" ? faSortAlphaAsc : faSortAlphaDesc}
              />
            </button>
          </div>
          {schedulerDays.map((day, index) => (
            <div
              className="p-2 text-sm text-gray-600 flex flex-col items-center justify-center border border-gray-200 font-semibold sticky top-0 z-10 bg-white "
              key={index}
            >
              <div className="flex justify-center items-center gap-2 mb-2 static">
                {moment(day.date, "DD-MM-YYYY").isSameOrAfter(moment(), "day") &&
                  moment(day.date, "DD-MM-YYYY").isSameOrBefore(
                    moment(selectedWeek.value.split("|")[1], "DD-MM-YYYY"),
                    "day"
                  ) && (
                    fetchingTrips ? (
                      <>
                        <div className="bg-transparent ">
                          <div className="loader ease-linear rounded-full border-2 border-gray-200 h-4 w-4 mb-1"></div>
                        </div>
                      </>
                    ) : (

                      <button onClick={() => {
                        setLastWeekDay(day.date);
                        openModalForLastWeekTrips()
                      }} className="p-1 bg-purple-500 mr-1 text-xl  rounded-md text-white flex justify-center items-center">
                        <FontAwesomeIcon icon={faHistory} className="text-xs" />
                      </button>


                    )
                  )}
                <p className="text-xs">
                  {day.day.slice(0, 3)} - {day.date.slice(0, 2)}
                </p>
              </div>

              <Countdown
                selectedDay={day.date}
                setTimesUp={setTimesUp}
                getCompanyTimeOut={getCompanyTimeOut}
                companyCutOffTime={companyCutOffTime}
                trip_type={view}
              />
            </div>
          ))}

          {/* Employees */}
          {sortedBookings.map((booking, index) => (
            <React.Fragment key={index}>
              <div
                className={`relative col-span-1 h-16 row-span-2 p-1 text-sm text-gray-700 flex items-center justify-center text-center border border-gray-200  ${index === sortedBookings.length - 1 ? "" : " border-b-2"
                  } ${booking.employee?.role === "manager" &&
                  "text-purple-500"
                  } border-t-2`}
              >
                <span
                  className="group text-sm"
                  onMouseEnter={() =>
                    booking.employee?.role === "manager" && setShowTooltip(true)
                  }
                  onMouseLeave={() => setShowTooltip(false)}
                >
                  {booking.employee?.name} {booking.employee?.surname} {booking.employee?.role === "manager" && <FontAwesomeIcon icon={faUsers} />}
                </span>


              </div>
              {/* Rest of the days */}
              <div
                className={`p-1 h-8 text-xs flex items-center justify-center border border-gray-200 border-t-2  cursor-pointer hover:bg-gray-100 hover:text-gray-500  relative
                ${booking.monday?.timeslot == "" ? "font-bold text-gray-700" : ""}
                ${timeIsUp(
                  booking.monday,
                  selectedWeek.value,
                  companyCutOffTime
                )
                    ? "bg-gray-200 text-gray-700"
                    : booking.monday?.status === "booked"
                      ? "bg-green-200 text-gray-700"
                      : booking.monday?.status === "incomplete"
                        ? "bg-red-200 text-gray-700"
                        : booking.monday?.status === "updated"
                          ? "bg-orange-200 text-gray-700"
                          : booking.monday?.status === "to_delete"
                            ? "bg-red-400 text-gray-700"
                            : booking.monday?.status === "new"
                              ? "bg-green-600 text-white"
                              : ""
                  }
                `}
                style={{ borderTopColor: "#CBD5E0" }}
                onClick={(e) => {
                  timeIsUp(booking.monday, selectedWeek.value, companyCutOffTime)
                    ? dayOfWeek === "monday" &&
                      (role === "admin" ||
                        role === "logistics_specialist" ||
                        role === "company_admin")
                      ? isTripPassed(schedulerDays, "Monday", booking.monday)
                      : isTripPassed(schedulerDays, "Monday", booking.monday)
                    : handleTimeslotCellClick(e, index, "monday");
                }}
              >
                {booking.monday?.timeslot ? booking.monday?.timeslot : "OFF"}
                {timeslotDropdownCell.index === index &&
                  timeslotDropdownCell.day === "monday" &&
                  renderTimeslotDropdown(booking, "monday")}
              </div>
              <div
                className={`p-1 h-8 text-xs flex items-center justify-center border border-gray-200 border-t-2   cursor-pointer hover:bg-gray-100 hover:text-gray-500 relative
                ${booking.tuesday?.timeslot == "" ? "font-bold text-gray-700" : ""}
                ${timeIsUp(
                  booking.tuesday,
                  selectedWeek.value,
                  companyCutOffTime
                )
                    ? "bg-gray-200 text-gray-700"
                    : booking.tuesday?.status === "booked"
                      ? "bg-green-200 text-gray-700"
                      : booking.tuesday?.status == "incomplete"
                        ? "bg-red-200 text-gray-700"
                        : booking.tuesday?.status == "updated"
                          ? "bg-orange-200 text-gray-700"
                          : booking.tuesday?.status == "to_delete"
                            ? "bg-red-400 text-gray-700"
                            : booking.tuesday?.status === "new"
                              ? "bg-green-600 text-white"
                              : ""
                  }`}
                onClick={(e) =>
                  timeIsUp(booking.tuesday, selectedWeek.value, companyCutOffTime)
                    ? dayOfWeek === "Tuesday" &&
                      (role === "admin" ||
                        role === "logistics_specialist" ||
                        role === "company_admin")
                      ? isTripPassed(schedulerDays, "Tuesday", booking.tuesday)
                      : isTripPassed(schedulerDays, "Tuesday", booking.tuesday)
                    : handleTimeslotCellClick(e, index, "tuesday")
                }
                style={{ borderTopColor: "#CBD5E0" }}
              >
                {booking.tuesday?.timeslot ? booking.tuesday?.timeslot : "OFF"}
                {timeslotDropdownCell.index === index &&
                  timeslotDropdownCell.day === "tuesday" &&
                  renderTimeslotDropdown(booking, "tuesday")}
              </div>
              <div
                className={`p-1 h-8 text-xs flex items-center justify-center border border-gray-200 border-t-2   cursor-pointer hover:bg-gray-100 hover:text-gray-500 relative
                ${booking.wednesday?.timeslot == "" ? "font-bold text-gray-700" : ""}
                ${timeIsUp(
                  booking.wednesday,
                  selectedWeek.value,
                  companyCutOffTime
                )
                    ? "bg-gray-200 text-gray-700"
                    : booking.wednesday?.status === "booked"
                      ? "bg-green-200 text-gray-700"
                      : booking.wednesday?.status == "incomplete"
                        ? "bg-red-200 text-gray-700"
                        : booking.wednesday?.status == "updated"
                          ? "bg-orange-200 text-gray-700"
                          : booking.wednesday?.status == "to_delete"
                            ? "bg-red-400 text-gray-700"
                            : booking.wednesday?.status === "new"
                              ? "bg-green-600 text-white"
                              : ""
                  }`}
                onClick={(e) =>
                  timeIsUp(
                    booking.wednesday,
                    selectedWeek.value,
                    companyCutOffTime
                  )
                    ? dayOfWeek === "Wednesday" &&
                      (role === "admin" ||
                        role === "logistics_specialist" ||
                        role === "company_admin")
                      ? isTripPassed(
                        schedulerDays,
                        "Wednesday",
                        booking.wednesday
                      )
                      : isTripPassed(
                        schedulerDays,
                        "Wednesday",
                        booking.wednesday
                      )
                    : handleTimeslotCellClick(e, index, "wednesday")
                }
                style={{ borderTopColor: "#CBD5E0" }}
              >
                {booking.wednesday?.timeslot
                  ? booking.wednesday?.timeslot
                  : "OFF"}
                {timeslotDropdownCell.index === index &&
                  timeslotDropdownCell.day === "wednesday" &&
                  renderTimeslotDropdown(booking, "wednesday")}
              </div>
              <div
                className={`p-1 h-8 text-xs flex items-center justify-center border border-gray-200 border-t-2   cursor-pointer hover:bg-gray-100 hover:text-gray-500 relative
                ${booking.thursday?.timeslot == "" ? "font-bold text-gray-700" : ""}
                ${timeIsUp(
                  booking.thursday,
                  selectedWeek.value,
                  companyCutOffTime
                )
                    ? "bg-gray-200 text-gray-700"
                    : booking.thursday?.status === "booked"
                      ? "bg-green-200 text-gray-700"
                      : booking.thursday?.status == "incomplete"
                        ? "bg-red-200 text-gray-700"
                        : booking.thursday?.status == "updated"
                          ? "bg-orange-200 text-gray-700"
                          : booking.thursday?.status == "to_delete"
                            ? "bg-red-400 text-gray-700"
                            : booking.thursday?.status === "new"
                              ? "bg-green-600 text-white"
                              : ""
                  }`}
                onClick={(e) =>
                  timeIsUp(
                    booking.thursday,
                    selectedWeek.value,
                    companyCutOffTime
                  )
                    ? dayOfWeek === "Thursday" &&
                      (role === "admin" ||
                        role === "logistics_specialist" ||
                        role === "company_admin")
                      ? isTripPassed(schedulerDays, "Thursday", booking.thursday)
                      : isTripPassed(schedulerDays, "Thursday", booking.thursday)
                    : handleTimeslotCellClick(e, index, "thursday")
                }
                style={{ borderTopColor: "#CBD5E0" }}
              >
                {booking.thursday?.timeslot ? booking.thursday?.timeslot : "OFF"}
                {timeslotDropdownCell.index === index &&
                  timeslotDropdownCell.day === "thursday" &&
                  renderTimeslotDropdown(booking, "thursday")}
              </div>
              <div
                className={`p-1 h-8 text-xs flex items-center justify-center border border-gray-200 border-t-2 cursor-pointer hover:bg-gray-100 hover:text-gray-500 relative
                ${booking.friday?.timeslot == "" ? "font-bold text-gray-700" : ""}
                ${timeIsUp(
                  booking.friday,
                  selectedWeek.value,
                  companyCutOffTime
                )
                    ? "bg-gray-200 text-gray-700"
                    : booking.friday?.status === "booked"
                      ? "bg-green-200 text-gray-700"
                      : booking.friday?.status == "incomplete"
                        ? "bg-red-200 text-gray-700"
                        : booking.friday?.status == "updated"
                          ? "bg-orange-200 text-gray-700"
                          : booking.friday?.status == "to_delete"
                            ? "bg-red-400 text-gray-700"
                            : booking.friday?.status === "new"
                              ? "bg-green-600 text-white"
                              : ""
                  }`}
                onClick={(e) =>
                  timeIsUp(booking.friday, selectedWeek.value, companyCutOffTime)
                    ? dayOfWeek === "Friday" &&
                      (role === "admin" ||
                        role === "logistics_specialist" ||
                        role === "company_admin")
                      ? isTripPassed(schedulerDays, "Friday", booking.friday)
                      : isTripPassed(schedulerDays, "Friday", booking.friday)
                    : handleTimeslotCellClick(e, index, "friday")
                }
                style={{ borderTopColor: "#CBD5E0" }}
              >
                {booking.friday?.timeslot ? booking.friday?.timeslot : "OFF"}
                {timeslotDropdownCell.index === index &&
                  timeslotDropdownCell.day === "friday" &&
                  renderTimeslotDropdown(booking, "friday")}
              </div>
              <div
                className={`p-1 h-8 text-xs flex items-center justify-center border border-gray-200 border-t-2   cursor-pointer hover:bg-gray-100 hover:text-gray-500 relative
                ${booking.saturday?.timeslot == "" ? "font-bold text-gray-700" : ""}
                ${timeIsUp(
                  booking.saturday,
                  selectedWeek.value,
                  companyCutOffTime
                )
                    ? "bg-gray-200 text-gray-700"
                    : booking.saturday?.status === "booked"
                      ? "bg-green-200 text-gray-700"
                      : booking.saturday?.status == "incomplete"
                        ? "bg-red-200 text-gray-700"
                        : booking.saturday?.status == "updated"
                          ? "bg-orange-200 text-gray-700"
                          : booking.saturday?.status == "to_delete"
                            ? "bg-red-400 text-gray-700"
                            : booking.saturday?.status === "new"
                              ? "bg-green-600 text-white"
                              : ""
                  }`}
                onClick={(e) =>
                  timeIsUp(
                    booking.saturday,
                    selectedWeek.value,
                    companyCutOffTime
                  )
                    ? dayOfWeek === "Saturday"
                      ? isTripPassed(schedulerDays, "Saturday", booking.saturday)
                      : isTripPassed(schedulerDays, "Saturday", booking.saturday)
                    : handleTimeslotCellClick(e, index, "saturday")
                }
                style={{ borderTopColor: "#CBD5E0" }}
              >
                {booking.saturday?.timeslot ? booking.saturday?.timeslot : "OFF"}
                {timeslotDropdownCell.index === index &&
                  timeslotDropdownCell.day === "saturday" &&
                  renderTimeslotDropdown(booking, "saturday")}
              </div>
              <div
                className={`p-1 h-8 text-xs  flex items-center justify-center border border-gray-200 border-t-2   cursor-pointer hover:bg-gray-100 hover:text-gray-500 relative
                ${booking.sunday?.timeslot == "" ? "font-bold text-gray-700" : ""}
                ${timeIsUp(
                  booking.sunday,
                  selectedWeek.value,
                  companyCutOffTime
                )
                    ? "bg-gray-200 text-gray-700"
                    : booking.sunday?.status === "booked"
                      ? "bg-green-200 text-gray-700"
                      : booking.sunday?.status == "incomplete"
                        ? "bg-red-200 text-gray-700"
                        : booking.sunday?.status == "updated"
                          ? "bg-orange-200 text-gray-700"
                          : booking.sunday?.status == "to_delete"
                            ? "bg-red-400 text-gray-700"
                            : booking.sunday?.status === "new"
                              ? "bg-green-600 text-white"
                              : ""
                  }`}
                onClick={(e) =>
                  timeIsUp(booking.sunday, selectedWeek.value, companyCutOffTime)
                    ? dayOfWeek === "Sunday" &&
                      (role === "admin" ||
                        role === "logistics_specialist" ||
                        role === "company_admin")
                      ? isTripPassed(schedulerDays, "Sunday", booking.sunday)
                      : isTripPassed(schedulerDays, "Sunday", booking.sunday)
                    : handleTimeslotCellClick(e, index, "sunday")
                }
                style={{ borderTopColor: "#CBD5E0" }}
              >
                {booking.sunday?.timeslot ? booking.sunday?.timeslot : "OFF"}
                {timeslotDropdownCell.index === index &&
                  timeslotDropdownCell.day === "sunday" &&
                  renderTimeslotDropdown(booking, "sunday")}
              </div>
              <div
                className={`p-1 h-8 text-xs flex items-center justify-center border border-gray-200  ${index === sortedBookings.length - 1 ? "" : " border-b-2"
                  }    text-xs cursor-pointer hover:bg-gray-100 hover:text-gray-500  relative
                ${timeIsUp(
                    booking.monday,
                    selectedWeek.value,
                    companyCutOffTime
                  )
                    ? "bg-gray-200 text-gray-700"
                    : booking.monday?.status === "booked"
                      ? "bg-green-200 text-gray-700"
                      : booking.monday?.status == "incomplete"
                        ? "bg-red-200 text-gray-700"
                        : booking.monday?.status == "updated"
                          ? "bg-orange-200 text-gray-700"
                          : booking.monday?.status == "to_delete"
                            ? "bg-red-400 text-gray-700"
                            : booking.monday?.status === "new"
                              ? "bg-green-600 text-white"
                              : ""
                  }`}
                style={{ borderBottomColor: "#CBD5E0" }}
                onClick={(e) =>
                  timeIsUp(booking.monday, selectedWeek.value, companyCutOffTime)
                    ? dayOfWeek === "Monday" &&
                      (role === "admin" ||
                        role === "logistics_specialist" ||
                        role === "company_admin")
                      ? isTripPassed(schedulerDays, "Monday", booking.monday)
                      : isTripPassed(schedulerDays, "Monday", booking.monday)
                    : handleAddressCellClick(e, index, "monday")
                }
              >
                {booking.trip_type == "inbound"
                  ? booking.monday?.destination?.split(",")[0]
                  : booking.monday?.location?.split(",")[0]}
                {addressDropdownCell.index === index &&
                  addressDropdownCell.day === "monday" &&
                  renderAddressDropdown(booking, "monday")}
              </div>
              <div
                className={`p-1 h-8 text-xs flex items-center justify-center border border-gray-200  ${index === sortedBookings.length - 1 ? "" : " border-b-2"
                  }    text-xs cursor-pointer hover:bg-gray-100 hover:text-gray-500  relative
                ${timeIsUp(
                    booking.tuesday,
                    selectedWeek.value,
                    companyCutOffTime
                  )
                    ? "bg-gray-200 text-gray-700"
                    : booking.tuesday?.status === "booked"
                      ? "bg-green-200 text-gray-700"
                      : booking.tuesday?.status == "incomplete"
                        ? "bg-red-200 text-gray-700"
                        : booking.tuesday?.status == "updated"
                          ? "bg-orange-200 text-gray-700"
                          : booking.tuesday?.status == "to_delete"
                            ? "bg-red-400 text-gray-700"
                            : booking.tuesday?.status === "new"
                              ? "bg-green-600 text-white"
                              : ""
                  }`}
                style={{ borderBottomColor: "#CBD5E0" }}
                onClick={(e) =>
                  timeIsUp(booking.tuesday, selectedWeek.value, companyCutOffTime)
                    ? dayOfWeek === "Tuesday" &&
                      (role === "admin" ||
                        role === "logistics_specialist" ||
                        role === "company_admin")
                      ? isTripPassed(schedulerDays, "Tuesday", booking.tuesday)
                      : isTripPassed(schedulerDays, "Tuesday", booking.tuesday)
                    : handleAddressCellClick(e, index, "tuesday")
                }
              >
                {booking.trip_type == "inbound"
                  ? booking.tuesday?.destination?.split(",")[0]
                  : booking.tuesday?.location?.split(",")[0]}
                {addressDropdownCell.index === index &&
                  addressDropdownCell.day === "tuesday" &&
                  renderAddressDropdown(booking, "tuesday")}
              </div>
              <div
                className={`p-1 h-8 text-xs flex items-center justify-center border border-gray-200  ${index === sortedBookings.length - 1 ? "" : " border-b-2"
                  }   text-xs cursor-pointer hover:bg-gray-100 hover:text-gray-500  relative
                ${timeIsUp(
                    booking.wednesday,
                    selectedWeek.value,
                    companyCutOffTime
                  )
                    ? "bg-gray-200 text-gray-700"
                    : booking.wednesday?.status === "booked"
                      ? "bg-green-200 text-gray-700"
                      : booking.wednesday?.status == "incomplete"
                        ? "bg-red-200 text-gray-700"
                        : booking.wednesday?.status == "updated"
                          ? "bg-orange-200 text-gray-700"
                          : booking.wednesday?.status == "to_delete"
                            ? "bg-red-400 text-gray-700"
                            : booking.wednesday?.status === "new"
                              ? "bg-green-600 text-white"
                              : ""
                  }`}
                style={{ borderBottomColor: "#CBD5E0" }}
                onClick={(e) =>
                  timeIsUp(
                    booking.wednesday,
                    selectedWeek.value,
                    companyCutOffTime
                  )
                    ? dayOfWeek === "Wednesday" &&
                      (role === "admin" ||
                        role === "logistics_specialist" ||
                        role === "company_admin")
                      ? isTripPassed(
                        schedulerDays,
                        "Wednesday",
                        booking.wednesday
                      )
                      : isTripPassed(
                        schedulerDays,
                        "Wednesday",
                        booking.wednesday
                      )
                    : handleAddressCellClick(e, index, "wednesday")
                }
              >
                {booking.trip_type == "inbound"
                  ? booking.wednesday?.destination?.split(",")[0]
                  : booking.wednesday?.location?.split(",")[0]}
                {addressDropdownCell.index === index &&
                  addressDropdownCell.day === "wednesday" &&
                  renderAddressDropdown(booking, "wednesday")}
              </div>
              <div
                className={`p-1 h-8 text-xs flex items-center justify-center  border border-gray-200  ${index === sortedBookings.length - 1 ? "" : " border-b-2"
                  }   text-xs cursor-pointer hover:bg-gray-100 hover:text-gray-500  relative
                ${timeIsUp(
                    booking.thursday,
                    selectedWeek.value,
                    companyCutOffTime
                  )
                    ? "bg-gray-200 text-gray-700"
                    : booking.thursday?.status === "booked"
                      ? "bg-green-200 text-gray-700"
                      : booking.thursday?.status == "incomplete"
                        ? "bg-red-200 text-gray-700"
                        : booking.thursday?.status == "updated"
                          ? "bg-orange-200 text-gray-700"
                          : booking.thursday?.status == "to_delete"
                            ? "bg-red-400 text-gray-700"
                            : booking.thursday?.status === "new"
                              ? "bg-green-600 text-white"
                              : ""
                  }`}
                style={{ borderBottomColor: "#CBD5E0" }}
                onClick={(e) =>
                  timeIsUp(
                    booking.thursday,
                    selectedWeek.value,
                    companyCutOffTime
                  )
                    ? dayOfWeek === "Thursday" &&
                      (role === "admin" ||
                        role === "logistics_specialist" ||
                        role === "company_admin")
                      ? isTripPassed(schedulerDays, "Thursday", booking.thursday)
                      : isTripPassed(schedulerDays, "Thursday", booking.thursday)
                    : handleAddressCellClick(e, index, "thursday")
                }
              >
                {booking.trip_type == "inbound"
                  ? booking.thursday?.destination?.split(",")[0]
                  : booking.thursday?.location?.split(",")[0]}
                {addressDropdownCell.index === index &&
                  addressDropdownCell.day === "thursday" &&
                  renderAddressDropdown(booking, "thursday")}
              </div>
              <div
                className={`p-1 h-8 text-xs flex items-center justify-center  border border-gray-200  ${index === sortedBookings.length - 1 ? "" : " border-b-2"
                  }   text-xs cursor-pointer hover:bg-gray-100 hover:text-gray-500  relative
                ${timeIsUp(
                    booking.friday,
                    selectedWeek.value,
                    companyCutOffTime
                  )
                    ? "bg-gray-200 text-gray-700"
                    : booking.friday?.status === "booked"
                      ? "bg-green-200 text-gray-700"
                      : booking.friday?.status == "incomplete"
                        ? "bg-red-200 text-gray-700"
                        : booking.friday?.status == "updated"
                          ? "bg-orange-200 text-gray-700"
                          : booking.friday?.status == "to_delete"
                            ? "bg-red-400 text-gray-700"
                            : booking.friday?.status === "new"
                              ? "bg-green-600 text-white"
                              : ""
                  }`}
                style={{ borderBottomColor: "#CBD5E0" }}
                onClick={(e) =>
                  timeIsUp(booking.friday, selectedWeek.value, companyCutOffTime)
                    ? dayOfWeek === "Friday" &&
                      (role === "admin" ||
                        role === "logistics_specialist" ||
                        role === "company_admin")
                      ? isTripPassed(schedulerDays, "Friday", booking.friday)
                      : isTripPassed(schedulerDays, "Friday", booking.friday)
                    : handleAddressCellClick(e, index, "friday")
                }
              >
                {booking.trip_type == "inbound"
                  ? booking.friday?.destination?.split(",")[0]
                  : booking.friday?.location?.split(",")[0]}
                {addressDropdownCell.index === index &&
                  addressDropdownCell.day === "friday" &&
                  renderAddressDropdown(booking, "friday")}
              </div>
              <div
                className={`p-1 h-8 text-xs  flex items-center justify-center border border-gray-200  ${index === sortedBookings.length - 1 ? "" : " border-b-2"
                  }   text-xs cursor-pointer hover:bg-gray-100 hover:text-gray-500  relative
                ${timeIsUp(
                    booking.saturday,
                    selectedWeek.value,
                    companyCutOffTime
                  )
                    ? "bg-gray-200 text-gray-700"
                    : booking.saturday?.status === "booked"
                      ? "bg-green-200 text-gray-700"
                      : booking.saturday?.status == "incomplete"
                        ? "bg-red-200 text-gray-700"
                        : booking.saturday?.status == "updated"
                          ? "bg-orange-200 text-gray-700"
                          : booking.saturday?.status == "to_delete"
                            ? "bg-red-400 text-gray-700"
                            : booking.saturday?.status === "new"
                              ? "bg-green-600 text-white"
                              : ""
                  }`}
                style={{ borderBottomColor: "#CBD5E0" }}
                onClick={(e) =>
                  timeIsUp(
                    booking.saturday,
                    selectedWeek.value,
                    companyCutOffTime
                  )
                    ? dayOfWeek === "Saturday"
                      ? isTripPassed(schedulerDays, "Saturday", booking.saturday)
                      : isTripPassed(schedulerDays, "Saturday", booking.saturday)
                    : handleAddressCellClick(e, index, "saturday")
                }
              >
                {booking.trip_type == "inbound"
                  ? booking.saturday?.destination?.split(",")[0]
                  : booking.saturday?.location?.split(",")[0]}
                {addressDropdownCell.index === index &&
                  addressDropdownCell.day === "saturday" &&
                  renderAddressDropdown(booking, "saturday")}
              </div>
              <div
                style={{
                  borderBottomRightRadius:
                    index === sortedBookings.length - 1 ? "0.5rem" : "0",
                  borderBottomColor: "#CBD5E0",
                }}
                className={`p-1 h-8 text-xs  flex items-center justify-center border border-gray-200  ${index === sortedBookings.length - 1 ? "" : " border-b-2"
                  } text-xs cursor-pointer hover:bg-gray-100 hover:text-gray-500 relative
                ${timeIsUp(
                    booking.sunday,
                    selectedWeek.value,
                    companyCutOffTime
                  )
                    ? "bg-gray-200 text-gray-700"
                    : booking.sunday?.status === "booked"
                      ? "bg-green-200 text-gray-700"
                      : booking.sunday?.status == "incomplete"
                        ? "bg-red-200 text-gray-700"
                        : booking.sunday?.status == "updated"
                          ? "bg-orange-200 text-gray-700"
                          : booking.sunday?.status == "to_delete"
                            ? "bg-red-400 text-gray-700"
                            : booking.sunday?.status === "new"
                              ? "bg-green-600 text-white"
                              : ""
                  }`}
                onClick={(e) =>
                  timeIsUp(booking.sunday, selectedWeek.value, companyCutOffTime)
                    ? dayOfWeek === "Sunday" &&
                      (role === "admin" ||
                        role === "logistics_specialist" ||
                        role === "company_admin")
                      ? isTripPassed(schedulerDays, "Sunday", booking.sunday)
                      : isTripPassed(schedulerDays, "Sunday", booking.sunday)
                    : handleAddressCellClick(e, index, "sunday")
                }
              >
                {booking.trip_type == "inbound"
                  ? booking.sunday?.destination?.split(",")[0]
                  : booking.sunday?.location?.split(",")[0]}
                {addressDropdownCell.index === index &&
                  addressDropdownCell.day === "sunday" &&
                  renderAddressDropdown(booking, "sunday")}
              </div>
            </React.Fragment>
          ))}
        </div>
      ) : (
        // is not AllowedToEditCsb
        <div
          className={`${view === "inbound"
            ? "text-green-500 border-green-500"
            : "text-gray-500 border-orange-500 "
            } grid grid-cols-8 border-2 overflow-visible  overflow-y-auto dark-gray-scrollbar`}
          style={{ borderRadius: "0.5rem", zIndex: 0, maxHeight: "800px" }}
        >
          {/* Headings */}

          <div
            style={{ borderTopLeftRadius: "0.5rem" }}
            className="p-2 text-sm text-gray-600 flex items-center justify-between border border-gray-200 font-semibold sticky top-0 z-10 bg-white "
          >
            Employee
            <button
              onClick={toggleSortingOrder}
              className="ml-1 mr-1 focus:outline-none font-thin text-gray-500 hover:text-gray-700"
            >
              <FontAwesomeIcon
                icon={sortingOrder === "asc" ? faSortAlphaAsc : faSortAlphaDesc}
              />
            </button>
          </div>
          {schedulerDays.map((day, index) => (
            <div
              className="p-2 text-sm text-gray-600 flex flex-col items-center justify-center border border-gray-200 font-semibold sticky top-0 z-10 bg-white "
              key={index}
            >
              <div className="flex justify-center items-center gap-2 static">
                <p className="text-xs">
                  {day.day.slice(0, 3)} - {day.date.slice(0, 2)}
                </p>
              </div>
              <Countdown
                selectedDay={day.date}
                setTimesUp={setTimesUp}
                getCompanyTimeOut={getCompanyTimeOut}
                companyCutOffTime={companyCutOffTime}
                trip_type={view}
              />
            </div>
          ))}

          {/* Employees */}
          {sortedBookings.map((booking, index) => (
            <React.Fragment key={index}>
              <div
                className={`relative col-span-1 h-16 row-span-2 p-1 text-sm text-gray-700 flex items-center justify-center text-center border border-gray-200  ${index === sortedBookings.length - 1 ? "" : " border-b-2"
                  } ${booking.employee?.role === "manager" &&
                  "text-purple-500"
                  } border-t-2`}
              >
                <span
                  className="group text-sm"
                  onMouseEnter={() =>
                    booking.employee?.role === "manager" && setShowTooltip(true)
                  }
                  onMouseLeave={() => setShowTooltip(false)}
                >
                  {booking.employee?.name} {booking.employee?.surname} {booking.employee?.role === "manager" && <FontAwesomeIcon icon={faUsers} />}
                </span>


              </div>
              {/* Rest of the days */}
              <div
                className={`p-1 h-8 text-xs flex items-center justify-center border border-gray-200 border-t-2  cursor-pointer hover:bg-gray-100 hover:text-gray-500  relative
                  ${booking.monday?.timeslot == "" ? "font-bold text-gray-700" : ""}
                  ${timeIsUp(
                  booking.monday,
                  selectedWeek.value,
                  companyCutOffTime
                )
                    ? "bg-gray-200 text-gray-700"
                    : booking.monday?.status === "booked"
                      ? "bg-green-200 text-gray-700"
                      : booking.monday?.status === "incomplete"
                        ? "bg-red-200 text-gray-700"
                        : booking.monday?.status === "updated"
                          ? "bg-orange-200 text-gray-700"
                          : booking.monday?.status === "to_delete"
                            ? "bg-red-400 text-gray-700"
                            : booking.monday?.status === "new"
                              ? "bg-green-600 text-white"
                              : ""
                  }
                  `}
                style={{ borderTopColor: "#CBD5E0" }}
                onClick={() => { }}
              >
                {booking.monday?.timeslot ? booking.monday?.timeslot : "OFF"}
                {timeslotDropdownCell.index === index &&
                  timeslotDropdownCell.day === "monday" &&
                  renderTimeslotDropdown(booking, "monday")}
              </div>
              <div
                className={`p-1 h-8 text-xs flex items-center justify-center border border-gray-200 border-t-2   cursor-pointer hover:bg-gray-100 hover:text-gray-500 relative
                  ${booking.tuesday?.timeslot == "" ? "font-bold text-gray-700" : ""}
                  ${timeIsUp(
                  booking.tuesday,
                  selectedWeek.value,
                  companyCutOffTime
                )
                    ? "bg-gray-200 text-gray-700"
                    : booking.tuesday?.status === "booked"
                      ? "bg-green-200 text-gray-700"
                      : booking.tuesday?.status == "incomplete"
                        ? "bg-red-200 text-gray-700"
                        : booking.tuesday?.status == "updated"
                          ? "bg-orange-200 text-gray-700"
                          : booking.tuesday?.status == "to_delete"
                            ? "bg-red-400 text-gray-700"
                            : booking.tuesday?.status === "new"
                              ? "bg-green-600 text-white"
                              : ""
                  }`}
                onClick={() => { }}
                style={{ borderTopColor: "#CBD5E0" }}
              >
                {booking.tuesday?.timeslot ? booking.tuesday?.timeslot : "OFF"}
                {timeslotDropdownCell.index === index &&
                  timeslotDropdownCell.day === "tuesday" &&
                  renderTimeslotDropdown(booking, "tuesday")}
              </div>
              <div
                className={`p-1 h-8 text-xs flex items-center justify-center border border-gray-200 border-t-2   cursor-pointer hover:bg-gray-100 hover:text-gray-500 relative
                  ${booking.wednesday?.timeslot == "" ? "font-bold text-gray-700" : ""}
                  ${timeIsUp(
                  booking.wednesday,
                  selectedWeek.value,
                  companyCutOffTime
                )
                    ? "bg-gray-200 text-gray-700"
                    : booking.wednesday?.status === "booked"
                      ? "bg-green-200 text-gray-700"
                      : booking.wednesday?.status == "incomplete"
                        ? "bg-red-200 text-gray-700"
                        : booking.wednesday?.status == "updated"
                          ? "bg-orange-200 text-gray-700"
                          : booking.wednesday?.status == "to_delete"
                            ? "bg-red-400 text-gray-700"
                            : booking.wednesday?.status === "new"
                              ? "bg-green-600 text-white"
                              : ""
                  }`}
                onClick={() => { }}
                style={{ borderTopColor: "#CBD5E0" }}
              >
                {booking.wednesday?.timeslot
                  ? booking.wednesday?.timeslot
                  : "OFF"}
                {timeslotDropdownCell.index === index &&
                  timeslotDropdownCell.day === "wednesday" &&
                  renderTimeslotDropdown(booking, "wednesday")}
              </div>
              <div
                className={`p-1 h-8 text-xs flex items-center justify-center border border-gray-200 border-t-2   cursor-pointer hover:bg-gray-100 hover:text-gray-500 relative
                  ${booking.thursday?.timeslot == "" ? "font-bold text-gray-700" : ""}
                  ${timeIsUp(
                  booking.thursday,
                  selectedWeek.value,
                  companyCutOffTime
                )
                    ? "bg-gray-200 text-gray-700"
                    : booking.thursday?.status === "booked"
                      ? "bg-green-200 text-gray-700"
                      : booking.thursday?.status == "incomplete"
                        ? "bg-red-200 text-gray-700"
                        : booking.thursday?.status == "updated"
                          ? "bg-orange-200 text-gray-700"
                          : booking.thursday?.status == "to_delete"
                            ? "bg-red-400 text-gray-700"
                            : booking.thursday?.status === "new"
                              ? "bg-green-600 text-white"
                              : ""
                  }`}
                onClick={() => { }}
                style={{ borderTopColor: "#CBD5E0" }}
              >
                {booking.thursday?.timeslot ? booking.thursday?.timeslot : "OFF"}
                {timeslotDropdownCell.index === index &&
                  timeslotDropdownCell.day === "thursday" &&
                  renderTimeslotDropdown(booking, "thursday")}
              </div>
              <div
                className={`p-1 h-8 text-xs flex items-center justify-center border border-gray-200 border-t-2 cursor-pointer hover:bg-gray-100 hover:text-gray-500 relative
                  ${booking.friday?.timeslot == "" ? "font-bold text-gray-700" : ""}
                  ${timeIsUp(
                  booking.friday,
                  selectedWeek.value,
                  companyCutOffTime
                )
                    ? "bg-gray-200 text-gray-700"
                    : booking.friday?.status === "booked"
                      ? "bg-green-200 text-gray-700"
                      : booking.friday?.status == "incomplete"
                        ? "bg-red-200 text-gray-700"
                        : booking.friday?.status == "updated"
                          ? "bg-orange-200 text-gray-700"
                          : booking.friday?.status == "to_delete"
                            ? "bg-red-400 text-gray-700"
                            : booking.friday?.status === "new"
                              ? "bg-green-600 text-white"
                              : ""
                  }`}
                onClick={() => { }}
                style={{ borderTopColor: "#CBD5E0" }}
              >
                {booking.friday?.timeslot ? booking.friday?.timeslot : "OFF"}
                {timeslotDropdownCell.index === index &&
                  timeslotDropdownCell.day === "friday" &&
                  renderTimeslotDropdown(booking, "friday")}
              </div>
              <div
                className={`p-1 h-8 text-xs flex items-center justify-center border border-gray-200 border-t-2   cursor-pointer hover:bg-gray-100 hover:text-gray-500 relative
                  ${booking.saturday?.timeslot == "" ? "font-bold text-gray-700" : ""}
                  ${timeIsUp(
                  booking.saturday,
                  selectedWeek.value,
                  companyCutOffTime
                )
                    ? "bg-gray-200 text-gray-700"
                    : booking.saturday?.status === "booked"
                      ? "bg-green-200 text-gray-700"
                      : booking.saturday?.status == "incomplete"
                        ? "bg-red-200 text-gray-700"
                        : booking.saturday?.status == "updated"
                          ? "bg-orange-200 text-gray-700"
                          : booking.saturday?.status == "to_delete"
                            ? "bg-red-400 text-gray-700"
                            : booking.saturday?.status === "new"
                              ? "bg-green-600 text-white"
                              : ""
                  }`}
                onClick={() => { }}
                style={{ borderTopColor: "#CBD5E0" }}
              >
                {booking.saturday?.timeslot ? booking.saturday?.timeslot : "OFF"}
                {timeslotDropdownCell.index === index &&
                  timeslotDropdownCell.day === "saturday" &&
                  renderTimeslotDropdown(booking, "saturday")}
              </div>
              <div
                className={`p-1 h-8 text-xs  flex items-center justify-center border border-gray-200 border-t-2   cursor-pointer hover:bg-gray-100 hover:text-gray-500 relative
                  ${booking.sunday?.timeslot == "" ? "font-bold text-gray-700" : ""}
                  ${timeIsUp(
                  booking.sunday,
                  selectedWeek.value,
                  companyCutOffTime
                )
                    ? "bg-gray-200 text-gray-700"
                    : booking.sunday?.status === "booked"
                      ? "bg-green-200 text-gray-700"
                      : booking.sunday?.status == "incomplete"
                        ? "bg-red-200 text-gray-700"
                        : booking.sunday?.status == "updated"
                          ? "bg-orange-200 text-gray-700"
                          : booking.sunday?.status == "to_delete"
                            ? "bg-red-400 text-gray-700"
                            : booking.sunday?.status === "new"
                              ? "bg-green-600 text-white"
                              : ""
                  }`}
                onClick={() => { }}
                style={{ borderTopColor: "#CBD5E0" }}
              >
                {booking.sunday?.timeslot ? booking.sunday?.timeslot : "OFF"}
                {timeslotDropdownCell.index === index &&
                  timeslotDropdownCell.day === "sunday" &&
                  renderTimeslotDropdown(booking, "sunday")}
              </div>
              <div
                className={`p-1 h-8 text-xs flex items-center justify-center border border-gray-200  ${index === sortedBookings.length - 1 ? "" : " border-b-2"
                  }    text-xs cursor-pointer hover:bg-gray-100 hover:text-gray-500  relative
                  ${timeIsUp(
                    booking.monday,
                    selectedWeek.value,
                    companyCutOffTime
                  )
                    ? "bg-gray-200 text-gray-700"
                    : booking.monday?.status === "booked"
                      ? "bg-green-200 text-gray-700"
                      : booking.monday?.status == "incomplete"
                        ? "bg-red-200 text-gray-700"
                        : booking.monday?.status == "updated"
                          ? "bg-orange-200 text-gray-700"
                          : booking.monday?.status == "to_delete"
                            ? "bg-red-400 text-gray-700"
                            : booking.monday?.status === "new"
                              ? "bg-green-600 text-white"
                              : ""
                  }`}
                style={{ borderBottomColor: "#CBD5E0" }}
                onClick={() => { }}
              >
                {booking.trip_type == "inbound"
                  ? booking.monday?.destination?.split(",")[0]
                  : booking.monday?.location?.split(",")[0]}
                {addressDropdownCell.index === index &&
                  addressDropdownCell.day === "monday" &&
                  renderAddressDropdown(booking, "monday")}
              </div>
              <div
                className={`p-1 h-8 text-xs flex items-center justify-center border border-gray-200  ${index === sortedBookings.length - 1 ? "" : " border-b-2"
                  }    text-xs cursor-pointer hover:bg-gray-100 hover:text-gray-500  relative
                  ${timeIsUp(
                    booking.tuesday,
                    selectedWeek.value,
                    companyCutOffTime
                  )
                    ? "bg-gray-200 text-gray-700"
                    : booking.tuesday?.status === "booked"
                      ? "bg-green-200 text-gray-700"
                      : booking.tuesday?.status == "incomplete"
                        ? "bg-red-200 text-gray-700"
                        : booking.tuesday?.status == "updated"
                          ? "bg-orange-200 text-gray-700"
                          : booking.tuesday?.status == "to_delete"
                            ? "bg-red-400 text-gray-700"
                            : booking.tuesday?.status === "new"
                              ? "bg-green-600 text-white"
                              : ""
                  }`}
                style={{ borderBottomColor: "#CBD5E0" }}
                onClick={() => { }}
              >
                {booking.trip_type == "inbound"
                  ? booking.tuesday?.destination?.split(",")[0]
                  : booking.tuesday?.location?.split(",")[0]}
                {addressDropdownCell.index === index &&
                  addressDropdownCell.day === "tuesday" &&
                  renderAddressDropdown(booking, "tuesday")}
              </div>
              <div
                className={`p-1 h-8 text-xs flex items-center justify-center border border-gray-200  ${index === sortedBookings.length - 1 ? "" : " border-b-2"
                  }   text-xs cursor-pointer hover:bg-gray-100 hover:text-gray-500  relative
                  ${timeIsUp(
                    booking.wednesday,
                    selectedWeek.value,
                    companyCutOffTime
                  )
                    ? "bg-gray-200 text-gray-700"
                    : booking.wednesday?.status === "booked"
                      ? "bg-green-200 text-gray-700"
                      : booking.wednesday?.status == "incomplete"
                        ? "bg-red-200 text-gray-700"
                        : booking.wednesday?.status == "updated"
                          ? "bg-orange-200 text-gray-700"
                          : booking.wednesday?.status == "to_delete"
                            ? "bg-red-400 text-gray-700"
                            : booking.wednesday?.status === "new"
                              ? "bg-green-600 text-white"
                              : ""
                  }`}
                style={{ borderBottomColor: "#CBD5E0" }}
                onClick={() => { }}
              >
                {booking.trip_type == "inbound"
                  ? booking.wednesday?.destination?.split(",")[0]
                  : booking.wednesday?.location?.split(",")[0]}
                {addressDropdownCell.index === index &&
                  addressDropdownCell.day === "wednesday" &&
                  renderAddressDropdown(booking, "wednesday")}
              </div>
              <div
                className={`p-1 h-8 text-xs flex items-center justify-center  border border-gray-200  ${index === sortedBookings.length - 1 ? "" : " border-b-2"
                  }   text-xs cursor-pointer hover:bg-gray-100 hover:text-gray-500  relative
                  ${timeIsUp(
                    booking.thursday,
                    selectedWeek.value,
                    companyCutOffTime
                  )
                    ? "bg-gray-200 text-gray-700"
                    : booking.thursday?.status === "booked"
                      ? "bg-green-200 text-gray-700"
                      : booking.thursday?.status == "incomplete"
                        ? "bg-red-200 text-gray-700"
                        : booking.thursday?.status == "updated"
                          ? "bg-orange-200 text-gray-700"
                          : booking.thursday?.status == "to_delete"
                            ? "bg-red-400 text-gray-700"
                            : booking.thursday?.status === "new"
                              ? "bg-green-600 text-white"
                              : ""
                  }`}
                style={{ borderBottomColor: "#CBD5E0" }}
                onClick={() => { }}
              >
                {booking.trip_type == "inbound"
                  ? booking.thursday?.destination?.split(",")[0]
                  : booking.thursday?.location?.split(",")[0]}
                {addressDropdownCell.index === index &&
                  addressDropdownCell.day === "thursday" &&
                  renderAddressDropdown(booking, "thursday")}
              </div>
              <div
                className={`p-1 h-8 text-xs flex items-center justify-center  border border-gray-200  ${index === sortedBookings.length - 1 ? "" : " border-b-2"
                  }   text-xs cursor-pointer hover:bg-gray-100 hover:text-gray-500  relative
                  ${timeIsUp(
                    booking.friday,
                    selectedWeek.value,
                    companyCutOffTime
                  )
                    ? "bg-gray-200 text-gray-700"
                    : booking.friday?.status === "booked"
                      ? "bg-green-200 text-gray-700"
                      : booking.friday?.status == "incomplete"
                        ? "bg-red-200 text-gray-700"
                        : booking.friday?.status == "updated"
                          ? "bg-orange-200 text-gray-700"
                          : booking.friday?.status == "to_delete"
                            ? "bg-red-400 text-gray-700"
                            : booking.friday?.status === "new"
                              ? "bg-green-600 text-white"
                              : ""
                  }`}
                style={{ borderBottomColor: "#CBD5E0" }}
                onClick={() => { }}
              >
                {booking.trip_type == "inbound"
                  ? booking.friday?.destination?.split(",")[0]
                  : booking.friday?.location?.split(",")[0]}
                {addressDropdownCell.index === index &&
                  addressDropdownCell.day === "friday" &&
                  renderAddressDropdown(booking, "friday")}
              </div>
              <div
                className={`p-1 h-8 text-xs  flex items-center justify-center border border-gray-200  ${index === sortedBookings.length - 1 ? "" : " border-b-2"
                  }   text-xs cursor-pointer hover:bg-gray-100 hover:text-gray-500  relative
                  ${timeIsUp(
                    booking.saturday,
                    selectedWeek.value,
                    companyCutOffTime
                  )
                    ? "bg-gray-200 text-gray-700"
                    : booking.saturday?.status === "booked"
                      ? "bg-green-200 text-gray-700"
                      : booking.saturday?.status == "incomplete"
                        ? "bg-red-200 text-gray-700"
                        : booking.saturday?.status == "updated"
                          ? "bg-orange-200 text-gray-700"
                          : booking.saturday?.status == "to_delete"
                            ? "bg-red-400 text-gray-700"
                            : booking.saturday?.status === "new"
                              ? "bg-green-600 text-white"
                              : ""
                  }`}
                style={{ borderBottomColor: "#CBD5E0" }}
                onClick={() => { }}
              >
                {booking.trip_type == "inbound"
                  ? booking.saturday?.destination?.split(",")[0]
                  : booking.saturday?.location?.split(",")[0]}
                {addressDropdownCell.index === index &&
                  addressDropdownCell.day === "saturday" &&
                  renderAddressDropdown(booking, "saturday")}
              </div>
              <div
                style={{
                  borderBottomRightRadius:
                    index === sortedBookings.length - 1 ? "0.5rem" : "0",
                  borderBottomColor: "#CBD5E0",
                }}
                className={`p-1 h-8 text-xs  flex items-center justify-center border border-gray-200  ${index === sortedBookings.length - 1 ? "" : " border-b-2"
                  } text-xs cursor-pointer hover:bg-gray-100 hover:text-gray-500 relative
                  ${timeIsUp(
                    booking.sunday,
                    selectedWeek.value,
                    companyCutOffTime
                  )
                    ? "bg-gray-200 text-gray-700"
                    : booking.sunday?.status === "booked"
                      ? "bg-green-200 text-gray-700"
                      : booking.sunday?.status == "incomplete"
                        ? "bg-red-200 text-gray-700"
                        : booking.sunday?.status == "updated"
                          ? "bg-orange-200 text-gray-700"
                          : booking.sunday?.status == "to_delete"
                            ? "bg-red-400 text-gray-700"
                            : booking.sunday?.status === "new"
                              ? "bg-green-600 text-white"
                              : ""
                  }`}
                onClick={() => { }}
              >
                {booking.trip_type == "inbound"
                  ? booking.sunday?.destination?.split(",")[0]
                  : booking.sunday?.location?.split(",")[0]}
                {addressDropdownCell.index === index &&
                  addressDropdownCell.day === "sunday" &&
                  renderAddressDropdown(booking, "sunday")}
              </div>
            </React.Fragment>
          ))}
        </div>
      )}


      {teamEmployees.length > 0 && isAllowedToEditCsb === true && (
        <>
          <div
            className="w-full justify-center text-gray-400 ml-2 mt-1"
            style={{ fontSize: "14px" }}
          >
            {teamEmployees.length} team member(s)
          </div>
          <div className="w-full flex justify-end gap-4">
            {(newBookings.length > 0 || changedBookings.length > 0 || toDeleteBookings.length > 0) && (
              <button
                onClick={resetNewBookings}
                className="px-4 py-2 rounded text-sm text-white cursor-pointer bg-purple-500 hover:bg-purple-600"
              >
                Reset Bookings
              </button>
            )}

            <div
              className={`p-2 text-white text-sm rounded-lg flex align-center justify-center relative ${(!changesAllowed()) ? "cursor-not-allowed bg-gray-300" : "cursor-pointer bg-green-500 hover:bg-green-600"} `}
              style={{ width: '156px', userSelect: 'none' }}
              onClick={() => { !changesAllowed() ? onHoverCheck() : handleConfirmBooking() }}
              onMouseEnter={toggleTooltip}
              onMouseLeave={toggleTooltip}
            >
              Confirm Bookings
              {
                tooltipVisible && (
                  <div className='tooltip-weekly w-full'>{onHoverCheck()}</div>
                )
              }
            </div>
          </div>
        </>
      )}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="modal-bg absolute inset-0 bg-gray-800 opacity-75"></div>
          <div className="modal relative bg-white p-6 rounded-lg shadow-lg w-1/4">
            <p className="mb-4">
              Timer has expired. <br /> Would you like to schedule an ad-hoc booking?
            </p>
            <div className="flex justify-end">
              <button
                onClick={handleNoClick}
                className="bg-gray-500 hover:bg-gray-300 text-white font-bold py-2 px-4 rounded mr-2"
              >
                Cancel
              </button>
              <button
                onClick={handleYesClick}
                className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded "
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Handle Applying last week trips */}
      <>

        {isLastWeekModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50 overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="relative z-50 w-auto my-6 mx-auto max-w-xl">
              <div className="bg-white rounded-lg shadow-lg outline-none focus:outline-none">
                <div className="p-6">
                  <h3 className="text-lg font-semibold">
                    Reschedule Bookings from Last Week
                  </h3>
                  <p className="mt-2 text-gray-600 font-normal">
                    Apply last week{" "}
                    <span className="text-purple-500 font-semibold capitalize">
                      {moment(lastWeekDay, "DD-MM-YYYY").format("dddd").toLowerCase()}'s
                    </span>{" "}
                    bookings to this week <span className="capitalize">{moment(lastWeekDay, "DD-MM-YYYY").format("dddd").toLowerCase()}</span> as well?
                  </p>
                </div>
                <div className="flex items-center justify-end px-6 py-4 bg-gray-50 rounded-b">
                  <button
                    className="mr-4 px-4 py-2 text-sm font-semibold text-white bg-purple-500 rounded hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500"
                    onClick={handleApplyTrips}
                  >
                    Yes
                  </button>
                  <button
                    className="px-4 py-2 text-sm font-semibold text-gray-700 bg-gray-200 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500"
                    onClick={closeLastWeekModal}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
            <div className="absolute inset-0 bg-gray-500 opacity-75 z-40"></div>
          </div>
        )}
      </>
    </>
  );
};

export default WeeklyTable;
