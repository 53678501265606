import React, { useRef } from 'react';
import ChartComponent from './ChartComponent';
import Dropdown from './Dropdown';
import DateDropdown from './DateDropdown';
import StatsTable from './StatsTable';
import { jsPDF } from 'jspdf';
import headerImg from '../../../../assets/images/Header-02.png';
import CalendarComponent from './CalendarComponent';

const DashMain = ({
  date_ranges,
  companies,
  regions,
  trips,
  kms,
  cancellations,
  bookings,
  recent_bookings,
  costs,
  selectedDateRange,
  setSelectedDateRange,
  showTooltip,
  setShowTooltip,
  groupedData,
  setGroupedData,
  searchQuery,
  setSearchQuery,
  tripsGraph,
  setTripsGraph,
  kmsGraph,
  setKmsGraph,
  theme,
}) => {
  const labels = [];
  const tripsData = [];
  const kmsData = [];
  const bookingsData = [];
  const cancellationsData = [];

  const tripChartRef = useRef(null);
  const kmChartRef = useRef(null);

  let companyList = [];

  {
    Object.keys(regions).map((region) => {
      regions[region].map((location) => {
        companyList.push({
          region: region,
          office: location.name,
        });

        const regionAcronym = region.match(/[A-Z]/g);
        let regionAcronymStr = regionAcronym ? regionAcronym.join('') : '';
        if (region === 'Gauteng') {
          regionAcronymStr = 'GP';
        }

        const textToDisplay =
          location.name.length > 12
            ? location.name.slice(0, 6) + '...'
            : location.name;

        labels.push(`${regionAcronymStr}: ${textToDisplay}`);
      });
    });
  }

  companyList.map((company) => {
    tripsData.push(trips[company.office] || 0);
    kmsData.push(kms[company.office] || 0);
    bookingsData.push(bookings[company.office] || 0);
    cancellationsData.push(cancellations[company.office] || 0);
  });

  const max_value_bookings = Math.ceil(Math.max(...bookingsData) / 10) * 10;
  const max_value_cancellations =
    Math.ceil(Math.max(...cancellationsData) / 10) * 10;
  const max_value_trips = Math.ceil(Math.max(...tripsData) / 10) * 10;
  const max_kms_value = Math.ceil(Math.max(...kmsData) / 100) * 100;

  // Trips: Bar
  const barChartData = {
    labels: labels,
    datasets: [
      {
        label: 'Trips',
        data: tripsData,
        backgroundColor: '#ffe680',
        borderColor: '#ffe680',
        borderWidth: 1,
        datalabels: {
          display: false,
        },
        yAxisID: 'y-axis-trips',
      },
      {
        label: 'Cancellations',
        data: cancellationsData,
        backgroundColor: '#4da6ff',
        borderColor: '#4da6ff',
        borderWidth: 1,
        datalabels: {
          display: false,
        },
        yAxisID: 'y-axis-cancellations',
      },
      {
        label: 'Bookings',
        data: bookingsData,
        backgroundColor: '#ff3333',
        borderColor: '#ff3333',
        borderWidth: 1,
        datalabels: {
          display: false,
        },
        yAxisID: 'y-axis-bookings',
      },
    ],
    options: {
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: 'x',
      scales: {
        yAxes: [
          {
            id: 'y-axis-bookings',
            ticks: {
              beginAtZero: true,
              max: Math.ceil(max_value_bookings),
            },
            scaleLabel: {
              display: true,
              labelString: 'Trips/Bookings',
            },
          },
          {
            id: 'y-axis-trips',
            display: false,
            ticks: {
              display: false,
              beginAtZero: true,
              max: Math.ceil(max_value_trips),
            },
            scaleLabel: {
              display: false,
              labelString: 'Trips',
            },
          },
          {
            id: 'y-axis-cancellations',
            ticks: {
              beginAtZero: true,
              max: Math.ceil(max_value_cancellations),
            },
            position: 'right',
            scaleLabel: {
              display: true,
              labelString: 'Cancellations',
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              fontSize: 9,
            },
          },
        ],
      },
    },
  };

  // Kilometers: Bar
  const kmsChartData = {
    labels: labels,
    datasets: [
      {
        label: 'Kilometers',
        data: kmsData,
        backgroundColor: '#ffe680',
        borderColor: '#ffe680',
        borderWidth: 1,
        datalabels: {
          display: false,
        },
        yAxisID: 'y-axis-trips',
      },
    ],
    options: {
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: 'x',
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: Math.ceil(max_kms_value),
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              fontSize: 9,
            },
          },
        ],
      },
    },
  };

  const downloadAll = () => {
    const pdfDoc = new jsPDF();
    pdfDoc.addImage(headerImg, 'PNG', 0, 0, 220, 30);

    const costsImageData = totalCostsId.toDataURL('image/png');
    pdfDoc.addImage(costsImageData, 'PNG', 60, 60, 85, 90);
    if (tripsGraph) {
      const tripImageData = tripsChartId.toDataURL('image/png');
      pdfDoc.addImage(tripImageData, 'PNG', 10, 170, 180, 100);
    }
    if (kmsGraph) {
      const kmsImageData = kmsChart.toDataURL('image/png');
      pdfDoc.addImage(kmsImageData, 'PNG', 10, 170, 180, 100);
    }

    pdfDoc.text(80, 50, 'Reports on Trips');
    pdfDoc.addPage();

    if (occupancyGraph) {
      const occupancyImageData = chartCanvas.toDataURL('image/png');
      pdfDoc.addImage(occupancyImageData, 'PNG', 10, 20, 180, 100);
    }
    if (mixedGraph) {
      const mixedImageData = mixedId.toDataURL('image/png');
      pdfDoc.addImage(mixedImageData, 'PNG', 10, 10, 185, 100);
    }

    pdfDoc.save('all_charts.pdf');
  };

  const downloadChart = (chartType) => {
    const pdfDoc = new jsPDF();
    pdfDoc.addImage(headerImg, 'PNG', 0, 0, 220, 30);

    if (chartType === 'costs') {
      const costsImageData = totalCostsId.toDataURL('image/png');
      pdfDoc.addImage(costsImageData, 'PNG', 30, 70, 150, 200);
      pdfDoc.text(80, 50, `Total costs in Rands for ${range}`);
      pdfDoc.save('total_costs_chart.pdf');
    } else if (chartType === 'trips') {
      const tripImageData = tripsChartId.toDataURL('image/png');
      pdfDoc.addImage(tripImageData, 'PNG', 10, 70, 180, 100);
      pdfDoc.text(10, 50, `Trips, Cancellations & Bookings for ${range}`);
      pdfDoc.save('trips_bookings_chart.pdf');
    } else if (chartType === 'kms') {
      const kmsImageData = kmsChart.toDataURL('image/png');
      pdfDoc.addImage(kmsImageData, 'PNG', 10, 70, 180, 100);
      pdfDoc.text(10, 50, `Kilometers for ${range}`);
      pdfDoc.save('kilometers_chart.pdf');
    } else if (chartType === 'occupancy') {
      const occupancyImageData = chartCanvas.toDataURL('image/png');
      pdfDoc.addImage(occupancyImageData, 'PNG', 10, 70, 180, 100);
      pdfDoc.text(10, 50, 'Occupancy for this year');
      pdfDoc.save('occupancy_chart.pdf');
    } else if (chartType === 'mixed') {
      const mixedImageData = mixedId.toDataURL('image/png');
      pdfDoc.addImage(mixedImageData, 'PNG', 10, 70, 190, 100);
      pdfDoc.text(10, 50, `Bookings and Cancellations for ${range}`);
      pdfDoc.save('bookings_cancellations_chart.pdf');
    }
  };

  // barchart options
  const tripsOptions = [
    {
      id: 't1',
      icon: 'fa fa-calendar mr-2',
      value: kmsGraph,
      label: 'Trips, Cancellations & Bookings',
    },
    {
      id: 't2',
      icon: 'disabled fa fa-tachometer-alt mr-2',
      value: tripsGraph,
      label: 'Kilometers',
    },
    {
      id: 't3',
      icon: 'fa fa-download mr-2',
      value: true,
      label: 'Download All',
    },
    {
      id: 't4',
      icon: 'fa fa-download mr-2',
      value: tripsGraph,
      label: 'Download Chart',
    },
    {
      id: 't5',
      icon: 'fa fa-download mr-2',
      value: kmsGraph,
      label: 'Download Chart',
    },
  ];

  const handleTripsSelect = (option) => {
    if (option.id === 't3') {
      downloadAll();
    } else if (option.id === 't4') {
      downloadChart('trips');
    } else if (option.id === 't5') {
      downloadChart('kms');
    } else {
      setTripsGraph(!tripsGraph);
      setKmsGraph(!kmsGraph);
    }
  };

  return (
    <div className="flex flex-col w-full mt-4 mb-2">
      <div className="flex flex-col md:flex-row w-full">
        <div className="w-full md:w-2/3 mb-4 sm:mb-0">
          <div className="flex weeklySchedule ">
            <div className="flex w-full md:w-2/3 border bg-white border-gray-300 rounded-lg rounded-r-none mt-1">
              <DateDropdown
                date_ranges={date_ranges}
                selectedDateRange={selectedDateRange}
                setSelectedDateRange={setSelectedDateRange}
              />
            </div>
            <a
              href="/dashboard/index"
              style={{ backgroundColor: theme }}
              className="px-2 py-1 mt-1 rounded-r-lg focus:outline-none bg-gray-400 text-white hover:bg-gray-600">
              <i className="fas fa-undo mx-2 my-3"></i>
            </a>
          </div>

          {companies.length > 1 ? (
            <div className="relative flex w-full mt-4 pr-0 sm:pr-4">
              <StatsTable
                companyList={companyList}
                trips={trips}
                kms={kms}
                cancellations={cancellations}
                bookings={bookings}
                costs={costs}
              />
            </div>
          ) : (
            <div className="infoTable w-full mt-4">
              <div className="w-full h-80 pr-2">
                <div className="flex flex-col h-full w-full rounded-lg bg-white py-2 px-4 shadow-md">
                  <div className="flex justify-between w-full">
                    <div className="order-first mt-2 text-gray-500 normal-case font-normal">
                      {tripsGraph
                        ? 'Trips, Cancellations & Bookings'
                        : 'Kilometers'}
                    </div>
                    <div className="order-last text-zinc-800">
                      <div className="float-right relative inline-block">
                        <Dropdown
                          options={tripsOptions}
                          onSelect={handleTripsSelect}
                        />
                      </div>
                    </div>
                  </div>
                  {tripsGraph && (
                    <ChartComponent
                      {...barChartData}
                      height={400}
                      width={500}
                      type="bar"
                      ref={tripChartRef}
                      id="tripsChartId"
                    />
                  )}
                  {kmsGraph && (
                    <ChartComponent
                      {...kmsChartData}
                      height={400}
                      width={500}
                      type="bar"
                      ref={kmChartRef}
                      id="kmsChart"
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="w-full md:w-1/3 ">
          <CalendarComponent
            recent_bookings={recent_bookings}
            showTooltip={showTooltip}
            setShowTooltip={setShowTooltip}
            groupedData={groupedData}
            setGroupedData={setGroupedData}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>
      </div>
    </div>
  );
};

export default DashMain;
