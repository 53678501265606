import React,{useState} from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';
import  DownloadCsvBookings  from '../csv_bookings/DownloadCsvBookings';
import UploadCsvBookings from '../csv_bookings/UploadCsvBookings';
import Bookings from '../bookings/Bookings';

const TaskTabs = ({
}) => {

    const [goToBookings, setGoToBookings] = useState(undefined);
    const [tabIndex, setTabIndex] = useState(0);

    const switchToBulkBookings = (state) =>{
        setGoToBookings(state);
        setTabIndex(0);
    }

    return(
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
            <TabList>
                <Tab>Bulk Bookings</Tab>
                <Tab>Download Bookings</Tab>
                <Tab>Upload Bookings</Tab>
            </TabList>

            <TabPanel>
                <Bookings
                    goToBookings={goToBookings}
                    setGoToBookings={setGoToBookings}
                />
            </TabPanel>
            <TabPanel>
                <DownloadCsvBookings/>
            </TabPanel>

            <TabPanel>
                <UploadCsvBookings
                    switchToBulkBookings={switchToBulkBookings}
                />
            </TabPanel>
        </Tabs>
    );
};

export default TaskTabs;