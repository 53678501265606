import React, { useEffect, useState } from "react";
import {
  faMinusCircle,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import moment from "moment";
import "../custom_scrollbar.css";
const initialWeekdays = [
  { day: "Monday", expanded: false },
  { day: "Tuesday", expanded: false },
  { day: "Wednesday", expanded: false },
  { day: "Thursday", expanded: false },
  { day: "Friday", expanded: false },
  { day: "Saturday", expanded: false },
  { day: "Sunday", expanded: false },
];

/**
 *
 * @param {array} teamEmployees The employees for the selected team
 * @param {object} selectedWeek The selected week for the date selection component(summary)
 * @param {object} selectedTeam The selected team
 * @param {string} selectedDay The selected day for the date selection component (summary)
 * @param {string} pageView The current (summary) view (day or week)
 * @param {function} refresh The state to refresh the data via Use Effect
 * @param {function} setIsLoadingSummary The function to set whether the summary is loading
 * @returns
 */
const UnscheduledEmployeesCard = ({
  teamEmployees,
  selectedWeek,
  selectedTeam,
  selectedDay,
  pageView,
  refresh,
  setIsLoadingSummary,
}) => {
  const [unscheduledEmployees, setUnscheduledEmployees] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [dailyInbound, setDailyInbound] = useState([]);
  const [dailyOutbound, setDailyOutbound] = useState([]);
  const [weeklyInbound, setWeeklyInbound] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });
  const [weeklyOutbound, setWeeklyOutbound] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });
  const [weekdays, setWeekdays] = useState(initialWeekdays);

  const setWeekdayExpanded = (clickedDay) => {
    const updatedWeekdays = weekdays.map((dayObj) => {
      if (dayObj.day === clickedDay) {
        // Toggle the 'expanded' property for the clicked day
        return { ...dayObj, expanded: !dayObj.expanded };
      } else {
        // Close all other days
        return { ...dayObj, expanded: false };
      }
    });

    setWeekdays(updatedWeekdays);
  };

  const getUnscheduledCount = () => {
    if (pageView == "day") {
      let count = 0;
      unscheduledEmployees.forEach((employee) => {
        if (
          employee.unscheduledDates &&
          employee.unscheduledDates["inbound"].includes(selectedDay)
        ) {
          count += 1;
        }
        if (
          employee.unscheduledDates &&
          employee.unscheduledDates["outbound"].includes(selectedDay)
        ) {
          count += 1;
        }
      });

      return count;
    } else {
      let count = 0;
      unscheduledEmployees.forEach((employee) => {
        if (employee.unscheduledDates) {
          count += employee.unscheduledDates["inbound"].length;
          count += employee.unscheduledDates["outbound"].length;
        }
      });
      return count;
    }
  };

  useEffect(() => {
    if (teamEmployees.length > 0) {
      const team_id = selectedTeam.team_id;
      const url = "/team_manager/get_unscheduled_employees";
      const date_range = selectedWeek.value;
      setIsLoadingSummary(true);
      Axios({
        method: "get",
        url: url,
        params: {
          date_range: date_range,
          team_id: team_id,
        },
        headers: { Accept: "application/json" },
      })
        .then((response) => {
          const unscheduledEmployees = response.data;
          const updatedEmployees = teamEmployees.map((employee) => {
            const userId = employee.id;
            const unscheduledDates = unscheduledEmployees[userId] || {};
            if (Object.keys(unscheduledDates).length === 0) {
              return employee;
            }
            return {
              ...employee,
              unscheduledDates: unscheduledDates,
            };
          });

          setUnscheduledEmployees(updatedEmployees);
          setIsLoadingSummary(false);
        })
        .catch((error) => {
          console.error("Error fetching unscheduled employees:", error);
          setIsLoadingSummary(false);
        });
    }
  }, [selectedTeam, refresh, selectedWeek]);

  //Group retrieved data to inbound / outbound and daily / weekly
  useEffect(() => {
    if (unscheduledEmployees.length > 0) {
      const dailyInbounds = [];
      const dailyOutbounds = [];
      unscheduledEmployees.forEach((employee) => {
        if (
          employee.unscheduledDates["inbound"].length > 0 &&
          employee.unscheduledDates["inbound"].includes(selectedDay)
        ) {
          dailyInbounds.push(employee);
        }
        if (
          employee.unscheduledDates["outbound"].length > 0 &&
          employee.unscheduledDates["outbound"].includes(selectedDay)
        ) {
          dailyOutbounds.push(employee);
        }
      });
      setDailyInbound(dailyInbounds);
      setDailyOutbound(dailyOutbounds);
      setWeeklyInbound({
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
      });
      setWeeklyOutbound({
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
      });
      unscheduledEmployees.forEach((employee) => {
        employee.unscheduledDates["inbound"].forEach((date) => {
          const weekday = moment(date, "DD-MM-YYYY").format("dddd");
          setWeeklyInbound((prevState) => {
            return {
              ...prevState,
              [weekday]: [...prevState[weekday], employee],
            };
          });
        });
        employee.unscheduledDates["outbound"].forEach((date) => {
          const weekday = moment(date, "DD-MM-YYYY").format("dddd");
          setWeeklyOutbound((prevState) => {
            return {
              ...prevState,
              [weekday]: [...prevState[weekday], employee],
            };
          });
        });
      });
    }
  }, [unscheduledEmployees, selectedDay, selectedWeek]);

  return (
    <div className="bg-gray-200 rounded-lg w-full px-2 py-1 mt-4">
      <div className="font-small text-gray-600">Unscheduled Employees</div>
      <div className="w-full mb-2">
        <div
          className="bg-white rounded px-2 flex flex-row mt-1 justify-between"
          style={{
            paddingTop: "0.1rem",
            paddingBottom: "0.1rem",
            userSelect: "none",
          }}
        >
          <div className="flex flex-col w-full">
            {/* <span className='text-xs text-gray-500 '>{getUnscheduledCount()} Unscheduled</span> */}
            {isExpanded && pageView == "day" && dailyInbound.length > 0 && (
              <div className="flex flex-col mt-2">
                <div className="text-sm text-gray-500 font-semibold">
                  Inbound
                </div>
                <div
                  className="bg-gray-200 py-1 my-1 overflow-y-scroll dark-gray-scrollbar rounded"
                  style={{ maxHeight: "52px" }}
                >
                  {dailyInbound.map((employee) => (
                    <div
                      key={employee.id}
                      className="text-xs ml-2  text-gray-500"
                    >
                      {employee.name} {employee.surname}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {isExpanded && pageView == "day" && dailyOutbound.length > 0 && (
              <div className="flex flex-col mt-2">
                <div className="text-sm text-gray-500 font-semibold">
                  Outbound
                </div>
                <div
                  className="bg-gray-200 py-1 my-1 overflow-y-scroll dark-gray-scrollbar rounded"
                  style={{ maxHeight: "52px" }}
                >
                  {dailyOutbound.map((employee) => (
                    <div
                      key={employee.id}
                      className="text-xs ml-2 text-gray-500"
                    >
                      {employee.name} {employee.surname}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {pageView === "week" && (weeklyInbound || weeklyOutbound) && (
              <div className="flex flex-col w-full mt-2">
                {weekdays.map(({ day, expanded }) => {
                  if (
                    weeklyInbound[day].length > 0 ||
                    weeklyOutbound[day].length > 0
                  ) {
                    return (
                      <div key={day}>
                        <div className="flex justify-between p-1 rounded-md">
                          <div className="  w-1/5">
                            <div className="bg-gray-200 text-xs py-1 px-2 my-1 rounded-md">
                              <h1 className="font-semibold text-gray-600">
                                {day.slice(0, 3)}
                              </h1>
                              <p className="text-center">
                                {moment()
                                  .isoWeekday(
                                    weekdays.findIndex((d) => d.day === day) + 1
                                  )
                                  .format("D")}
                              </p>
                            </div>
                          </div>

                          <div className="flex-grow text-xs bg-gray-200 rounded-md mx-2 py-1 px-2">
                            {weeklyInbound[day].length > 0 && !expanded && (
                              <h1 className="p-1 text-xs flex justify-between">
                                <span className="text-sm bg-gray-400 p-2  text-white rounded-lg flex justify-center items-center">
                                  {weeklyInbound[day].length +
                                    weeklyOutbound[day].length}
                                </span>
                                <span className="text-gray-600 pl-2">
                                Unscheduled Employees
                                </span>
                              </h1>
                            )}
                            {expanded && (
                              <div>
                                {weeklyInbound[day].length > 0 && expanded && (
                                  <>
                                    <div className="text-sm text-gray-500 font-semibold flex justify-between">
                                      <span>Inbound</span>{" "}
                                      <span>{weeklyInbound[day].length}</span>
                                    </div>
                                    <div
                                      className="bg-white p-1 my-1 overflow-y-scroll dark-gray-scrollbar rounded"
                                      style={{ maxHeight: "52px" }}
                                    >
                                      {weeklyInbound[day].map((employee) => (
                                        <div
                                          key={employee.id}
                                          className="text-xs text-gray-500"
                                        >
                                          {employee.name} {employee.surname}
                                        </div>
                                      ))}
                                    </div>
                                  </>
                                )}

                                {weeklyOutbound[day].length > 0 && expanded && (
                                  <>
                                    <div className="text-sm text-gray-500 font-semibold flex justify-between">
                                      <span>Outbound</span>{" "}
                                      <span>{weeklyOutbound[day].length}</span>
                                    </div>
                                    <div
                                      className="bg-white p-1 my-1 overflow-y-scroll dark-gray-scrollbar rounded"
                                      style={{ maxHeight: "48px" }}
                                    >
                                      {weeklyOutbound[day].map((employee) => (
                                        <div
                                          key={employee.id}
                                          className="text-xs text-gray-500"
                                        >
                                          {employee.name} {employee.surname}
                                        </div>
                                      ))}
                                    </div>
                                  </>
                                )}
                              </div>
                            )}
                          </div>

                          <button
                            onClick={() => setWeekdayExpanded(day)}
                            className="py-4 px-2 bg-purple-500 rounded-lg flex justify-center items-center "
                          >
                            <FontAwesomeIcon
                              icon={expanded ? faMinusCircle : faPlusCircle}
                              size={"xs"}
                              className="text-white my-1"
                            />
                          </button>
                        </div>
                      </div>
                    );
                  }
                  return null; // Don't render if both inbound and outbound are empty
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnscheduledEmployeesCard;
