import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

/**
 * 
 * @param {setSearchQuery} setSearchQuery - The function to set the search query
 * @param {searchQuery} searchQuery - The search query
 * @returns {JSX.Element} - The search box component
 */
const SearchBox = ({setSearchQuery, searchQuery}) => {

    return (
        <>
            <div className='text-gray-400 text-sm py-1 px-2 rounded-lg mt-2 border border-gray-200 flex justify-between justify-center items-center rounded' style={{ width: '170px', height: '36px' }} >
                <input type="text" placeholder="Search" value={searchQuery} className="bg-transparent focus:outline-none w-full h-full" onChange={(e)=>setSearchQuery(e.target.value)} />
                <FontAwesomeIcon icon={faMagnifyingGlass} className="text-gray-400" />
            </div>
        </>
    )

}

export default SearchBox;