import React, { useState, useEffect, useRef } from "react";
import DateSelection from "./DateSelection";
import DaySelection from "./DaySelection";
import TeamSelection from "./TeamSelection";
import Countdown from "./Countdown";
import DailyTable from "./DailyTable";
import WeeklyTable from "./WeeklyTable";
import EmployeeMultiSelect from "./EmployeeMultiSelect";
import TimeslotPicker from "./TimeslotPicker";
import CompanyAddressSelection from "./CompanyAddressSelection";
import Axios from "axios";
import SearchBox from "./SearchBox";
import ConfirmationPopup from "./ConfirmationPopup";
import BoundSelector from "./BoundSelector";
import AdhocView from "./AdhocView";
import moment from "moment";
import {
  faPeopleGroup,
  faDownload,
  faUpload,
  faXmark, faPlus
} from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreateTeamDialogue from "./CreateTeamDialogue";
import UnsavedChanges from "./Modals/UnsavedChanges";
import EditTeamDialogue from "./EditTeams/EditTeamDialogue";
import ResponseMessage from "./EditTeams/Message";
import CompanySelection from "./CompanySelection";
import DownloadCsv from "./Csv/DownloadCsv";
import UploadCsv from "./Csv/UploadCsv";
import SchedularInfo from "./Cards/SchedularInfo";
import OutboundTimeSelector from "./utils/OutboundTimeSelector";
import InboundTimeSelector from "./utils/InboundTimeSelector";
import { set } from "date-fns";

import { showToast } from "../../toasts";
/**
 *
 * @param {string} view The current (booking) view (day or week)
 * @param {function} setView The function to set the current (booking) view
 * @param {array} dateRanges The date ranges for the date selection component
 * @param {string} selectedWeek The selected week for the date selection component(booking)
 * @param {function} setWeek The function to set the selected week (booking)
 * @param {string} selectedDay The selected day for the date selection component (booking)
 * @param {function} setDay The function to set the selected day (booking)
 * @param {array} teams The teams for the team selection component
 * @param {object} selectedTeam The selected team
 * @param {function} setSelectedTeam The function to set the selected team
 * @param {function} reloadTeams The state to reload the teams via Use Effect
 * @param {function} setReloadTeams The function to set the state to reload the teams via Use Effect
 * @param {array} companyAddresses The company addresses for the selected team
 * @param {function} setTimesUp The function to set the state to determine if the booking time has passed
 * @param {boolean} timesUp The state to determine if the booking time has passed
 * @param {array} teamEmployees The employees for the selected team
 * @param {function} setTeamEmployees The function to set the employees for the selected team
 * @param {boolean} teamsLoading The state to determine if the teams are still loading
 * @param {boolean} addressLoading The state to determine if the company addresses are still loading
 * @param {boolean} bookingViewIsLoading The state to determine if the booking view is still loading
 * @param {function} setBookingViewIsLoading The function to set the state to determine if the booking view is still loading
 * @param {string} role the current user's role (manager, logistics_specialist, admin)
 * @param {function} refresh The state to refresh the data via Use Effect
 * @param {boolean} setRefresh The function to set the state to refresh the data via Use Effect
 * @param {array} bookedTrips The booked trips for the selected day from the backend
 * @param {function} setBookedTrips The function to set the booked trips for the selected day via axios
 * @param {array} weeklyBookedTrips The booked trip details from the backend
 *
 * @returns {JSX.Element} The booking view component on the right side of the page, which contains the daily and weekly tables
 */
const BookingView = ({
  companies,
  setCompanies,
  selectedCompany,
  handleSelectChange,
  view,
  viewAdhoc,
  setViewAdhoc,
  setView,
  dateRanges,
  selectedWeek,
  setWeek,
  selectedDay,
  setDay,
  teams,
  setTeams,
  selectedTeam,
  setSelectedTeam,
  reloadTeams,
  setReloadTeams,
  companyAddresses,
  setTimesUp,
  timesUp,
  teamEmployees,
  setTeamEmployees,
  teamsLoading,
  addressLoading,
  bookingViewIsLoading,
  setBookingViewIsLoading,
  role,
  refresh,
  setRefresh,
  bookedTrips,
  summaryAdhocData,
  setBookedTrips,
  weeklyBookedTrips,
  setWeeklyBookedTrips,
  user,
  trainingScheduler,
  trainingAdhocs,
  trainingTeams,
  trainingDownload,
  trainingUpload,
  isAllowedToEditCsb,
}) => {
  const [tripType, setTripType] = useState("inbound");
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [dailyBookings, setDailyBookings] = useState([]);
  const [weeklyBookings, setWeeklyBookings] = useState([]);
  const [selectedTimeslot, setSelectedTimeslot] = useState(null);
  const [inboundTimeslot, setInboundTimeslot] = useState(null);
  const [outboundTimeslot, setOutboundTimeslot] = useState(null);
  const [selectedCompanyAddress, setSelectedCompanyAddress] = useState({
    name: "Select an Address",
  });
  const [updateState, setUpdateState] = useState(0); //When the address doesn't change states, the daily table doesn't get updated, this state will cater for that.

  const [inboundCountDaily, setInboundCountDaily] = useState(0);
  const [outboundCountDaily, setOutboundCountDaily] = useState(0);
  const [inboundCountWeekly, setInboundCountWeekly] = useState(0);
  const [outboundCountWeekly, setOutboundCountWeekly] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [showPopup, setShowPopup] = useState(null);

  //   Team Creation
  const [createNewTeamView, setCreateNewTeamView] = useState(false);

  //Daily Table States
  const [bookingToDelete, setBookingToDelete] = useState(null);
  const [bookingToEdit, setBookingToEdit] = useState(null);
  const [dailyChangesMade, setDailyChangesMade] = useState(false);

  //Weekly Table States
  const [changedBookings, setChangedBookings] = useState([]);
  const [newBookings, setNewBookings] = useState([]);
  const [toDeleteBookings, setToDeleteBookings] = useState([]);
  const [cleanUpBookings, setCleanUpBookings] = useState(false);
  const [weeklyChangesMade, setWeeklyChangesMade] = useState(false);
  const [showAdhoc, setShowAdhoc] = useState(false);
  const [companyCutOffTime, setCompanyCutOffTime] = useState("16:00PM");

  const [showWarning, setShowWarning] = useState(false);
  const [weekToEdit, setWeekToEdit] = useState(null);
  const [dayToEdit, setDayToEdit] = useState(null);
  //Refs for centering the screen to the popup
  const confirmDailyRef = useRef(null);
  const deleteDailyRef = useRef(null);
  const warningRef = useRef(null);
  const adhocRef = useRef(null);
  const weeklyChangesRef = useRef(null);
  const dayWarningRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [showViewSwitchModal, setShowViewSwitchModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);
  const [viewOptions, setViewOptions] = useState(false);
  const [editTeamView, setEditTeamView] = useState(false);

  const [showMessage, setShowMessage] = useState(false);
  const [responseTitle, setResponseTitle] = useState("");
  const [responseMessage, setResponseMessage] = useState("");

  const [activeTab, setActiveTab] = useState("createTeamTab");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [preLoadBookings, setPreLoadBookings] = useState(false);
  const [selectedView, setSelectedView] = useState("");

  const parentManagedScheduler = () => {
    trainingScheduler(true);
  };

  const parentManagedAdhocs = () => {
    trainingAdhocs(true);
  };

  const parentManagedTeams = () => {
    trainingTeams(true, activeTab == "createTeamTab" ? "steps_create" : "steps_edit");
  };

  const parentManagedDownload = () => {
    trainingDownload(true);
  };

  const parentManagedUpload = () => {
    trainingUpload(true);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setActiveTab("");
  };

  const handlePreloadBookings = () => {
    setPreLoadBookings(!preLoadBookings);
  };

  const boxRef = useRef(null);
  const dropdownRef = useRef(null);

  // Function to handle tab selection
  const handleTabSelect = (selectedTab) => {
    if (tripType !== selectedTab) {
      if (weeklyChangesMade) {
        setSelectedTab(selectedTab);
        setChangedBookings([]);
        setNewBookings([]);
        setShowModal(true);
      } else {
        resetState(selectedTab);
      }
    } else {
      resetState(selectedTab);
    }
  };

  const resetState = (selectedTab) => {
    setTripType(selectedTab);
    setNewBookings([]);
    // setWeeklyChangesMade(false);
    setChangedBookings([]);
    setToDeleteBookings([]);
  };

  const handleYesClick = () => {
    setTripType(selectedTab);
    setShowModal(false);
    // setWeeklyChangesMade(false);
    resetNewBookings();
  };

  const handleNoClick = () => {
    setShowModal(false);
  };

  const handleViewSwitchYes = () => {
    setShowViewSwitchModal(false);
    setView(selectedView);
    setChangedBookings([])
    setNewBookings([])
    setToDeleteBookings([])
    // setWeeklyChangesMade(false);
  };

  const handleViewSwitchNo = () => {
    setShowViewSwitchModal(false);
  };

  //Handle Confirm Bookings for Weekly Table
  const handleConfirmWeeklyBookings = () => {
    if (
      changedBookings.length > 0 ||
      newBookings.length > 0 ||
      toDeleteBookings.length > 0
    ) {
      setShowPopup(null);
      setBookingViewIsLoading(true);
      const data = {
        changed_bookings: changedBookings,
        new_bookings: newBookings,
        to_delete_bookings: toDeleteBookings,
      };
      // Make the POST request to create the bookings
      Axios({
        method: "post",
        url: `/team_manager/create_bookings_weekly`,
        data: data,
        headers: { Accept: "application/json" },
      })
        .then((response) => {
          setBookingViewIsLoading(false);
          setCleanUpBookings(!cleanUpBookings);
          showToast("success", response.data.message);
          setRefresh(!refresh);
        })
        .catch((error) => {
          let errors = error?.response?.data?.errors.reduce((acc, trip) => {
            acc[trip.error] = acc[trip.error] || [];
            acc[trip.error].push(trip.user_full_name);
            return acc;
          }, {});

          let errorMessages = Object.entries(errors).map(([error, users]) => {
            return users.length > 1 ? `${users.join(", ")}: ${error}` : `${users[0]}: ${error}`;
          }).join(". ");

          showToast("error", errorMessages || "Error occurred while creating bookings");
          if (error?.response?.data?.cut_off_time) {
            setCompanyCutOffTime(error?.response?.data?.cut_off_time);
          }
          setBookingViewIsLoading(false);
          setRefresh(!refresh);
        });
    }
  };
  //Body of text for weekly changes popup
  const getWeeklyChangesBody = () => {
    return (
      <div>
        <p>You’ve made the following changes:</p>

        <div className="flex justify-between py-2 text-center gap-2 w-full">
          <div className="p-2 bg-gray-200 rounded-md">
            <p>New Trips</p>
            <p className="font-semibold">{newBookings.length}</p>
          </div>

          <div className="p-2 bg-gray-200 rounded-md">
            <p>Changed Trips</p>
            <p className="font-semibold">{changedBookings.length}</p>
          </div>

          <div className="p-2 bg-gray-200 rounded-md">
            <p>Deleted Trips</p>
            <p className="font-semibold">{toDeleteBookings.length}</p>
          </div>
        </div>
      </div>
    );
  };

  //Handle Confirm Bookings for Daily Table
  const handleConfirmBooking = () => {
    if (!timesUp) {
      if (bookingToEdit.length > 0) {
        const data = {
          bookings: bookingToEdit,
        };
        // Make the POST request to create the bookings
        setBookingViewIsLoading(true);
        setShowPopup(null);
        Axios.post("/team_manager/create_bookings", data)
          .then((response) => {
            // Handle the response if needed
            response?.data?.trips?.forEach((trip) => {
              setDailyBookings((prevBookings) =>
                prevBookings.map((booking) =>
                  booking.employee.id === trip.user_id &&
                    booking.trip_type.toLowerCase() === trip.trip_type
                    ? { ...booking, selection: false }
                    : booking
                )
              );
            });
            setSelectedEmployees([]);
            setSelectedCompanyAddress(null);
            setSelectedTimeslot(null);
            setBookingToEdit(null);
            setRefresh(!refresh);
            setBookingViewIsLoading(false);
            setDailyChangesMade(true);
          })
          .catch((error) => {
            console.error("Error creating bookings:", error);
            // Handle the error if needed
            setRefresh(!refresh);
            setBookingViewIsLoading(false);
            setDailyChangesMade(true);
          });
      }
    }
  };

  //Opens the new team dialog
  const handleNewTeam = () => {
    setCreateNewTeamView(true);
  };

  //Handle close team creation dialogue
  const handleCloseDialogue = () => {
    setCreateNewTeamView(false);
  };

  const handleCloseTeamEditDialogue = () => {
    // e.stopPropagation();
    setCreateNewTeamView(false);
    setEditTeamView(false);
    setViewOptions(false);
  };

  //Handle Confirm DELETE Bookings for Daily Table
  const handleDeleteBooking = (tripId) => {
    if (!timesUp && bookingToDelete) {
      setShowPopup(null);
      setBookingViewIsLoading(true);
      const { trip_type } = bookingToDelete;
      // Make the DELETE request to delete the booking
      Axios({
        method: "delete",
        url: `/team_manager/delete_booking/${tripId}`,
        headers: { Accept: "application/json" },
      })
        .then((response) => {
          setDailyBookings((prevBookings) =>
            prevBookings.map((booking) =>
              booking.trip_id === tripId && booking.trip_type === trip_type
                ? { ...getInitialBookingState(booking), trip_id: null }
                : booking
            )
          );
          setSelectedEmployees([]);
          setRefresh(!refresh);
          setBookingViewIsLoading(false);
          setBookingToDelete(null);
        })
        .catch((error) => {
          console.error("Error creating bookings:", error);
          // Handle the error if needed
          setRefresh(!refresh);
          setBookingViewIsLoading(false);
        });
    }
  };

  const getInitialBookingState = (booking) => {
    if (booking.trip_type === "Inbound") {
      return {
        selection: false,
        employee: booking.employee,
        day: booking.day,
        timeslot: null,
        pick_up: booking.employee.address,
        drop_off: null,
        trip_type: "Inbound",
        status: "unbooked",
      };
    } else if (booking.trip_type === "Outbound") {
      return {
        selection: false,
        employee: booking.employee,
        day: booking.day,
        timeslot: null,
        pick_up: null,
        drop_off: booking.employee.address,
        trip_type: "Outbound",
        status: "unbooked",
      };
    } else {
      return {};
    }
  };

  const getCompanyTimeOut = () => {
    const time = moment(companyCutOffTime, "hh:mmA");
    const hour = time.hour();
    const minute = time.minute();
    return { hour: hour, minute: minute, second: 0 };
  };

  //You can open the edit team dialog here.

  const handleClickOutside = (event) => {
    if (
      boxRef.current &&
      dropdownRef.current &&
      !boxRef.current.contains(event.target)
      //   !dropdownRef.current.contains(event.target)
    ) {
      setViewOptions(false);
    }
  };

  const resetNewBookings = () => {
    // Function to update status to "to_delete" for entries with id === null
    function setStatusToDeleteForNullId(employee) {
      const daysOfWeek = [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ];

      daysOfWeek.forEach((day) => {
        const trip = employee[day];
        if (trip.id === null) {
          trip.status = "";
          trip.timeslot = "";
          trip.destination = "";
          trip.location = "";
        }
      });
    }

    // Iterate through the list and update the status for entries with id === null
    weeklyBookings.forEach((employee) => {
      setStatusToDeleteForNullId(employee);
    });

    // Log the updated data
    setNewBookings([]);
    // setWeeklyChangesMade(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      setCompanyCutOffTime(selectedCompany?.cut_off_time);
    }
  }, [selectedCompany]);

  //Clear search on view change
  useEffect(() => {
    setSearchQuery("");
  }, [view]);

  //Clear selected employees on day change
  useEffect(() => {
    setSelectedEmployees([]);
    setSelectedTimeslot(null);
    setSelectedCompanyAddress({ name: "Select an Address" });
  }, [selectedDay]);

  //trip count for daily bookings
  useEffect(() => {
    const inboundTrips = bookedTrips.filter(
      (trip) => trip.trip_type == "inbound"
    );
    const outboundTrips = bookedTrips.filter(
      (trip) => trip.trip_type == "outbound"
    );
    setInboundCountDaily(inboundTrips.length);
    setOutboundCountDaily(outboundTrips.length);
  }, [bookedTrips]);

  //trip count for weekly bookings
  useEffect(() => {
    let inboundTrips = 0;
    let outboundTrips = 0;

    Object.keys(weeklyBookedTrips).forEach((tripType) => {
      // Iterate over each day (date) in the selected week
      Object.keys(weeklyBookedTrips[tripType]).forEach((date) => {
        const tripsForDay = weeklyBookedTrips[tripType][date];
        if (tripType == "inbound") {
          inboundTrips += tripsForDay.length;
        } else {
          outboundTrips += tripsForDay.length;
        }
      });
    });
    setInboundCountWeekly(inboundTrips);
    setOutboundCountWeekly(outboundTrips);
  }, [weeklyBookedTrips]);

  useEffect(() => {
    setView("week");
  }, []);

  useEffect(() => {
    // Fetch the team member trips data
    if (selectedTeam && view == "day") {
      const date = selectedDay;
      const team_id = selectedTeam.team_id;

      let url = "/team_manager/get_team_trips";
      url += `?date=${date}`;
      url += `&team_id=${team_id}`;
      setBookingViewIsLoading(true);
      Axios({
        method: "get",
        url: url,
        headers: { Accept: "application/json" },
      })
        .then((response) => {
          const data = response.data;
          showToast("success", "Trips fetched successfully");
          setBookedTrips(data);
          setBookingViewIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching teams:", error);
          showToast("error", "An error occurred, please try again");
          setBookingViewIsLoading(false);
        });
    }
  }, [teamEmployees, selectedDay, refresh, view]);

  useEffect(() => {
    // Fetch the team member weekly trips data
    if (selectedTeam && view == "week") {
      const dateRange = selectedWeek.value;
      const team_id = selectedTeam.team_id;

      let url = "/team_manager/get_team_trips_weekly";
      url += `?date_range=${dateRange}`;
      url += `&team_id=${team_id}`;
      setBookingViewIsLoading(true);
      Axios({
        method: "get",
        url: url,
        headers: { Accept: "application/json" },
      })
        .then((response) => {
          setWeeklyBookedTrips(response.data);
          setBookingViewIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching teams:", error);
        });
    }
  }, [teamEmployees, selectedWeek, refresh, view]);

  useEffect(() => {
    if (newBookings.length > 0 || changedBookings.length > 0) {
      setWeeklyChangesMade(true);
    } else {
      setWeeklyChangesMade(false);
    }
  }, [newBookings.length, changedBookings.length, toDeleteBookings.length]);

  // Scroll to the ConfirmationPopup when it's visible
  useEffect(() => {
    // Scroll to the warning dialog when it's shown
    if (showWarning && (weeklyChangesMade || dailyChangesMade)) {
      if (warningRef.current) {
        warningRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }

    // Scroll to the daily changes warning dialog when it's shown
    if (showWarning && dailyChangesMade && dayToEdit) {
      if (dayWarningRef.current) {
        dayWarningRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }

    // Scroll to the adhoc dialog when it's shown
    if (showAdhoc) {
      if (adhocRef.current) {
        adhocRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }

    // Scroll to the delete dialog when it's shown
    if (showPopup === "delete" && bookingToDelete) {
      if (deleteDailyRef.current) {
        deleteDailyRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }

    // Scroll to the confirm dialog when it's shown
    if (
      showPopup === "confirm" &&
      bookingToEdit?.length > 0 &&
      !bookingToDelete
    ) {
      if (confirmDailyRef.current) {
        confirmDailyRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }

    // Scroll to the weekly changes dialog when it's shown
    if (
      showPopup === "weekly" &&
      (changedBookings.length > 0 ||
        newBookings.length > 0 ||
        toDeleteBookings.length > 0)
    ) {
      if (weeklyChangesRef.current) {
        weeklyChangesRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [
    showWarning,
    showAdhoc,
    showPopup,
    weeklyChangesMade,
    dailyChangesMade,
    dayToEdit,
    bookingToDelete,
    bookingToEdit,
    changedBookings,
    newBookings,
    toDeleteBookings,
  ]);

  return (
    <div className="bg-white shadow-md rounded-lg w-full pb-2  relative">
      {bookingViewIsLoading && (
        <>
          <div className="absolute top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 z-50 flex items-center justify-center rounded-lg">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8 my-1"></div>
          </div>
        </>
      )}
      {showWarning && weekToEdit && (weeklyChangesMade || dailyChangesMade) && (
        <>
          <div
            className="absolute top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 z-50 flex items-center justify-center rounded-lg"
            onClick={() => setShowWarning(false)}
          ></div>
          <div
            className="absolute top-0 left-0 w-full h-full flex items-center z-50 justify-center"
            ref={warningRef}
          >
            <ConfirmationPopup
              title={"Warning"}
              body={
                "You are about to lose unsubmitted changes. Are you sure you want to continue?"
              }
              onNoClick={() => {
                setShowWarning(false);
              }}
              onYesClick={() => {
                setWeek(weekToEdit);
                setShowWarning(false);
                setWeekToEdit(null);
                setDailyChangesMade(false);
                // setWeeklyChangesMade(false);
              }}
              warning={true}
            />
          </div>
        </>
      )}
      {
        //
        showWarning && dailyChangesMade && dayToEdit && (
          <>
            <div
              className="absolute top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 z-50 flex items-center justify-center rounded-lg"
              onClick={() => setShowWarning(false)}
            ></div>
            <div
              className="absolute top-0 left-0 w-full h-full flex items-center z-50 justify-center"
              ref={dayWarningRef}
            >
              <ConfirmationPopup
                title={"Warning"}
                body={
                  "You are about to lose unsubmitted changes. Are you sure you want to continue?"
                }
                onNoClick={() => {
                  setShowWarning(false);
                }}
                onYesClick={() => {
                  setDay(dayToEdit);
                  setShowWarning(false);
                  setDayToEdit(null);
                  setDailyChangesMade(false);
                }}
                warning={true}
              />
            </div>
          </>
        )
      }

      {showPopup == "delete" && bookingToDelete && (
        <>
          <div
            className="absolute top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 z-50 flex items-center justify-center rounded-lg"
            onClick={() => setShowPopup(null)}
          ></div>
          <div
            className="absolute top-0 left-0 w-full h-full flex items-center z-50 justify-center"
            ref={deleteDailyRef}
          >
            <ConfirmationPopup
              title={"Cancel Booking"}
              body={"This will cancel the booking. Are you sure?"}
              onNoClick={() => {
                setShowPopup(null);
              }}
              onYesClick={() => {
                handleDeleteBooking(bookingToDelete);
              }}
            />
          </div>
        </>
      )}
      {showPopup == "confirm" &&
        bookingToEdit?.length > 0 &&
        !bookingToDelete && (
          <>
            <div
              className="absolute top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 z-50 flex items-center justify-center rounded-lg"
              onClick={() => setShowPopup(null)}
            ></div>
            <div
              className="absolute top-0 left-0 w-full h-full flex items-center z-50 justify-center"
              ref={confirmDailyRef}
            >
              <ConfirmationPopup
                title={"Confirm Bookings"}
                body={`This will submit your changes to ${bookingToEdit.length} trips(s). Are you sure?`}
                onNoClick={() => {
                  setShowPopup(null);
                }}
                onYesClick={() => {
                  handleConfirmBooking();
                }}
              />
            </div>
          </>
        )}
      {showPopup == "weekly" &&
        (changedBookings.length > 0 ||
          newBookings.length > 0 ||
          toDeleteBookings.length > 0) && (
          <>
            <div
              className="absolute top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 z-50 flex items-center justify-center rounded-lg"
              onClick={() => setShowPopup(null)}
            ></div>
            <div
              className="absolute top-0 left-0 w-full h-full flex items-center z-50 justify-center"
              ref={weeklyChangesRef}
            >
              <ConfirmationPopup
                title={"Confirm Changes"}
                body={getWeeklyChangesBody()}
                onNoClick={() => {
                  setShowPopup(null);
                }}
                onYesClick={() => {
                  handleConfirmWeeklyBookings();
                }}
              />
            </div>
          </>
        )}

      <div className="booker">
        <div>
          <div className="w-full flex mb-4">
            {role !== "employee" && (
              <div className="tabs flex bg-gray-200 rounded-t-lg w-full">
                <button
                  className={`scheduler ${view === "week"
                    ? " text-purple-500 border-t border-r border-gray-300 bg-white  "
                    : "bg-gray-200 border-b text-gray-700"
                    } text-sm py-2 px-4 rounded-t-lg focus:outline-none w-1/5 ${selectedCompany === null
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                    }`}
                  onClick={() => {
                    setView("week");
                  }}
                  disabled={selectedCompany === null}
                >
                  Scheduler
                  {view === "week" && selectedTeam !== null && (
                    <button
                      className='text-gray-500 cursor-pointer hover:text-gray-900 ml-2'
                      onClick={parentManagedScheduler}
                    >
                      <i className="fas fa-question-circle" />
                    </button>
                  )}
                </button>

                {(role === "admin" || role === "logistics_specialist" || role === "company_admin") && (
                  <>
                    <button
                      className={`ad-hocs ${view === "ad-hoc"
                        ? " text-purple-500 border-t border-r border-gray-300 bg-white  "
                        : "bg-gray-200 border-b text-gray-700"
                        } text-sm py-2 px-4 rounded-t-lg focus:outline-none w-1/5 ${(selectedCompany === null || role !== "admin")
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                        }`}
                      onClick={() => {
                        setViewAdhoc(true);
                        setSelectedView("ad-hoc");
                        weeklyChangesMade
                          ? setShowViewSwitchModal(true)
                          : setView("ad-hoc");
                      }}
                      disabled={(selectedCompany === null || role !== "admin")}
                    >
                      Ad-hocs
                      {view === "ad-hoc" && (
                        <button
                          className='text-gray-500 cursor-pointer hover:text-gray-900 ml-2'
                          onClick={parentManagedAdhocs}
                        >
                          <i className="fas fa-question-circle" />
                        </button>
                      )}
                    </button>

                    <button
                      className={`teams ${view === "teams"
                        ? " text-purple-500 border-t border-l border-gray-300 bg-white "
                        : "bg-gray-200 border-b text-gray-700"
                        } text-sm py-2 px-4 rounded-t-lg focus:outline-none w-1/5 ${(selectedCompany === null || selectedCompany === undefined || isAllowedToEditCsb === false)
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                        }`}

                      onClick={() => {
                        setSelectedView("teams");
                        weeklyChangesMade
                          ? setShowViewSwitchModal(true)
                          : setView("teams");
                      }}
                      disabled={(selectedCompany === null || isAllowedToEditCsb === false)}
                    >
                      Teams
                      {view === "teams" && (
                        <button
                          className='text-gray-500 cursor-pointer hover:text-gray-900 ml-2'
                          onClick={parentManagedTeams}
                        >
                          <i className="fas fa-question-circle" />
                        </button>
                      )}
                    </button>

                    <button
                      className={`download ${view === "download"
                        ? " text-purple-500 border-t border-l border-r border-gray-300 bg-white  "
                        : "bg-gray-200 border-b text-gray-700"
                        } text-sm py-2 px-4 rounded-t-lg focus:outline-none w-1/5 ${(selectedTeam === null || isAllowedToEditCsb === false)
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                        }`}

                      onClick={() => {
                        setSelectedView("download");
                        weeklyChangesMade
                          ? setShowViewSwitchModal(true)
                          : setView("download");
                      }}
                      disabled={(selectedTeam === null || isAllowedToEditCsb === false)}
                    >
                      Download
                      {view === "download" && (
                        <button
                          className='text-gray-500 cursor-pointer hover:text-gray-900 ml-2'
                          onClick={parentManagedDownload}
                        >
                          <i className="fas fa-question-circle" />
                        </button>
                      )}
                    </button>

                    <button
                      className={`upload ${view === "upload"
                        ? " text-purple-500 border-t border-l border-r border-gray-300 bg-white  "
                        : "bg-gray-200 border-b text-gray-700"
                        } text-sm py-2 px-4 rounded-t-lg focus:outline-none w-1/5 ${(selectedTeam === null || isAllowedToEditCsb === false)
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                        }`}

                      onClick={() => {
                        setSelectedView("upload");
                        weeklyChangesMade
                          ? setShowViewSwitchModal(true)
                          : setView("upload");
                      }}
                      disabled={(selectedTeam === null || isAllowedToEditCsb === false)}
                    >
                      Upload
                      {view === "upload" && (
                        <button
                          className='text-gray-500 cursor-pointer hover:text-gray-900 ml-2'
                          onClick={parentManagedUpload}
                        >
                          <i className="fas fa-question-circle" />
                        </button>
                      )}
                    </button>
                  </>
                )}
              </div>
            )}

            {showViewSwitchModal && (
              <UnsavedChanges
                handleYesClick={handleViewSwitchYes}
                handleNoClick={handleViewSwitchNo}
              />
            )}
          </div>
          {
            isAllowedToEditCsb === false && (
              <p className="text-sm px-4 text-gray-500">
                Your current role is not allowed to edit/make bookings or manage teams.
              </p>
            )
          }
          {
            // Select a company first before choosing a team, if there are multiple companies available for the user to choose from (e.g. admin) - otherwise, just show the team selection dropdown (e.g. manager) - don't show the company selection dropdown if there's only one company available
            (role === "admin" || role === "logistics_specialist" || role === "company_admin") && (selectedCompany === null || selectedCompany === undefined) && (
              <div className=" p-4 w-full">

                <div className="w-1/3">
                  <CompanySelection
                    selectedCompany={selectedCompany}
                    companies={companies}
                    handleSelectChange={handleSelectChange}
                    teamsLoading={teamsLoading}
                  />
                </div>
              </div>
            )
          }
        </div>
      </div>

      <div className="p-4 py-0">
        {view == "download" && selectedCompany !== null && (
          <div>
            <>
              <div className="w-full flex flex-row space-x-4 justify-center items-center bg-white">
                <div className="w-full">
                  {(role === "admin" || role === "logistics_specialist" || role === "company_admin") && (selectedCompany !== null || selectedCompany !== undefined) && (
                    <CompanySelection
                      selectedCompany={selectedCompany}
                      companies={companies}
                      handleSelectChange={handleSelectChange}
                      teamsLoading={teamsLoading}
                    />
                  )}
                </div>
                <TeamSelection
                  selectedCompany={selectedCompany}
                  teams={teams}
                  selectedTeam={selectedTeam}
                  setSelectedTeam={setSelectedTeam}
                  setTeamEmployees={setTeamEmployees}
                  reloadTeams={reloadTeams}
                  setReloadTeams={setReloadTeams}
                  teamsLoading={teamsLoading}
                  role={role}
                  user={user}
                />

                <DateSelection
                  dateRanges={dateRanges}
                  selectedWeek={selectedWeek}
                  setWeek={setWeek}
                  pageType={"downloads"}
                  setShowWarning={setShowWarning}
                  setWeekToEdit={setWeekToEdit}
                  dailyChangesMade={dailyChangesMade}
                  weeklyChangesMade={weeklyChangesMade}
                />
              </div>

              {/* Bookings Selector */}
              {selectedCompany !== null && selectedTeam !== null && (
                <>
                  <div className="grid grid-cols-4 gap-4  bg-gray-200 rounded-lg py-2 mt-2 px-2">
                    <div className="col-span-1">
                      <div className="w-full">
                        <EmployeeMultiSelect
                          setShowDropdown={() => { }}
                          showDropdown={""}
                          availableEmployees={selectedTeam ? teamEmployees : []}
                          selectedEmployees={selectedEmployees}
                          setSelectedEmployees={setSelectedEmployees}
                          excludeSelected={false}
                          selectedDay={selectedDay}
                          timesUp={timesUp}
                          setShowAdhoc={setShowAdhoc}
                        />
                      </div>
                    </div>

                    <div className="col-span-1">
                      <div className="w-full">
                        <InboundTimeSelector
                          timeSlot={inboundTimeslot}
                          setTimeSlot={setInboundTimeslot}
                          selectedDay={selectedDay}
                          isAdhoc={timesUp}
                          selectedEmployees={selectedEmployees}
                        />
                      </div>
                    </div>

                    <div className="col-span-1">
                      <div className="w-full">
                        <OutboundTimeSelector
                          timeSlot={outboundTimeslot}
                          setTimeSlot={setOutboundTimeslot}
                          selectedDay={selectedDay}
                          isAdhoc={timesUp}
                          selectedEmployees={selectedEmployees}
                        />
                      </div>
                    </div>

                    <div className="col-span-1">
                      <div className="w-full">
                        <CompanyAddressSelection
                          className="bg-gray-200 text-gray-500 font-small py-1 px-2 rounded-lg"
                          selectedCompanyAddress={selectedCompanyAddress}
                          setSelectedCompanyAddress={setSelectedCompanyAddress}
                          companyAddresses={companyAddresses}
                          addressLoading={addressLoading}
                          selectedEmployees={selectedEmployees}
                          setUpdateState={setUpdateState}
                          updateState={updateState}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
            <DownloadCsv
              selectedTimeslot={selectedTimeslot}
              outboundTimeslot={outboundTimeslot}
              inboundTimeslot={inboundTimeslot}
              selectedEmployees={selectedEmployees}
              selectedWeek={selectedWeek}
              selectedCompanyAddress={selectedCompanyAddress}
              tripType={tripType}
              selectedCompany={selectedCompany}
            />
          </div>
        )}
        {view == "upload" && selectedCompany !== null && (
          <div>
            {role !== "employee" && (
              <div className="flex gap-2">
                <UploadCsv
                  selectedTimeslot={selectedTimeslot}
                  selectedEmployees={selectedEmployees}
                />
              </div>
            )}
          </div>
        )}
        {view == "teams" && selectedCompany !== null && (
          <div>
            <div className="flex space-x-4 ">
              {(role == "admin" || role == "company_admin" || role == "logistics_specialist") && (
                <button
                  className={`teamCreate py-2 px-4 rounded text-sm ${activeTab === "createTeamTab"
                    ? "bg-purple-500 text-white"
                    : "bg-gray-200 text-gray-700"
                    }`}
                  onClick={() => handleTabClick("createTeamTab")}
                  disabled={selectedCompany === null}
                >
                  Create Team
                </button>
              )}
              <button
                className={`teamEdit py-2 px-4 rounded text-sm ${activeTab === "editTeamTab"
                  ? "bg-purple-500 text-white"
                  : "bg-gray-200 text-gray-700"
                  }`}
                disabled={teams.length === 0}
                onClick={() =>
                  teams.length > 0 && handleTabClick("editTeamTab")
                }
              >
                Edit Team
              </button>
            </div>

            <div className="">
              {activeTab === "createTeamTab" && (

                <CreateTeamDialogue
                  companies={companies}
                  userCompany={selectedCompany}
                  role={role}
                  handleCloseDialogue={closeModal}
                  reloadTeams={reloadTeams}
                  setReloadTeams={setReloadTeams}
                  teamsLoading={teamsLoading}
                  handleSelectChange={handleSelectChange}
                  selectedCompany={selectedCompany}
                  handleTabClick={handleTabClick}
                />
              )}
              {activeTab === "editTeamTab" && (
                <EditTeamDialogue
                  selectedCompany={selectedCompany}
                  handleCloseDialogue={closeModal}
                  reloadTeams={reloadTeams}
                  teams={teams}
                  setTeams={setTeams}
                  setReloadTeams={setReloadTeams}
                  role={role}
                  handleTabClick={handleTabClick}
                />
              )}
            </div>
          </div>
        )}

        {view == "week" && (selectedCompany !== null && selectedCompany !== undefined) && (
          <>
            <div className="w-full flex flex-row space-x-4 justify-center items-center py-2 bg-white">
              <div className="w-full">
                {(role === "admin" || role === "logistics_specialist" || role === "company_admin") && (
                  <CompanySelection
                    selectedCompany={selectedCompany}
                    companies={companies}
                    handleSelectChange={handleSelectChange}
                    teamsLoading={teamsLoading}
                  />
                )}
              </div>
              <TeamSelection
                selectedCompany={selectedCompany}
                teams={teams}
                selectedTeam={selectedTeam}
                setSelectedTeam={setSelectedTeam}
                setTeamEmployees={setTeamEmployees}
                reloadTeams={reloadTeams}
                setReloadTeams={setReloadTeams}
                teamsLoading={teamsLoading}
                role={role}
                user={user}
              />
              <DateSelection
                dateRanges={dateRanges}
                selectedWeek={selectedWeek}
                setWeek={setWeek}
                pageType={"bookings"}
                setShowWarning={setShowWarning}
                setWeekToEdit={setWeekToEdit}
                dailyChangesMade={dailyChangesMade}
                weeklyChangesMade={weeklyChangesMade}
              />
            </div>

            {/* Bookings Selector */}
            {(selectedCompany !== null && selectedCompany !== undefined) && selectedTeam !== null && isAllowedToEditCsb === true && role !== "employee" && (
              <>
                <div className="bookingsSelect flex w-full bg-gray-200 rounded-lg py-2 mt-2 px-2">
                  <div className="grid grid-cols-4  gap-5 w-full">
                    <div className="col-span-1">
                      <div className="w-full">
                        <DaySelection
                          selectedWeek={selectedWeek}
                          selectedDay={selectedDay}
                          setDay={setDay}
                          pageType={"bookings"}
                          dailyChangesMade={dailyChangesMade}
                          setShowWarning={setShowWarning}
                          setDayToEdit={setDayToEdit}
                          closingTime={selectedCompany?.cut_off_time ? selectedCompany?.cut_off_time : "16:00PM"}
                        />
                      </div>
                    </div>

                    <div className="col-span-1">
                      <div className="w-full">
                        <EmployeeMultiSelect
                          setShowDropdown={() => { }}
                          showDropdown={""}
                          availableEmployees={selectedTeam ? teamEmployees : []}
                          selectedEmployees={selectedEmployees}
                          setSelectedEmployees={setSelectedEmployees}
                          excludeSelected={false}
                          selectedDay={selectedDay}
                          timesUp={timesUp}
                          setShowAdhoc={setShowAdhoc}
                        />
                      </div>
                    </div>

                    <div className="col-span-1">
                      <div className="w-full">
                        <TimeslotPicker
                          timeSlot={selectedTimeslot}
                          setTimeSlot={setSelectedTimeslot}
                          selectedDay={selectedDay}
                          isAdhoc={timesUp}
                          selectedEmployees={selectedEmployees}
                          bookingTimeRange={selectedCompany?.booking_time_range || 2}
                          openingTime={selectedCompany?.opening_time || "06:00AM"}
                          closingTime={selectedCompany?.cut_off_time || "16:00PM"}
                        />
                      </div>
                    </div>

                    <div className="col-span-1">
                      <div className="w-full">
                        <CompanyAddressSelection
                          selectedCompanyAddress={selectedCompanyAddress}
                          setSelectedCompanyAddress={setSelectedCompanyAddress}
                          companyAddresses={companyAddresses}
                          addressLoading={addressLoading}
                          selectedEmployees={selectedEmployees}
                          setUpdateState={setUpdateState}
                          updateState={updateState}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="addBooking flex ml-2">
                    <button
                      className={`py-1 px-3 rounded text-white text-sm ${(selectedDay === null && selectedTimeslot === null) ||
                        selectedEmployees.length === 0 ||
                        selectedCompanyAddress.name == "Select an Address"
                        ? "bg-gray-300 cursor-not-allowed"
                        : "bg-purple-500 hover:bg-purple-700 animate-pulse "
                        }`}
                      onClick={handlePreloadBookings}
                      disabled={
                        (selectedDay === null && selectedTimeslot === null) ||
                        selectedEmployees.length === 0 ||
                        selectedCompanyAddress.name == "Select an Address"
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {/* DAY and WEEK views */}
        {view === "day" && teamEmployees.length > 0 && (
          <>
            <div className="w-full">
              <div className="w-full h">
                <DaySelection
                  selectedWeek={selectedWeek}
                  selectedDay={selectedDay}
                  setDay={setDay}
                  pageType={"bookings"}
                  dailyChangesMade={dailyChangesMade}
                  setShowWarning={setShowWarning}
                  setDayToEdit={setDayToEdit}
                  closingTime={selectedCompany?.cut_off_time ? selectedCompany?.cut_off_time : "16:00PM"}
                />
              </div>
              <div className="w-full my-4">
                <EmployeeMultiSelect
                  setShowDropdown={() => { }}
                  showDropdown={""}
                  availableEmployees={selectedTeam ? teamEmployees : []}
                  selectedEmployees={selectedEmployees}
                  setSelectedEmployees={setSelectedEmployees}
                  excludeSelected={false}
                  selectedDay={selectedDay}
                  timesUp={timesUp}
                  setShowAdhoc={setShowAdhoc}
                />
              </div>
              <div className="w-full flex flex-row justify-between mt-4 space-x-2">
                <div className="w-1/3">
                  <TimeslotPicker
                    timeSlot={selectedTimeslot}
                    setTimeSlot={setSelectedTimeslot}
                    selectedDay={selectedDay}
                    timesUp={timesUp}
                    selectedEmployees={selectedEmployees}
                    bookingTimeRange={selectedCompany?.booking_time_range || 2}
                    openingTime={selectedCompany?.opening_time || "06:00AM"}
                    closingTime={selectedCompany?.cut_off_time || "16:00PM"}
                  />
                </div>
                <div className="w-1/3">
                  <CompanyAddressSelection
                    className="bg-gray-200 text-gray-500 font-small py-1 px-2 rounded-lg"
                    selectedCompanyAddress={selectedCompanyAddress}
                    setSelectedCompanyAddress={setSelectedCompanyAddress}
                    companyAddresses={companyAddresses}
                    addressLoading={addressLoading}
                    selectedEmployees={selectedEmployees}
                    setUpdateState={setUpdateState}
                    updateState={updateState}
                  />
                </div>
                <div className="w-1/3">
                  <BoundSelector
                    className="bg-gray-200 text-gray-500 font-small py-1 px-2 rounded-lg"
                    handleTabSelect={handleTabSelect}
                    tripType={tripType}
                    inboundCountDaily={inboundCountDaily}
                    outboundCountDaily={outboundCountDaily}
                  />
                </div>
              </div>
              <div
                className="w-full justify-center align-center mt-2"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  zIndex: 0,
                }}
              >
                <SearchBox
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                />
              </div>
            </div>
            <DailyTable
              teamEmployees={teamEmployees}
              selectedDay={selectedDay}
              view={tripType}
              selectedEmployees={selectedEmployees}
              setSelectedEmployees={setSelectedEmployees}
              selectedTimeslot={selectedTimeslot}
              selectedTripType={tripType}
              selectedCompanyAddress={selectedCompanyAddress}
              bookings={dailyBookings}
              setBookings={setDailyBookings}
              bookedTrips={bookedTrips}
              setRefresh={setRefresh}
              refresh={refresh}
              timesUp={timesUp}
              searchQuery={searchQuery}
              pageView={view}
              setShowPopup={setShowPopup}
              setBookingToDelete={setBookingToDelete}
              setBookingToEdit={setBookingToEdit}
              updateState={updateState}
              setDailyChangesMade={setDailyChangesMade}
            />
          </>
        )}
        {view === "week" && teamEmployees?.length > 0 && (
          <>
            <div
              className="w-full justify-center align-center my-2"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="flex gap-3 items-center">
                {teamEmployees.length > 1 && (
                  <>
                    <SearchBox
                      searchQuery={searchQuery}
                      setSearchQuery={setSearchQuery}
                    />


                  </>
                )}
                <SchedularInfo />
              </div>

              <div className="w-1/4 justify-end ">
                <BoundSelector
                  handleTabSelect={handleTabSelect}
                  tripType={tripType}
                  inboundCountDaily={inboundCountWeekly}
                  outboundCountDaily={outboundCountWeekly}
                />
              </div>
            </div>
            <div style={{ zIndex: 0, userSelect: "none" }}>
              <WeeklyTable
                bookings={weeklyBookings}
                setBookings={setWeeklyBookings}
                setWeek={setWeek}
                setWeekToEdit={setWeekToEdit}
                setDailyChangesMade={setDailyChangesMade}
                setShowWarning={setShowWarning}
                weekToEdit={weekToEdit}
                bookedTrips={weeklyBookedTrips}
                view={tripType}
                setTimesUp={setTimesUp}
                timesUp={timesUp}
                selectedWeek={selectedWeek}
                teamEmployees={teamEmployees}
                companyAddresses={companyAddresses}
                refresh={refresh}
                setRefresh={setRefresh}
                setCleanUpBookings={setCleanUpBookings}
                searchQuery={searchQuery}
                pageView={view}
                setShowPopup={setShowPopup}
                setChangedBookings={setChangedBookings}
                setNewBookings={setNewBookings}
                setToDeleteBookings={setToDeleteBookings}
                changedBookings={changedBookings}
                newBookings={newBookings}
                toDeleteBookings={toDeleteBookings}
                cleanUpBookings={cleanUpBookings}
                setShowAdhoc={setShowAdhoc}
                companyCutOffTime={getCompanyTimeOut()}
                bookingTimeRange={selectedCompany?.booking_time_range || 2}
                openingTime={selectedCompany?.opening_time || "06:00AM"}
                closingTime={selectedCompany?.cut_off_time || "16:00PM"}
                setWeeklyChangesMade={setWeeklyChangesMade}
                reloadTeams={reloadTeams}
                setReloadTeams={setReloadTeams}
                weeklyChangesMade={weeklyChangesMade}
                handleTabSelect={handleTabSelect}
                setView={setView}
                role={role}
                selectedTimeslot={selectedTimeslot}
                selectedEmployees={selectedEmployees}
                selectedCompanyAddress={selectedCompanyAddress}
                setSelectedEmployees={setSelectedEmployees}
                setSelectedTimeslot={setSelectedTimeslot}
                setSelectedCompanyAddress={setSelectedCompanyAddress}
                daySelected={selectedDay}
                tripType={tripType}
                preLoadBookings={preLoadBookings}
                teamSelected={selectedTeam}
                isAllowedToEditCsb={isAllowedToEditCsb}
                weekRanges={dateRanges}
              />
            </div>
          </>
        )}
        {view == "ad-hoc" && (
          <>
            <div>
              <AdhocView
                setWeeklyChangesMade={setWeeklyChangesMade}
                cleanUpBookings={cleanUpBookings}
                setCleanUpBookings={setCleanUpBookings}
                teamEmployees={teamEmployees}
                onNoClick={() => {
                  setShowAdhoc(false);
                }}
                summaryAdhocData={summaryAdhocData}
                selectedDay={selectedDay}
                selectedTeam={selectedTeam}
                companyAddresses={companyAddresses}
                addressLoading={addressLoading}
                setRefresh={setRefresh}
                refresh={refresh}
                reloadTeams={reloadTeams}
                setReloadTeams={setReloadTeams}
                isAllowedToEditCsb={isAllowedToEditCsb}
                bookingTimeRange={selectedCompany?.booking_time_range || 2}
                openingTime={selectedCompany?.opening_time || "06:00AM"}
                closingTime={selectedCompany?.cut_off_time || "16:00PM"}
              />
            </div>
          </>
        )}
      </div>
      {showMessage && (
        <ResponseMessage title={responseTitle} message={responseMessage} />
      )}
      {showModal && (
        <UnsavedChanges
          handleYesClick={handleYesClick}
          handleNoClick={handleNoClick}
        />
      )}
    </div>
  );
};

export default BookingView;
