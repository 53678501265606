import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './company_admin/company_admin.css';
import { Steps } from 'intro.js-react';

// components
import StatsCard from './company_admin/StatsCard';
import GraphsCard from './company_admin/GraphsCard';
import DashMain from './company_admin/DashMain';

const CompanyAdminDashboard = ({ user, theme_color }) => {
  const [data, setData] = useState(null);

  // calendar state
  const [showTooltip, setShowTooltip] = useState(false);
  const [groupedData, setGroupedData] = useState({});
  const [searchQuery, setSearchQuery] = useState('');

  // Graphs card states
  const [tripsGraph, setTripsGraph] = useState(true);
  const [kmsGraph, setKmsGraph] = useState(false);
  const [occupancyGraph, setOccupancyGraph] = useState(true);
  const [mixedGraph, setMixedGraph] = useState(false);

  // search
  const [selectedDateRange, setSelectedDateRange] = useState({
    label: null,
    labelPart: 'This week',
    datePart: null,
    dates: null,
  });

  // loader
  const [fetchLoader, setFetchLoader] = useState(true);

  const fetchData = async () => {
    if (selectedDateRange?.label != null) {
      setFetchLoader(true);
    }
    try {
      let url = '/dashboard/index';
      if (selectedDateRange?.label) {
        url += `?date_range_filter=${selectedDateRange?.label}`;
      }
      const response = await axios.get(url, {
        headers: { Accept: 'application/json' },
      });
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setFetchLoader(false);
    }
  };

  const [introState, setIntroState] = useState({
    stepsEnabled: false,
    initialStep: 0,
    options: {
      showProgress: true,
      showBullets: false,
      tooltipClass: 'etapathTooltip',
    },
    steps: [
      {
        element: '.heading',
        title:
          "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Welcome to Etapath!",
        intro: "Let's get you trained up!</span>",
        position: 'left',
      },
      {
        element: '.summary',
        title:
          "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Info Summaries",
        intro: 'See a summary of all your company info.</span>',
        position: 'left',
      },
      // {
      //   element: '.trips',
      //   title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Total Trips",
      //   intro: "See your company's total trips.</span>",
      //   position: 'left'
      // },
      // {
      //   element: '.totalKms',
      //   title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Total Kilometers",
      //   intro: "See the total kilometers travelled this week.</span>",
      //   position: 'left'
      // },
      // {
      //   element: '.totalCancellations',
      //   title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Cancellations",
      //   intro: "See this week's total cancellations made.</span>",
      //   position: 'left'
      // },
      // {
      //   element: '.totalBookings',
      //   title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Bookings",
      //   intro: "See this week's total bookings.</span>",
      //   position: 'left'
      // },
      // {
      //   element: '.totalCosts',
      //   title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Total Costs",
      //   intro: "See your company's total costs for this week.</span>",
      //   position: 'left'
      // },
      {
        element: '.weeklySchedule', // Haven't found element
        title:
          "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Weekly Schedule",
        intro: "Select which week's schedule you would like to see.</span>",
        position: 'left',
      },
      {
        element: '.infoTable',
        title:
          "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Trip Information",
        intro:
          "View more detailed information about your company's trips.</span>",
        position: 'left',
      },
      {
        element: '.recentBookings',
        title:
          "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Recent Bookings",
        intro: "View your company's most recent bookings.</span>",
        position: 'left',
      },
      {
        element: '.annualStats',
        title:
          "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Annual Statistics",
        intro:
          "Select or download detailed information about your company's activities this year.</span>",
        position: 'left',
      },
    ],
  });

  useEffect(() => {
    fetchData();
  }, [selectedDateRange]);

  return (
    <div className="w-full justify-between text-xl font-medium text-gray-700">
      <div className="heading flex flex-row">
        <h1 className="">Company Admin Dashboard</h1>
        <button
          className="text-gray-500 cursor-pointer hover:text-gray-900 ml-2"
          onClick={() => {
            setIntroState({ ...introState, stepsEnabled: true });
          }}>
          <i className="fas fa-question-circle" />
        </button>
      </div>
      <p className="text-sm text-gray-500">
        <span className="font-normal">Logged in as</span> {user.name}{' '}
        {user.surname}
      </p>

      {fetchLoader && selectedDateRange?.label === null ? (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-50 flex flex-col items-center justify-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4 h-12 w-12 mb-4"></div>
        </div>
      ) : (
        <>
          {fetchLoader && selectedDateRange?.label != null && (
            <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-50 flex flex-col items-center justify-center">
              <div className="loader ease-linear rounded-full border-4 border-t-4 h-12 w-12 mb-4"></div>
            </div>
          )}
          <StatsCard
            data={data}
            theme={theme_color}
            range={selectedDateRange?.labelPart}
          />
          <DashMain
            data={data}
            companies={data.companies}
            regions={data.regions_list}
            trips={data.company_stats[0]}
            kms={data.company_stats[1]}
            cancellations={data.company_stats[2]}
            bookings={data.company_stats[3]}
            recent_bookings={data.recent_bookings}
            showTooltip={showTooltip}
            setShowTooltip={setShowTooltip}
            groupedData={groupedData}
            setGroupedData={setGroupedData}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            costs={data.company_stats[4]}
            date_ranges={data.date_ranges}
            tripsGraph={tripsGraph}
            setTripsGraph={setTripsGraph}
            kmsGraph={kmsGraph}
            setKmsGraph={setKmsGraph}
            theme={theme_color}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
          />
          <GraphsCard
            companies={data.companies}
            regions={data.regions_list}
            ranges={data.date_ranges}
            occupancy={data.occupancy_stats}
            costs={data.company_stats[4]}
            trips={data.company_stats[0]}
            kms={data.company_stats[1]}
            cancellations={data.company_stats[2]}
            bookings={data.company_stats[3]}
            range={selectedDateRange?.labelPart}
            tripsGraph={tripsGraph}
            setTripsGraph={setTripsGraph}
            kmsGraph={kmsGraph}
            setKmsGraph={setKmsGraph}
            occupancyGraph={occupancyGraph}
            setOccupancyGraph={setOccupancyGraph}
            mixedGraph={mixedGraph}
            setMixedGraph={setMixedGraph}
          />
          <Steps
            enabled={introState.stepsEnabled}
            steps={introState.steps}
            initialStep={introState.initialStep}
            onExit={() => {
              setTimeout(() => {
                setIntroState({ ...introState, stepsEnabled: false });
              }, 1000);
            }}
            options={introState.options}
          />
        </>
      )}
    </div>
  );
};

export default CompanyAdminDashboard;
