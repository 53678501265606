import React, { useRef } from 'react';
import { jsPDF } from 'jspdf';
import Dropdown from './Dropdown';
import LineChartComponent from './LineChartComponent';
import ChartComponent from './ChartComponent';
import headerImg from '../../../../assets/images/Header-02.png';

const GraphsCard = ({
  companies,
  regions,
  occupancy,
  costs,
  trips,
  kms,
  bookings,
  cancellations,
  range,
  tripsGraph,
  setTripsGraph,
  kmsGraph,
  setKmsGraph,
  occupancyGraph,
  setOccupancyGraph,
  mixedGraph,
  setMixedGraph,
}) => {
  const companyList = [];
  const labels = [];
  const occupancyDataset = [];
  const pieChartDataset = [];
  const costsData = [];
  const tripsData = [];
  const kmsData = [];
  const bookingsData = [];
  const cancellationsData = [];
  const filteredLabels = [];
  const filteredCostsData = [];
  const filteredColors = [];
  const chartRef = useRef(null);
  const pieChartRef = useRef(null);
  const tripChartRef = useRef(null);
  const kmChartRef = useRef(null);
  const mixedChartRef = useRef(null);

  const companiesLength = companies.length;
  // let pieColors = null;

  let monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  {
    Object.keys(regions).map((region) => {
      regions[region].map((location) => {
        let occupancyData = Array.from({ length: 12 }, () => 0);
        const backgroundColors = Array.from({ length: 12 }, () =>
          getRandomColor()
        );
        // pieColors = Array.from({ length: companyList.length }, () =>
        //   getRandomColor()
        // );

        companyList.push({
          region: region,
          office: location.name,
        });

        const regionAcronym = region.match(/[A-Z]/g);
        let regionAcronymStr = regionAcronym ? regionAcronym.join('') : '';
        if (region === 'Gauteng') {
          regionAcronymStr = 'GP';
        }

        Object.entries(occupancy).forEach(([key, value]) => {
          for (let i = 1; i < monthNames.length + 1; i++) {
            let month = parseInt(key.split('-')[1], 10);
            let company_name = key.split('-')[0];

            if (company_name === location.name && month === i) {
              let occupancyValue = value;
              if (occupancyValue !== 0) {
                let occupancyFixedValue = Math.min(Number(occupancyValue), 100);
                occupancyData[month - 1] = Math.round(occupancyFixedValue);
              }
            }
          }
        });

        const textToDisplay =
          location.name.length > 12
            ? location.name.slice(0, 6) + '...'
            : location.name;

        occupancyDataset.push({
          label: location.name,
          data: occupancyData,
          backgroundColor: backgroundColors[backgroundColors.length - 1],
          borderColor: backgroundColors[backgroundColors.length - 1],
          borderWidth: 1,
          datalabels: {
            display: false,
          },
          fill: false,
        });

        labels.push(`${regionAcronymStr}: ${textToDisplay}`);
      });
    });
  }

  companyList.map((company) => {
    costsData.push(costs[company.office] || 0);
    tripsData.push(trips[company.office] || 0);
    kmsData.push(kms[company.office] || 0);
    bookingsData.push(bookings[company.office] || 0);
    cancellationsData.push(cancellations[company.office] || 0);
  });

  companyList.forEach((company, index) => {
    let cost = costsData[index];
    let bgColor = getRandomColor();
    if (cost > 0) {
      filteredLabels.push(labels[index]);
      filteredCostsData.push(costsData[index]);
      filteredColors.push(bgColor);
    }
  });

  pieChartDataset.push({
    label: 'Costs',
    data: filteredCostsData,
    backgroundColor: filteredColors,
    borderColor: filteredColors,
    borderWidth: 1,
    hoverOffset: 2,
    datalabels: {
      display: false,
    },
    fill: false,
  });

  const max_value_bookings = Math.ceil(Math.max(...bookingsData) / 100) * 100;
  const max_value_cancellations =
    Math.ceil(Math.max(...cancellationsData) / 10) * 10;
  const max_value_trips = Math.ceil(Math.max(...tripsData) / 10) * 10;
  const max_kms_value = Math.ceil(Math.max(...kmsData) / 1000) * 1000;

  // Trips: Bar
  const barChartData = {
    labels: labels,
    datasets: [
      {
        label: 'Trips',
        data: tripsData,
        backgroundColor: '#ffe680',
        borderColor: '#ffe680',
        borderWidth: 1,
        datalabels: {
          display: false,
        },
        yAxisID: 'y-axis-trips',
      },
      {
        label: 'Cancellations',
        data: cancellationsData,
        backgroundColor: '#4da6ff',
        borderColor: '#4da6ff',
        borderWidth: 1,
        datalabels: {
          display: false,
        },
        yAxisID: 'y-axis-cancellations',
      },
      {
        label: 'Bookings',
        data: bookingsData,
        backgroundColor: '#ff3333',
        borderColor: '#ff3333',
        borderWidth: 1,
        datalabels: {
          display: false,
        },
        yAxisID: 'y-axis-bookings',
      },
    ],
    options: {
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: 'x',
      scales: {
        yAxes: [
          {
            id: 'y-axis-bookings',
            ticks: {
              beginAtZero: true,
              max: Math.ceil(max_value_bookings),
            },
            scaleLabel: {
              display: true,
              labelString: 'Trips/Bookings',
            },
          },
          {
            id: 'y-axis-trips',
            display: false,
            ticks: {
              display: false,
              beginAtZero: true,
              max: Math.ceil(max_value_trips),
            },
            scaleLabel: {
              display: false,
              labelString: 'Trips',
            },
          },
          {
            id: 'y-axis-cancellations',
            ticks: {
              beginAtZero: true,
              max: Math.ceil(max_value_cancellations),
            },
            position: 'right',
            scaleLabel: {
              display: true,
              labelString: 'Cancellations',
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              fontSize: 9,
            },
          },
        ],
      },
    },
  };

  // Kilometers: Bar
  const kmsChartData = {
    labels: labels,
    datasets: [
      {
        label: 'Kilometers',
        data: kmsData,
        backgroundColor: '#ffe680',
        borderColor: '#ffe680',
        borderWidth: 1,
        datalabels: {
          display: false,
        },
        yAxisID: 'y-axis-trips',
      },
    ],
    options: {
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: 'x',
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: Math.ceil(max_kms_value),
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              fontSize: 9,
            },
          },
        ],
      },
    },
  };

  // Total Costs: Pie
  const pieChartData = {
    labels: filteredLabels,
    datasets: pieChartDataset,
    options: { responsive: true, maintainAspectRatio: false },
  };

  // Occupancy: Line
  const lineChartData = {
    labels: monthNames,
    datasets: occupancyDataset,
    width: 500,
    height: 400,
  };

  // Bookings & Cancellations: Bar
  const mixedChartData = {
    labels: labels,
    datasets: [
      {
        label: 'Bookings',
        data: bookingsData,
        backgroundColor: '#0099ff',
        borderColor: '#0099ff',
        borderWidth: 1,
        datalabels: {
          display: false,
        },
        yAxisID: 'y-axis-bookings',
      },
      {
        label: 'Cancellations',
        data: cancellationsData,
        backgroundColor: '#ff3300',
        borderColor: '#ff3300',
        borderWidth: 1,
        datalabels: {
          display: false,
        },
        yAxisID: 'y-axis-cancellations',
      },
    ],
    options: {
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: 'x',
      scales: {
        yAxes: [
          {
            id: 'y-axis-bookings',
            ticks: {
              beginAtZero: true,
              max: Math.ceil(max_value_bookings),
            },
            scaleLabel: {
              display: true,
              labelString: 'Bookings',
            },
          },
          {
            id: 'y-axis-cancellations',
            ticks: {
              beginAtZero: true,
              max: Math.ceil(max_value_cancellations),
            },
            position: 'right',
            scaleLabel: {
              display: true,
              labelString: 'Cancellations',
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              fontSize: 9,
            },
          },
        ],
      },
    },
  };

  // piechart options
  const options = [
    {
      id: 'p1',
      icon: 'fa fa-download mr-2',
      value: '1',
      label: 'Download All',
    },
    {
      id: 'p2',
      icon: 'fa fa-download mr-2',
      value: '2',
      label: 'Download Chart',
    },
  ];

  // barchart options
  const tripsOptions = [
    {
      id: 't1',
      icon: 'fa fa-calendar mr-2',
      value: kmsGraph,
      label: 'Trips, Cancellations & Bookings',
    },
    {
      id: 't2',
      icon: 'fa fa-tachometer-alt mr-2',
      value: tripsGraph,
      label: 'Kilometers',
    },
    {
      id: 't3',
      icon: 'fa fa-download mr-2',
      value: true,
      label: 'Download All',
    },
    {
      id: 't4',
      icon: 'fa fa-download mr-2',
      value: tripsGraph,
      label: 'Download Chart',
    },
    {
      id: 't5',
      icon: 'fa fa-download mr-2',
      value: kmsGraph,
      label: 'Download Chart',
    },
  ];

  // linechart options
  const occupancyOptions = [
    {
      id: 'o1',
      icon: 'fas fa-chart-bar mr-2',
      value: occupancyGraph,
      label: 'Bookings & Cancellations',
    },
    {
      id: 'o2',
      icon: 'fas fa-chart-line mr-2',
      value: mixedGraph,
      label: 'Occupancy',
    },
    {
      id: 'oA',
      icon: 'fa fa-download mr-2',
      value: true,
      label: 'Download All',
    },
    {
      id: 'o3',
      icon: 'fa fa-download mr-2',
      value: occupancyGraph,
      label: 'Download Chart',
    },
    {
      id: 'o4',
      icon: 'fa fa-download mr-2',
      value: mixedGraph,
      label: 'Download Chart',
    },
  ];

  const downloadAll = () => {
    const pdfDoc = new jsPDF();
    pdfDoc.addImage(headerImg, 'PNG', 0, 0, 220, 30);

    const costsImageData = totalCostsId.toDataURL('image/png');
    pdfDoc.addImage(costsImageData, 'PNG', 60, 60, 85, 90);
    if (tripsGraph) {
      const tripImageData = tripsChartId.toDataURL('image/png');
      pdfDoc.addImage(tripImageData, 'PNG', 10, 170, 180, 100);
    }
    if (kmsGraph) {
      const kmsImageData = kmsChart.toDataURL('image/png');
      pdfDoc.addImage(kmsImageData, 'PNG', 10, 170, 180, 100);
    }

    pdfDoc.text(80, 50, 'Reports on Trips');
    pdfDoc.addPage();

    if (occupancyGraph) {
      const occupancyImageData = chartCanvas.toDataURL('image/png');
      pdfDoc.addImage(occupancyImageData, 'PNG', 10, 20, 180, 100);
    }
    if (mixedGraph) {
      const mixedImageData = mixedId.toDataURL('image/png');
      pdfDoc.addImage(mixedImageData, 'PNG', 10, 10, 185, 100);
    }

    pdfDoc.save('all_charts.pdf');
  };

  const downloadChart = (chartType) => {
    const pdfDoc = new jsPDF();
    pdfDoc.addImage(headerImg, 'PNG', 0, 0, 220, 30);

    if (chartType === 'costs') {
      const costsImageData = totalCostsId.toDataURL('image/png');
      pdfDoc.addImage(costsImageData, 'PNG', 30, 70, 150, 200);
      pdfDoc.text(80, 50, `Total costs in Rands for ${range}`);
      pdfDoc.save('total_costs_chart.pdf');
    } else if (chartType === 'trips') {
      const tripImageData = tripsChartId.toDataURL('image/png');
      pdfDoc.addImage(tripImageData, 'PNG', 10, 70, 180, 100);
      pdfDoc.text(10, 50, `Trips, Cancellations & Bookings for ${range}`);
      pdfDoc.save('trips_bookings_chart.pdf');
    } else if (chartType === 'kms') {
      const kmsImageData = kmsChart.toDataURL('image/png');
      pdfDoc.addImage(kmsImageData, 'PNG', 10, 70, 180, 100);
      pdfDoc.text(10, 50, `Kilometers for ${range}`);
      pdfDoc.save('kilometers_chart.pdf');
    } else if (chartType === 'occupancy') {
      const occupancyImageData = chartCanvas.toDataURL('image/png');
      pdfDoc.addImage(occupancyImageData, 'PNG', 10, 70, 180, 100);
      pdfDoc.text(10, 50, 'Occupancy for this year');
      pdfDoc.save('occupancy_chart.pdf');
    } else if (chartType === 'mixed') {
      const mixedImageData = mixedId.toDataURL('image/png');
      pdfDoc.addImage(mixedImageData, 'PNG', 10, 70, 190, 100);
      pdfDoc.text(10, 50, `Bookings and Cancellations for ${range}`);
      pdfDoc.save('bookings_cancellations_chart.pdf');
    }
  };

  const handleTripsSelect = (option) => {
    if (option.id === 't3') {
      downloadAll();
    } else if (option.id === 't4') {
      downloadChart('trips');
    } else if (option.id === 't5') {
      downloadChart('kms');
    } else {
      setTripsGraph(!tripsGraph);
      setKmsGraph(!kmsGraph);
    }
  };

  const handleOccupancySelect = (option) => {
    if (option.id === 'o3') {
      downloadChart('occupancy');
    } else if (option.id === 'o4') {
      downloadChart('mixed');
    } else if (option.id === 'oA') {
      downloadAll();
    } else {
      setOccupancyGraph(!occupancyGraph);
      setMixedGraph(!mixedGraph);
    }
  };

  const handleSelect = (option) => {
    if (option.id === 'p1') {
      downloadAll();
    } else if (option.id === 'p2') {
      downloadChart('costs');
    }
  };

  return (
    <div className="annualStats flex flex-col w-full mt-4 mb-2">
      <div className="w-full mt-4 mb-2">
        <div className="flex flex-col sm:flex-row justify-center">
          <div
            className={`flex ${
              companiesLength > 1
                ? 'w-full mb-4 sm:w-1/3 block'
                : 'w-1/3 hidden'
            } h-72 flex-col mr-4`}>
            <div className="flex flex-col h-full w-full rounded-lg bg-white py-2 px-4 shadow-md">
              <div className="flex justify-between w-full">
                <div className="order-first mt-2 text-base font-normal text-gray-500 normal-case w-full sm:w-2/3">
                  Estimated Total costs for {range.toLowerCase()} in Rands
                </div>
                <div className="order-last text-zinc-800 w-1/3">
                  <div className="float-right">
                    <Dropdown options={options} onSelect={handleSelect} />
                  </div>
                </div>
              </div>
              <ChartComponent
                {...pieChartData}
                type="pie"
                height={400}
                width={400}
                ref={pieChartRef}
                id="totalCostsId"
              />
            </div>
          </div>

          <div
            className={` ${
              companiesLength > 1 ? 'w-full sm:w-2/3 block' : 'w-2/3 hidden'
            } h-80`}>
            <div className="flex flex-col h-full w-full rounded-lg bg-white py-2 px-4 shadow-md">
              <div className="flex justify-between w-full">
                <div className="order-first mt-2 text-base font-normal truncate text-gray-500 normal-case w-2/3">
                  {tripsGraph
                    ? `Trips, Cancellations & Bookings for ${range.toLowerCase()}`
                    : `Kilometers for ${range.toLowerCase()}`}
                </div>
                <div className="order-last text-zinc-800 w-1/3">
                  <div className="float-right">
                    <Dropdown
                      options={tripsOptions}
                      onSelect={handleTripsSelect}
                    />
                  </div>
                </div>
              </div>
              {tripsGraph && (
                <ChartComponent
                  {...barChartData}
                  height={400}
                  width={500}
                  type="bar"
                  ref={tripChartRef}
                  id="tripsChartId"
                />
              )}
              {kmsGraph && (
                <ChartComponent
                  {...kmsChartData}
                  height={400}
                  width={500}
                  type="bar"
                  ref={kmChartRef}
                  id="kmsChart"
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full mt-4">
        <div className="w-full h-80">
          <div className="flex flex-col h-full w-full rounded-lg bg-white py-2 px-4 shadow-md">
            <div className="flex justify-between w-full">
              <div className="order-first mt-2 text-base font-normal truncate text-gray-500 normal-case w-2/3">
                {occupancyGraph
                  ? 'Occupancy for this year'
                  : `Bookings & Cancellations for ${range.toLowerCase()}`}
              </div>
              <div className="order-last text-zinc-800 w-1/3">
                <div className="float-right">
                  <Dropdown
                    options={occupancyOptions}
                    onSelect={handleOccupancySelect}
                  />
                </div>
              </div>
            </div>
            {occupancyGraph && (
              <LineChartComponent
                {...lineChartData}
                type="line"
                ref={chartRef}
                width={600}
                height={500}
              />
            )}
            {mixedGraph && (
              <ChartComponent
                {...mixedChartData}
                height={400}
                width={500}
                type="bar"
                ref={mixedChartRef}
                id="mixedId"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraphsCard;

function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
