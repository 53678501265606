import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './otpsignup.scss';
import axios from 'axios';
import { Autocomplete } from '@react-google-maps/api';
import OtpInput from 'react-otp-input';

const OtpSignup = ({
}) => {

    const [signupState, setSignupState] = useState({
        pageNumber: 0,
        name: "",
        surname: "",
        addressPrimary: "",
        email: "",
        password: "",
        confirmPassword: "",
        contactNumber: "",
        employeeNumber: "",
        termsAndConditions: false,
        showError: false,
        errorMessage: "",
        token: "",
        passwordShow: false,
        confirmPasswordShow: false
    });

    const [isLoading, setIsLoading] = useState(false);
    const [showResent, setShowResent] = useState(false);
    const [signupIsLoading, setSignupIsLoading] = useState(false);
    const [autocomplete, setAutocomplete] = useState(null);

    const onValueChanged = (type, value) => {
        setSignupState((prevState) => ({
            ...prevState,
            [type]: value
        }));
    }

    const onSignup = async () => {

        try {
            const { name, surname, addressPrimary, email, password, confirmPassword, termsAndConditions, contactNumber, token, employeeNumber } = signupState;
            let trimmedContactNumber = contactNumber?.trim()?.replace(/\s/g, '')
            let trimmedLowerEmail = email?.trim()?.toLowerCase()?.replace(/\s/g, '')
            if (token == "") {
                throw new Error('Please enter OTP.');
            }

            if (name == "" || surname == ""
                || addressPrimary == "" || email == ""
                || password == "" || password.length < 6 || confirmPassword !== password
                || termsAndConditions == false || contactNumber == "") {
                throw new Error('Please complete all fields.');
            }
            setSignupIsLoading(true);

            await axios('/otp_signup/save_user', {
                method: 'PUT',
                data: {
                    email: trimmedLowerEmail,
                    name,
                    surname,
                    password: password?.trim(),
                    address_primary: addressPrimary,
                    contact_number: trimmedContactNumber,
                    terms_and_conditions: termsAndConditions,
                    employee_number: employeeNumber,
                    token,
                },
                headers: { Accept: 'application/json' },
            });
            setSignupIsLoading(false);
            window.location.replace('/users/sign_in');

        } catch (e) {

            setSignupState((prevState) => ({
                ...prevState,
                showError: true,
                errorMessage: e.response?.data?.message ? e.response?.data?.message : e.message
            }));
            setTimeout(() => {
                setSignupState((prevState) => ({
                    ...prevState,
                    showError: false,
                    errorMessage: ""
                }));
            }, 3000)
            setSignupIsLoading(false);
        }


    }

    const onValidate = async (context) => {
        try {
            const { name, surname, addressPrimary, email, password, confirmPassword, termsAndConditions, contactNumber } = signupState;
            let trimmedContactNumber = contactNumber?.trim()?.replace(/\s/g, '')
            let trimmedLowerEmail = email?.trim()?.toLowerCase()?.replace(/\s/g, '')
            // if (name == "" || surname == ""
            //     || addressPrimary == "" || email == ""
            //     || password == "" || password.length < 6 || confirmPassword !== password
            //     || termsAndConditions == false || contactNumber == "") {
            //     throw new Error('Please complete all fields, passwords should match and have a minimum of 6 characters.');
            // }

            // Contact details errors
            if (name == "" || surname == "" || email == "" || contactNumber == "" ) {
                throw new Error('Please ensure your contact details are correct.');
            }
            // contact format error
            if (trimmedContactNumber?.length != 10)
            {
                throw new Error('Your contact number is incorrect, it should be 10 digits, eg 0762338289.');
            }

            // Address errors
            if (addressPrimary == "") {
                throw new Error('Please enter a valid home address.');
            }

            // Password errors
            if (password == "") {
                throw new Error('Please enter a valid password.');
            }
            if (password.length < 6) {
                throw new Error('Your password must be at least 6 characters long.');
            }
            if (confirmPassword == "") {
                throw new Error('Please confirm your password.');
            }
            if (confirmPassword !== password) {
                throw new Error('Passwords do not match.');
            }

            // T's & C's error
            if (termsAndConditions == false) {
                throw new Error('Pleae read and accept our Terms & Conditions.');
            }

            
            setIsLoading(true)
            await axios('/otp_signup/send_otp', {
                method: 'POST',
                data: { email: trimmedLowerEmail, contact_number: trimmedContactNumber},
                headers: { Accept: 'application/json' },
            });

            if (context == "resend")
            {
                setShowResent(true);
                setTimeout(()=>{
                    setShowResent(false);
                    setIsLoading(false);
                },3000)
            }
            else{
                setIsLoading(false)
            }

            setSignupState((prevState) => ({
                ...prevState,
                pageNumber: 1
            }));

        } catch (e) {
            setIsLoading(false)

            setSignupState((prevState) => ({
                ...prevState,
                showError: true,
                errorMessage: e.response?.data?.message ? e.response?.data?.message : e.message
            }));
            setTimeout(() => {
                setSignupState((prevState) => ({
                    ...prevState,
                    showError: false,
                    errorMessage: ""
                }));
            }, 5000)
        }

    }

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const formated_address = autocomplete.getPlace()?.formatted_address;
            const address = (formated_address == null) ? "" : formated_address;
            onValueChanged("addressPrimary", address)
        }
        else {
            console.log('Autocomplete is not loaded yet!')
        }
    }

    return (
        <div className='otpsignup bg-purple-500 h-full md:h-screen flex items-center'>
            <div className='otpsignup__main flex flex-col p-5 w-5/6 md:w-1/2 mx-auto my-6 bg-white rounded-lg shadow'>
                <div className='flex flex-wrap -mb-6'>
                    <div className='w-1/2 grid justify-items-end'>
                        <div className={`otpsignup__breadcrumb rounded-xl shadow mr-2 ${signupState.pageNumber == 0 ? 'active' : ''}`}>
                            1
                        </div>
                    </div>
                    <div className='w-1/2 grid justify-items-start'>
                        <div className={`otpsignup__breadcrumb rounded-xl shadow ml-2 ${signupState.pageNumber == 1 ? 'active' : ''}`}>
                            2
                        </div>
                    </div>
                </div>
                <h1 className='otpsignup__header mb-2'>Sign Up</h1>
                {
                    (signupState.pageNumber == 0) &&
                    <div>
                         <div className='otpsignup__formheader w-full mt-2 mb-2'>
                            <h1>Contact Details</h1>
                            <p>Your email address will be used to validate your account</p>
                        </div>
                            <div className='grid md:grid-cols-2 md:gap-4'>
                                <div className='w-full'>
                                    <label className="label">Email Address</label>
                                    <input
                                        className="input"
                                        type="text"
                                        name=""
                                        id="signup_email_address"
                                        placeholder='email@example.com'
                                        onChange={(e) => onValueChanged("email", e.target.value)}
                                        value={signupState.email}
                                    />
                                </div>
                                <div className="w-full">
                                    <label className="label">Contact Number</label>
                                    <div className='flex'>
                                        <div className="flex w-1/4">
                                            <select className="countryCodeSelect flex-initial w-1/4 select input">
                                                <option className="option" value="27">ZA 27</option>
                                                <option className="option" value="880">BD 880</option>
                                                <option className="option" value="32">BE 32</option>
                                                <option className="option" value="226">BF 226</option>
                                                <option className="option" value="359">BG 359</option>
                                                <option className="option" value="387">BA 387</option>
                                                <option className="option" value="+1-246">BB +1-246</option>
                                                <option className="option" value="681">WF 681</option>
                                                <option className="option" value="590">BL 590</option>
                                                <option className="option" value="+1-441">BM +1-441</option>
                                                <option className="option" value="673">BN 673</option>
                                                <option className="option" value="591">BO 591</option>
                                                <option className="option" value="973">BH 973</option>
                                                <option className="option" value="257">BI 257</option>
                                                <option className="option" value="229">BJ 229</option>
                                                <option className="option" value="975">BT 975</option>
                                                <option className="option" value="+1-876">JM +1-876</option>
                                                <option className="option" value="BV">BV BV</option>
                                                <option className="option" value="267">BW 267</option>
                                                <option className="option" value="685">WS 685</option>
                                                <option className="option" value="599">BQ 599</option>
                                                <option className="option" value="55">BR 55</option>
                                                <option className="option" value="+1-242">BS +1-242</option>
                                                <option className="option" value="+44-1534">JE +44-1534</option>
                                                <option className="option" value="375">BY 375</option>
                                                <option className="option" value="501">BZ 501</option>
                                                <option className="option" value="7">RU 7</option>
                                                <option className="option" value="250">RW 250</option>
                                                <option className="option" value="381">RS 381</option>
                                                <option className="option" value="670">TL 670</option>
                                                <option className="option" value="262">RE 262</option>
                                                <option className="option" value="993">TM 993</option>
                                                <option className="option" value="992">TJ 992</option>
                                                <option className="option" value="40">RO 40</option>
                                                <option className="option" value="690">TK 690</option>
                                                <option className="option" value="245">GW 245</option>
                                                <option className="option" value="+1-671">GU +1-671</option>
                                                <option className="option" value="502">GT 502</option>
                                                <option className="option" value="GS">GS GS</option>
                                                <option className="option" value="30">GR 30</option>
                                                <option className="option" value="240">GQ 240</option>
                                                <option className="option" value="590">GP 590</option>
                                                <option className="option" value="81">JP 81</option>
                                                <option className="option" value="592">GY 592</option>
                                                <option className="option" value="+44-1481">GG +44-1481</option>
                                                <option className="option" value="594">GF 594</option>
                                                <option className="option" value="995">GE 995</option>
                                                <option className="option" value="+1-473">GD +1-473</option>
                                                <option className="option" value="44">GB 44</option>
                                                <option className="option" value="241">GA 241</option>
                                                <option className="option" value="503">SV 503</option>
                                                <option className="option" value="224">GN 224</option>
                                                <option className="option" value="220">GM 220</option>
                                                <option className="option" value="299">GL 299</option>
                                                <option className="option" value="350">GI 350</option>
                                                <option className="option" value="233">GH 233</option>
                                                <option className="option" value="968">OM 968</option>
                                                <option className="option" value="216">TN 216</option>
                                                <option className="option" value="962">JO 962</option>
                                                <option className="option" value="385">HR 385</option>
                                                <option className="option" value="509">HT 509</option>
                                                <option className="option" value="36">HU 36</option>
                                                <option className="option" value="852">HK 852</option>
                                                <option className="option" value="504">HN 504</option>
                                                <option className="option" value="HM">HM HM</option>
                                                <option className="option" value="58">VE 58</option>
                                                <option className="option" value="1-939">PR 1-939</option>
                                                <option className="option" value="970">PS 970</option>
                                                <option className="option" value="680">PW 680</option>
                                                <option className="option" value="351">PT 351</option>
                                                <option className="option" value="47">SJ 47</option>
                                                <option className="option" value="595">PY 595</option>
                                                <option className="option" value="964">IQ 964</option>
                                                <option className="option" value="507">PA 507</option>
                                                <option className="option" value="689">PF 689</option>
                                                <option className="option" value="675">PG 675</option>
                                                <option className="option" value="51">PE 51</option>
                                                <option className="option" value="92">PK 92</option>
                                                <option className="option" value="63">PH 63</option>
                                                <option className="option" value="870">PN 870</option>
                                                <option className="option" value="48">PL 48</option>
                                                <option className="option" value="508">PM 508</option>
                                                <option className="option" value="260">ZM 260</option>
                                                <option className="option" value="212">EH 212</option>
                                                <option className="option" value="372">EE 372</option>
                                                <option className="option" value="20">EG 20</option>
                                                <option className="option" value="593">EC 593</option>
                                                <option className="option" value="39">IT 39</option>
                                                <option className="option" value="84">VN 84</option>
                                                <option className="option" value="677">SB 677</option>
                                                <option className="option" value="251">ET 251</option>
                                                <option className="option" value="252">SO 252</option>
                                                <option className="option" value="263">ZW 263</option>
                                                <option className="option" value="966">SA 966</option>
                                                <option className="option" value="34">ES 34</option>
                                                <option className="option" value="291">ER 291</option>
                                                <option className="option" value="382">ME 382</option>
                                                <option className="option" value="373">MD 373</option>
                                                <option className="option" value="261">MG 261</option>
                                                <option className="option" value="590">MF 590</option>
                                                <option className="option" value="212">MA 212</option>
                                                <option className="option" value="377">MC 377</option>
                                                <option className="option" value="998">UZ 998</option>
                                                <option className="option" value="95">MM 95</option>
                                                <option className="option" value="223">ML 223</option>
                                                <option className="option" value="853">MO 853</option>
                                                <option className="option" value="976">MN 976</option>
                                                <option className="option" value="692">MH 692</option>
                                                <option className="option" value="389">MK 389</option>
                                                <option className="option" value="230">MU 230</option>
                                                <option className="option" value="356">MT 356</option>
                                                <option className="option" value="265">MW 265</option>
                                                <option className="option" value="960">MV 960</option>
                                                <option className="option" value="596">MQ 596</option>
                                                <option className="option" value="+1-670">MP +1-670</option>
                                                <option className="option" value="+1-664">MS +1-664</option>
                                                <option className="option" value="222">MR 222</option>
                                                <option className="option" value="+44-1624">IM +44-1624</option>
                                                <option className="option" value="256">UG 256</option>
                                                <option className="option" value="255">TZ 255</option>
                                                <option className="option" value="60">MY 60</option>
                                                <option className="option" value="52">MX 52</option>
                                                <option className="option" value="972">IL 972</option>
                                                <option className="option" value="33">FR 33</option>
                                                <option className="option" value="246">IO 246</option>
                                                <option className="option" value="290">SH 290</option>
                                                <option className="option" value="358">FI 358</option>
                                                <option className="option" value="679">FJ 679</option>
                                                <option className="option" value="500">FK 500</option>
                                                <option className="option" value="691">FM 691</option>
                                                <option className="option" value="298">FO 298</option>
                                                <option className="option" value="505">NI 505</option>
                                                <option className="option" value="31">NL 31</option>
                                                <option className="option" value="47">NO 47</option>
                                                <option className="option" value="264">NA 264</option>
                                                <option className="option" value="678">VU 678</option>
                                                <option className="option" value="687">NC 687</option>
                                                <option className="option" value="227">NE 227</option>
                                                <option className="option" value="672">NF 672</option>
                                                <option className="option" value="234">NG 234</option>
                                                <option className="option" value="64">NZ 64</option>
                                                <option className="option" value="977">NP 977</option>
                                                <option className="option" value="674">NR 674</option>
                                                <option className="option" value="683">NU 683</option>
                                                <option className="option" value="682">CK 682</option>
                                                <option className="option" value="XK">XK XK</option>
                                                <option className="option" value="225">CI 225</option>
                                                <option className="option" value="41">CH 41</option>
                                                <option className="option" value="57">CO 57</option>
                                                <option className="option" value="86">CN 86</option>
                                                <option className="option" value="237">CM 237</option>
                                                <option className="option" value="56">CL 56</option>
                                                <option className="option" value="61">CC 61</option>
                                                <option className="option" value="1">CA 1</option>
                                                <option className="option" value="242">CG 242</option>
                                                <option className="option" value="236">CF 236</option>
                                                <option className="option" value="243">CD 243</option>
                                                <option className="option" value="420">CZ 420</option>
                                                <option className="option" value="357">CY 357</option>
                                                <option className="option" value="61">CX 61</option>
                                                <option className="option" value="506">CR 506</option>
                                                <option className="option" value="599">CW 599</option>
                                                <option className="option" value="238">CV 238</option>
                                                <option className="option" value="53">CU 53</option>
                                                <option className="option" value="268">SZ 268</option>
                                                <option className="option" value="963">SY 963</option>
                                                <option className="option" value="599">SX 599</option>
                                                <option className="option" value="996">KG 996</option>
                                                <option className="option" value="254">KE 254</option>
                                                <option className="option" value="211">SS 211</option>
                                                <option className="option" value="597">SR 597</option>
                                                <option className="option" value="686">KI 686</option>
                                                <option className="option" value="855">KH 855</option>
                                                <option className="option" value="+1-869">KN +1-869</option>
                                                <option className="option" value="269">KM 269</option>
                                                <option className="option" value="239">ST 239</option>
                                                <option className="option" value="421">SK 421</option>
                                                <option className="option" value="82">KR 82</option>
                                                <option className="option" value="386">SI 386</option>
                                                <option className="option" value="850">KP 850</option>
                                                <option className="option" value="965">KW 965</option>
                                                <option className="option" value="221">SN 221</option>
                                                <option className="option" value="378">SM 378</option>
                                                <option className="option" value="232">SL 232</option>
                                                <option className="option" value="248">SC 248</option>
                                                <option className="option" value="7">KZ 7</option>
                                                <option className="option" value="+1-345">KY +1-345</option>
                                                <option className="option" value="65">SG 65</option>
                                                <option className="option" value="46">SE 46</option>
                                                <option className="option" value="249">SD 249</option>
                                                <option className="option" value="1-829">DO 1-829</option>
                                                <option className="option" value="+1-767">DM +1-767</option>
                                                <option className="option" value="253">DJ 253</option>
                                                <option className="option" value="45">DK 45</option>
                                                <option className="option" value="+1-284">VG +1-284</option>
                                                <option className="option" value="49">DE 49</option>
                                                <option className="option" value="967">YE 967</option>
                                                <option className="option" value="213">DZ 213</option>
                                                <option className="option" value="1">US 1</option>
                                                <option className="option" value="598">UY 598</option>
                                                <option className="option" value="262">YT 262</option>
                                                <option className="option" value="1">UM 1</option>
                                                <option className="option" value="961">LB 961</option>
                                                <option className="option" value="+1-758">LC +1-758</option>
                                                <option className="option" value="856">LA 856</option>
                                                <option className="option" value="688">TV 688</option>
                                                <option className="option" value="886">TW 886</option>
                                                <option className="option" value="+1-868">TT +1-868</option>
                                                <option className="option" value="90">TR 90</option>
                                                <option className="option" value="94">LK 94</option>
                                                <option className="option" value="423">LI 423</option>
                                                <option className="option" value="371">LV 371</option>
                                                <option className="option" value="676">TO 676</option>
                                                <option className="option" value="370">LT 370</option>
                                                <option className="option" value="352">LU 352</option>
                                                <option className="option" value="231">LR 231</option>
                                                <option className="option" value="266">LS 266</option>
                                                <option className="option" value="66">TH 66</option>
                                                <option className="option" value="TF">TF TF</option>
                                                <option className="option" value="228">TG 228</option>
                                                <option className="option" value="235">TD 235</option>
                                                <option className="option" value="+1-649">TC +1-649</option>
                                                <option className="option" value="218">LY 218</option>
                                                <option className="option" value="379">VA 379</option>
                                                <option className="option" value="+1-784">VC +1-784</option>
                                                <option className="option" value="971">AE 971</option>
                                                <option className="option" value="376">AD 376</option>
                                                <option className="option" value="+1-268">AG +1-268</option>
                                                <option className="option" value="93">AF 93</option>
                                                <option className="option" value="+1-264">AI +1-264</option>
                                                <option className="option" value="+1-340">VI +1-340</option>
                                                <option className="option" value="354">IS 354</option>
                                                <option className="option" value="98">IR 98</option>
                                                <option className="option" value="374">AM 374</option>
                                                <option className="option" value="355">AL 355</option>
                                                <option className="option" value="244">AO 244</option>
                                                <option className="option" value="AQ">AQ AQ</option>
                                                <option className="option" value="+1-684">AS +1-684</option>
                                                <option className="option" value="54">AR 54</option>
                                                <option className="option" value="61">AU 61</option>
                                                <option className="option" value="43">AT 43</option>
                                                <option className="option" value="297">AW 297</option>
                                                <option className="option" value="91">IN 91</option>
                                                <option className="option" value="+358-18">AX +358-18</option>
                                                <option className="option" value="994">AZ 994</option>
                                                <option className="option" value="353">IE 353</option>
                                                <option className="option" value="62">ID 62</option>
                                                <option className="option" value="380">UA 380</option>
                                                <option className="option" value="974">QA 974</option>
                                                <option className="option" value="258">MZ 258</option>
                                            </select>
                                        </div>
                                        <div className="flex w-3/4">
                                            <input
                                                placeholder="0831234567"
                                                className="input w-3/4 phoneNumberInput"
                                                type="text"
                                                name=""
                                                id="signup_contact_number"
                                                onChange={(e) => onValueChanged("contactNumber", e.target.value)}
                                                value={signupState.contactNumber}
                                                maxLength={10}
                                                onKeyPress={(e) => {
                                                    const pattern = /[0-9]/;
                                                    const inputChar = String.fromCharCode(e.charCode);
                                                    if (!pattern.test(inputChar)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='grid md:grid-cols-2 md:gap-4'>
                                <div className="w-full">
                                    <label className="label">Name</label>
                                    <input
                                        className="input"
                                        type="text"
                                        name=""
                                        id="signup_name"
                                        placeholder='John'
                                        onChange={(e) => onValueChanged("name", e.target.value)}
                                        value={signupState.name}
                                    />
                                </div>
                                <div className="w-full">
                                    <label className="label">Surname</label>
                                    <input
                                        className="input"
                                        type="text"
                                        name=""
                                        id="signup_surname"
                                        placeholder='Smith'
                                        onChange={(e) => onValueChanged("surname", e.target.value)}
                                        value={signupState.surname}
                                    />
                                </div>
                            </div>
                            <div className='otpsignup__formheader w-full mt-2 mb-2'>
                                <h1>Address</h1>
                                <p>Fill in your home address</p>
                            </div>
                            <div className="w-full">
                                <label className="label">Home Address</label>
                                <Autocomplete
                                    onLoad={(autocomplete) => {
                                        setAutocomplete(autocomplete);
                                    }}
                                    restrictions={{
                                        country: ['za', 'eg']
                                    }}
                                    onPlaceChanged={() => onPlaceChanged()}
                                >
                                    <input
                                        className="input"
                                        type="text"
                                        name=""
                                        id="signup_primary_address"
                                        placeholder="Enter address"
                                        defaultValue={signupState.addressPrimary}
                                    />
                                </Autocomplete>

                            </div>
                            <div className='otpsignup__formheader w-full mt-2 mb-2'>
                                <h1>Password</h1>
                                <p>Choose the password for your account</p>
                            </div>
                            <div className='grid md:grid-cols-2 md:gap-4'>
                                <div className="w-full">
                                    <label className="label">Password</label>
                                    <div className='flex flex-row'>
                                        <input
                                            className="input otpsignup__password"
                                            type={signupState.passwordShow ? "text" : "password"}
                                            name=""
                                            id="signup_password"
                                            onChange={(e) => onValueChanged("password", e.target.value)}
                                            value={signupState.password}
                                        />
                                        <button
                                            className='otpsignup__showhidePassword bg-purple-500 px-2'
                                            onClick={() => onValueChanged("passwordShow", !signupState.passwordShow)}
                                        >{signupState.passwordShow ? <i className='fas fa-eye-slash'></i> : <i className='fas fa-eye'></i>}</button>
                                    </div>

                                </div>
                                <div className="w-full">
                                    <label className="label">Confirm Password</label>
                                    <div className='flex flex-row'>
                                        <input
                                            className="input otpsignup__password"
                                            type={signupState.confirmPasswordShow ? "text" : "password"}
                                            name=""
                                            id="signup_password_confirm"
                                            onChange={(e) => onValueChanged("confirmPassword", e.target.value)}
                                            value={signupState.confirmPassword}
                                        />
                                        <button
                                            className='otpsignup__showhidePassword bg-purple-500 px-2'
                                            onClick={() => onValueChanged("confirmPasswordShow", !signupState.confirmPasswordShow)}
                                        > {signupState.confirmPasswordShow ? <i className='fas fa-eye-slash'></i> : <i className='fas fa-eye'></i>}</button>
                                    </div>
                                </div>
                            </div>
                            <div className='flex w-full mt-2 mb-2'>
                                <input
                                    type="checkbox"
                                    value={signupState.termsAndConditions}
                                    checked={signupState.termsAndConditions}
                                    name="user[terms_and_conditions]"
                                    id="user_terms_and_conditions"
                                    onChange={(e) => onValueChanged("termsAndConditions", !signupState.termsAndConditions)}
                                />
                                <p className='inline-block text-xs text-gray-700 ml-2 '>I understand and accept the <a target="_blank" className="text-purple-500 underline" href="/terms_and_conditions/index_customer">Terms &amp; Conditions</a>
                                </p>
                            </div>
                            <div className='otpsignup__formactions w-full mt-2 flex flex-wrap'>
                                <div className='w-full md:w-4/5 grid justify-items-start'>
                                    {signupState.showError && <h3 style={{ color: 'red', textAlign: 'center' }} className="otpsignup__errormessage"><i className="fas fa-exclamation-circle"></i> {signupState.errorMessage}</h3>}
                                </div>
                                <div className='w-full md:w-1/5 grid justify-items-end'>
                                    <button
                                        className="button"
                                        onClick={() => onValidate("validate")}
                                    >
                                        {
                                            !isLoading &&
                                            <div>Validate</div>
                                        }
                                        {
                                            isLoading &&
                                            <div className='flex flex-row items-center justify-center'>
                                                <div className='mr-2'>Validating</div>
                                                <i className="fa fa-spinner fa-spin"></i>
                                            </div>
                                        }
                                    </button>
                                </div>
                            </div>
                    </div>
                }
                {
                    (signupState.pageNumber == 1) &&
                    <div>
                        <div className='flex flex-wrap'>
                            <div className='otpsignup__formheader w-full mt-2 mb-2'>
                                <h1>OTP Confirmation</h1>
                                <p className='mt-1'>An OTP has been sent to your email address. Enter it below in order to validate your account. (Please check your spam folder in case the email isn’t in your inbox.)</p>
                            </div>
                            <div className='grid w-full justify-items-center'>
                                <div className="w-full md:w-1/2 lg:w-1/3">
                                    <p className="label">OTP</p>
                                    <OtpInput
                                        containerStyle={{
                                            // width: 100 + "%",
                                            // height: 100,
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}

                                        inputStyle={{
                                            marginTop: '8px',
                                            height: 55,
                                            width: 25 + '%',
                                            borderWidth: 0,
                                            backgroundColor: '#F5F4F9',
                                            borderRadius: 5
                                        }}
                                        value={signupState.token}
                                        onChange={(value)=>onValueChanged("token",value)}
                                        numInputs={4}
                                        renderSeparator={<span className="p-2"></span>}
                                        renderInput={(props) => <input {...props} />}
                                    />
                                    <div className='otpsignup__login'>
                                        <div className='grid justify-items-center'>
                                            {
                                                !isLoading &&
                                                <p className='otpsignup__resend'>Resend <a onClick={() => onValidate("resend")} className='cursor-pointer underline'>OTP</a></p>
                                            }
                                            {
                                                (isLoading && !showResent) &&
                                                <div className='flex flex-row item-center justify-center'>
                                                    <div className='mr-2'>Resending OTP</div>
                                                    <i className="fa fa-spinner fa-spin text-gray-500"></i>
                                                </div>
                                            }
                                            {
                                                (isLoading && showResent) &&
                                                <div className='flex flex-row item-center justify-center'>
                                                    <div className='mr-2'>OTP sent to your email</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {signupState.showError && <h3 style={{ color: 'red', textAlign: 'center' }} className="otpsignup__errormessage"><i className="fas fa-exclamation-circle"></i> {signupState.errorMessage}</h3>}
                                </div>
                            </div>
                        </div>
                        <div className='otpsignup__formactions w-full mt-2 flex flex-wrap'>
                            <div className='w-1/2 grid justify-items-start'>
                                <button
                                    type="submit"
                                    className="button otpsignup__back"
                                    onClick={() => onValueChanged("pageNumber", 0)}
                                >Back</button>
                            </div>
                            <div className='w-1/2 grid justify-items-end'>
                                <button
                                    className="button"
                                    onClick={() => onSignup()}
                                >
                                    {
                                        !signupIsLoading &&
                                        <div>Confirm</div>
                                    }
                                    {
                                        signupIsLoading &&
                                        <div className='flex flex-row items-center justify-center'>
                                            <div className='mr-2'>Confirming</div>
                                            <i className="fa fa-spinner fa-spin"></i>
                                        </div>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                }
                <div className='otpsignup__login'>
                    <div className='grid justify-items-center'>
                        <p>Already have an account? <a href="/users/sign_in" className='cursor-pointer underline'>Log in</a></p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default OtpSignup