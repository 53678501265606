import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import companyClosingTime from "./utils/companyClosingTime";

const DaySelection = ({
  selectedWeek,
  selectedDay,
  setDay,
  pageType,
  dailyChangesMade,
  setShowWarning,
  setDayToEdit,
  closingTime,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [startDate, endDate] = selectedWeek
    ? selectedWeek.value?.split("|")
    : moment(Date.now);

  const start = moment().startOf("day"); // Start from the current day
  const end = moment(endDate, "DD-MM-YYYY");
  const currentTimeslot = moment().format("HH:mm");
  const hasCompanyClosed = companyClosingTime(currentTimeslot, closingTime.split(" ").join(""));

  const [currentSelectedDay, setCurrentSelectedDay] = useState(selectedDay);

  const dates = [];
  let currentDate = start;
  while (currentDate.isSameOrBefore(end, "date")) {
    dates.push({
      day: currentDate.format("ddd"),
      date: currentDate.format("DD"),
      month: currentDate.format("MMM"),
      value: currentDate.format("DD-MM-YYYY"),
    });
    currentDate.add(1, "day");
  }

  // Filter dates to exclude today if company has closed
  const filteredDates = dates.filter(dateInfo => {
    const isToday = dateInfo.value === moment().format("DD-MM-YYYY");
    return !hasCompanyClosed || !isToday;
  });

  // Update selected day if the company has closed and the current selected day is today
  useEffect(() => {
    const today = moment().format("DD-MM-YYYY");
    if (hasCompanyClosed && currentSelectedDay === today) {
      const nextAvailableDay = filteredDates[0]?.value;
      setCurrentSelectedDay(nextAvailableDay);
      setDay(nextAvailableDay);
    }
  }, [hasCompanyClosed, filteredDates, currentSelectedDay, setDay]);

  useEffect(() => {
    setCurrentSelectedDay(selectedDay);
  }, [selectedDay]);

  let height;
  if (filteredDates.length > 1) {
    height = "200px";
  } else {
    height = "calc(100% / 3)";
  }

  const handleDateClick = (dateInfo) => {
    if (
      dailyChangesMade &&
      dateInfo.value !== selectedDay &&
      pageType === "bookings"
    ) {
      setDayToEdit(dateInfo.value);
      setShowWarning(true);
    } else {
      setDay(dateInfo.value);
    }
    setCurrentSelectedDay(dateInfo.value);
    closeDropdown();
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const dropdownRef = useRef(null);

  const handleClickAway = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickAway);
    return () => {
      document.removeEventListener("click", handleClickAway);
    };
  }, []);

  return (
    <div className="daySelect w-full relative" ref={dropdownRef}>
      <div
        className="flex space-x-1 justify-evenly align-center"
        style={{ fontSize: "14px" }}
      >
        <div
          className="w-full flex justify-between items-center rounded-md text-gray-600 bg-white px-2 py-1"
          onClick={toggleDropdown}
        >
          {currentSelectedDay}{" "}
          <FontAwesomeIcon icon={faCalendarAlt} className="text-gray-600" />
        </div>
        {isDropdownOpen && (
          <div style={{ height, overflowY: "scroll" }} className="absolute top-full dark-gray-scrollbar left-0 w-full border rounded-md bg-gray-100 z-40 max-h-40 overflow-y-auto mt-8">
            {filteredDates.map((dateInfo, index) => (
              <div
                key={index}
                className={`flex p-2 ${dateInfo.value === currentSelectedDay
                  ? "bg-gray-200"
                  : "hover:bg-gray-200"
                  }`}
                onClick={() => handleDateClick(dateInfo)}
              >
                <div
                  className={`${dateInfo.value === currentSelectedDay
                    ? "text-purple-600"
                    : ""
                    }`}
                >
                  {dateInfo.day}{" "}
                </div>
                <div
                  className={`${dateInfo.value === currentSelectedDay
                    ? "text-purple-500"
                    : ""
                    }`}
                >
                  {`${dateInfo.date} ${dateInfo.month}`}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DaySelection;
