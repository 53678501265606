import React, { useState, useEffect } from 'react';
import axios from 'axios';

const NotificationsSettings = ({user_id}) => {
  // just initialt state to show something
  const [settings, setSettings] = useState({
    bulk_booking_alert: false,
    create_trip_alert: false,
    // ... add other settings here
  });


  const fetchSettings = async () => {
    try {
      const response = await axios.get(`/settings/get_settings?user_id=${user_id}`);
      setSettings(response.data.settings);
    } catch (error) {
      console.error('Error fetching settings:', error);
    }
  };

  useEffect(() => {


    fetchSettings();
  }, []);

  const filterAndTransform = (data) => {
    return Object.entries(data)
      .filter(([key, value]) => key.endsWith('_alert') && typeof value === 'boolean')
      .map(([key, value]) => ({
        name: `${key.split('_').join(' ')}`,
        key,
      }));
  };

  const handleCheckboxChange = async (settingName) => {
    try {
      const response = await axios.put(
        `/settings/update_setting_by_id?user_id=${user_id}&setting_id=${settingName}`
      );

      setSettings((prevSettings) => ({
        ...prevSettings,
        [settingName]: response.data.message === 'Setting updated successfully',
      }));

      fetchSettings();
    } catch (error) {
      console.error('Error updating setting:', error);
    }
  };

  useEffect(() => {
    const updateOtherSettings = async () => {
      try {
        const response = await axios.get(`/settings/get_settings?user_id=${user_id}`);
        setSettings(response.data.settings);
      } catch (error) {
        console.error('Error updating other settings:', error);
      }
    };

    updateOtherSettings();
  }, []);

  return (
    <div className="max-w-full ">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-7 gap-4">
        {filterAndTransform(settings).map((setting) => (
          <div key={setting.key} className="flex items-start">
            <input
              type="checkbox"
              className="mr-2"
              checked={settings[setting.key]}
              onChange={() => handleCheckboxChange(setting.key)}
            />
            <label className="text-sm">{setting.name}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotificationsSettings;
