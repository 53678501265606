/**
 * @module Select Component
 * @description
 * A styled wrapper around a native select component. Makes use of `Dropdown` Component for
 *  handling the dropdown, and an `Input` component for the selected item. This can be styled
 *  as 'minimal' by passing it the prop.
 */
import React, {Component} from 'react';
import {arrayOf, bool, func, number, oneOfType, shape, string,} from 'prop-types';
import './select.scss';

import Dropdown from '../dropdown/Dropdown';
import DropdownMenu from '../dropdown/DropdownMenu';
import Input from '../input/Input';
import {getClassNames, isNull, isUndefined, noOp} from '../../tools/helpers';

class Select extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            selectedValue: props.value || null,
        };
    }

    resetSelected() {
        const {menuItems} = this.props;
        return menuItems.forEach((item) => item.selected = false);
    }

    /** Finds the label of the associated `selectedValue`. */
    getSelectedLabel() {
        const {placeholder = 'Choose something'} = this.props;
        const {selectedValue} = this.state;

        if (isNull(selectedValue)) {
            return placeholder;
        }

        this.resetSelected();
        const selectedOption = this.findSelectedOption(selectedValue);
        if (isUndefined(selectedOption)) {
            console.error(`Select (${this.props.name}): There was a problem getting the label for selected option ${selectedValue} (${typeof selectedValue})`);
            return placeholder;
        }

        selectedOption.selected = true;
        return selectedOption.label;
    }

    /** Formats all menu items for use by the dropdown. */
    getMenuItems() {
        const {menuItems} = this.props;
        return menuItems.map((item) => this.formatMenuItem(item));
    }

    /**
     * Given a menu item with just `label` and `value`, attach click handlers for selecting an item,
     *  and whether or not the item is selected.
     * @param {Object} item - item containing a `label` and `value`
     */
    formatMenuItem(item) {
        const {value} = item;
        const {selectedValue} = this.state;

        return {
            ...item,
            onClick: () => this.handleChangeOption(value),
            selected: value === selectedValue,
        };
    }

    /** Find an option based on `selectedValue`. */
    findSelectedOption(selectedValue) {
        const {menuItems} = this.props;
        return menuItems.find(({value}) => value === selectedValue);
    }

    /**
     * Update the select value, then call `onChange` handler.
     * @param {string} value - value of the associated option, to set as the `selectedValue`
     */
    handleChangeOption(value) {
        const {onChange} = this.props;

        this.setState({
                selectedValue: value,
                open: false,
            },
            () => onChange(value));
    }

    renderDropdownMenu() {
        const {minimal} = this.props;
        return (
            <DropdownMenu
                select
                minimal={minimal}
                menuItems={this.getMenuItems()}
            />
        );
    }

    render() {
        const {minimal} = this.props;
        const {open, selectedValue} = this.state;

        const buildContainerClasses = () => getClassNames('select', {
            open,
            'has-value': !isNull(selectedValue),
            minimal
        });

        return (
            <div className={buildContainerClasses()}>
                <Dropdown
                    placement='bottom'
                    menu={this.renderDropdownMenu()}
                    open={open}
                    onOpenPopper={(isOpen) => this.setState({open: isOpen})}
                    expandPopper
                >
                    <Input minimal={minimal}>
                        <div className="select__selected">
                            <p className="select__label">{this.getSelectedLabel()}</p>
                            {/*<IconChevDown className="select__indicator"/>*/}
                        </div>
                    </Input>
                </Dropdown>
            </div>
        );
    }
}

// TODO: use native select for forms; accept default selected prop;

Select.defaultProps = {
    menuItems: [],
    onChange: noOp,
    placeholder: 'Choose something',
    minimal: false,
};

Select.propTypes = {
    menuItems: arrayOf(shape({
        label: string.isRequired,
        value: oneOfType([string, number]).isRequired,
    })),
    placeholder: string,
    minimal: bool,
    onChange: func,
};

export default Select;
