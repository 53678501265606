import React, { useState, useEffect } from "react";
import Axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faTimes, faSave, faEye, faPlusCircle } from '@fortawesome/free-solid-svg-icons'

const PaymentsRow = React.forwardRef(({
    driver,
    week,
    paymentsState,
    setPaymentsState,
    position,
    incentiveState,
    setIncentiveState,
    formatter,
    current_user
},ref)=> {
    const handleOnChange = (e, type) =>{

        let _incentiveState = JSON.parse(JSON.stringify(incentiveState));
        _incentiveState[driver.id][type] = e.target.value
        setIncentiveState(_incentiveState);

    }

    const handleOnSubmit = () =>{

        Axios({
            method: 'post',
            url: `/incentives/create`,
            headers: { Accept: 'application/json' },
            data:{
                user_id: driver.id,
                start_date_filter: week.from,
                end_date_filter: week.to,
                performance: incentiveState[driver.id].performance,
                bonus_deduction: incentiveState[driver.id].bonus_deduction,
                fuel: incentiveState[driver.id].fuel,
                km_overuse: incentiveState[driver.id].km_overuse,
                uber: incentiveState[driver.id].uber,
                other: incentiveState[driver.id].other,
            }
        }).then((response)=>{
            const { data } = response;
            const incentive = data.incentive

            // adjust payments total fee, total balance, incentives
            let _paymentsStateClone = JSON.parse(JSON.stringify(paymentsState));

            if (_paymentsStateClone.data[position].incentive.bonus_deduction != incentive.bonus_deduction)
            {
                let bonusDiff = incentive.bonus_deduction - _paymentsStateClone.data[position].incentive.bonus_deduction

                _paymentsStateClone.totalBalance += bonusDiff;
                _paymentsStateClone.totalIncentives.bonus_deduction += bonusDiff;
                _paymentsStateClone.totalIncentives.total += bonusDiff;
                _paymentsStateClone.data[position].balance += bonusDiff;
                _paymentsStateClone.data[position].incentive.total += bonusDiff;
                _paymentsStateClone.data[position].incentive.bonus_deduction = incentive.bonus_deduction;

            }

            if (_paymentsStateClone.data[position].incentive.fuel != incentive.fuel)
            {
                let fuelDiff = incentive.fuel - _paymentsStateClone.data[position].incentive.fuel

                _paymentsStateClone.totalBalance += fuelDiff;
                _paymentsStateClone.totalIncentives.fuel += fuelDiff;
                _paymentsStateClone.totalIncentives.total += fuelDiff;
                _paymentsStateClone.data[position].balance += fuelDiff;
                _paymentsStateClone.data[position].incentive.total += fuelDiff;
                _paymentsStateClone.data[position].incentive.fuel = incentive.fuel;
            }

            if (_paymentsStateClone.data[position].incentive.performance != incentive.performance)
            {
                let performanceDiff = incentive.performance - _paymentsStateClone.data[position].incentive.performance
                
                _paymentsStateClone.totalBalance += performanceDiff;
                _paymentsStateClone.totalIncentives.performance += performanceDiff;
                _paymentsStateClone.totalIncentives.total += performanceDiff;
                _paymentsStateClone.data[position].balance += performanceDiff;
                _paymentsStateClone.data[position].incentive.total += performanceDiff;
                _paymentsStateClone.data[position].incentive.performance = incentive.performance
            }

            if (_paymentsStateClone.data[position].incentive.km_overuse != incentive.km_overuse)
            {
                let kmOverDiff = incentive.km_overuse - _paymentsStateClone.data[position].incentive.km_overuse;

                _paymentsStateClone.totalBalance += kmOverDiff;
                _paymentsStateClone.totalIncentives.km_overuse += kmOverDiff;
                _paymentsStateClone.totalIncentives.total += kmOverDiff;
                _paymentsStateClone.data[position].balance += kmOverDiff;
                _paymentsStateClone.data[position].incentive.total += kmOverDiff;
                _paymentsStateClone.data[position].incentive.km_overuse = incentive.km_overuse
            }

            if (_paymentsStateClone.data[position].incentive.uber != incentive.uber)
            {
                let uberDiff = incentive.uber - _paymentsStateClone.data[position].incentive.uber

                _paymentsStateClone.totalBalance += uberDiff;
                _paymentsStateClone.totalIncentives.uber += uberDiff;
                _paymentsStateClone.totalIncentives.total += uberDiff;
                _paymentsStateClone.data[position].balance += uberDiff;
                _paymentsStateClone.data[position].incentive.total += uberDiff;
                _paymentsStateClone.data[position].incentive.uber = incentive.uber
            }

            if (_paymentsStateClone.data[position].incentive.other != incentive.other)
            {
                let otherDiff = incentive.other - _paymentsStateClone.data[position].incentive.other

                _paymentsStateClone.totalBalance += otherDiff;
                _paymentsStateClone.totalIncentives.other += otherDiff;
                _paymentsStateClone.totalIncentives.total += otherDiff;
                _paymentsStateClone.data[position].balance += otherDiff;
                _paymentsStateClone.data[position].incentive.total += otherDiff;
                _paymentsStateClone.data[position].incentive.other = incentive.other
            }

            setPaymentsState(_paymentsStateClone);

            let _incentiveState = JSON.parse(JSON.stringify(incentiveState));
            _incentiveState[driver.id] = {
                bonus_deduction: incentive.bonus_deduction,
                fuel: incentive.fuel,
                performance: incentive.performance,
                km_overuse: incentive.km_overuse,
                uber: incentive.uber,
                other: incentive.other,

                showEdit: false
            }
            setIncentiveState(_incentiveState);

        }).catch((error)=>{
            console.log(error)
        })


    }

    const getStyledCell = (value) =>{

        if(value < 0)
        {
            return(<td className= "border-r text-red-500" >{formatter.format(value)}</td>)
        }
        return(<td className= "border-r text-green-500" >+ {formatter.format(value)}</td>)
    }

    const customButton = {
        padding: "4px",
        height: "35px",
        width: "34px",
        marginRight: "8px",
        marginBottom: '4px'
    } 

    const customInput = {
        padding: '4px'
    }

    const showEdit = () =>{
        let _incentiveState = JSON.parse(JSON.stringify(incentiveState));
        _incentiveState[driver.id].showEdit = true; 
        setIncentiveState(_incentiveState);
    } 

    const cancelEdit = () => {
        let _incentiveState = JSON.parse(JSON.stringify(incentiveState));
        _incentiveState[driver.id] = {
            bonus_deduction: driver.incentive.bonus_deduction,
            fuel: driver.incentive.fuel,
            performance: driver.incentive.performance,
            km_overuse: driver.incentive.km_overuse,
            uber: driver.incentive.uber,
            other: driver.incentive.other,

            showEdit: false
        }
        setIncentiveState(_incentiveState);
    }

    if (incentiveState[driver.id].showEdit == true)
    {
        return(
  
            <tr className="text-left text-sm text-gray-800 border-b">
                <td className="border-r">
                    <div className="flex items-center ml-1">
                        <div className="inline-block flex text-center bg-purple-200 h-10 w-10 rounded-full mr-3">
                            <i className="fas fa-user self-center w-full text-purple-400"></i>
                        </div>
                        <div className="font-medium text-purple-500">
                            {driver.name}
                        </div>  
                    </div>  
                </td>   
                <td className="border-r">{driver.total_trips}</td>
                <td className="border-r">{`${driver.total_distance}`}</td>
                <td className="border-r">{`${driver.duration.hours}hr ${driver.duration.min}min`}</td>
                <td className="border-r">{driver.total_cancellations}</td>
                <td className="border-r">{`${driver.currency.toUpperCase()} ${driver.total_fee}`}</td>
                <td className="border-r"><input className="input" style={customInput} type="text" value={ incentiveState[driver.id].bonus_deduction } onChange={(e)=>handleOnChange(e, "bonus_deduction")} /></td>
                <td className="border-r"><input className="input" style={customInput} type="text" value={ incentiveState[driver.id].performance } onChange={(e)=>handleOnChange(e, "performance")} /></td>
                <td className="border-r"><input className="input" style={customInput} type="text" value={ incentiveState[driver.id].fuel } onChange={(e)=>handleOnChange(e, "fuel")} /></td>
                <td className="border-r"><input className="input" style={customInput} type="text" value={ incentiveState[driver.id].km_overuse } onChange={(e)=>handleOnChange(e, "km_overuse")} /></td>
                <td className="border-r">
                    <div>
                        <div>Uber</div>
                        <input className="input" style={customInput} type="text" value={ incentiveState[driver.id].uber } onChange={(e)=>handleOnChange(e, "uber")} />
                    </div>
                    <div>
                        <div>Other</div>
                        <input className="input" style={customInput} type="text" value={ incentiveState[driver.id].other } onChange={(e)=>handleOnChange(e, "other")} />
                    </div>
                </td>
                { getStyledCell(driver.incentive.total)}
                <td className="border-r">{`${driver.currency.toUpperCase()} ${driver.balance}`}</td>
                <td className="text-center">
                    <button className="button" style={customButton} onClick={()=>cancelEdit()}><FontAwesomeIcon icon={faTimes}/></button>
                    <button className="button" style={customButton} onClick={()=>handleOnSubmit()}><FontAwesomeIcon icon={faSave}/></button>
                </td>
            </tr>
            
        )
    }else
    {
        return(
            <tr className="text-left text-sm text-gray-800 border-b">
                <td className="border-r">
                    <div className="flex items-center ml-1">
                        <div className="inline-block flex text-center bg-purple-200 h-10 w-10 rounded-full mr-3">
                            <i className="fas fa-user self-center w-full text-purple-400"></i>
                        </div>
                        <div className="font-medium text-purple-500">
                            {driver.name}
                        </div>  
                    </div>  
                </td>   
                <td className="border-r">{driver.total_trips}</td>
                <td className="border-r">{`${driver.total_distance}`}</td>
                <td className="border-r">{`${driver.duration.hours}hr ${driver.duration.min}min`}</td>
                <td className="border-r">{driver.total_cancellations}</td>
                <td className="border-r">{`${driver.currency.toUpperCase()} ${formatter.format(driver.total_fee)}`}</td>
                { getStyledCell(driver.incentive.bonus_deduction)}
                { getStyledCell(driver.incentive.performance)}
                { getStyledCell(driver.incentive.fuel)}
                { getStyledCell(driver.incentive.km_overuse)}
                { getStyledCell(driver.incentive.other + driver.incentive.uber)}
                { getStyledCell(driver.incentive.total)}
                <td className="border-r">{`${driver.currency.toUpperCase()} ${formatter.format(driver.balance)}`}</td>
                {
                (current_user.role == "admin") &&
                <td className="text-center">
                    <button className="button" style={customButton} onClick={()=>showEdit()}><FontAwesomeIcon icon={faEdit}/></button>
                    <button className="button" style={customButton}><a href={`/payments/show?id=${driver.id}&start_date_filter=${week.from}&end_date_filter=${week.to}`} target="_blank"><FontAwesomeIcon icon={faEye}/></a></button>
                </td>
                }
                {
                (current_user.role != "admin") &&
                <td className="text-center">
                    <button className="button" style={customButton}><a href={`/payments/show?id=${driver.id}&start_date_filter=${week.from}&end_date_filter=${week.to}`} target="_blank"><FontAwesomeIcon icon={faEye}/></a></button>
                </td>
                }
            </tr>
        )
    }
    
});

export default PaymentsRow;