function companyTimeslots(options, openingTime) {
  // Check if options is not an array or is empty
  if (!Array.isArray(options) || options.length === 0) {
    console.error("Error: options is empty or not an array.");
    return [];
  }

  const openHour = parseInt(openingTime.split(":")[0]);
  const openMinutes = parseInt(openingTime.split(":")[1].slice(0, 2));
  const isAM = openingTime.slice(-2) === "AM";

  return options.filter(option => {
    if (typeof option !== 'string' || option === "") {
      return false; // Skip invalid options
    }

    if (option === "Off") {
      return true; // Include "Off" option
    }

    const hour = parseInt(option.split(":")[0]);
    const minutes = parseInt(option.split(":")[1].slice(0, 2));
    const isOptionAM = option.slice(-2) === "AM";

    // Convert 12AM to 0 for comparison
    const normalizedOpeningHour = isAM && openHour === 12 ? 0 : openHour;
    const normalizedOptionHour = isOptionAM && hour === 12 ? 0 : hour;

    // Convert PM to 24-hour format for comparison
    const finalOpeningHour = !isAM && openHour !== 12 ? normalizedOpeningHour + 12 : normalizedOpeningHour;
    const finalOptionHour = !isOptionAM && hour !== 12 ? normalizedOptionHour + 12 : normalizedOptionHour;

    // Compare hours and minutes
    if (finalOptionHour > finalOpeningHour) return true;
    if (finalOptionHour === finalOpeningHour && minutes >= openMinutes) return true;
    return false;
  });
}

export default companyTimeslots;
