import React, { useState, useEffect } from "react";
import moment from "moment";
import "./tooltip.css";
import "./../../groupedTrips/enhancedGroupedTripsComponents/notification_pulse.css";

/**
 * Helper function for the weekly table cells to determine if the time is up for the selected day
 * @param {booking} - the booking object (table cell)
 * @param {selectedWeek} - the selected week
 * @param {companyCutOffTime} - the company cut off time
 * @returns {boolean} - true if the time is up, false if the time is not up
 */
export const timeIsUp = (booking, selectedWeek, companyCutOffTime) => {
  //selected week '07-08-2023|13-08-2023' needs to be split into a start and end date
  const [startDay, endDay] = selectedWeek.split("|");

  // Map days to their corresponding indexes (0 for Sunday, 1 for Monday, etc.)
  const daysMap = {
    monday: 0,
    tuesday: 1,
    wednesday: 2,
    thursday: 3,
    friday: 4,
    saturday: 5,
    sunday: 6,
  };

  // Calculate the date of the booking based on the day of the week
  const bookingDate = moment(startDay, "DD-MM-YYYY").add(
    booking.day ? daysMap[booking.day.toLowerCase()] : 0,
    "days"
  ); //set to the day booking cell

  const currentTime = moment();
  const targetTime = moment(bookingDate, "DD-MM-YYYY")
    .add(companyCutOffTime?.hour, "hours")
    .add(companyCutOffTime?.minute, "minutes"); // Set the target time to the company cut-off time

  const remainingTime = targetTime.diff(currentTime);

  if (remainingTime <= 0) {
    return true;
  } else {
    return false;
  }
};

/**
 *
 * @param {setTimesUp} - function to set the times up state
 * @param {selectedDay} - the selected day
 * @param {getCompanyTimeOut} - function to get the company cut off time
 * @param {companyCutOffTime} - state of the company cut off time to trigger a re-render
 * @returns
 */
const Countdown = ({
  setTimesUp,
  selectedDay,
  getCompanyTimeOut,
  companyCutOffTime,
  trip_type,
}) => {
  const [countdown, setCountdown] = useState(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [dangerZone, setDangerZone] = useState(false);
  // Calculate the remaining time and update the countdown
  const calculateCountdown = () => {
    const currentTime = moment();
    const targetTime = moment(selectedDay, "DD-MM-YYYY").set(companyCutOffTime);

    const remainingTime = targetTime.diff(currentTime);
    if (remainingTime <= 0) {
      setCountdown("0h 00m");
      setTimesUp(true);
      setDangerZone(false);
    } else {
      const duration = moment.duration(remainingTime);
      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes();
      setTimesUp(false);
      if (minutes <= 30 && hours <= 0 && days <= 0) {
        setDangerZone(true);
      } else if (remainingTime > 30) {
        setDangerZone(false);
      }

      if (days > 0) {
        setCountdown(`${days}d ${hours}h`);
      } else {
        setCountdown(`${hours}h ${minutes}m`);
      }
    }
  };

  useEffect(() => {
    calculateCountdown();
    const timer = setInterval(() => {
      calculateCountdown();
    }, 60000);

    return () => {
      clearInterval(timer);
    };
  }, [selectedDay, companyCutOffTime]);

  // Toggle tooltip visibility
  const toggleTooltip = () => {
    setTooltipVisible(!tooltipVisible);
  };

  return (
    <>
      <div
        className={`rounded-lg px-2 flex space-x-3 items-center justify-center ${dangerZone
          ? "bg-red-700 border-2 border-red-700 pulse"
          : `${countdown === "0h 00m" ? "bg-gray-300" : (trip_type === "inbound" ? "bg-green-500" : "bg-orange-500")}`
          }`}
      >
        <div className="flex flex-row items-center justify-center" style={{ whiteSpace: 'pre-wrap' }}>
          <div
            className="tooltip-icon"
            onMouseEnter={toggleTooltip}
            onMouseLeave={toggleTooltip}
          >
            <p className="text-white text-xs flex">{countdown}</p>
            {tooltipVisible && (
              <p className="tooltip">
                {countdown === "0h 00m" ? "Time expired" : "Ensure bookings are made before time reaches zero."}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Countdown;
