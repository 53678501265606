import React, { useState } from 'react';
import './driver-option.css';

const FleetOption = ({ fleet, setSelectedFleet }) => {
    return (
        <div
            className={"driver-option hover:bg-gray-100"}
            onClick={() => setSelectedFleet(fleet)} 
        >
            <div className="display-name">
                {fleet.name}
            </div>
        </div>
    );
};

export default FleetOption;
