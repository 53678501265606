/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("@hotwired/turbo-rails")
// console.log('Importing NotificationsSettings');
// import NotificationsSettings from '../components/notifications_settings/NotificationsSettings';
// console.log('Imported NotificationsSettings:', NotificationsSettings);
import '../styles/application.scss';
import Chart from 'chart.js'; // Support component names relative to this directory:
var componentRequireContext = require.context('components', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);

if ($('[type="date"]').prop('type') != 'date') {
  $('[type="date"]').datepicker();
}

import "controllers"
