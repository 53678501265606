import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Axios from 'axios';
import CancelConfirm from './CancelConfirm';
import { getFormattedDate, getFormattedTimeSlot24 } from '../../../tools/date.util';


const AddTripDialouge = ({ onNoClick, onYesClick, companyId, address, date, timeslot, bound, setIsLoadingPage, setRefresh, currentTrips }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [selectedPax, setSelectedPax] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [confirmBooking, setConfirmBooking] = useState(false)
  const [searchQuery, setSearchQuery] = useState('');


  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredPaxSelect = filteredEmployees.filter((passenger) => {
    return passenger.label.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handlePaxSelect = (passenger) => {
    if (selectedPax.includes(passenger)) {
      setSelectedPax(selectedPax.filter((item) => item !== passenger));
    } else {
      setSelectedPax([...selectedPax, passenger]);
    }
  };

  const handleRemovePax = (passenger) => {
    setSelectedPax(selectedPax.filter((item) => item !== passenger));
  };

  const handleOnCreateClick = () => {
    const trips = selectedPax.map((passenger) => {
      const { company_addresses, ...itemWithoutCompanyAddresses } = passenger.item;
      return itemWithoutCompanyAddresses;
    });
    setIsLoadingPage(true)
    Axios({
      method: 'post',
      url: '/bulk_bookings/adhoc_trips',
      headers: { Accept: 'application/json' },
      data: {
        company_id: companyId,
        address: address,
        timeslot: getFormattedTimeSlot24(timeslot),
        date: getFormattedDate(date),
        bound: bound,
        trips: trips,
      },
    })
    
      .then((response) => {
        // Handle the response
        onYesClick();
        setConfirmBooking(false)
        setIsLoadingPage(false)
        setRefresh()
      })
      .catch((error) => {
        // Handle the error
        setConfirmBooking(false)
        setIsLoadingPage(false)
        console.error('Failed to create adhoc trips', error);
      });

  }

  const getSelectedPaxLabels = () => {
    let message = '';
    for (const item of selectedPax) {
      message += `${item.label}, `;
    }
    message = message.slice(0, -2);  // Remove the trailing comma and space
    return message;
  }

  const getConfirmMessage = () => {
    const message = (
      <div>
        <span>You are about to book </span>
        <span style={{ fontWeight: 'bold' }}>{bound}</span>
        <span> trip(s) for </span>
        <span style={{ fontWeight: 'bold' }}>{getSelectedPaxLabels()}</span>
        <span> on </span>
        <span style={{ fontWeight: 'bold' }}>{getFormattedDate(date)}</span>
        <span style={{ fontWeight: 'bold' }}> - {getFormattedTimeSlot24(timeslot)}</span>
        <span>{(bound=="Inbound") ? " to " : " from "}</span>
        <span style={{ fontWeight: 'bold' }}>{address}</span>
        <span>. Are you sure?</span>
      </div>
    )
    return message;
  }
  const handleConfirmBooking = () => {
    setDropdownOpen(false)
    setConfirmBooking(true)
  }
  useEffect(() => {
    setIsLoadingPage(true)
    Axios({
      method: 'get',
      url: `/bulk_bookings/employees?company_id=${companyId}`,
      headers: { Accept: 'application/json' },
    })
      .then((response) => {
        const { data } = response;
        const employees = data.map((item) => ({
          label: `${item.name} ${item.surname}`,
          value: item.id,
          company_id: item.company_id,
          item,
        }));
        setEmployees(employees);
        setIsLoadingPage(false)
      })
      .catch((error) => {
        console.error('Failed to fetch employees', error);
        setIsLoadingPage(false)
      });
  }, []);

  useEffect(() => {

    const availableEmployees = employees.filter((employee) => {
      // Check if employee.label matches any currentTrip.full_name
      return !currentTrips.some((trip) => trip.full_name === employee.label);
    });

    const filteredEmployees = availableEmployees.filter((employee) => !selectedPax.includes(employee));
    setFilteredEmployees(filteredEmployees);
  }, [employees, selectedPax]);

  return (
    <div className="flex items-center justify-center h-full ">
      <div className="bg-white rounded-md shadow-lg p-2 flex flex-col items-center"  style={{ width: "480px" }} >
        <div>
          <div className="text-gray-800 mb-1 text-center">Create New Trip</div>
          <div className="text-gray-500 text-sm font-light text-center">Select all of the passengers for the new trip</div>
        </div>
        <div className="w-full mt-4 px-2">
          <div data-testid ={'toggle-dropdown-button'}
            className="h-8 max-h-20 w-full px-2 py-2 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded border border-gray-700 flex items-center justify-between focus:outline-none"
            onClick={handleDropdownToggle}
          >
            <span className="ml-2 text-gray-500 text-sm font-light">Passenger multi-select</span>
            <FontAwesomeIcon icon={faChevronDown} size="xs" />
          </div>
          {dropdownOpen && (
            <div className="absolute z-10 bg-white shadow-sm rounded-bl rounded-br overflow-y-auto" style={{ width: "448px" }} data-testid={'dropdown-div'}>
              <input
                    type="text"
                    className="px-2 py-1 text-xs text-gray-700 w-full bg-gray-100 border border-gray-700 border-t-0 border-b-0"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                />
              <div className="pb-1 bg-gray-100 border border-gray-700 border-t-0" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                {filteredPaxSelect.map((passenger) => (
                  <div 
                    key={passenger.value}
                    className="block px-2 py-1 text-xs text-gray-700 ml-2 hover:bg-gray-200 hover:text-gray-900 cursor-pointer"
                    onClick={() => handlePaxSelect(passenger)}
                  >
                    {passenger.label}
                    
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-wrap justify-center items-center mt-2 overflow-y-auto h-64" style={{ maxHeight: "64px", minHeight: "64px" }}>
          {selectedPax.map((passenger) => (
            <span
              key={passenger.value}
              className="inline-flex items-center bg-gray-200 text-gray-700 text-xs rounded-full px-2 py-1 m-1 cursor-pointer"
              style={{ height: "26px" }}
              onClick={() => handleRemovePax(passenger)}
              >
                {passenger.label}
                <FontAwesomeIcon icon={faTimesCircle} className="ml-1 text-gray-500" />
              </span> 
            ))}
          </div>
          <div className="mt-4 w-full border-gray-200 flex justify-between">
            <button
              onClick={onNoClick}
              className="text-gray-700 rounded-xl bg-gray-200 px-6 py-1 ml-1 hover:text-gray-900 text-sm focus:outline-none "
            >
              Cancel
            </button>
            <button
              onClick={() => {handleConfirmBooking() }}
              className="bg-gray-200 rounded-xl text-gray-700 hover:bg-gray-300 rounded px-6 py-1 mr-1 text-sm focus:outline-none"
            >
              Create
            </button>
          </div>
        </div>
        {
          confirmBooking &&  (companyId != 'All Companies') && (address != 'None') && date && timeslot && bound &&
            <>
              <div style={{zIndex: 10}}
                  className="absolute top-0 left-0 w-full h-full bg-black opacity-50"
                  onClick={() => setConfirmBooking(false)}
              ></div>
              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                  <CancelConfirm
                      title={"Create Booking(s)"}
                      body = {getConfirmMessage()}
                      onNoClick = {()=> {setConfirmBooking(false)}}
                      onYesClick= {()=>{handleOnCreateClick()}}
                  />
              </div>
            </>
        }
      </div>
    );
  };
  
  export default AddTripDialouge;
  
