/**
 * @module Input Component
 * @description
 * An input html element wrapped in formatted div.
 * Can make use of the input container's styling to contain other elements by
 *  providing `children` content.
 */
import React from 'react';
import {bool, string, node, oneOfType, func, shape, instanceOf} from 'prop-types';
import './input.scss';
import {getClassNames} from '../../tools/helpers';

/** Helper to render an input suffix element, if provided. */
const renderSuffix = suffix => {
    return suffix && <div className='input__suffix'>{suffix}</div>;
};

const renderErrors = (error, errorMsg) => {
    return error && <p className='input__error-msg'>{errorMsg}</p>;
};

/** If `children` are provided, render that instead of the default input. */
const renderInput = (children, inputRef, type, name, placeholder, value, onChange, suffix, min, max) => {
    return children ? children : (
        <>
            <input
                className="cursor-pointer"
                ref={inputRef}
                type={type}
                name={name}
                placeholder={placeholder}
                defaultValue={value}
                onChange={onChange}
                min={min}
                max={max}
            />
            {renderSuffix(suffix)}
        </>
    );
};

const Input = ({
                   type,
                   name,
                   placeholder,
                   value,
                   suffix,
                   inputRef,
                   onChange,
                   error,
                   errorMsg,
                   minimal = false,
                   lighter = false,
                   disabled,
                   children,
                   min,
                   max
               }) => (
    <div className={getClassNames('input', {error, minimal, lighter, disabled, 'custom-content': children})}>
        <div className='input__input-wrapper'>
            {renderInput(children, inputRef, type, name, placeholder, value, onChange, suffix, min, max)}
        </div>
        {renderErrors(error, errorMsg)}
    </div>
);

Input.defaultProps = {
    type: 'text',
    placeholder: '',
    error: false,
    errorMsg: null,
    disabled: false,
};

Input.propTypes = {
    type: string.isRequired,
    name: string,
    placeholder: string.isRequired,
    value: string,
    suffix: node,
    disabled: bool,
    inputRef: oneOfType([
        func,                         // either a function
        shape({                       // or an object shaped like this
            current: instanceOf(Element)
        })
    ]),
    onChange: func,
    error: bool.isRequired,
    errorMsg: string
};

export default Input;
