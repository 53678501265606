import { start } from '@popperjs/core';
import React, { useState } from 'react';
import './driver-option.css';
import { tr } from 'date-fns/locale';

const DriverOption = ({ driver, driverTrips, setSelectedDriver, groupDetails, dateTime, alreadyAssigned }) => {
  const [expanded, setExpanded] = useState(false);
  const maxTimeslots = 2;

  const handleExpand = () => {
    setExpanded(true);
  };

  const handleContract = () => {
    setExpanded(false);
  };

  const filterTimeslots = () => {
    let tripTimeslot = ''
    let tripDate = ''
    let currentTripDateTime = '';
    
    if (!driverTrips) {
      return { displayTimeslots: [], extraTimeslots: []};
    }

    if (groupDetails){
      tripTimeslot = groupDetails.timeslot
      tripDate = groupDetails.date
      currentTripDateTime = new Date(`${tripDate}`);
      const tripTime = tripTimeslot.slice(0, -2);
      const tripPeriod = tripTimeslot.slice(-2);
      const tripHour = parseInt(tripTime.split(":")[0]) % 12 + (tripPeriod === "PM" ? 12 : 0);
      const tripMinute = parseInt(tripTime.split(":")[1]);
  
      currentTripDateTime.setHours(tripHour, tripMinute, 0, 0)

    }else if (dateTime){
      const [date, time] = dateTime.split(" - ");
      const [day, month, year] = date.split("/");
      const [hour, minute] = time.slice(0, -2).split(":");
      const period = time.slice(-2);

      currentTripDateTime = new Date(year, month - 1, day, hour % 12 + (period === "PM" ? 12 : 0), minute);
    }



    const startTime = new Date(currentTripDateTime.getTime() - 12 * 60 * 60 * 1000);
    const endTime = new Date(currentTripDateTime.getTime() + 12 * 60 * 60 * 1000);

    const filteredTrips = driverTrips.filter(trip => {

      if(trip.id == groupDetails?.id){
        return false;
      }
      if (trip.status == 'complete'){
        return false;
      }
      if (trip.deleted_at != null){
        return false;
      }
      const tripDateTime = new Date(trip.date);
      const tripTime = trip.timeslot.slice(0, -2);
      const tripPeriod = trip.timeslot.slice(-2);
      const tripHour = parseInt(tripTime.split(":")[0]) % 12 + (tripPeriod === "PM" ? 12 : 0);
      const tripMinute = parseInt(tripTime.split(":")[1]);
  
      tripDateTime.setHours(tripHour, tripMinute, 0, 0)
      return tripDateTime >= startTime && tripDateTime <= endTime;
    });

    const sortedTrips = filteredTrips.sort((a, b) => {
      const aDateTime = new Date(a.date);
      const bDateTime = new Date(b.date);
      return aDateTime - bDateTime;
    });


    const displayTimeslots = sortedTrips.slice(0, maxTimeslots);
    const extraTimeslots = sortedTrips.slice(maxTimeslots);
    return { displayTimeslots, extraTimeslots };
  }

  const renderTimeslots = () => {
    const { displayTimeslots, extraTimeslots } = filterTimeslots();
    if (extraTimeslots.length > 0) {

      return (
        <>
          {displayTimeslots.map((trip) => (
            <div key={trip.id} 
              className={`timeslot-pill ${getBackgroundColorClass(trip.trip_type)}`}
            >
              {trip.timeslot}
            </div>
          ))}
          <div
            className={`timeslot-pill ${getBackgroundColorClass(expanded ? extraTimeslots[0].trip_type : 3)}`}
          >
            {expanded ? extraTimeslots[0].timeslot : '...'}
          </div>
          {expanded && extraTimeslots.length > 1 && (
            <>
              {extraTimeslots.slice(1).map((trip) => (
                <div key={trip.id} 
                className={`timeslot-pill ${getBackgroundColorClass(trip.trip_type)}`}
                >
                  {trip.timeslot}
                </div>
              ))}
            </>
          )}
        </>
      );
    }

    return (
      <div className="timeslots-container">
        {displayTimeslots.map((trip) => (
          <div key={trip.id} 
          className={`timeslot-pill ${getBackgroundColorClass(trip.trip_type)}`}
          >
            {trip.timeslot}
          </div>
        ))}
      </div>
    );
  };


  const getBackgroundColorClass = (trip_type) => {
    // pending: 0,
    // scheduled: 1,
    // accepted: 2,

    switch (trip_type) {
      case 0:
        return 'bg-green-100 border border-green-400';
      case 1:
        return 'bg-orange-100 border border-orange-400';
      default:
        return 'bg-gray-100 border border-gray-400';
    }
  };


  return (
    <div
    className={`driver-option ${(alreadyAssigned) ? "bg-orange-200" : "hover:bg-gray-100"} `}
      onClick={() => alreadyAssigned ? {} : setSelectedDriver(driver)}
      
    >
      <div className="display-name">
        {`${driver.tag ? `(${driver.tag})-` : ''}${driver.username} ${driver.usersurname}`}
      </div>
      <div className="timeslots"  style={{userSelect: 'none'}}
          onMouseEnter={handleExpand}
          onMouseLeave={handleContract}           
      >

            {renderTimeslots()} 
      </div>
    </div>
  );
};

export default DriverOption;
