import React, { useEffect } from 'react';
import moment from 'moment';

const CalendarComponent = ({
  recent_bookings,
  showTooltip,
  setShowTooltip,
  groupedData,
  setGroupedData,
  searchQuery,
  setSearchQuery,
}) => {
  const formattedDate = moment().startOf('isoWeek').format('MMM DD');

  const weekDates = Array.from({ length: 7 }, (_, index) =>
    moment().startOf('isoWeek').add(index, 'days')
  );

  useEffect(() => {
    const groupedTrips = {};

    Object.keys(recent_bookings).forEach((date) => {
      const trips = recent_bookings[date];
      const dayName = moment(date).format('dddd');

      if (dayName !== 'Invalid date') {
        if (!groupedTrips[dayName]) {
          groupedTrips[dayName] = [];
        }

        Object.keys(trips).forEach((users) => {
          const userTrips = trips[users];
          const userNames = users.substring(2, users.indexOf('"]=>'));

          if (userTrips.length > 0 && userTrips[0].timeslot) {
            const timeslot = userTrips[0].timeslot;
            const trip_type = userTrips[0].trip_type;
            const trip_status = userTrips[0].status;
            const grouped_trip_id = userTrips[0].grouped_trip_id;
            const trip_id = userTrips[0].id;

            groupedTrips[dayName].push({
              users: userNames.replace(/"/g, '').split(', ').join(' '),
              timeslot: timeslot,
              trip_type: trip_type,
              trip_status: trip_status,
              grouped_trip_id: grouped_trip_id,
              trip_id: trip_id,
            });
          }
        });
      }
    });

    setGroupedData(groupedTrips);
  }, [recent_bookings]);

  const filteredData = Object.keys(groupedData).reduce((acc, day) => {
    acc[day] = groupedData[day].filter((trip) =>
      trip.users.toLowerCase().includes(searchQuery.toLowerCase())
    );
    return acc;
  }, {});

  return (
    <div className="recentBookings p-4 rounded-md shadow bg-white">
      <div className="flex flex-row justify-between mb-4 w-full">
        <div className="flex flex-row order-first w-5/12">
          <div className="order-first flex-col text-sm text-gray-600">
            <div className="flex flex-row">
              Recent Bookings
              <div className="relative">
                <button
                  className="p-2 -mt-4 rounded relative cursor-help"
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}>
                  <i
                    className="fa fa-question-circle text-sm text-gray-400"
                    aria-hidden="true"></i>
                  {showTooltip && (
                    <div className="absolute -mt-10  rounded-md">
                      <div className="bg-white -mt-10 p-4 rounded-md shadow-lg flex w-48">
                        <div className="flex flex-row items-baseline mr-1">
                          <span className="w-2 h-2 bg-green-400"></span>
                          <span className="text-xs text-gray-400">Inbound</span>
                        </div>
                        <div className="flex flex-row items-baseline mr-1">
                          <span className="w-2 h-2 bg-orange-400"></span>
                          <span className="text-xs text-gray-400">
                            Outbound
                          </span>
                        </div>
                        <div className="flex flex-row items-baseline">
                          <span
                            className="w-2 h-2"
                            style={{ backgroundColor: '#745d96' }}></span>
                          <span className="text-xs text-gray-400">Other</span>
                        </div>
                      </div>
                    </div>
                  )}
                </button>
              </div>
            </div>
            <div className="text-xs font-normal text-gray-400">
              Week starting:{' '}
              <span className="font-semibold">{formattedDate}</span>
            </div>
          </div>
        </div>

        <div className="flex flex-row order-last w-7/12">
          <div className="w-full">
            <input
              type="text"
              name="search_filter"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="block w-3/4 float-right bg-white border border-gray-300 text-gray-700 text-sm py-1 px-2 rounded-xl leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              autoComplete="off"
              placeholder="Search"
            />
          </div>
        </div>
      </div>
      <div>
        {weekDates.map((date, index) => (
          <div key={index} className="flex mb-3 border-b pb-1 border-gray-200">
            <div className="grid w-1/6 bg-gray-200 rounded-lg mr-2">
              <div className="w-full text-center mt-1 font-medium text-sm">
                {date.format('ddd')}
              </div>
              <div
                className="w-full text-center font-medium text-sm"
                id={`dayOfCurrentWeek${index + 1}`}>
                {date.format('DD')}
              </div>
            </div>

            <div className="w-full max-w-full custom-scrollbar-bookings overflow-x-hidden">
              <div>
                {filteredData[date.format('dddd')]?.map((trip, tripIndex) => (
                  <a
                    href={
                      trip.grouped_trip_id != null &&
                      trip.trip_status == 'accepted'
                        ? `/groupedtrips/${trip.grouped_trip_id}`
                        : `/trips/${trip.trip_id}`
                    }
                    key={tripIndex}
                    className={`inline-block rounded-lg py-1 px-2 mr-3 ${
                      trip.trip_type === 'inbound'
                        ? 'bg-green-400'
                        : trip.trip_type === 'outbound'
                        ? 'bg-orange-400'
                        : 'bg-purple-600'
                    }`}>
                    <div className="flex text-sm text-white">
                      {trip.users.length > 10
                        ? trip.users.slice(0, 9) + '...'
                        : trip.users}
                    </div>
                    {trip.timeslot && (
                      <div className="flex text-xs text-white">
                        {trip.timeslot}
                      </div>
                    )}
                  </a>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalendarComponent;
