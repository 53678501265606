import React,{useState, useEffect} from "react"
import { DayPicker } from "react-day-picker"
import 'react-day-picker/dist/style.css';
import moment from "moment";


const WeeklyDatePicker = ({
    setWeek,
    week
}) =>{

    useEffect(()=>{
        const day= moment(week.from, 'DD/MM/YYYY').toDate();
        const days = getCurrentWeek(day);
        setSelectedDays(days);
    },[])

    const [selectedDays, setSelectedDays] = useState([]);

    const getCurrentWeek = (day) => {
        const selectedDate = moment(day);

        const weekStart = selectedDate.clone().startOf('isoWeek');
        const days = [];

        for (let i = 0; i < 7; i++) {
            const value = moment(weekStart).add(i, 'days').toDate();
            days.push(value);
        }

        return days;
    };

    const handleDayClick = (day, modifiers) => {
        const days = getCurrentWeek(day);
        setSelectedDays(days);
        setWeek({
            from: moment(days[0]).format('DD/MM/YYYY'),
            to: moment(days[days.length-1]).format('DD/MM/YYYY')
        })
    };
    
    return(
        <div className="absolute bg-white mt-3 z-10">
            <DayPicker
                weekStartsOn={1}
                onDayClick={handleDayClick}
                selected={selectedDays}
            />
        </div>
        
    )
}

export default WeeklyDatePicker;