import React, { useEffect, forwardRef } from 'react';
import Chart from 'chart.js';

const LineChartComponent = forwardRef(
  (
    {
      type,
      labels,
      data,
      datasets,
      backgroundColor,
      borderColor,
      borderWidth,
      width,
      height,
    },
    ref
  ) => {
    useEffect(() => {
      const ctx = ref.current.getContext('2d');

      new Chart(ctx, {
        type: type,
        data: {
          labels: labels,
          datasets: datasets,
        },
        options: {
          legend: {
            position: 'top',
          },
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  stepSize: 10,
                  max: 100,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Occupancy as (%)',
                },
              },
            ],
          },
          maintainAspectRatio: false,
        },
      });
    }, [type, labels, data, backgroundColor, borderColor, borderWidth, ref]);

    return (
      <div>
        <canvas
          ref={ref}
          width={width}
          height={height}
          id="chartCanvas"></canvas>
      </div>
    );
  }
);

export default LineChartComponent;
