import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AlertCheckboxComponent = () => {
  const [settings, setSettings] = useState({});
  const [checkedStatus, setCheckedStatus] = useState({});
  const [isLoading, setLoading] = useState(true);

  const userId = 1; // Replace with the actual user ID

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(`/settings/get_settings?user_id=${userId}`);
        const fetchedSettings = response.data.settings;
        setSettings(fetchedSettings);
        setCheckedStatus(createCheckedStatus(fetchedSettings));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching settings:', error);
        setLoading(false);
      }
    };

    fetchSettings();
  }, [userId,isLoading]);

  const createCheckedStatus = (settings) => {
    const checkedStatus = {};
    for (const [alertKey, status] of Object.entries(settings)) {
      if (alertKey.endsWith('alert')) {
        checkedStatus[alertKey] = status === 'accepted';
      }
    }
    return checkedStatus;
  };

  const updateSettingById = async (alertKey) => {
    setLoading(true);
    try {
      const response = await axios.put(
        `/settings/update_setting_by_id`,
        { setting_id: alertKey },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          params: {
            user_id: userId,
          },
        }
      );

      setLoading(false);

      setSettings((prevSettings) => ({
        ...prevSettings,
        [alertKey]: response.data,
      }));

      setCheckedStatus((prevCheckedStatus) => ({
        ...prevCheckedStatus,
        [alertKey]: response.data === 'accepted',
      }));
    } catch (error) {
      console.error('Error updating setting:', error.response.data.errors);
      setLoading(false);
    }
  };

  const handleCheckboxChange = (alertKey) => {
    updateSettingById(alertKey);
  };

  return (
    <div>
      <h2>Alerts</h2>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <h2>User Settings</h2>
          <form>
            {Object.entries(settings)
              .filter(([alertKey]) => alertKey.endsWith('alert'))
              .map(([alertKey]) => (
                <div key={alertKey}>
                  <label>
                    {alertKey.replace(/_/g, ' ')}:
                    <input
                      type="checkbox"
                      checked={checkedStatus[alertKey]}
                      onChange={() => handleCheckboxChange(alertKey)}
                    />
                  </label>
                </div>
              ))}
          </form>
        </div>
      )}
    </div>
  );
};

export default AlertCheckboxComponent;
