import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { useState, useEffect, useRef } from "react";

const DateSelection = ({
  dateRanges,
  selectedWeek,
  pageType,
  setWeek,
  setShowWarning,
  setWeekToEdit,
  dailyChangesMade,
  weeklyChangesMade,
}) => {
  const [viewOptions, setViewOptions] = useState(false);
  const dropdownRef = useRef(null);
  const boxRef = useRef(null);

  const toggleView = () => {
    setViewOptions(!viewOptions);
  };

  const handleSelect = (week) => {
    setViewOptions(false);
    if (week.value === selectedWeek.value) return;

    if (pageType === "bookings" && (dailyChangesMade || weeklyChangesMade)) {
      setWeekToEdit(week);
      setShowWarning(true);
    } else {
      setWeek(week);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        boxRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !boxRef.current.contains(event.target)
      ) {
        setViewOptions(false);
      }
    };

    setWeek(sortedDateRanges[0]);

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Function to sort dateRanges array based on the start date
  const sortedDateRanges = [...dateRanges].sort((a, b) => {
    const startDateA = new Date(a.value.split("|")[0]);
    const startDateB = new Date(b.value.split("|")[0]);
    return startDateA - startDateB;
  });

  // Determine the layout of weeks based on pageType
  let weekLayout = [sortedDateRanges[2], sortedDateRanges[0], sortedDateRanges[1]];

  // Exclude the last week if pageType is "downloads"
  if (pageType === "downloads") {
    weekLayout = [sortedDateRanges[0], sortedDateRanges[1]]; // Exclude the last week
  }

  return (
    <>
      <div
        className="weekSelect flex justify-between items-center bg-gray-200 rounded-lg w-full text-sm px-2 py-1 relative"
        onClick={toggleView}
        ref={boxRef}
      >
        <div className="text-gray-600 font-small">{selectedWeek.label}</div>
        <FontAwesomeIcon icon={faCalendarAlt} className="text-gray-600" />
        {viewOptions && (
          <div
            ref={dropdownRef}
            className="absolute top-full left-0 bg-white rounded-lg shadow-md border border-gray-300 w-full overflow-hidden z-40"
            style={{ top: "calc(100%)" }}
          >
            {weekLayout.map((range, index) => (
              <div
                key={index}
                className={`px-4 py-1 text-gray-600 font-normal cursor-pointer hover:bg-gray-100 ${index === 0 ? "last-week" : index === 1 ? "this-week" : ""
                  }`}
                onClick={() => {
                  handleSelect(range);
                }}
              >
                {range.label}
                {index === 0 && (
                  <span className="text-xs font-bold text-purple-500">
                    {" "} {
                      pageType === "downloads" ? "" : "[Last Week]"
                    }
                  </span>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default DateSelection;
