import React, { useState, useEffect } from "react";
import axios from "axios";

import ActionCable from "actioncable";

const socketUrl = process.env.REACT_APP_SECURE_ACTION_CABLE_URL;

ActionCable.logger.enabled = true;
const cable = ActionCable.createConsumer(socketUrl);

const NotificationCenter = ({ userId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [showMoreNotifications, setShowMoreNotifications] = useState(false);
  const [notificationsAmount, setNotificationsAmount] = useState(0);
  const [loading, setLoading] = useState(true);

  const [notifications, setNotifications] = useState([]);

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const unreadNotifications = notifications?.filter(
    (notification) => !notification.read
  );

  const getNotifications = () => {
    setLoading(true);
    axios
      .get("/notifications/get_notifications")
      .then((response) => {
        setLoading(false);

        setNotifications(response.data);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const maxVisibleNotifications = 5;
  const filterNotifications = () => {
    if (activeTab === 1) {
      if (selectedTypes.length === 0) {
        return notifications;
      } else {
        return notifications.filter((notification) =>
          selectedTypes.includes(notification.type)
        );
      }
    } else {
      return notifications;
    }
  };

  const markAsRead = (notification) => {
    if (notification.trackable_type === "Trip") {
      if (notification.read === true && notification.type !== "trip.destroy") {
        window.location.replace(`/trips/${notification.trackable_id}`);
      } else if (notification.read === false) {
        setLoading(true);
        axios
          .put(`/activities/mark_as_read/${notification.id}`)
          .then((response) => {
            const { trackableExists } = response.data;
            // console.log(response.data.trackableExists);
            // getNotifications();
            setLoading(false);
            notification.type !== "trip.destroy" &&
              trackableExists &&
              window.location.replace(`/trips/${notification.trackable_id}`);
          })
          .catch((error) => {
            console.error(error);
            setLoading(false);
          });
      }
    }
  };

  const markAllAsRead = () => {
    if (unreadNotifications.length === 0) {
      return;
    }
    const notificationIds = unreadNotifications.map(
      (notification) => notification.id
    );

    setLoading(true);

    axios
      .post(`/activities/mark_all_as_read`, {
        unread_activity_ids: notificationIds,
      })
      .then((response) => {
        getNotifications();
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  function renderCheckbox(type) {
    return (
      <label className="block mt-2" key={type}>
        <input
          type="checkbox"
          className="pl-2"
          value={type}
          checked={selectedTypes.includes(type)}
          onChange={(e) => handleCheckboxChange(e, type)}
        />
        <span className="pl-2">{type}</span>
      </label>
    );
  }

  function handleCheckboxChange(e, type) {
    if (e.target.checked) {
      setSelectedTypes([...selectedTypes, type]);
    } else {
      setSelectedTypes(
        selectedTypes.filter((selectedType) => selectedType !== type)
      );
    }
  }

  const filterTrackableTypes = (trackableType) => {
    return notifications.filter(
      (notification) => notification.trackable_type === trackableType
    );
  };

  useEffect(() => {
    const channel = cable.subscriptions.create({
      channel: "NotificationCenterChannel",
      user_id: userId,
    });
    channel.connected = () => {
      console.log("CONNECTED");
    };
    channel.received = (response) => {
      getNotifications();
    };

    return () => {
      channel.unsubscribe();
    };
  }, []);

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    const notificationsCount =
      activeTab === 1 ? filterNotifications().length : notifications.length;
    setNotificationsAmount(notificationsCount);
  }, [activeTab, selectedTypes, notifications]);

  return (
    <>
      <div className="relative">
        <div className="flex justify-end">
          <button
            onClick={handleToggleOpen}
            className={`nav-button text-gray-500 p-2 px-3 rounded-md mr-2 focus:outline-none ${
              unreadNotifications.length > 0
                ? "hover:bg-red-200 hover:text-red-500"
                : "hover:bg-purple-200 hover:text-purple-500"
            }`}
          >
            <i className="fas fa-bell"></i>
            <span
              className={`absolute top-2 right-12 w-2 h-2 rounded-full -mt-1 -mr-1 ${
                unreadNotifications.length > 0 ? "bg-red-500 animate-ping" : ""
              }`}
            ></span>
          </button>
        </div>

        {isOpen && (
          <div className="absolute top-14 right-0 z-20 bg-white rounded-lg shadow-lg w-96 py-4">
            <div className="flex justify-between px-4">
              <h1 className="text-xl font-semibold">Notifications</h1>
              <button
                className="text-purple-500"
                onClick={() => markAllAsRead()}
              >
                Mark all as read ({unreadNotifications.length})
              </button>
            </div>

            <div
              className="mt-4 overflow-y-scroll dark-gray-scrollbar "
              style={{ maxHeight: "400px", overflowY: "auto" }}
            >
              <div className="flex justify-between space-x-4 px-4 py-2 ">
                <div>
                  <button
                    className={`py-2 px-4 text-sm rounded-md focus:outline-none  ${
                      activeTab === 1
                        ? "bg-purple-500 text-white "
                        : "bg-white text-gray-500"
                    }`}
                    onClick={() => setActiveTab(1)}
                  >
                    All
                    <span className="bg-purple-800 text-white p-2 ml-2 rounded-full">
                      {notificationsAmount < 0
                        ? notifications.length
                        : notificationsAmount}
                    </span>
                  </button>
                  <button
                    className={`py-2 px-4 text-sm rounded-md focus:outline-none  ${
                      activeTab === 2
                        ? "bg-purple-500 text-white"
                        : "bg-white text-gray-500"
                    }`}
                    onClick={() => setActiveTab(2)}
                  >
                    Trips
                  </button>
                </div>
                <button
                  disabled
                  className="py-2 px-4 text-purple-800 focus:outline-none "
                  onClick={() => setActiveTab(3)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 text-gray-300"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                    />
                  </svg>
                </button>
              </div>

              {activeTab === 1 && (
                <div
                  className="mt-4 overflow-y-scroll dark-gray-scrollbar "
                  style={{ maxHeight: "400px", overflowY: "auto" }}
                >
                  {loading ? (
                    // Loader component while loading
                    <div className="flex justify-center items-center p-4">
                      <div className="animate-spin rounded-full border-t-2 border-b-2 border-purple-500 h-8 w-8"></div>
                    </div>
                  ) : (
                    // Render notifications once loaded
                    <div>
                      <div>
                        <ul>
                          {filterNotifications()
                            .slice(
                              0,
                              showMoreNotifications
                                ? filterNotifications().length
                                : maxVisibleNotifications
                            )
                            .map((notification) => (
                              <li
                                key={notification.id}
                                className={`${
                                  notification.read
                                    ? "bg-white text-gray-900"
                                    : "bg-purple-400 text-gray-100"
                                }  border-b  cursor-pointer w-full`}
                                onClick={() => markAsRead(notification)}
                              >
                                <div className="p-2 flex justify-start items-start ">
                                  <div className="w-1/6 flex items-center justify-center">
                                    <div className="w-10 h-10 p-4 bg-purple-500 rounded-full text-white flex items-center justify-center">
                                      <i className="fas fa-car"></i>
                                    </div>
                                  </div>
                                  <div>
                                    <p className="text-sm">
                                      {notification.name}{" "}
                                      {notification.activity_name}{" "}
                                    </p>
                                    <p className="text-sm">
                                      {notification.created_at} -{" "}
                                      {notification.trackable_type}
                                    </p>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                        {filterNotifications().length >
                          maxVisibleNotifications && (
                          <div className="flex justify-end p-4">
                            <button
                              onClick={() =>
                                setShowMoreNotifications(!showMoreNotifications)
                              }
                              className="bg-purple-500 px-4 py-2 rounded text-white hover:bg-purple-800 focus:outline-none"
                            >
                              {showMoreNotifications
                                ? "Show Less"
                                : "Show More"}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}

              {activeTab === 2 && (
                <div>
                  {/* filtered trips with trackable type Trip */}
                  {filterTrackableTypes("Trip").map((notification) => (
                    <div
                      key={notification.id}
                      className={`${
                        notification.read
                          ? "bg-white text-gray-900"
                          : "bg-purple-400 text-gray-100"
                      }  border-b  cursor-pointer w-full`}
                      onClick={() => markAsRead(notification)}
                    >
                      <div className="p-2 flex justify-start items-start ">
                        <div className="w-1/6 flex items-center justify-center">
                          <div className="w-10 h-10 p-4 bg-purple-500 rounded-full text-white flex items-center justify-center">
                            <i className="fas fa-car"></i>
                          </div>
                        </div>
                        <div>
                          <p className="text-sm">
                            {notification.name} {notification.activity_name}{" "}
                          </p>
                          <p className="text-sm">
                            {notification.created_at} -{" "}
                            {notification.trackable_type}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {activeTab === 3 && (
                <div className="p-4">
                  <h2 className="text-lg font-medium text-gray-900">
                    Select which notifications you want to filter
                  </h2>
                  <div className="mt-4">
                    <div className="mb-2">
                      <h3 className="text-lg font-medium text-gray-700">
                        Trip Notifications
                      </h3>
                      {renderCheckbox("trip.bulk_booked")}
                      {renderCheckbox("trip.create")}
                      {renderCheckbox("trip.destroy")}
                      {renderCheckbox("trip.update")}
                    </div>
                    <div className="mb-2">
                      <h3 className="text-lg font-medium text-gray-700">
                        User Notifications
                      </h3>
                      {renderCheckbox("user.create")}
                      {renderCheckbox("user.update")}
                      {renderCheckbox("user.destroy")}
                    </div>
                    <div className="mb-2">
                      <h3 className="text-lg font-medium text-gray-700">
                        Company Notifications
                      </h3>
                      {renderCheckbox("company.create")}
                      {renderCheckbox("company.update")}
                      {renderCheckbox("company.destroy")}
                    </div>
                    <div className="mb-2">
                      <h3 className="text-lg font-medium text-gray-700">
                        Grouped Trip Notifications
                      </h3>
                      {renderCheckbox("grouped_trip.create")}
                      {renderCheckbox("grouped_trip.update")}
                      {renderCheckbox("grouped_trip.destroy")}
                    </div>
                    <div className="mb-2">
                      <h3 className="text-lg font-medium text-gray-700">
                        Fleet Notifications
                      </h3>
                      {renderCheckbox("fleet.create")}
                      {renderCheckbox("fleet.update")}
                      {renderCheckbox("fleet.destroy")}
                    </div>
                    <div className="mb-2">
                      <h3 className="text-lg font-medium text-gray-700">
                        Vehicle Notifications
                      </h3>
                      {renderCheckbox("vehicle.create")}
                      {renderCheckbox("vehicle.update")}
                      {renderCheckbox("vehicle.destroy")}
                    </div>
                    <div className="mb-2">
                      <h3 className="text-lg font-medium text-gray-700">
                        Query Notifications
                      </h3>
                      {renderCheckbox("query.create")}
                      {renderCheckbox("query.update")}
                      {renderCheckbox("query.destroy")}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default NotificationCenter;
