import { faTriangleExclamation, faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useState, useEffect} from 'react'
import Axios from 'axios'
import moment from 'moment';

/**
 *
 * @param {string} view The current (summary) view (day or week)
 * @param {object} selectedTeam The selected team
 * @param {object} selectedWeek The selected week for the date selection component(summary)
 * @param {string} selectedDay The selected day for the date selection component (summary)
 * @param {function} refresh The state to refresh the data via Use Effect
 * @param {function} setIsLoadingSummary The function to set whether the summary is loading
 * @param {array} teamEmployees The employees for the selected team`
 */

const CancellationsCard = ({view, selectedTeam, selectedWeek, selectedDay, refresh, setIsLoadingSummary, teamEmployees}) => {
    const [expanded, setExpanded] = useState(false);
    const [cancellationsInbound, setCancellationsInbound] = useState([]);
    const [cancellationsOutbound, setCancellationsOutbound] = useState([]);
    const [dailyCancellationsInbound, setDailyCancellationsInbound] = useState([]);
    const [dailyCancellationsOutbound, setDailyCancellationsOutbound] = useState([]);

    //Call backend data when a team/week is selected and the refresh state is changed
    useEffect(() => {
        if (teamEmployees.length > 0) {
            const team_id = selectedTeam.team_id;
            const url = '/team_manager/get_cancelled_trips';
            const date_range = selectedWeek.value;
            setIsLoadingSummary(true)
            Axios({
                method: 'get',
                url: url,
                params: {
                date_range: date_range,
                team_id: team_id
                },
                headers: { Accept: 'application/json' },
            })
            .then((response) => {
                const cancelledEmployees = response.data;
                const inboundCancellations = []
                const outboundCancellations = []
                teamEmployees.forEach((employee) => {
                    const userId = employee.id;
                    const cancelledDates = cancelledEmployees[userId] || {};
                    if (cancelledDates['inbound'].length > 0) {
                        inboundCancellations.push({employee: employee, dates: cancelledDates['inbound']})
                    }
                    if (cancelledDates['outbound'].length > 0) {
                        outboundCancellations.push({employee: employee, dates: cancelledDates['outbound']})
                    }
                });
                setCancellationsInbound(inboundCancellations)
                setCancellationsOutbound(outboundCancellations)
                setIsLoadingSummary(false)
            })
            .catch((error) => {
                console.error("Error fetching unscheduled employees:", error);
                setIsLoadingSummary(false)
            });
        }
    }, [selectedTeam, refresh, selectedWeek]);

    //Map data to inbound / outbound cancellations for the day view
    useEffect(() => {
        if(view == 'day'){
            const dailyInboundCancels = []
            const dailyOutboundCancels = []
            cancellationsInbound.forEach((employee) => {
                if(employee.dates.includes(selectedDay)){
                    dailyInboundCancels.push(employee)
                }
            })
            cancellationsOutbound.forEach((employee) => {
                if(employee.dates.includes(selectedDay)){
                    dailyOutboundCancels.push(employee)
                }
            })
            setDailyCancellationsInbound(dailyInboundCancels)
            setDailyCancellationsOutbound(dailyOutboundCancels)
        }

    },[cancellationsInbound, cancellationsOutbound, selectedDay])

    return(
        <div className="bg-gray-200 rounded-lg w-full px-2 py-1 mt-4">
            <div className='font-small text-gray-600' >Cancellations</div>

            <div className='w-full mb-2'>
                <div className='bg-white rounded px-2 flex flex-row mt-1 justify-between' style={{ paddingTop: '0.1rem', paddingBottom: '0.1rem', userSelect: 'none' }}>
                    <div className='flex flex-col w-full'>
                        <span className='text-xs text-gray-500'><FontAwesomeIcon icon={faTriangleExclamation} className="text-gray-500 mr-1"/>{view == 'day' ? dailyCancellationsInbound.length + dailyCancellationsOutbound.length : cancellationsInbound.length + cancellationsOutbound.length} Cancellations</span>
                        {view === 'day' && expanded && dailyCancellationsInbound.length > 0 && (
                            <div className='flex flex-col w-full'>
                                <div className='font-semibold mt-1 flex text-sm text-gray-500'>
                                Inbound
                                </div>
                                <div className='bg-gray-200 py-1 my-1 overflow-y-scroll dark-gray-scrollbar rounded' style={{maxHeight: "52px"}}>
                                {
                                    dailyCancellationsInbound.map((item) => {
                                        return (
                                        <div key={item.employee.id} className='w-full text-xs text-gray-600 flex justify-between'>
                                            {item.employee.name} {item.employee.surname}
                                        </div>
                                        )
                                    })
                                }
                                </div>
                            </div>
                        )}
                        {view === 'day' && expanded && dailyCancellationsOutbound.length > 0 && (
                            <div className='flex flex-col w-full'>
                                <div className='font-semibold mt-1 flex text-sm text-gray-500'>
                                Outbound
                                </div>
                                <div className='bg-gray-200 py-1 my-1 overflow-y-scroll dark-gray-scrollbar rounded' style={{maxHeight: "52px"}}>
                                {
                                    dailyCancellationsOutbound.map((item) => {
                                        return (
                                        <div key={item.employee.id} className='ml-2 w-full text-xs text-gray-600 flex justify-between'>
                                            {item.employee.name} {item.employee.surname}
                                        </div>
                                        )
                                    })
                                }
                                </div>
                            </div>
                        )}
                        {view === 'week' && expanded && cancellationsInbound.length > 0 && (
                            <div className='flex flex-col w-full'>
                                <div className='w-full font-semibold text-sm text-gray-600 mt-1 flex '>
                                    Inbound
                                </div>
                                {
                                    cancellationsInbound.map((item) => {
                                        return (
                                        <>
                                            <div key={item.employee.id} className='w-full text-xs text-gray-600 flex justify-between'>
                                                {item.employee.name} {item.employee.surname}
                                            </div>
                                            <div className='w-full text-xs text-gray-500 flex justify-between'>
                                                {item.dates.map((date) => {
                                                    return (
                                                        <div key={date} className="flex flex-row items-center ml-1">
                                                            <span>{moment(date, 'DD-MM-YYYY').format('dddd')}</span>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </>
                                        )
                                    })
                                }
                            </div>

                        )}
                        {view === 'week' && expanded && cancellationsOutbound.length > 0 && (
                            <div className='flex flex-col w-full'>
                                <div className='w-full font-semibold text-sm text-gray-600 mt-1 flex '>
                                    Outbound
                                </div>
                                {
                                    cancellationsInbound.map((item) => {
                                        return (
                                        <>
                                            <div key={item.employee.id} className='w-full ml-1 text-xs text-gray-600 flex justify-between'>
                                                {item.employee.name} {item.employee.surname}
                                            </div>
                                            <div className='w-full text-xs text-gray-500 flex justify-between'>
                                                {item.dates.map((date) => {
                                                    return (
                                                        <div key={date} className="flex flex-row items-center ml-2">
                                                            <span>{moment(date, 'DD-MM-YYYY').format('dddd')}</span>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </>
                                        )
                                    })
                                }
                            </div>

                        )}
                    </div>
                    <FontAwesomeIcon icon={expanded ? faMinusCircle : faPlusCircle} size={'xs'} className="text-gray-500 my-1" onClick={() => { setExpanded(!expanded) }} />
                </div>
            </div>
        </div>
    )
}

export default CancellationsCard;
