import React,{useState, useEffect, useRef} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRetweet } from "@fortawesome/free-solid-svg-icons";

/**
 *
 * @param {function} handleTabSelect The function to set the selected bound (inbound or outbound)
 * @param {string} tripType The selected bound (inbound or outbound)
 * @returns {JSX.Element} The bound selector component for the adhoc booking view
 */
const AdhocBoundSelector = ({handleTabSelect, tripType}) => {

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const boxRef = useRef(null)

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleOptionSelect = (selectedType) => {
        handleTabSelect(selectedType);
        setIsDropdownOpen(false);
    };

    //Function for click outside of component to dismiss dropdown
    useEffect(() => {
        // Function to handle the click outside the component
        const handleClickOutside = (event) => {
            if (dropdownRef.current && boxRef.current && !dropdownRef.current.contains(event.target) && !boxRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        // Add the event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <div
                className="flex justify-between items-center bg-gray-200 text-gray-500 rounded-lg px-2 py-1 relative"
                style={{zIndex: 12, userSelect: 'none'}}
                ref={boxRef}
                onClick={toggleDropdown}
            >
                <div className='text-gray-600' style={{fontSize: "14px"}}> { !tripType ? "Trip Type" : tripType === "inbound" ? "Inbound" : "Outbound"} </div>
                <FontAwesomeIcon icon={faRetweet} className="text-gray-600" />


                {isDropdownOpen && (
                <div
                    className="absolute top-full left-0 bg-white rounded-lg shadow-md border border-gray-300 w-full"
                    style={{ zIndex: 12, top: 'calc(100%)' }}
                    ref={dropdownRef}
                >
                    <div className="py-1" role="none">
                        <div
                            className={`${
                                "text-gray-500 hover:bg-purple-100 hover:text-purple-500 focus:bg-purple-200"
                            } flex w-full justify-between items-center px-4 py-2 text-sm focus:outline-none`}
                            onClick={() => handleOptionSelect("inbound")}
                        >
                            Inbound
                        </div>
                        <div
                            className={`${
                                "text-gray-500 hover:bg-purple-100 hover:text-purple-500 focus:bg-purple-200"
                            } flex w-full justify-between  items-center px-4 py-2 text-sm focus:outline-none`}
                            onClick={() => handleOptionSelect("outbound")}
                        >
                            Outbound
                        </div>
                    </div>
                </div>
                )}
            </div>
        </>
    )
}

export default AdhocBoundSelector
