import {action} from "@storybook/addon-actions";
import React from 'react';

import Select from './Select';

export default {
    title: 'Form Elements | Select',
};

export const BasicSelect = () => (
    <div style={{padding: '20px', height: '500px'}}>
        <Select
            menuItems={[
                {label: 'One', value: 'onesy'},
                {label: 'Two', value: 2},
                {label: 'Three', value: 3},
                {label: 'Four', value: 4},
            ]}
            onChange={action('Option changed!')}
        />
    </div>
);
