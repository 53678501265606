import React,  { useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faBookmark, faSave } from '@fortawesome/free-solid-svg-icons'
import CancelConfirm from './CancelConfirm';
import FleetOption from './FleetOption';
import './driver-option.css';


const FleetDropDown = ({fleets, groupId, updateFleet, groupDetails, assignAllFleets, setLoadingCard, trips}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedFleet, setSelectedFleet] = useState(null);
    const [confirmAssign, setConfirmAssign] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredFleets, setFilteredFleets] = useState(fleets);


    const handleDropdownToggle = () => {
      setDropdownOpen(!dropdownOpen);
    };

    const handleFleetSelect = (fleet) => {
      setSelectedFleet(fleet);
      setDropdownOpen(false);
    };

    const handleFleetAssign = () => {
        if (selectedFleet){
            updateFleet(selectedFleet,groupId)
            setConfirmAssign(false)
            //setLoadingCard(true)
        }
    }

    useEffect(()=>{
        if (trips[0]?.fleet_id){
            const savedFleet = fleets?.find(fleet => fleet?.id === trips[0]?.fleet_id);
            if(savedFleet){
                setSelectedFleet(savedFleet)
            }
        }
    },[])

    useEffect(()=>{
        handleFleetAssign()
    },[assignAllFleets])


    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    // Handle the search query and update the filtered fleets list
    useEffect(() => {
        const updatedFilteredFleets = fleets?.filter((fleet) => {
            const fleetName = fleet.name ;
            return fleetName.toLowerCase().includes(searchQuery.toLowerCase());
        });
        setFilteredFleets(updatedFilteredFleets);
    }, [searchQuery, fleets]);

    const getSelectedFleet = () => {
        if(selectedFleet){
            return selectedFleet.name
        }else{
            return 'Fleet'
        }
    }
    const handleConfirm = () =>{
        setDropdownOpen(false);
       if (selectedFleet) {
        setConfirmAssign(true)
       }else{
        setConfirmAssign(false)
       }
    }

    return(
        <div className='w-full flex overflow-visible'>
            <div className={`h-5 max-h-20 w-full px-2 py-2 text-gray-700 flex items-center justify-between focus:outline-none rounded-l-full border border-gray-700 ${(groupDetails?.status == 'complete') ? "bg-gray-200 cursor-not-allowed" : "bg-gray-100 hover:bg-gray-200" }  `}
                onClick={ (groupDetails?.status == 'complete') ? {} : handleDropdownToggle}
                data-testid={'driver-dropdown'}
            >
                <span className=' ml-2 text-xs font-semibold'>{getSelectedFleet()}</span>
                <FontAwesomeIcon icon={faChevronDown} size={'xs'} />
            </div>
            <div onClick={(groupDetails?.status == 'complete') ? {} : handleConfirm} data-testid={'save-btn'}
                className={`max-h-20 px-2 py-2 rounded-r-full w-8 h-5 border border-gray-700 flex items-center justify-between focus:outline-none text-gray-700 ${(groupDetails?.status == 'complete') ? "bg-gray-200 cursor-not-allowed" : "bg-gray-100 hover:bg-gray-200" } `}>
                <FontAwesomeIcon
                    icon={faSave}
                    size="xs"
                    className='mr-1' />
            </div>
            {
            dropdownOpen &&
            <div className="absolute z-10 mt-5 w-64 bg-white shadow-lg rounded-md overflow-y-scroll scrollable-dropdown" style={{height: 'auto', maxHeight: "120px"}}>
                <input
                    type="text"
                    autoFocus
                    className="px-2 py-1 text-xs text-gray-700 w-full"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                />
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu" aria-valuenow={selectedFleet}>
                    {filteredFleets.map(fleet => (
                    <FleetOption
                        data-testid={`option-${fleet.id}`}
                        key={fleet.id}
                        fleet={fleet}
                        setSelectedFleet={handleFleetSelect}
                        groupDetails={groupDetails}
                    />
                    ))}
                </div>
            </div>
            }
            {
                confirmAssign &&
                <>
                    <div style={{zIndex: 10}}
                        className="absolute top-0 left-0 w-full h-full bg-black opacity-50"
                        onClick={() => setConfirmAssign(false)}
                    ></div>
                    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                        <CancelConfirm
                            data-testid={'confirm-assign'}
                            title={"Assign Fleet"}
                            body = {"You are about to assign a fleet. Are you sure?"}
                            onNoClick = {()=> {setConfirmAssign(false)}}
                            onYesClick= {()=>{handleFleetAssign()}}
                        />
                    </div>
                </>
            }
        </div>
    )

}

export default FleetDropDown;
