import React, { useRef, useState, useEffect } from 'react';
import {
  GoogleMap,
  InfoWindow,
  Marker,
  MarkerClusterer,
} from '@react-google-maps/api';
import { isEmpty } from '../../../../tools/helpers'
import { stylesArr } from './MapStyles';

const path = 'M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0';

const highlightColor = 'rgba(186, 18, 0, 0.5)'

export const renderMapMarkers = (
  color,
  locations,
  handleMarkerClick,
  handleMouseOver,
  handleMouseExit,
  bound,
  itemToHighLight
) => {
  return(
    !isEmpty(locations) && (
      <MarkerClusterer averageCenter={true} gridSize={2} maxZoom={18}>
        {
        (clusterer) =>
          locations.map((item, index) => {
            return (
            <Marker
              key={item.id}
              position={{
                lat:
                  bound === 'Inbound'
                    ? item.location_latitude
                    : item.destination_latitude,
                lng:
                  bound === 'Inbound'
                    ? item.location_longitude
                    : item.destination_longitude,
              }}
              index={index}
              icon={{
                path: path,
                fillColor: color,
                fillOpacity: 1,
                scale: 0.2,
                strokeColor: (itemToHighLight == item?.id) ? highlightColor : 'rgba(255, 255, 255, 0.5)',
                strokeWeight: 15,
              }}
              clusterer={clusterer}
              onClick={(e) => handleMarkerClick(e, item, index)}
              onMouseOver={(e) => handleMouseOver(e, item)}
              onMouseOut={(e) => handleMouseExit(e, item)}
            />
          );
            })
        }
      </MarkerClusterer>
  ));
}
  

const renderGroupedMapMarkers = (
  color,
  locations,
  handleGroupMarkerClick,
  handleMouseOver,
  handleMouseExit,
  bound,
  groupToHighLight,
  itemToHighLight
) => {
  const groupArr = [];
  let labelIndex = -1;


  const getGroupLabel = (labelIndex) =>{
    const labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    if(labelIndex < labels.length)
    {
      return labels[labelIndex]
    }
    else
    {
      const index = Math.floor(labelIndex/labels.length)-1;
      let _customLabel = labels[index];
      const remainder = labelIndex  % labels.length;
      _customLabel += labels[remainder];
      return _customLabel;
    }
  }

  for (const [group_id, trips] of Object.entries(locations)) {
    if (!isEmpty(trips)) {
      groupArr.push(
        <MarkerClusterer
          key={group_id}
          averageCenter={true}
          gridSize={2}
          maxZoom={18}
        >
          {(clusterer) => {
            labelIndex++;
            return trips.map((trip, index) => (
              <Marker
                key={trip.id}
                position={{
                  lat:
                    bound === 'Inbound'
                      ? trip.location_latitude
                      : trip.destination_latitude,
                  lng:
                    bound === 'Inbound'
                      ? trip.location_longitude
                      : trip.destination_longitude,
                }}
                index={index}
                label={{
                  text: getGroupLabel(labelIndex),
                  className: 'label',
                  fontSize: '14px',
                  fontWeight: '700',
                  color: 'white',
                }}
                icon={{
                  path: path,
                  fillColor: color,
                  fillOpacity: 1,
                  scale: 0.2,
                  strokeColor: (groupToHighLight === group_id) ? highlightColor : (itemToHighLight === trip?.id) ? highlightColor : 'rgba(255, 255, 255, 0.5)' ,
                  strokeWeight: 15,
                }}
                clusterer={clusterer}
                onClick={(e) => handleGroupMarkerClick(e, trip, index)}
                onMouseOver={(e) => handleMouseOver(e, trip)}
                onMouseOut={(e) => handleMouseExit(e, trip)}
              />
            ));
          }}
        </MarkerClusterer>,
      );
    }
  }
  return groupArr;
};

const renderAssignedGroupedMapMarkers = (
  color,
  locations,
  handleAssignedMarkerClick,
  handleMouseOver,
  handleMouseExit,
  bound,
  groupToHighLight,
  itemToHighLight
  ) => {
  const groupArr = [];
  let labelIndex = -1;

  const getGroupLabel = (labelIndex) =>{
    const labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    if(labelIndex < labels.length)
    {
      return labels[labelIndex]
    }
    else
    {
      const index = Math.floor(labelIndex/labels.length)-1;
      let _customLabel = labels[index];
      const remainder = labelIndex  % labels.length;
      _customLabel += labels[remainder];
      return _customLabel;
    }
  }

  locations.forEach(({group_id, trips}) => {
    if (!isEmpty(trips)) {
      groupArr.push(
        <MarkerClusterer
          key={group_id}
          averageCenter={true}
          gridSize={2}
          maxZoom={18}
        >
          {(clusterer) => {
            labelIndex++;
            return trips.map((trip, index) => (
              <Marker
                key={trip.id}
                position={{
                  lat:
                    bound === 'Inbound'
                      ? trip.location_latitude
                      : trip.destination_latitude,
                  lng:
                    bound === 'Inbound'
                      ? trip.location_longitude
                      : trip.destination_longitude,
                }}
                index={index}
                label={{
                  text: getGroupLabel(labelIndex),
                  className: 'label',
                  fontSize: '14px',
                  fontWeight: '700',
                  color: 'white',
                }}
                icon={{
                  path: path,
                  fillColor: (trip.status == 'complete') ? "rgba(50, 50, 50, 0.5)"  : color,
                  fillOpacity: 1,
                  scale: 0.2,
                  strokeColor: (groupToHighLight === group_id) ? highlightColor : (itemToHighLight === trip?.id) ? highlightColor : 'rgba(255, 255, 255, 0.5)' ,
                  strokeWeight: 15,
                }}
                clusterer={clusterer}
                onClick={(e) => (trip.status == 'complete') ? {} : handleAssignedMarkerClick(e, trip, index)}
                onMouseOver={(e) => handleMouseOver(e, trip)}
                onMouseOut={(e) => handleMouseExit(e, trip)}
              />
            ));
          }}
        </MarkerClusterer>,
      );
    }
  });
  return groupArr;
};

const renderInfoWindow = (
  {
    location_latitude,
    location_longitude,
    destination_latitude,
    destination_longitude,
    full_name,
    location,
    destination,
  },
  bound,
) => {
  if (bound === 'Inbound') {
    return (
      location_latitude && (
        <InfoWindow
          position={{
            lat: location_latitude,
            lng: location_longitude,
          }}
        >
          <>
            <h4>{full_name}</h4>
            <h4>{location}</h4>
          </>
        </InfoWindow>
      )
    );
  } else {
    return (
      destination_latitude && (
        <InfoWindow
          position={{
            lat: destination_latitude,
            lng: destination_longitude,
          }}
        >
          <>
            <h4>{full_name}</h4>
            <h4>{destination}</h4>
          </>
        </InfoWindow>
      )
    );
  }
};

const renderPassengers = (locations, type, handleUngroup) => {
  return (
    <>
      <b className="text-xs font-bold text-gray-800">
        Selected {type === 'selected' ? `Passengers` : 'Group'}
        {': '}
        {locations.length}
      </b>
      <ul className="h-24 mt-4">
        {locations.map((loc) => {
          return (
            <li
              className="flex justify-between text-xs text-gray-800"
              key={loc.id}
            >
              {loc.full_name}
              {type === 'group' && (
                <button
                  className="underline text-gray-400 hover:text-gray-800"
                  onClick={(e) => handleUngroup(e, loc)}
                >
                  Remove
                </button>
              )}
            </li>
          );
        })}
      </ul>
    </>
  );
};

const renderInfoPanel = (
  selected,
  selectedGroup,
  handleCreateGroup,
  handleReGroup,
  handleUngroup,
) => {
  if (!isEmpty(selected) && !isEmpty(selectedGroup)) {
    return (
      <>
        {renderPassengers(selectedGroup, 'group', handleUngroup)}
        {renderPassengers(selected, 'selected')}

        <button
          className="bg-gray-800 text-white text-sm px-3 py-2 rounded-md hover:bg-gray-700 mt-10"
          onClick={handleReGroup}
        >
          Re-Group Passengers {`(${selected.length + selectedGroup.length})`}
          <i className="fas fa-random ml-2" />
        </button>
      </>
    );
  }

  if (!isEmpty(selected)) {
    return (
      <>
        {renderPassengers(selected, 'selected')}
        <button
          className="bg-gray-800 text-white text-sm px-3 py-2 rounded-md hover:bg-gray-700 mt-10"
          onClick={handleCreateGroup}
        >
          Group Passengers
          <i className="fas fa-random ml-2" />
        </button>
      </>
    );
  }

  if (!isEmpty(selectedGroup)) {
    return renderPassengers(selectedGroup, 'group', handleUngroup);
  }
};

const getCenter = (trips, bound, groupedTrips, assignedTrips, centerFocus) => {
  if (isEmpty(trips) && isEmpty(groupedTrips) && isEmpty(assignedTrips)) {
    return {
      lat: centerFocus.lat,
      lng: centerFocus.lng,
    };
  }
  
  if (isEmpty(trips)) {
    let c = {};
    const activeTrips = isEmpty(groupedTrips) ? assignedTrips : groupedTrips
    const ids = Object.keys(activeTrips);
    if (bound === 'Inbound') {
      ids.forEach((id) => {
        if (!isEmpty(activeTrips[id])) {
          c = {
            lat: activeTrips[id][0]?.destination_latitude,
            lng: activeTrips[id][0]?.destination_longitude,
          };
        }
      });
      if (isEmpty(c)) {
        return {
          lat: -28.48322,
          lng: 24.676997,
        };
      } else {
        return c;
      }
    } else if (bound === 'Outbound') {
      ids.forEach((id) => {
        if (!isEmpty(activeTrips[id])) {
          c = {
            lat: activeTrips[id][0]?.location_latitude,
            lng: activeTrips[id][0]?.location_longitude,
          };
        }
      });
      if (isEmpty(c)) {
        return {
          lat: -28.48322,
          lng: 24.676997,
        };
      } else {
        return c;
      }
    }
  } else {
    if (bound === 'Inbound') {
      return {
        lat: trips[0]?.destination_latitude,
        lng: trips[0]?.destination_longitude,
      };
    } else if (bound === 'Outbound') {
      return {
        lat: trips[0]?.location_latitude,
        lng: trips[0]?.location_longitude,
      };
    }
  }
};

const getCompanyPosition = (companies, selectedAddress, selectedCompany) => {
  const company = companies.find(company => company.id == selectedCompany);

  if (company) {
    for (const address of company.company_addresses) {
      if (address.name === selectedAddress) {
        return {
          lat: address.latitude,
          lng: address.longitude,
        };
      }
    }
  }

  return {           
    lat: -28.48322,
    lng: 24.676997, 
  }
}

export const EnhancedTripMap = ({
  trips,
  handleMarkerClick,
  handleGroupMarkerClick,
  handleAssignedMarkerClick,
  selectedGroup,
  selected,
  companies,
  selectedAddress,
  selectedCompany,
  groupedTrips,
  handleCreateGroup,
  handleReGroup,
  bound,
  handleUngroup,
  selectedDate,
  selectedTimeslot,
  assignedTrips,
  showAssignedTrips,
  showGroupedTrips,
  centerFocus,
  itemToHighLight,
  groupToHighLight,
  currentUser,
}) => {

  const [infoSelect, setInfoSelect] = useState({});
  const [center, setCenter] = useState(getCenter(trips, bound, groupedTrips, assignedTrips, centerFocus));

  const mapRef = useRef(null);
  const mapStyles = {
    height: '88vh',
    width: '100%',
  };

  const handleLoad = (map) => {
    mapRef.current = map;
  };

  const handleCenter = () => {
    if (!mapRef.current) return;
    const newPos = mapRef.current.getCenter().toJSON();
    setCenter(newPos);
  };

  const handleMouseOver = (_e, item) => {
    setInfoSelect(item);
  };

  const handleMouseExit = () => {
    setInfoSelect({});
  };

  useEffect(()=>{
    setCenter(centerFocus)
  },[centerFocus])

  return (
    <>
      <div className='introMap'>
        <GoogleMap
          options={{
            styles: stylesArr,
            disableDefaultUI: true,
          }}
          mapContainerStyle={mapStyles}
          zoom={13}
          center={center}
          onLoad={handleLoad}
          onDragEnd={handleCenter}
        >
           {/* style={{transform: 'scale(0.8)', transformOrigin: 'bottom right'}} */}
          <div className="absolute right-0 bottom-0 w-64 z-10 bg-white rounded-md shadow-md p-4 m-4" >
            <h3 className="text-sm font-medium text-gray-700">
              Grouping Information
            </h3>
            {renderInfoPanel(
              selected,
              selectedGroup,
              handleCreateGroup,
              handleReGroup,
              handleUngroup,
            )}
          </div>
          {/* style={{transform: 'scale(0.8)', transformOrigin: 'bottom left'}} */}
          <button
            className="absolute text-gray-700 left-0 bottom-0 z-10 bg-white rounded-md shadow-md p-4 m-4"
            onClick={() => setCenter(getCenter(trips, bound, groupedTrips, assignedTrips, centerFocus))}
          >
            Re-center
          </button>
          <Marker
            position={getCompanyPosition(companies, selectedAddress, selectedCompany)}
            icon={{
              path:
                'M17.0710678,2.92893219 C20.9763107,6.83417511 20.9763107,13.1658249 17.0710678,17.0710678 L10,24.1421356 L2.92893219,17.0710678 C-0.976310729,13.1658249 -0.976310729,6.83417511 2.92893219,2.92893219 C6.83417511,-0.976310729 13.1658249,-0.976310729 17.0710678,2.92893219 Z M10,5 C7.23857625,5 5,7.23857625 5,10 C5,12.7614237 7.23857625,15 10,15 C12.7614237,15 15,12.7614237 15,10 C15,7.23857625 12.7614237,5 10,5 Z',
              fillColor: 'black',
              fillOpacity: 1,
              scale: 2,
              strokeColor: 'black',
              strokeWeight: 0.2,
            }}
          />
          {selectedDate && selectedTimeslot && renderMapMarkers(
            'rgba(59, 130, 246, 1)',
            trips,
            handleMarkerClick,
            handleMouseOver,
            handleMouseExit,
            bound,
            itemToHighLight
          )}
          {selectedDate && selectedTimeslot && renderMapMarkers(
            'rgba(16, 185, 129, 1)',
            selected,
            handleMarkerClick,
            handleMouseOver,
            handleMouseExit,
            bound,
            itemToHighLight
          )}
          {selectedDate && selectedTimeslot && renderMapMarkers(
            'rgba(16, 185, 129, 1)',
            selectedGroup,
            handleGroupMarkerClick,
            handleMouseOver,
            handleMouseExit,
            bound,
            itemToHighLight
          )}
          {selectedDate && selectedTimeslot && showGroupedTrips && renderGroupedMapMarkers(
            'rgba(139, 92, 246, 1)',
            groupedTrips,
            handleGroupMarkerClick,
            handleMouseOver,
            handleMouseExit,
            bound,
            groupToHighLight,
            itemToHighLight
          )}
          {selectedDate && selectedTimeslot &&showAssignedTrips && renderAssignedGroupedMapMarkers(
            'rgba(255, 153, 0, 1)',
            assignedTrips,
            handleAssignedMarkerClick,
            handleMouseOver,
            handleMouseExit,
            bound,
            groupToHighLight,
            itemToHighLight
          )}
          {renderInfoWindow(infoSelect, bound)}
        </GoogleMap>
      </div>
    </>
  );
};
export default EnhancedTripMap;
