/**
 * @module Button Component
 * @description
 * A general purpose html button wrapper.
 */
import React from "react";
import {bool, func, string} from "prop-types";
import {getClassNames, noOp} from "../../tools/helpers";

//import './button.scss';

const Button = ({ className, type, children, hollow, expand, onClick, name, disabled}) => (
    <button 
        type={type} 
        className={getClassNames('button' + ` ${className}`, {hollow, expand})} 
        onClick={ onClick } 
        name={name}
        disabled={disabled}>
        {children}
    </button>
);

Button.defaultProps = {
    type: 'button',
    hollow: false,
    onClick: noOp,
};

Button.propTypes = {
    type: string,
    hollow: bool,
    onClick: func
};

export default Button;
