/**
 * @module Table Component
 * @description
 * A table driven by `react-table` (https://github.com/tannerlinsley/react-table), which gives
 *  us many table data-handling tools like sorting, filtering and pagination.
 * By using the `useTable` hook, we simply add table functionality using our own UI elements.
 */
import React, { useMemo } from 'react';
import {
    arrayOf,
    object,
    string,
    shape,
    number,
} from 'prop-types';
import './table.scss';

import { useTable, usePagination } from 'react-table';
import PaginationControls from './PaginationControls';
import { getClassNames } from '../../tools/helpers';

/**
 * Renders the header row, applying react-table properties to `tr` and `th`.
 * @param {Object[]} headerGroups - Header row, containing header column properties
 * @param {Object} headerModifiers - Object with keys being the column id,
 *  containing class modifiers for the header column.
 */
const renderHeaders = (headerGroups, headerModifiers) => {
    return headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
                <th
                    {...column.getHeaderProps()}
                    className={getClassNames('table-heading', headerModifiers[column.id])}
                >
                    {column.render('Header')}
                </th>
            ))}
        </tr>
    ));
};

/**
 * Renders each data row, and their `td` elements, applying react-table properties.
 * @param {Object[]} page - A collection of data rows, contained within the current page
 * @param {function} prepareRow - react-table provided function to execute on a row before display
 * @param {Object} columnModifiers - Object with keys being the column id,
 *  containing class modifiers for a column of data.
 */
const renderRows = (page, prepareRow, columnModifiers, setRowSelection) => {
    return page.map((row) => {
        prepareRow(row);

        return (
            <tr {...row.getRowProps({
                onClick: ignored => {
                    setRowSelection(row.index);
                },
            })}>
                {row.cells.map(cell => (
                    <td
                        {...cell.getCellProps()}
                        className={getClassNames('table-cell', columnModifiers[cell.column.id])}
                    >
                        {cell.render('Cell')}
                    </td>
                ))}
            </tr>
        );
    });
};

const Table = ({ cols, rowData, headerModifiers, columnModifiers, pageLength, setRowSelection }) => {
    // react-table requires us to use memoisation on the columns and data to gain speed optimisations
    const columns = useMemo(() => cols, []);

    let {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data: rowData,
            initialState: { pageSize: pageLength },
        },
        usePagination,
    );

    return (
        <>
            <table {...getTableProps()} className="table">
                <thead className="table__header">
                    {renderHeaders(headerGroups, headerModifiers)}
                </thead>

                <tbody {...getTableBodyProps()} className="table__body">
                    {renderRows(page, prepareRow, columnModifiers, setRowSelection)}
                </tbody>
            </table>

            {/*<div className="table__pagination">*/}
            {/*    <PaginationControls*/}
            {/*        pageIndex={pageIndex}*/}
            {/*        pageSize={pageSize}*/}
            {/*        pageCount={pageCount}*/}
            {/*        canPreviousPage={canPreviousPage}*/}
            {/*        previousPage={previousPage}*/}
            {/*        canNextPage={canNextPage}*/}
            {/*        nextPage={nextPage}*/}
            {/*        gotoPage={gotoPage}*/}
            {/*    />*/}
            {/*</div>*/}
        </>
    );
};

Table.defaultProps = {
    rowData: [],
    headerModifiers: {},
    columnModifiers: {},
    pageLength: 1000,
};

Table.propTypes = {
    cols: arrayOf(shape({
        Header: string.isRequired,
        accessor: string.isRequired,
    })).isRequired,
    rowData: arrayOf(object),
    headerModifiers: object,
    columnModifiers: object,
    pageLength: number,
};

export default Table;
