import React from 'react';

const StatsTable = ({
  companyList,
  trips,
  kms,
  cancellations,
  bookings,
  costs,
}) => {
  return (
    <div className="totalKms w-full overflow-y-auto rounded-lg shadow-md bg-white h-table">
      <table className="w-full leading-normal">
        <thead>
          <tr>
            <th className="border-b-2 border-t border-gray-50 bg-gray-100 pl-5 pr-2 py-3 text-left text-sm tracking-wider text-gray-700">
              Region
            </th>
            <th className="border-b-2 border-t border-gray-50 bg-gray-100 px-2 py-3 text-left text-sm tracking-wider text-gray-700">
              Office/Site
            </th>
            <th className="border-b-2 border-t border-gray-50 bg-gray-100 px-2 py-3 text-left text-sm tracking-wider text-gray-700">
              Trips
            </th>
            <th className="border-b-2 border-t border-gray-50 bg-gray-100 px-2 py-3 text-left text-sm tracking-wider text-gray-700">
              Kilometers (km)
            </th>
            <th className="border-b-2 border-t border-gray-50 bg-gray-100 px-2 py-3 text-left text-sm tracking-wider text-gray-700">
              Cancellations
            </th>
            <th className="border-b-2 border-t border-gray-50 bg-gray-100 px-2 py-3 text-left text-sm tracking-wider text-gray-700">
              Bookings
            </th>
            <th className="border-b-2 border-t border-gray-50 bg-gray-100 pl-2 pr-5 py-3 text-left text-sm tracking-wider text-gray-700">
              Costs (R) <sup className="text-xxs">Estimated</sup>
            </th>
          </tr>
        </thead>

        <tbody>
          {companyList.map((company, index) => {
            const isFirstInRegion =
              index === 0 || companyList[index - 1].region !== company.region;
            const regionCell = isFirstInRegion ? (
              <td
                className="border-b border-gray-200 font-normal bg-white pl-5 pr-2 py-4 text-sm"
                rowSpan={
                  companyList.filter((c) => c.region === company.region).length
                }>
                {company.region}
              </td>
            ) : null;

            return (
              <tr key={index}>
                {regionCell}
                <td className="border-b border-gray-200 bg-white px-2 py-4 text-sm">
                  <p className="whitespace-no-wrap text-left font-normal text-gray-900">
                    {' '}
                    {company.office.length > 18
                      ? company.office.slice(0, 18) + '...'
                      : company.office}
                  </p>
                </td>
                <td className="border-b border-gray-200 bg-white px-2 py-4 text-sm">
                  <p className="whitespace-no-wrap text-left font-normal text-gray-900">
                    {trips[company.office] ? trips[company.office] : '-'}
                  </p>
                </td>
                <td className="border-b border-gray-200 bg-white px-2 py-4 text-sm">
                  <p className="whitespace-no-wrap text-left font-normal text-gray-900">
                    {kms[company.office] ? kms[company.office].toFixed(2) : '-'}
                  </p>
                </td>
                <td className="border-b border-gray-200 bg-white px-2 py-4 text-sm">
                  <p className="whitespace-no-wrap text-left font-normal text-gray-900">
                    {cancellations[company.office]
                      ? cancellations[company.office]
                      : '-'}
                  </p>
                </td>
                <td className="border-b border-gray-200 bg-white px-2 py-4 text-sm">
                  <p className="whitespace-no-wrap text-left font-normal text-gray-900">
                    {bookings[company.office] ? bookings[company.office] : '-'}
                  </p>
                </td>
                <td className="border-b border-gray-200 bg-white px-2 py-4 text-sm">
                  <p className="whitespace-no-wrap text-left font-normal text-gray-900">
                    {costs[company.office]
                      ? `R ${costs[company.office].toFixed(2)}`
                      : '-'}
                  </p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default StatsTable;
