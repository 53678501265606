// ApplyLastWeekTrips.js

import React, { useState } from "react";
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

const ApplyLastWeekTrips = ({ handleApplyLastWeekTrips, day }) => {
  const [isLastWeekModalOpen, setIsLastWeekModalOpen] = useState(false);

  const dateObject = moment(day, "DD-MM-YYYY");
  const dayOfWeek = dateObject.format("dddd").toLowerCase();

  const openModalForLastWeekTrips = () => {
    setIsLastWeekModalOpen(true);
  };

  const closeLastWeekModal = () => {
    setIsLastWeekModalOpen(false);
  };

  const handleApplyTrips = () => {
    handleApplyLastWeekTrips(day);
    closeLastWeekModal();
  };

  return (
    <>
      <button onClick={openModalForLastWeekTrips} className=" ">
        <FontAwesomeIcon icon={faHistory} />
      </button>
      {isLastWeekModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="relative z-50 w-auto my-6 mx-auto max-w-xl">
            <div className="bg-white rounded-lg shadow-lg outline-none focus:outline-none">
              <div className="p-6">
                <h3 className="text-lg font-semibold">
                  Apply Last Week's Trips
                </h3>
                <p className="mt-2 text-gray-600 font-normal">
                  Apply last week{" "}
                  <span className="text-purple-500 font-semibold capitalize">
                    {dayOfWeek}'s
                  </span>{" "}
                  bookings to today?
                </p>
              </div>
              <div className="flex items-center justify-end px-6 py-4 bg-gray-50 rounded-b">
                <button
                  className="mr-4 px-4 py-2 text-sm font-semibold text-white bg-purple-500 rounded hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500"
                  onClick={handleApply}
                >
                  Yes
                </button>
                <button
                  className="px-4 py-2 text-sm font-semibold text-gray-700 bg-gray-200 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500"
                  onClick={closeModal}
                >
                  No
                </button>
              </div>
            </div>
          </div>
          <div className="absolute inset-0 bg-gray-500 opacity-75 z-40"></div>
        </div>
      )}
    </>
  );
};

export default ApplyLastWeekTrips;
