import React,{useState, useEffect, createRef } from "react"
import Select from 'react-select'
import WeeklyDatePicker from "./paymentsComponents/WeeklyDatePicker"
import Axios from "axios"
import { Steps, Hints } from 'intro.js-react';

import { ThreeDots } from 'react-loader-spinner';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSort,faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import PaymentsRow from "./paymentsComponents/PaymentsRow"

const Payments = ({
    fleets,
    params,
    current_user
}) =>{
    
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [selectedDrivers, setSelectedDrivers] = useState([]);
    const [selectedVehicles, setSelectedVehicles] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState({ label:"Active", value:"Active"});
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [selectedFleets, setSelectedFleets] = useState([]);

    const [sortTrips, setSortTrips] = useState("none"); 
    const [sortDistances, setSortDistances] = useState("none");
    const [sortDurations, setSortDurations] = useState("none");
    const [sortCancellations, setSortCancellations] = useState("none");
    const [sortFees, setSortFees] = useState("none");



    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [showFailedMsg, setShowFailedMsg] = useState(false);
    const [skippedIncentives, setSkippedIncentives] = useState([]);
    const [incentiveState, setIncentiveState ] = useState({});
    const [isLoading, setIsLoading ] = useState(false);
    const [introState, setIntroState] = useState({
        stepsEnabled: false,
        initialStep: 0,
        options: {
            showProgress: true,
            showBullets: false,
            tooltipClass: 'etapathTooltip',
        },
        steps: [
            {
                element: '.paymentsPlatform',
                title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Payments",
                intro: "This is the payments page. You can view a company's payment metrics and incentives here.</span>",
                position: 'left'
            },
            {
                element: '.paymentsPlatformFleetFilterIntro',
                title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Fleet Filter",
                intro: "Search for a fleet to view their payment information.</span>",
                position: 'left'
            }, 
            {
                element: '.paymentsPlatformWeeklyFilterIntro',
                title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Fleet Filter",
                intro: "Select the week that you want to see.</span>",
                position: 'left'
            },
            {
                element: '.uploadFileButton',
                title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Fleet Filter",
                intro: "Upload a CSV file with the payment info that you want to add.</span>",
                position: 'left'
            },
            {
                element: '.downloadTemplateButton',
                title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Fleet Filter",
                intro: "Download a template to fill in a company's payment information.</span>",
                position: 'left'
            },
        ]
    });

    const [paymentsState, setPaymentsState] = useState({
        totalFee: 0,
        totalTrips: 0,
        currency: "ZAR",
        totalCancellations: 0,
        totalBalance: 0,
        totalDuration:{
            hours: 0,
            min: 0
        },
        totalDistance: 0,
        status: [],
        allDrivers: [],
        vehicles: [],
        data: [],
        params: {},
        companies: [],
        totalIncentives:{
            fuel: 0,
            bonus_deduction: 0,
            performance: 0,
            uber: 0,
            other : 0,
            total: 0
        }
    })

    const [week,setWeek] = useState({
        from: params.start_date_filter,
        to: params.end_date_filter
    });

    const [file, setFile] = useState();
    const handleChange = (event) =>{
        setFile(event.target.files[0]);
    }
    const handleUploadCsv = () => {
        const formData = new FormData();
        formData.append('incentives_file',file, file.name);
        formData.append('start_date_filter', week.from);
        formData.append('end_date_filter', week.to);
        Axios.post('/incentives/upload_csv.json',formData)
        .then((res)=>{
            const { saved_incentives, skipped_incentives } = res.data;
            updatePaymentsChanges(saved_incentives);

            if (skipped_incentives.length != 0)
            {
                setSkippedIncentives(skippedIncentives);
                setShowFailedMsg(true)

                setTimeout(()=>{
                    setSkippedIncentives([]);
                    setShowFailedMsg(false)
                }, 5000)
            }
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    const handleDownloadCsv = () =>{
        Axios.post('/incentives/download_csv.csv',{
            driver_filter: paymentsState.data.map(driver=> driver.id),
            end_date_filter: week.to,
            start_date_filter: week.from,
        })
        .then((res)=>{
           
            console.log(res);
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
       // currency: '',
      
        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
    });

    useEffect(()=>{
        if (selectedFleets.length != 0)
        {
            setIsLoading(true)
            Axios({
                method: 'post',
                url: `/payments/fleet_payments`,
                headers: { Accept: 'application/json' },
                data:{
                    fleets_filter: selectedFleets.map(fleet=> fleet.value),
                    end_date_filter: week.to,
                    start_date_filter: week.from,
                    status_filter: selectedStatus.value
                }
            }).then((response)=>{
                const { data } = response;
                // create refs
                const driverData = data.data;
                let _newIncentives = {}

                driverData.forEach((driver)=>{

                    if (!incentiveState.hasOwnProperty(driver.id))
                    {
                        _newIncentives[driver.id] = {
                            bonus_deduction: driver.incentive.bonus_deduction,
                            fuel: driver.incentive.fuel,
                            performance: driver.incentive.performance,
                            km_overuse: driver.incentive.km_overuse,
                            uber: driver.incentive.uber,
                            other: driver.incentive.other,

                        }
                    }
                });

                setIncentiveState({...incentiveState, ..._newIncentives });
                setPaymentsState(data)
                setIsLoading(false)
            }).catch((error)=>{
                console.log(error)
                setIsLoading(false)
            })
        }
       
    },[selectedFleets])

    useEffect(()=>{
        setShowDatePicker(false);

        if (selectedFleets.length != 0)
        {
            setIsLoading(true)
            Axios({
                method: 'post',
                url: `/payments/fleet_payments`,
                headers: { Accept: 'application/json' },
                data:{
                    fleets_filter: selectedFleets.map(fleet=> fleet.value),
                    end_date_filter: week.to,
                    start_date_filter: week.from,
                    status_filter: selectedStatus.value
                }
            }).then((response)=>{
                const { data } = response;

                // create refs
                const driverData = data.data;
                let _newIncentives = {}

                driverData.forEach((driver)=>{

                    if (!incentiveState.hasOwnProperty(driver.id))
                    {
                        _newIncentives[driver.id] = {
                            bonus_deduction: driver.incentive.bonus_deduction,
                            fuel: driver.incentive.fuel,
                            performance: driver.incentive.performance,
                            km_overuse: driver.incentive.km_overuse,
                            uber: driver.incentive.uber,
                            other: driver.incentive.other,
                            showEdit: false
                        }
                    }
                    
                });

                setIncentiveState({...incentiveState, ..._newIncentives });
                setPaymentsState(data)
                setIsLoading(false)
            }).catch((error)=>{
                console.log(error)
                setIsLoading(false)
            })
        }
    },[week])


    const driversSelect = () =>
    {
        const formatedDrivers =  paymentsState.allDrivers.map((driver)=>{
            return { label: `${driver.full_name}`, value: driver.id}
        })
        return formatedDrivers;
    }

    const onChangedSelectedDrivers = (selectedDrivers) =>
    {
        setSelectedDrivers(selectedDrivers)
    }

    const vehiclesSelect = () =>
    {
        const formatedVehicles =  paymentsState.vehicles.map((vehicle)=>{
            return { label: `${vehicle.registration_number}`, value: vehicle.id}
        })
        return formatedVehicles;
    }

    const onChangedSelectedVehicles = (selectedVehicles) =>
    {
        setSelectedVehicles(selectedVehicles)
    }


    const statusSelect = () =>{
        const formatedStatus =  paymentsState.status.map((status)=>{
            return { label: status.toUpperCase(), value: status}
        })
        return formatedStatus;
    }

    const onChangedSelectedStatus = (selectedStatus) =>
    {
        setSelectedStatus(selectedStatus)
    }

    const companiesSelect = () =>{
        const formatedCompanies =  paymentsState.companies.map((company)=>{
            return { label: company, value: company }
        })
        return formatedCompanies;
    }

    const onChangedSelectedCompanies = (selectedCompanies) =>
    {
        setSelectedCompanies(selectedCompanies)
    }

    const fleetsSelect = () =>{
        const formatedFleets =  fleets.map((fleet)=>{
            return { label: fleet.name, value: fleet.id }
        })
        return formatedFleets;
    }

    const onChangedSelectedFleets = (selectedFleets) =>
    {
        setSelectedFleets(selectedFleets)
    }

    const sortData = (type) =>{

        var _paymentsStateDataClone = JSON.parse(JSON.stringify(paymentsState.data));

        switch (type) {
            case "trips":
                if (sortTrips == "none" || sortTrips == "lToH")
                {
                    // toggle sorting to hToL
                    _paymentsStateDataClone.sort((a, b)=> a.total_trips - b.total_trips)
                    setSortTrips('hToL');
                }
                else
                {
                    // toggle sorting to lToH
                    _paymentsStateDataClone.sort((a, b)=> b.total_trips - a.total_trips)
                    setSortTrips('lToH')
                }
                break;

            case "distance":
                if (sortDistances == "none" || sortDistances == "lToH")
                {
                    // toggle sorting to hToL
                    _paymentsStateDataClone.sort((a, b)=> a.total_distance - b.total_distance)
                    setSortDistances('hToL');
                }
                else
                {
                    // toggle sorting to lToH
                    _paymentsStateDataClone.sort((a, b)=> b.total_distance - a.total_distance)
                    setSortDistances('lToH')
                }
                break;
            case "duration":
                if (sortDurations == "none" || sortDurations == "lToH")
                {
                    // toggle sorting to hToL
                    _paymentsStateDataClone.sort((a, b)=> {
                        let aDuration = (a.duration.hours * 60) + a.duration.min;
                        let bDuration = (b.duration.hours * 60) + b.duration.min;
                        return (aDuration - bDuration)
                    })
                    setSortDurations('hToL');
                }
                else
                {
                    // toggle sorting to lToH
                    _paymentsStateDataClone.sort((a, b)=> {
                        let aDuration = (a.duration.hours * 60) + a.duration.min;
                        let bDuration = (b.duration.hours * 60) + b.duration.min;
                        return(bDuration - aDuration)
                    })
                    setSortDurations('lToH')
                }
                break;
            case "cancelled":
                if (sortCancellations == "none" || sortCancellations == "lToH")
                {
                    // toggle sorting to hToL
                    _paymentsStateDataClone.sort((a, b)=> a.total_cancellations - b.total_cancellations)
                    setSortCancellations('hToL');
                }
                else
                {
                    // toggle sorting to lToH
                    _paymentsStateDataClone.sort((a, b)=> b.total_cancellations - a.total_cancellations)
                    setSortCancellations('lToH')
                }
                break;
            case "fees":
                if (sortFees == "none" || sortFees == "lToH")
                {
                    // toggle sorting to hToL
                    _paymentsStateDataClone.sort((a, b)=> a.total_fee - b.total_fee)
                    setSortFees('hToL');
                }
                else
                {
                    // toggle sorting to lToH
                    _paymentsStateDataClone.sort((a, b)=> b.total_fee - a.total_fee)
                    setSortFees('lToH')
                }
                break;
            default:
                break;
        }
        

        setPaymentsState((paymentsState)=> ({
            ...paymentsState,
            data: _paymentsStateDataClone
        }));

    }

    const updateAllIncentives = () =>{
        let paymentsToUpdate = [];

        paymentsState.data.forEach((driver)=>{

            if ( driver.incentive.fuel != incentiveState[driver.id].fuel
                || driver.incentive.performance != incentiveState[driver.id].performance
                || driver.incentive.bonus_deduction != incentiveState[driver.id].bonus_deduction
                || driver.incentive.km_overuse != incentiveState[driver.id].km_overuse
                || driver.incentive.uber != incentiveState[driver.id].uber                
                || driver.incentive.other != incentiveState[driver.id].other
                )
            {
                paymentsToUpdate.push({
                    user_id: driver.id,
                    fuel: incentiveState[driver.id].fuel,
                    performance: incentiveState[driver.id].performance,
                    bonus_deduction: incentiveState[driver.id].bonus_deduction,
                    km_overuse: incentiveState[driver.id].km_overuse,
                    uber: incentiveState[driver.id].uber,
                    other: incentiveState[driver.id].other

                })
            } 
        });
        setIsLoading(true)
        Axios({
            method: 'post',
            url: `/incentives/bulk_create`,
            headers: { Accept: 'application/json' },
            data:{
                incentives: paymentsToUpdate,
                start_date_filter: week.from,
                end_date_filter: week.to,
            }
        }).then((response)=>{
            const { data } = response;
            const { saved_incentives, skipped_incentives } = data;

            updatePaymentsChanges(saved_incentives);

            if (skipped_incentives.length != 0)
            {
                setSkippedIncentives(skippedIncentives);
                setShowFailedMsg(true);
                setTimeout(()=>{
                    setSkippedIncentives([]);
                    setShowFailedMsg(false);
                },5000);
            }
            setIsLoading(false)
        }).catch((error)=>{
            console.log(error)
            setIsLoading(false)
        })
    }

    const updatePaymentsChanges = (saved_incentives) => {

        let _paymentsStateClone = JSON.parse(JSON.stringify(paymentsState));
        let _incentiveState = JSON.parse(JSON.stringify(incentiveState));

        saved_incentives.forEach((incentive)=>{

            var position = _paymentsStateClone.data.findIndex((item)=>{
                return (item.id == incentive.user_id)
            })

            if (position != -1 )
            {
                if (_paymentsStateClone.data[position].incentive.bonus_deduction != incentive.bonus_deduction)
                {
                    let bonusDiff = incentive.bonus_deduction - _paymentsStateClone.data[position].incentive.bonus_deduction

                    _paymentsStateClone.totalBalance += bonusDiff;
                    _paymentsStateClone.totalIncentives.bonus_deduction += bonusDiff;
                    _paymentsStateClone.totalIncentives.total += bonusDiff;
                    _paymentsStateClone.data[position].balance += bonusDiff;
                    _paymentsStateClone.data[position].incentive.total += bonusDiff;
                    _paymentsStateClone.data[position].incentive.bonus_deduction = incentive.bonus_deduction;

                }

                if (_paymentsStateClone.data[position].incentive.fuel != incentive.fuel)
                {
                    let fuelDiff = incentive.fuel - _paymentsStateClone.data[position].incentive.fuel

                    _paymentsStateClone.totalBalance += fuelDiff;
                    _paymentsStateClone.totalIncentives.fuel += fuelDiff;
                    _paymentsStateClone.totalIncentives.total += fuelDiff;
                    _paymentsStateClone.data[position].balance += fuelDiff;
                    _paymentsStateClone.data[position].incentive.total += fuelDiff;
                    _paymentsStateClone.data[position].incentive.fuel = incentive.fuel
                }

                if (_paymentsStateClone.data[position].incentive.performance != incentive.performance)
                {
                    let performanceDiff = incentive.performance - _paymentsStateClone.data[position].incentive.performance

                    _paymentsStateClone.totalBalance += performanceDiff;
                    _paymentsStateClone.totalIncentives.performance += performanceDiff;
                    _paymentsStateClone.totalIncentives.total += performanceDiff;
                    _paymentsStateClone.data[position].balance += performanceDiff;
                    _paymentsStateClone.data[position].incentive.total += performanceDiff;
                    _paymentsStateClone.data[position].incentive.performance = incentive.performance
                }

                if (_paymentsStateClone.data[position].incentive.km_overuse != incentive.km_overuse)
                {
                    let kmOverDiff = incentive.km_overuse - _paymentsStateClone.data[position].incentive.km_overuse;

                    _paymentsStateClone.totalBalance += kmOverDiff;
                    _paymentsStateClone.totalIncentives.km_overuse += kmOverDiff;
                    _paymentsStateClone.totalIncentives.total += kmOverDiff;
                    _paymentsStateClone.data[position].balance += kmOverDiff;
                    _paymentsStateClone.data[position].incentive.total += kmOverDiff;
                    _paymentsStateClone.data[position].incentive.km_overuse = incentive.km_overuse
                }

                if (_paymentsStateClone.data[position].incentive.uber != incentive.uber)
                {
                    let uberDiff = incentive.uber - _paymentsStateClone.data[position].incentive.uber

                    _paymentsStateClone.totalBalance += uberDiff;
                    _paymentsStateClone.totalIncentives.uber += uberDiff;
                    _paymentsStateClone.totalIncentives.total += uberDiff;
                    _paymentsStateClone.data[position].balance += uberDiff;
                    _paymentsStateClone.data[position].incentive.total += uberDiff;
                    _paymentsStateClone.data[position].incentive.uber = incentive.uber
                }

                if (_paymentsStateClone.data[position].incentive.other != incentive.other)
                {
                    let otherDiff = incentive.other - _paymentsStateClone.data[position].incentive.other

                    _paymentsStateClone.totalBalance += otherDiff;
                    _paymentsStateClone.totalIncentives.other += otherDiff;
                    _paymentsStateClone.totalIncentives.total += otherDiff;
                    _paymentsStateClone.data[position].balance += otherDiff;
                    _paymentsStateClone.data[position].incentive.total += otherDiff;
                    _paymentsStateClone.data[position].incentive.other = incentive.other
                }

                _incentiveState[incentive.user_id] = {
                    bonus_deduction: incentive.bonus_deduction,
                    fuel: incentive.fuel,
                    performance: incentive.performance,
                    km_overuse: incentive.km_overuse,
                    uber: incentive.uber,
                    other: incentive.other,
                    showEdit: false
                }
            }
        });

        setPaymentsState(_paymentsStateClone);
        setIncentiveState(_incentiveState);
        setShowSuccessMsg(true);
        setTimeout(()=>{
            setShowSuccessMsg(false);
        },5000);

    }


    const applyFilters = () => {

        if (selectedFleets.length != null)
        {
            setIsLoading(true)
            Axios({
                method: 'post',
                url: `/payments/fleet_payments`,
                headers: { Accept: 'application/json' },
                data:{
                    fleets_filter: selectedFleets.map(fleet=> fleet.value),
                    status_filter: selectedStatus.value,
                    driver_filter: selectedDrivers.map(driver=> driver.value),
                    company_filter: selectedCompanies.map(company=> company.value),
                    vehicle_filter: selectedVehicles.map(vehicle=> vehicle.value),
                    end_date_filter: week.to,
                    start_date_filter: week.from,
                }
            }).then((response)=>{
                const { data } = response;

                const driverData = data.data;
                let _newIncentives = {}

                driverData.forEach((driver)=>{

                    if (!incentiveState.hasOwnProperty(driver.id))
                    {
                        _newIncentives[driver.id] = {
                            bonus_deduction: driver.incentive.bonus_deduction,
                            fuel: driver.incentive.fuel,
                            performance: driver.incentive.performance,
                            km_overuse: driver.incentive.km_overuse,
                            uber: driver.incentive.uber,
                            other: driver.incentive.other,

                        }
                    }
                });

                setIncentiveState({...incentiveState, ..._newIncentives });
    
                setPaymentsState(data)
                setIsLoading(false)
            }).catch((error)=>{
                console.log(error)
                setIsLoading(false)
            })
        }
       
    }

    const customPadding = {
        paddingTop: "9px",
        paddingBottom: '10px',
        paddingLeft: '10px'
    }

    const customDropshadow = {
        boxShadow: '1px 2px 9px rgba(0,0,0,0.1)',
    };

    const customButton = {
        padding: "8px",
        height: "35px",
    }

    const customOrder = {
        float: "right",
        marginLeft: "8px",
        marginTop: "4px"
    }

    const customHeader = {
        paddingLeft: "16px"
    }

    const customBorderBottom = {
        borderBottom: '0px'
    }

    const customUpdateAllBtn = {
        height: '100%',
        padding: "8px"
    }

    return(
        <> 
            <div className="flex w-full justify-between">
                <div className="text-xl font-medium text-gray-700">
                    <div className="flex flex-row">
                        <h1 className="">Payments</h1>
                        <button 
                            className= 'text-gray-500 cursor-pointer hover:text-gray-900 ml-2'
                            onClick={() =>{
                                setIntroState({...introState, stepsEnabled: true})
                            }}
                        >
                            <i className="fas fa-question-circle"/> 
                        </button>
                    </div>
                    <p className="text-sm text-gray-500">
                        {`Logged in as ${current_user.name} ${current_user.surname}`}
                    </p>
                </div>
            </div>
            <div className="flex w-full justify-center ">
                { showSuccessMsg && <div className="text-green-500"> Incentives successfully uploaded!</div> }
                { showFailedMsg && <div className="text-green-500"> { `Incentives Skipped: ${JSON.stringify(skippedIncentives)}` }</div> }
            </div>
            
            <form onSubmit={(e)=>{ e.preventDefault(); applyFilters();}} method='get'>
                <div className="bg-white p-5 rounded-lg mt-4 mb-4" style={customDropshadow}>
                    <div className='md:flex mb-5 w-full'>
                        <div className="w-full">
                            <label className="inline-block text-xs text-gray-700 mb-2 ml-1 mr-1">Fleet Filter</label>
                            <div className="relative text-sm ml-1 mr-1 paymentsPlatformFleetFilterIntro" >
                                <Select
                                    //ref={}
                                    placeholder = {"Fleet(s)"}
                                    isMulti
                                    isSearchable
                                    options={fleetsSelect()}
                                    onChange={onChangedSelectedFleets}
                                    closeMenuOnSelect={false} 
                                    value = {selectedFleets}
                                />
                            </div>
                        </div>
                        <div className="w-full">
                            <label className="inline-block text-xs text-gray-700 mb-2 ml-1 mr-1">Weekly Filter</label>
                            <div className="relative text-sm ml-1 mr-1 paymentsPlatformWeeklyFilterIntro">
                                <div className="block appearance-none w-full flex bg-white border border-gray-300 text-gray-700 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer" style={customPadding} onClick={()=>setShowDatePicker(!showDatePicker)}>
                                    <div>{week.from}</div> 
                                    <div> - </div>
                                    <div>{week.to}</div>
                                </div>
                                {
                                    showDatePicker &&
                                    <WeeklyDatePicker
                                        setWeek={setWeek} 
                                        week = {week} 
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full justify-end ">
                        <input className=""
                            type="file"
                            onChange={handleChange}
                        />
                        <div className="w-fit">
                            <button className="button mr-1 uploadFileButton" onClick={() =>handleUploadCsv() }>Upload File</button>
                        </div>
                        <div className="w-fit">
                            <a  href={`/incentives/download_csv.csv?driver_filter=${paymentsState.data.map(driver=> driver.id).join('|')}&end_date_filter=${week.to}&start_date_filter=${week.from}`}>
                                <div className ="button mr-1 downloadTemplateButton">Download Template</div>
                            </a>
                        </div>
                    </div>     
                </div>

                {
                    (selectedFleets.length != 0) &&
                    <div className="bg-white p-5 rounded-lg mt-4 mb-4" style={customDropshadow}>
                        <div className='flex mb-5 w-full'>
                            <div className="md:w-1/5 w-full">
                                <label className="inline-block text-xs text-gray-700 mb-2">Driver Filter</label>
                                <div className="relative text-sm mr-3 driverFilter">
                                    <Select
                                        //ref={}
                                        placeholder = {"Select Driver(s)"}
                                        isMulti
                                        isSearchable
                                        options={driversSelect()}
                                        onChange={onChangedSelectedDrivers}
                                        closeMenuOnSelect={false} 
                                        value = {selectedDrivers}
                                    />
                                </div>
                            </div>

                            <div className="md:w-1/5 w-full">
                                <label className="inline-block text-xs text-gray-700 mb-2">Vehicle Filter</label>
                                <div className="relative text-sm mr-3">
                                    <Select
                                        //ref={}
                                        placeholder = {"Select Vehicle(s)"}
                                        isMulti
                                        isSearchable
                                        options={vehiclesSelect()}
                                        onChange={onChangedSelectedVehicles}
                                        closeMenuOnSelect={false} 
                                        value = {selectedVehicles}
                                    />
                                </div>
                            </div>

                            <div className="md:w-1/5 w-full">
                                <label className="inline-block text-xs text-gray-700 mb-2">Status Filter</label>
                                <div className="relative text-sm mr-3">
                                    <Select
                                        //ref={}
                                        placeholder = {"Select Status"}
                                        options={statusSelect()}
                                        onChange={onChangedSelectedStatus}
                                        closeMenuOnSelect={true} 
                                        value = {selectedStatus}
                                    />
                                </div>
                            </div>

                            <div className="md:w-1/5 w-full">
                                <label className="inline-block text-xs text-gray-700 mb-2">Company Filter</label>
                                <div className="relative text-sm mr-3">
                                    <Select
                                        //ref={}
                                        placeholder = {"Select Companie(s)"}
                                        isMulti
                                        isSearchable
                                        options={companiesSelect()}
                                        onChange={onChangedSelectedCompanies}
                                        closeMenuOnSelect={false} 
                                        value = {selectedCompanies}
                                    />
                                </div>
                            </div>

                            <div className="md:w-1/5 w-full pt-6">
                                <button type="submit" className="button w-full mr-2">Search</button>
                                <a href="/payments/index" className="w-md-full md:w-auto inline-block cursor-pointer text-sm text-center px-4 py-3 border border-gray-500 text-gray-500 rounded hover:bg-gray-500 hover:text-white">Reset</a>
                            </div>
                        </div>
                    </div>
                }    
            </form>
                

            {
                (selectedFleets.length != 0) &&
                // <div className="flex justify-start w-full" >
                <div className="md:flex w-full justify-between" >
                    <div className="justify-center justify-items-center w-full mb-4 p-5 bg-white rounded-lg md:mr-5" style={customDropshadow}>
                        <h1 className="text-lg font-medium text-gray-700 mb-4">Payments</h1>
                        <div className='md:flex w-full'>
                            <div className="flex w-1/2">
                                <div className="text-md text-gray-500">
                                    Total Fare
                                </div>
                            </div>
                            <div className="flex w-1/2">
                                <div className="text-md font-medium text-gray-700">
                                    { `${paymentsState.currency} ${formatter.format(paymentsState.totalFee)}` }
                                </div>
                            </div>
                        </div>
                        <div className='md:flex w-full'>
                            <div className="flex w-1/2">
                                <div className="text-md text-gray-500">
                                    Total Incentives
                                </div>
                            </div>
                            <div className="flex w-1/2">
                                <div className="text-md font-medium text-gray-700">
                                { `${paymentsState.currency} ${formatter.format(paymentsState.totalIncentives.total)}` }
                                </div>
                            </div>
                        </div>
                        <div className='md:flex w-full'>
                            <div className="flex w-1/2">
                                <div className="text-md text-gray-500">
                                    Total Payout
                                </div>
                            </div>
                            <div className="flex w-1/2">
                                <div className="text-md font-medium text-gray-700">
                                { `${paymentsState.currency} ${formatter.format(paymentsState.totalBalance)}` }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="justify-center justify-items-center w-full mb-4 p-5 bg-white rounded-lg" style={customDropshadow}>
                        <h1 className="text-lg font-medium text-gray-700 mb-4">Metrics</h1>
                        
                        <div className='md:flex w-full'>
                            <div className="flex w-1/2">
                                <div className="text-md text-gray-500">
                                    Total Trips
                                </div>
                            </div>
                            <div className="flex w-1/2">
                                <div className="text-md font-medium text-gray-700">
                                    {paymentsState.totalTrips}
                                </div>
                            </div>
                                </div>
                        <div className='md:flex w-full'>
                            <div className="flex w-1/2">
                                <div className="text-md text-gray-500">
                                    Total Cancellations
                            </div>
                        </div>
                            <div className="flex w-1/2">
                                <div className="text-md font-medium text-gray-700">
                                    {paymentsState.totalCancellations}
                                </div>
                            </div>
                                </div>
                        <div className='md:flex w-full'>
                            <div className="flex w-1/2">
                                <div className="text-md text-gray-500">
                                    Total Distance
                            </div>
                        </div>
                            <div className="flex w-1/2">
                                <div className="text-md font-medium text-gray-700">
                                    {paymentsState.totalDistance}
                                </div>
                            </div>
                        </div>
                        <div className='md:flex w-full'>
                            <div className="flex w-1/2">
                                <div className="text-md text-gray-500">
                                    Total Duration
                                </div>
                            </div>
                            <div className="flex w-1/2">
                            <div className="text-md font-medium text-gray-700">
                                {`${paymentsState.totalDuration.hours}hr ${paymentsState.totalDuration.min}min`}
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="justify-center justify-items-center w-full mb-4 p-5 bg-white rounded-lg md:ml-5" style={customDropshadow}>
                        <h1 className="text-lg font-medium text-gray-700 mb-4">Incentives</h1>
                        <div className='md:flex w-full'>
                            <div className="flex w-1/2">
                                <div className="text-md text-gray-500">
                                    Bon/Ded
                                </div>
                            </div>
                            <div className="flex w-1/2">
                                <div className="text-md font-medium text-gray-700">
                                    { `${paymentsState.currency} ${formatter.format(paymentsState.totalIncentives.bonus_deduction)}` }
                                </div>
                            </div>
                        </div>
                        <div className='md:flex w-full'>
                            <div className="flex w-1/2">
                                <div className="text-md text-gray-500">
                                    Performance
                                </div>
                            </div>
                            <div className="flex w-1/2">
                                <div className="text-md font-medium text-gray-700">
                                    { `${paymentsState.currency} ${formatter.format(paymentsState.totalIncentives.performance)}` }
                                </div>
                            </div>
                        </div>
                        <div className='md:flex w-full'>
                            <div className="flex w-1/2">
                                <div className="text-md text-gray-500">
                                    Fuel
                                </div>
                            </div>
                            <div className="flex w-1/2">
                                <div className="text-md font-medium text-gray-700">
                                    { `${paymentsState.currency} ${formatter.format(paymentsState.totalIncentives.fuel)}` }
                                </div>
                            </div>
                                </div>
                        <div className='md:flex w-full'>
                            <div className="flex w-1/2">
                                <div className="text-md text-gray-500">
                                    Km Overuse
                            </div>
                        </div>
                            <div className="flex w-1/2">
                            <div className="text-md font-medium text-gray-700">
                                    { `${paymentsState.currency} ${formatter.format(paymentsState.totalIncentives.km_overuse)}` }
                            </div>
                            </div>
                            </div>
                        <div className='md:flex w-full'>
                            <div className="flex w-1/2">
                                <div className="text-md text-gray-500">
                                    Other
                            </div>
                            </div>
                            <div className="flex w-1/2">
                            <div className="text-md font-medium text-gray-700">
                                    { `${paymentsState.currency} ${formatter.format( paymentsState.totalIncentives.other + paymentsState.totalIncentives.uber)}` }
                            </div>
                            </div>
                            </div>
                    </div>
                </div>
            }
            {
                (selectedFleets != 0 ) &&
                // <div className="w-full bg-white p-5 rounded-lg" style={customDropshadow}>
                <div className="w-full rounded-lg bg-white rounded-lg" style={customDropshadow}>
                    <table className="w-full text-left">
                        <thead>
                            {/* <tr className="font-medium text-left text-base text-gray-700 border border-gray-400 bg-purple-200"> */}
                            <tr className="font-medium text-left text-base text-gray-700">
                                <th rowSpan={2} className="border-r font-medium text-left text-base text-gray-700" style={customHeader}>Drivers</th>
                                <th colSpan={5} className="border-r font-medium text-left text-base text-gray-700" style={customHeader}>Trips</th>
                                <th colSpan={6} className="border-r font-medium text-left text-base text-gray-700" style={customHeader}>Incentives</th>
                                <th rowSpan={2} className="font-medium text-center text-base text-gray-700 border-r">Payout</th>
                                {/* <th></th> */}
                                {   
                                (current_user.role == "admin") &&
                                    <td rowSpan={2} className="text-center"><button className="button" style={customUpdateAllBtn} onClick={()=>updateAllIncentives()}>Update All</button></td>
                                }
                                {
                                   (current_user.role != "admin") &&
                                   <td rowSpan={2} className="text-center"></td> 
                                }
                            </tr>
                            <tr className="font-medium text-left text-base text-gray-700">
                                {/* <th className="border-r font-medium text-left text-base text-gray-700">Names</th> */}
                                <th className="border-r font-medium text-center text-base text-gray-700">Trips <FontAwesomeIcon style={customOrder} icon= {faSort} onClick={()=>sortData("trips")}/>   </th>
                                <th className="border-r font-medium text-center text-base text-gray-700">Kms <FontAwesomeIcon style={customOrder} icon= {faSort} onClick={()=>sortData("distance")}/></th>
                                <th className="border-r font-medium text-center text-base text-gray-700">Duration <FontAwesomeIcon style={customOrder} icon= {faSort}onClick={()=>sortData("duration")}/></th>
                                <th className="border-r font-medium text-center text-base text-gray-700">Cancelled <FontAwesomeIcon style={customOrder} icon= {faSort}onClick={()=>sortData("cancelled")}/></th>
                                <th className="border-r font-medium text-center text-base text-gray-700">Fees <FontAwesomeIcon style={customOrder} icon= {faSort} onClick={()=>sortData("fees")}/></th>
                                <th className="border-r font-medium text-center text-base text-gray-700">Bon/Ded</th>
                                <th className="border-r font-medium text-center text-base text-gray-700">Performance</th>
                                <th className="border-r font-medium text-center text-base text-gray-700">Fuel</th>
                                <th className="border-r font-medium text-center text-base text-gray-700">Kms +</th>
                                <th className="border-r font-medium text-center text-base text-gray-700">Other</th>
                                <th className="border-r font-medium text-center text-base text-gray-700">Total</th>
                                {/* <th className="border-r font-medium text-left text-base text-gray-700">Payout</th> */}
                                {/* <td className="text-center">Actions</td> */}
                                {/* <td></td> */}
                            </tr>
                        </thead>
                        {
                            (paymentsState.data != 0) &&
                            <tbody className="font-light text-left text-xs text-gray-700">
                                {
                                    paymentsState.data.map((driver, index)=>{ 
                                        return (
                                            <PaymentsRow 
                                                formatter = {formatter}
                                                paymentsState={paymentsState}
                                                setPaymentsState={setPaymentsState} 
                                                driver={driver} 
                                                week={week} 
                                                key={index}
                                                position={index}
                                                incentiveState = {incentiveState}
                                                setIncentiveState = {setIncentiveState}
                                                current_user = {current_user}
                                            />
                                        )
                                    
                                    })
                                }
                            </tbody>
                        }                   
                        {
                                isLoading &&
                                <tbody>
                                    <tr className="text-left text-sm text-gray-800 border-b">         
                                        <td colSpan={14} className="text-center">
                                            <div className='flex justify-center mt-10'>
                                                        <ThreeDots
                                                            type="ThreeDots" 
                                                            color="#9B999E" 
                                                            height="100" 
                                                            width="100"
                                                        />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            }                     
                        <tfoot>
                            <tr className="text-left text-sm text-gray-800" style={customBorderBottom}>
                                
                                <td colSpan={5} className="font-medium text-left text-base text-gray-700" style={customHeader}>Total Amount Due</td>
                                <td className="font-medium text-left text-base text-gray-700 border-r" style={customHeader}>{`${paymentsState.currency} ${paymentsState.totalFee}`}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="border-r border-l" style={customHeader}>{`${paymentsState.currency} ${paymentsState.totalIncentives.total}`}</td>
                                <td className="font-medium text-left text-base text-gray-700 border-r" style={customHeader}>{`${paymentsState.currency} ${paymentsState.totalBalance}`}</td>
                                <td></td>
                                {/* <td></td> */}
                            </tr>
                        </tfoot>
                    </table>
                </div>
            }
            <Steps
                enabled={introState.stepsEnabled}
                steps={introState.steps}
                initialStep={introState.initialStep}
                onExit={() => { 
                    setTimeout(()=>{
                        setIntroState({...introState, stepsEnabled: false})
                    }, 1000)
                }}
                options={introState.options}
            /> 
        </>
    )
}

export default Payments;