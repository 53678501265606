import React from 'react';
import './TransportProtocols.css';
import Booking from '../../../assets/images/transport_protocols/Bookings.svg'
import Cancel from '../../../assets/images/transport_protocols/Cancellations.svg'
import Communication from '../../../assets/images/transport_protocols/Communication.svg'
import HomePickup from '../../../assets/images/transport_protocols/HomePickup.svg'
import Settings from '../../../assets/images/transport_protocols/Settings.svg'
import WorkPickup from '../../../assets/images/transport_protocols/WorkPickup.svg'

const bookingPoints = [
  "Send your shift schedule to your manager one week in advance.",
  "Etapath will make all your bookings for you.",
  "Communicate any changes to your trip before 14h30pm daily.",
  "Ad hoc trips are booked 2 - 4 hours in advance."
];

const cancelPoints = [
  "Locate your trip on your app and select the cancel button.",
  "Managers can cancel on your behalf on the Whatsapp group.",
  "Cancellations must be made the day before the trip (24hrs).",
  "Cancellations within 24hrs receive a per head cancellation fee."
];

const communicatePoints = [
  "An SMS or in app push notification notifies you of the trip details.",
  "An in app chat allows you to communicate with your driver once they are on en route.",
  "Support tickets can be raised by selecting the [ ? ] button on your trip card",
];

const manageTripsPoints = [
  "Passengers must leave site as soon as their shift has ended.",
  "Driver will wait a maximum of 15 minutes before leaving.",
]

const personalPoints=[
  "Passengers can only update their cellphone number but must submit a request to change their name and address.",
  "One address change can be made per month.",
  "Any changes must be made 24 hours before the trip.",
  "All changes must be approved before becoming effective."
]

const TransportProtocols = () => {
  return (
    <div className='flex flex-col justify-center items-center w-full bg-offwhite'>
      <div className='w-full md:w-1/2 justify-center items-center p-4 md:p-0'>
        <h2 className="text-gray-700 font-bold text-lg my-2">
          How Transport Works
        </h2>
        <p className="text-sm mb-8 font-light">
          This training content will guide you in understanding how Etapath's transportation works.
        </p>
        <h3 className="transport-protocols-subheading font-extrabold text-lg mb-4">
          BOOKINGS AND CANCELLATIONS
        </h3>
        <div className="flex flex-row w-124 justify-between ">
          <div className="flex-1 flex justify-center  ">
            <img src={Booking} alt="Booking" className='w-24 h-24  md:w-48 md:h-48  object-contain mb-4' />
          </div>
          <div className="w-3/5 flex-1 mb-2 mt-0  justify-center ">
            <h4 className="text-gray-700 font-bold text-md md:text-md md:text-xl">How bookings work</h4>
            <ul className="list-disc pt-0 mt-0 mb-0 pl-4">
              {bookingPoints.map((point) => (
                <li
                  key={point}
                  className="text-sm font-light leading-tight mb-2"
                >
                  {point}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="flex flex-row w-124 justify-between">
          <div className="w-3/5 flex-1  mb-2 mt-0  justify-center">
            <h4 className="text-gray-700 font-bold text-md md:text-xl">How cancellations work</h4>
            <ul className="list-disc pt-0 mt-0 mb-0 pl-4">
              {cancelPoints.map((point) => (
                <li
                  key={point}
                  className="text-sm font-light leading-tight mb-2"
                >
                  {point}
                </li>
              ))}
            </ul>
          </div>
          <div className="flex-1 flex justify-center">
            <img src={Cancel} alt="Cancel" className='w-24 h-24 md:w-48 md:h-48 object-contain mb-4' />
          </div>
        </div>
        <h3 className="transport-protocols-subheading  font-extrabold text-lg mb-4">
          COMMUNICATIONS
        </h3>
        <div className="flex flex-row w-124 justify-between">
          <div className="flex-1 flex justify-center">
            <img src={Communication} alt="Communication" className='w-24 h-24 md:w-48 md:h-48 object-contain mb-4' />
          </div>
          <div className="w-3/5 flex-1  mb-2 mt-0  justify-center">
            <h4 className="text-gray-700 font-bold text-md md:text-xl">
              How Etapath communicates with you
            </h4>
            <ul className="list-disc pt-0 mt-0 mb-0 pl-4">
              {communicatePoints.map(point => (
                <li key={point} className="text-sm font-light leading-tight mb-2">
                  {point}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <h3 className="transport-protocols-subheading font-extrabold text-lg mb-4">
          TRIP MANAGEMENT
        </h3>
        <div className="flex flex-row w-124 justify-between">
          <div className="w-3/5 flex-1  mb-2 mt-0  justify-center">
            <h4 className="text-gray-700 font-bold text-md md:text-xl">
              How to manage your trips
            </h4>
            <ul className="list-disc pt-0 mt-0 mb-0 pl-4">
              {manageTripsPoints.map(point => (
                <li key={point} className="text-sm font-light leading-tight mb-2">
                  {point}
                </li>
              ))}
            </ul>
          </div>
          <div className="flex-1 flex justify-center">
            <img src={HomePickup} alt="Trip Management"  className='w-24 h-24 md:w-48 md:h-48 object-contain mb-4'/>
          </div>
        </div>
        <h3 className="transport-protocols-subheading  font-extrabold text-lg mb-4">
          PERSONAL DETAILS
        </h3>
        <div className="flex flex-row w-124 mb-8 justify-between">
          <div className="flex-1 flex justify-center">
            <img src={Settings} alt="Personal Details" className='w-24 h-24 md:w-48 md:h-48 object-contain mb-4' />
          </div>
          <div className="w-3/5 flex-1 mb-2 mt-0 md:mb-2 mt-0  justify-center pb-8">
            <h4 className="text-gray-700 font-bold text-md md:text-xl">
              How to update your details
            </h4>
            <ul className="list-disc pt-0 mt-0 mb-0 pl-4">
              {personalPoints.map(point => (
                <li key={point} className="text-sm font-light leading-tight mb-2">
                  {point}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransportProtocols;
