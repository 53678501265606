import React, { useState, useCallback } from 'react';

const ConfirmationModal = React.memo(({ handleYesClick, children }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOpenModalClick = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const handleCloseModalClick = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  return (
    <>
      <button onClick={handleOpenModalClick} className=''>
        {children}
      </button>

      <div className={`fixed inset-0 flex items-center justify-center z-50 ${isModalVisible ? '' : 'hidden'}`}>
        <div className="modal-bg absolute inset-0 bg-gray-800 opacity-75" onClick={handleCloseModalClick}></div>
        <div className="modal relative bg-white p-6 rounded-lg shadow-lg w-64">
          <p className="mb-4" style={{ whiteSpace: 'pre-wrap' }}>
            The action you are about to take cannot be undone. Do you want to continue?{' '}
          </p>
          <div className="flex justify-around">
            <button
              onClick={() => {
                handleCloseModalClick();
              }}
              className="bg-gray-500 hover:bg-gray-300 text-white font-bold py-2 px-4 rounded mr-2"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                handleYesClick();
                handleCloseModalClick();
              }}
              className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </>
  );
});

export default ConfirmationModal;
