import React, { useState } from 'react';

const StatsCard = ({ data, theme, range }) => {
  const statsList = [
    {
      id: 1,
      title: 'Trips',
      icon: 'fas fa-car',
      linkTo: '/trips',
      value: data.total_groups,
    },
    {
      id: 2,
      title: 'Kilometers',
      icon: 'fas fa-tachometer-alt',
      linkTo: '/trips',
      value: data.total_distance.toFixed(2),
    },
    {
      id: 3,
      title: 'Cancellations',
      icon: 'fas fa-ban',
      linkTo: '/trips',
      value: data.total_cancellations,
    },
    {
      id: 4,
      title: 'Bookings',
      icon: 'far fa-bookmark',
      linkTo: '/trips',
      value: data.individual_bookings,
    },
    {
      id: 5,
      title: 'Costs',
      icon: 'fas fa-dollar-sign',
      linkTo: '/trips',
      value: `R ${data.total_costs.toFixed(2)}`,
    },
  ];

  const [isDropdownOpen, setIsDropdownOpen] = useState(
    statsList.map(() => false)
  );

  const toggleDropdown = (index) => {
    setIsDropdownOpen((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  return (
    <div className="summary grid grid-cols-1 gap-4 md:grid-cols-5">
      {statsList.map((stat, index) => (
        <div
          key={stat.id}
          className="trips p-4 rounded-md shadow-md bg-white relative mt-4 hover:shadow-xl transform hover:scale-105 transition-transform duration-300">
          <div className="flex justify-between w-full">
            <div className="float-left flex">
              <div
                style={{ backgroundColor: theme }}
                className="w-6 p-3 h-6 rounded-full bg-opacity-50 flex items-center justify-center">
                <i
                  className={`${stat.icon} text-base text-white`}
                  aria-hidden="true"></i>
              </div>
              <h2 className="text-xl ml-2 text-gray-800 font-normal">
                <span>
                  {stat.title}
                  {stat.id == 5 ? (
                    <sup className="mt-4 font-thin text-xxs">Estimated</sup>
                  ) : (
                    ''
                  )}
                </span>
              </h2>
            </div>

            <div className="text-zinc-800 -mt-4 right-0 pr-2">
              <div className="float-right relative inline-block">
                <button
                  className={`${
                    stat.id !== 1 ? 'text-gray-300' : 'text-gray-800'
                  } b-0 px-2 -mr-2 pt-0 outline-none cursor-pointer text-center items-center`}
                  onClick={() => toggleDropdown(index)}
                  disabled={stat.id == 1 ? false : true}>
                  &#8230;
                </button>

                {isDropdownOpen[index] && (
                  <div className="overflow-auto absolute py-1 right-0 mt-2 min-w-40 rounded-md shadow-xl bg-white">
                    <a
                      href={stat.linkTo}
                      className="py-2 px-4 w-40 no-underline flex flex-row justify-between items-center align-middle hover:bg-gray-100">
                      <i
                        className="fa fa-eye mr-2 text-gray-700"
                        aria-hidden="true"></i>
                      <p className="text-gray-700"> View Details</p>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex text-2xl font-semibold justify-start text-gray-800">
            {stat.value}
          </div>
          <div className="flex text-sm  font-thin justify-start text-gray-800">
            <span className="selectedFilterText">{range}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StatsCard;
