import React,{useEffect, useState} from "react";
import './tracking.scss'
import actionCable from 'actioncable'
const socketUrl = process.env.REACT_APP_SECURE_ACTION_CABLE_URL;
const CableApp = {};
CableApp.cable = actionCable.createConsumer(socketUrl);

let driverMarkers = {};

const styleArray =  [
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e9e9e9',
            },
            {
                lightness: 17,
            },
        ],
    },
    {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e4e4e4',
            },
            {
                lightness: 20,
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#efefef',
            },
            {
                lightness: 17,
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#efefef',
            },
            {
                lightness: 29,
            },
            {
                weight: 0.2,
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
            {
                color: '#efefef',
            },
            {
                lightness: 18,
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
            {
                color: '#efefef',
            },
            {
                lightness: 16,
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e4e4e4',
            },
            {
                lightness: 21,
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
            {
                color: '#dedede',
            },
            {
                lightness: 21,
            },
        ],
    },
    {
        elementType: 'labels.text.stroke',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#ffffff',
            },
            {
                lightness: 16,
            },
        ],
    },
    {
        elementType: 'labels.text.fill',
        stylers: [
            {
                saturation: 36,
            },
            {
                color: '#333333',
            },
            {
                lightness: 40,
            },
        ],
    },
    {
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f2f2f2',
            },
            {
                lightness: 19,
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#fefefe',
            },
            {
                lightness: 20,
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#fefefe',
            },
            {
                lightness: 17,
            },
            {
                weight: 1.2,
            },
        ],
    },
];

const FleetTracking = ({
    fleet_id
}) =>{
    
    var map = null; 

    useEffect(()=>{
        // her get the grouped trip detsils
        var mapOptions = {
            center: {lat: -26.1994, lng: 27.9925},
            zoom: 14,
            styles: styleArray
        };
        map = new google.maps.Map(document.getElementById('mapTracking'), mapOptions);
    },[]);

    useEffect(()=>{
        CableApp.cable.subscriptions.create
        (
            {
                channel: 'FleetLocationsChannel',
                fleet_id,
            },
            {
                received: async (driver_data) => {
                    if(map != null)
                    {
                        if(Object.entries(driverMarkers).length == 0)
                        {
                            map.setCenter({lat: driver_data.location.latitude, lng: driver_data.location.longitude});
                        }

                        if( !driverMarkers.hasOwnProperty(driver_data.id))
                        {
                            addDriverMarker(driver_data);
                        }
                        else{
                            driverMarkers[driver_data.id].setPosition({lat: driver_data.location.latitude, lng: driver_data.location.longitude});
                        }

                    }
                }
            }
        )
    },[]);
    
    const addDriverMarker = (driver_data) =>
    {
        driverMarkers[driver_data.id] = new google.maps.Marker({
            position:{
            lat: driver_data.location.latitude,
            lng: driver_data.location.longitude
            },
            map: map,
            icon: 'http://maps.google.com/mapfiles/kml/pal4/icon54.png'

        });

        driverMarkers[driver_data.id]._infowindow = new google.maps.InfoWindow({
            content: driver_data.name,
            shouldFocus: false
        });
      
        google.maps.event.addListener(driverMarkers[driver_data.id], 'click', function() {
            this._infowindow.open({ map, shouldFocus: false},this);
        });
          // open marker on load
        google.maps.event.trigger(driverMarkers[driver_data.id], 'click');
    }

    return(
        <div className="tracking">
            <div id="mapTracking" className="tracking_map"></div>
        </div>
    )
}
export default FleetTracking;