import React, { useState, useEffect } from 'react';
const TIME_SLOTS = [
  '00:00AM',
  '00:30AM', 
  '01:00AM',
  '01:30AM',
  '02:00AM',
  '02:30AM',
  '03:00AM',
  '03:30AM',
  '04:00AM',
  '04:30AM',
  '05:00AM',
  '05:30AM',
  '06:00AM',
  '06:30AM',
  '07:00AM',
  '07:30AM',
  '08:00AM',
  '08:30AM',
  '09:00AM',
  '09:30AM',
  '10:00AM',
  '10:30AM',
  '11:00AM',
  '11:30AM',
  '12:00PM',
  '12:30PM',
  '13:00PM',
  '13:30PM',
  '14:00PM',
  '14:30PM',
  '15:00PM',
  '15:30PM',
  '16:00PM',
  '16:30PM',
  '17:00PM',
  '17:30PM',
  '18:00PM',
  '18:30PM',
  '19:00PM',
  '19:30PM',
  '20:00PM',
  '20:30PM',
  '21:00PM',
  '21:30PM',
  '22:00PM',
  '22:30PM',
  '23:00PM',
  '23:30PM',
];

const TimePickerDropDown = ({
  setTimeSlot,
  handleCloseModal,
  timeSlot,
  trips,
}) => {
  const [selectedSlot, setSelectedSlot] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredTimeslots = TIME_SLOTS.filter((slot) => {
    return slot.toLowerCase().includes(searchQuery.toLowerCase());
  });

  useEffect(() => {
    setSelectedSlot(timeSlot);
  }, [timeSlot]);

  const onSave = (slot) => {
    setTimeSlot(slot);
    handleCloseModal();
  };

  const onCancel = () => {
    setTimeSlot('');
    setSelectedSlot('');
    handleCloseModal();
  };

  const tripAvailable = (slot) => {
    return trips.some((trip) => trip.timeslot === slot);
  };

  const tripCount = (slot) => {
    return trips.filter((trip) => trip.timeslot == slot).length;
  };

  return (
    <div
      className="w-48"
      style={{ userSelect: 'none' }}
      data-testid={'time-dropdown'}>
      <div
        className="bg-white rounded-lg shadow-lg m-1 p-2 overflow-y-auto"
        style={{ maxHeight: '200px' }}>
        <input
          type="text"
          className="px-2 py-1 text-xs text-gray-700 w-full"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearchInputChange}
        />
        <div className="flex text-gray-800 text-sm w-full justify-center">
          <div className="h-80 overflow-auto mr-2 w-full">
            {filteredTimeslots.map((slot) => (
              <div
                key={slot}
                className={`cursor-pointer rounded hover:bg-gray-200 px-1 my-1 text-center w-full ${
                  selectedSlot === slot
                    ? 'bg-purple-400 rounded text-white hover:text-black'
                    : tripAvailable(slot)
                    ? 'bg-purple-100'
                    : ''
                }`}
                onClick={() => onSave(slot)}>
                {slot} {tripCount(slot) > 0 ? `(${tripCount(slot)})` : ''}
              </div>
            ))}
          </div>
        </div>
        <hr className="my-1" />
        <div className="flex justify-center">
          <button
            data-testid={'btn-clear'}
            type="button"
            className="text-purple-400 hover:text-purple-600 text-xs px-2 py-0 rounded-full border border-purple-400 mr-2"
            onClick={onCancel}>
            Clear
          </button>
        </div>
      </div>
    </div>
  );
};

export default TimePickerDropDown;
