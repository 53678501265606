import React from "react";

import Input from './Input';
import chevDown from '../../../assets/images/icon-chev-down.svg';

export default {
    title: 'Form Elements | Input',
};

export const BasicTextInput = () => (
    <div style={{padding: '20px'}}>
        <Input placeholder={'Placeholder ...'}/>
        <br/>
        <Input value={'Provided value'} onChange={() => {
        }}/>
    </div>
);

export const BasicTextInputWithSuffix = () => (
    <div style={{padding: '20px'}}>
        <Input placeholder={'With a text suffix'} suffix={'ml'}/>
        <br/>
        <Input placeholder={'With an icon suffix'} suffix={<img src={chevDown} alt="suffix"/>}/>
    </div>
);
