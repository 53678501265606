/** Tool to check for undefined */
export const isUndefined = (value) => typeof value === 'undefined';

/** Tool to check for empty array */
export const isEmpty = (item) => {
  if (Array.isArray(item)) {
    return item.length === 0;
  } else {
    return Object.keys(item).length === 0;
  }
};

/**
 * Utililty function for setting class names onto the document.body.
 *
 * @param {string} classNames - string containing list of class names to apply.
 */
export const setBodyClass = (classNames) => {
  if (isUndefined(classNames)) {
    document.body.classList = [];
    return;
  }

  document.body.classList.add(classNames);
};

/** Tool to check nulls */
export const isNull = (value) => value === null;

/** Check for empty strings */
export const isEmptyStr = (value) => value === '';

/** A placeholder function that does nothing. */
export const noOp = () => {};

/** Used by event handlers to prevent default event behaviour. */
export const preventDefault = (e) => e.preventDefault();

/** An asynchronous delay function */
export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

/**
 * Joins a `baseClassName` together with an array of associated class `modifiers`.
 *
 * @param {string} baseClassName - the class name of the component, also used for
 *                                 prefixing modifiers
 * @param {object} modifiers - object with keys being the modifier name, their
 *                             values being a boolean representing whether the
 *                             modifier is active
 *
 * @example <caption>Get class names for element 'button' with modifiers 'large',
 *                   'disabled'.</caption>
 * getClassNames('button', {large: true, disabled: true})
 * // returns 'button button--large button--disabled'
 *
 */
export function getClassNames(baseClassName, modifiers) {
  const classes = [baseClassName];

  if (modifiers) {
    Object.entries(modifiers).forEach(([name, active]) => {
      if (!active) return;

      classes.push(`${baseClassName}--${name}`);
    });
  }

  return classes.join(' ');
}

/**
 * Joins a `baseClassName` together with an array of associated class `modifiers` and their variations.
 *
 * @param {string} baseClassName - the class name of the component, also used for
 *                                 prefixing modifiers
 * @param {object} modifiers - object with keys being the modifier name, their
 *                             values being a string representing a variation
 *
 * @example <caption>Get class names for element 'button' with modifiers 'size'
 * and their variations,
 *
 * getVariationClassNames('icon', {size: 'md'})
 * // returns 'icon icon--size--md'
 *
 */
export function getVariationClassNames(baseClassName, modifiers) {
  const classes = [baseClassName];

  Object.entries(modifiers).forEach(([name, value]) => {
    classes.push(`${baseClassName}--${name}--${value}`);
  });

  return classes.join(' ');
}

/**
 * Generate a random ID, to avoid using `index` as `key`.
 * Converts a random seed to base 36 (numbers & letters),
 * then takes the first 9 chars after decimal.
 */
export function generateId() {
 return `_${ Math.random().toString(36).substr(2, 9) }`;
}
