import React from 'react';

import Button from "./Button";

export default {
    title: 'Form Elements | Button',
};

export const NormalButton = () => (
    <div style={{padding: '20px'}}>
        <Button>I have a purple body</Button>
    </div>
);

export const HollowButton = () => (
    <div style={{padding: '20px'}}>
        <Button hollow>Oh no! Where did my body go?</Button>
    </div>
);

export const ExpandedButton = () => (
    <>
        <div style={{padding: '20px', width: '700px'}}>
            <Button expand>I fit my parent container</Button>
        </div>
        <div style={{padding: '20px', width: '500px'}}>
            <Button hollow expand>I fit mine too</Button>
        </div>
    </>
);
