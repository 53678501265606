import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react';

const TIMESLOTS = [
  '',
  '00:00AM',
  '00:30AM', 
  '01:00AM',
  '01:30AM',
  '02:00AM',
  '02:30AM',
  '03:00AM',
  '03:30AM',
  '04:00AM',
  '04:30AM',
  '05:00AM',
  '05:30AM',
  '06:00AM',
  '06:30AM',
  '07:00AM',
  '07:30AM',
  '08:00AM',
  '08:30AM',
  '09:00AM',
  '09:30AM',
  '10:00AM',
  '10:30AM',
  '11:00AM',
  '11:30AM',
  '12:00PM',
  '12:30PM',
  '13:00PM',
  '13:30PM',
  '14:00PM',
  '14:30PM',
  '15:00PM',
  '15:30PM',
  '16:00PM',
  '16:30PM',
  '17:00PM',
  '17:30PM',
  '18:00PM',
  '18:30PM',
  '19:00PM',
  '19:30PM',
  '20:00PM',
  '20:30PM',
  '21:00PM',
  '21:30PM',
  '22:00PM',
  '22:30PM',
  '23:00PM',
  '23:30PM',
];

/**
 *
 * @param {string} timeSlot - The current timeslot
 * @param {function} setTimeSlot - The function to set the timeslot
 * @param {string} selectedDay - The selected day
 * @param {boolean} isAdhoc - Whether the booking is adhoc or not
 * @returns {JSX.Element} - The timeslot picker component
 */
const InboundTimeSelector = ({
  timeSlot,
  setTimeSlot,
  selectedDay,
  isAdhoc,
  selectedEmployees,
}) => {
  const [viewOptions, setViewOptions] = useState(false);

  const dropdownRef = useRef(null);
  const boxRef = useRef(null);

  const filteredTimeslots = TIMESLOTS.filter((slot) => {
    const now = moment();
    const tomorrow = moment().add(1, 'days');
    const selectedDate = moment(selectedDay, 'DD-MM-YYYY');
    if (isAdhoc) {
      return true; // Return all timeslots for adhoc bookings
    } else if (slot == '' || slot == 'Off' || slot == 'Clear') {
      // If the slot is empty, return it since it works as the clear option
      return true;
    } else if (selectedDate.isSame(tomorrow, 'day')) {
      // Check if it's tomorrow
      const twentyTwoHoursAgo = now.subtract(22, 'hours'); //Limit to be 2 hours into the next day by subtracting 22 from today.
      const optionTime = moment(slot, 'hh:mmA');
      return optionTime >= twentyTwoHoursAgo;
    } else if (selectedDate.isSame(now, 'day')) {
      //Check if it's today
      const slotTime = moment(slot, 'hh:mmA');
      return slotTime.hour() >= now.hour() + 2; //Return the timeslots 2 hours from now onwards
    } else {
      return true;
    }
  });

  const toggleView = () => {
    setViewOptions(!viewOptions);
  };

  const handleSelect = (slot) => {
    setViewOptions(false);
    if (slot === '' || slot === 'Clear') {
      setTimeSlot(null);
    } else {
      setTimeSlot(slot);
    }
  };

  useEffect(() => {
    // Function to handle the click outside the component
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        boxRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !boxRef.current.contains(event.target)
      ) {
        setViewOptions(false);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        className="flex justify-between items-center bg-white text-gray-500 rounded-lg px-2 py-1 relative"
        onClick={selectedEmployees?.length > 0 ? toggleView : null}
        style={{ userSelect: 'none' }}
        ref={boxRef}>
        <div className="text-gray-600" style={{ fontSize: '14px' }}>
          {timeSlot ? timeSlot : 'Inbound Timeslot'}
        </div>
        <FontAwesomeIcon icon={faClock} className="text-gray-600" />
        {viewOptions && (
          <div
            ref={dropdownRef}
            className="absolute top-full left-0 bg-white rounded-lg shadow-md border border-gray-300 w-full overflow-y-auto justify-center items-center z-40"
            style={{ top: 'calc(100%)', maxHeight: '250px' }}>
            {filteredTimeslots.map((slot, index) => (
              <div
                key={index}
                className="px-4 py-1 text-gray-600 font-normal cursor-pointer hover:bg-gray-100"
                style={{ fontSize: '14px', minHeight: '28px' }}
                onClick={() => {
                  handleSelect(slot);
                }}>
                {slot != '' ? slot : 'Clear'}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default InboundTimeSelector;
