import React, { useRef, useState, useEffect } from 'react';
import {
  GoogleMap,
  InfoWindow,
  Marker,
  MarkerClusterer,
} from '@react-google-maps/api';
import { isEmpty } from '../../../../tools/helpers';
import { stylesArr } from './MapStyles';
import { Steps } from 'intro.js-react';

const path = 'M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0';

const renderMapMarkers = (
  color,
  locations,
  handleMarkerClick,
  handleMouseOver,
  handleMouseExit,
  bound,
) =>
  !isEmpty(locations) && (
    <MarkerClusterer averageCenter={true} gridSize={2} maxZoom={18}>
      {(clusterer) =>
        locations.map((item, index) => (
          <Marker
            key={item.id}
            position={{
              lat:
                bound === 'Inbound'
                  ? item.location_latitude
                  : item.destination_latitude,
              lng:
                bound === 'Inbound'
                  ? item.location_longitude
                  : item.destination_longitude,
            }}
            index={index}
            icon={{
              path: path,
              fillColor: color,
              fillOpacity: 1,
              scale: 0.2,
              strokeColor: 'rgba(255, 255, 255, 0.5)',
              strokeWeight: 15,
            }}
            clusterer={clusterer}
            onClick={(e) => handleMarkerClick(e, item, index)}
            onMouseOver={(e) => handleMouseOver(e, item)}
            onMouseOut={(e) => handleMouseExit(e, item)}
          />
        ))
      }
    </MarkerClusterer>
  );

const renderGroupedMapMarkers = (
  color,
  locations,
  handleGroupMarkerClick,
  handleMouseOver,
  handleMouseExit,
  bound,
) => {
  const groupArr = [];
  let labelIndex = -1;

  const getGroupLabel = (labelIndex) =>{
    const labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    if(labelIndex < labels.length)
    {
      return labels[labelIndex]
    }
    else
    {
      const index = Math.floor(labelIndex/labels.length)-1;
      let _customLabel = labels[index];
      const remainder = labelIndex  % labels.length;
      _customLabel += labels[remainder];
      return _customLabel;
    }
  }

  for (const [group_id, trips] of Object.entries(locations)) {
    if (!isEmpty(trips)) {
      groupArr.push(
        <MarkerClusterer
          key={group_id}
          averageCenter={true}
          gridSize={2}
          maxZoom={18}
        >
          {(clusterer) => {
            labelIndex++;
            return trips.map((trip, index) => (
              <Marker
                key={trip.id}
                position={{
                  lat:
                    bound === 'Inbound'
                      ? trip.location_latitude
                      : trip.destination_latitude,
                  lng:
                    bound === 'Inbound'
                      ? trip.location_longitude
                      : trip.destination_longitude,
                }}
                index={index}
                label={{
                  text: getGroupLabel(labelIndex),
                  className: 'label',
                  fontSize: '14px',
                  fontWeight: '700',
                  color: 'white',
                }}
                icon={{
                  path: path,
                  fillColor: color,
                  fillOpacity: 1,
                  scale: 0.2,
                  strokeColor: 'rgba(255, 255, 255, 0.5)',
                  strokeWeight: 15,
                }}
                clusterer={clusterer}
                onClick={(e) => handleGroupMarkerClick(e, trip, index)}
                onMouseOver={(e) => handleMouseOver(e, trip)}
                onMouseOut={(e) => handleMouseExit(e, trip)}
              />
            ));
          }}
        </MarkerClusterer>,
      );
    }
  }
  return groupArr;
};

const renderInfoWindow = (
  {
    location_latitude,
    location_longitude,
    destination_latitude,
    destination_longitude,
    full_name,
    location,
    destination,
  },
  bound,
) => {
  if (bound === 'Inbound') {
    return (
      location_latitude && (
        <InfoWindow
          position={{
            lat: location_latitude,
            lng: location_longitude,
          }}
        >
          <>
            <h4>{full_name}</h4>
            <h4>{location}</h4>
          </>
        </InfoWindow>
      )
    );
  } else {
    return (
      destination_latitude && (
        <InfoWindow
          position={{
            lat: destination_latitude,
            lng: destination_longitude,
          }}
        >
          <>
            <h4>{full_name}</h4>
            <h4>{destination}</h4>
          </>
        </InfoWindow>
      )
    );
  }
};

const renderPassengers = (locations, type, handleUngroup) => {
  return (
    <>
      <b className="text-xs font-bold text-gray-800">
        Selected {type === 'selected' ? `Passengers` : 'Group'}
        {': '}
        {locations.length}
      </b>
      <ul className="h-24 mt-4">
        {locations.map((loc) => {
          return (
            <li
              className="flex justify-between text-xs text-gray-800"
              key={loc.id}
            >
              {loc.full_name}
              {type === 'group' && (
                <button
                  className="underline text-gray-400 hover:text-gray-800"
                  onClick={(e) => handleUngroup(e, loc)}
                >
                  Remove
                </button>
              )}
            </li>
          );
        })}
      </ul>
    </>
  );
};

const renderInfoPanel = (
  selected,
  selectedGroup,
  handleCreateGroup,
  handleReGroup,
  handleUngroup,
) => {
  if (!isEmpty(selected) && !isEmpty(selectedGroup)) {
    return (
      <>
        {renderPassengers(selectedGroup, 'group', handleUngroup)}
        {renderPassengers(selected, 'selected')}

        <button
          className="bg-gray-800 text-white text-sm px-3 py-2 rounded-md hover:bg-gray-700 mt-10"
          onClick={handleReGroup}
        >
          Re-Group Passengers {`(${selected.length + selectedGroup.length})`}
          <i className="fas fa-random ml-2" />
        </button>
      </>
    );
  }

  if (!isEmpty(selected)) {
    return (
      <>
        {renderPassengers(selected, 'selected')}
        <button
          className="bg-gray-800 text-white text-sm px-3 py-2 rounded-md hover:bg-gray-700 mt-10"
          onClick={handleCreateGroup}
        >
          Group Passengers
          <i className="fas fa-random ml-2" />
        </button>
      </>
    );
  }

  if (!isEmpty(selectedGroup)) {
    return renderPassengers(selectedGroup, 'group', handleUngroup);
  }
};

const getCenter = (trips, bound, groupedTrips) => {
  if (isEmpty(trips) && isEmpty(groupedTrips)) {
    return {
      lat: -28.48322,
      lng: 24.676997,
    };
  }
  if (isEmpty(trips)) {
    let c = {};
    const ids = Object.keys(groupedTrips);
    if (bound === 'Inbound') {
      ids.forEach((id) => {
        if (!isEmpty(groupedTrips[id])) {
          c = {
            lat: groupedTrips[id][0].destination_latitude,
            lng: groupedTrips[id][0].destination_longitude,
          };
        }
      });
      if (isEmpty(c)) {
        return {
          lat: -28.48322,
          lng: 24.676997,
        };
      } else {
        return c;
      }
    } else if (bound === 'Outbound') {
      ids.forEach((id) => {
        if (!isEmpty(groupedTrips[id])) {
          c = {
            lat: groupedTrips[id][0].location_latitude,
            lng: groupedTrips[id][0].location_longitude,
          };
        }
      });
      if (isEmpty(c)) {
        return {
          lat: -28.48322,
          lng: 24.676997,
        };
      } else {
        return c;
      }
    }
  } else {
    if (bound === 'Inbound') {
      return {
        lat: trips[0].destination_latitude,
        lng: trips[0].destination_longitude,
      };
    } else if (bound === 'Outbound') {
      return {
        lat: trips[0].location_latitude,
        lng: trips[0].location_longitude,
      };
    }
  }
};

const TripMap = ({
  trips,
  handleMarkerClick,
  handleGroupMarkerClick,
  selectedGroup,
  selected,
  groupedTrips,
  handleCreateGroup,
  handleReGroup,
  bound,
  handleUngroup,
  selectedAddress,
  trained
}) => {

  const [introState, setIntroState] = useState({
      stepsEnabled: !trained,
      initialStep: 0,
      options: {
        showProgress: true,
        showBullets: false,
        tooltipClass: 'etapathTooltip',
      },
      steps: [
          {
            element: '.groupingIntro',
            title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Groupings",
            intro: "Groupings are made on the map. Select the company address from the drop down and whether the trips are inbound or outbound trips."
          },
          {
              element: '.introMap',
              title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Grouping Map",
              intro: "The passengers' locations will appear on the map as pins. Select a number of pins together and select the create grouping button to create a grouping. <br><br> Groupings can be altered by adding or removing specific passenger pins from the grouping."
          },
      ]
  })
  const [infoSelect, setInfoSelect] = useState({});
  const [center, setCenter] = useState(getCenter(trips, bound, groupedTrips));

  const mapRef = useRef(null);
  const mapStyles = {
    height: '88vh',
    width: '100%',
  };

  const handleLoad = (map) => {
    mapRef.current = map;
  };

  const handleCenter = () => {
    if (!mapRef.current) return;
    const newPos = mapRef.current.getCenter().toJSON();
    setCenter(newPos);
  };

  const handleMouseOver = (_e, item) => {
    setInfoSelect(item);
  };

  const handleMouseExit = () => {
    setInfoSelect({});
  };

  useEffect(() => {
    setCenter(getCenter(trips, bound, groupedTrips));
  }, [selectedAddress, bound]);

  return (
    <>
      <div className='introMap'>
        <GoogleMap
          options={{
            styles: stylesArr,
            disableDefaultUI: true,
          }}
          mapContainerStyle={mapStyles}
          zoom={13}
          center={center}
          onLoad={handleLoad}
          onDragEnd={handleCenter}
        >
          <div className="absolute right-0 bottom-0 w-64 z-10 bg-white rounded-md shadow-md p-4 m-4">
            <h3 className="text-sm font-medium text-gray-700">
              Grouping Information
            </h3>
            {renderInfoPanel(
              selected,
              selectedGroup,
              handleCreateGroup,
              handleReGroup,
              handleUngroup,
            )}
          </div>
          <button
            className="absolute text-gray-700 left-0 bottom-0 z-10 bg-white rounded-md shadow-md p-4 m-4"
            onClick={() => setCenter(getCenter(trips, bound, groupedTrips))}
          >
            Re-center
          </button>
          <Marker
            position={getCenter(trips, bound, groupedTrips)}
            icon={{
              path:
                'M17.0710678,2.92893219 C20.9763107,6.83417511 20.9763107,13.1658249 17.0710678,17.0710678 L10,24.1421356 L2.92893219,17.0710678 C-0.976310729,13.1658249 -0.976310729,6.83417511 2.92893219,2.92893219 C6.83417511,-0.976310729 13.1658249,-0.976310729 17.0710678,2.92893219 Z M10,5 C7.23857625,5 5,7.23857625 5,10 C5,12.7614237 7.23857625,15 10,15 C12.7614237,15 15,12.7614237 15,10 C15,7.23857625 12.7614237,5 10,5 Z',
              fillColor: 'black',
              fillOpacity: 1,
              scale: 2,
              strokeColor: 'black',
              strokeWeight: 0.2,
            }}
          />
          {renderMapMarkers(
            'rgba(59, 130, 246, 1)',
            trips,
            handleMarkerClick,
            handleMouseOver,
            handleMouseExit,
            bound,
          )}
          {renderMapMarkers(
            'rgba(16, 185, 129, 1)',
            selected,
            handleMarkerClick,
            handleMouseOver,
            handleMouseExit,
            bound,
          )}
          {renderMapMarkers(
            'rgba(16, 185, 129, 1)',
            selectedGroup,
            handleGroupMarkerClick,
            handleMouseOver,
            handleMouseExit,
            bound,
          )}
          {renderGroupedMapMarkers(
            'rgba(139, 92, 246, 1)',
            groupedTrips,
            handleGroupMarkerClick,
            handleMouseOver,
            handleMouseExit,
            bound,
          )}
          {renderInfoWindow(infoSelect, bound)}
        </GoogleMap>
      </div>
      <Steps
        enabled={introState.stepsEnabled}
        steps={introState.steps}
        initialStep={introState.initialStep}
        onExit={() => {}}
        options={introState.options}
      />
    </>
  );
};
export default TripMap;
