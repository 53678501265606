import React from 'react';

const TIME_SLOTS = [
  '00:00AM',
  '00:30AM', 
  '01:00AM',
  '01:30AM',
  '02:00AM',
  '02:30AM',
  '03:00AM',
  '03:30AM',
  '04:00AM',
  '04:30AM',
  '05:00AM',
  '05:30AM',
  '06:00AM',
  '06:30AM',
  '07:00AM',
  '07:30AM',
  '08:00AM',
  '08:30AM',
  '09:00AM',
  '09:30AM',
  '10:00AM',
  '10:30AM',
  '11:00AM',
  '11:30AM',
  '12:00PM',
  '12:30PM',
  '13:00PM',
  '13:30PM',
  '14:00PM',
  '14:30PM',
  '15:00PM',
  '15:30PM',
  '16:00PM',
  '16:30PM',
  '17:00PM',
  '17:30PM',
  '18:00PM',
  '18:30PM',
  '19:00PM',
  '19:30PM',
  '20:00PM',
  '20:30PM',
  '21:00PM',
  '21:30PM',
  '22:00PM',
  '22:30PM',
  '23:00PM',
  '23:30PM',
];

const renderTimeslots = (selectedTimeslot, handleTimeslotSelect, allSlot) =>
  TIME_SLOTS.map((timeslot) => (
    <div
      className={
        timeslot === selectedTimeslot && !allSlot
          ? 'flex w-full bg-gray-100 border border-gray-200 rounded p-2 mt-1 cursor-pointer bg-gray-800 text-white'
          : 'flex w-full bg-gray-100 border border-gray-200 rounded p-2 mt-1 cursor-pointer text-gray-700 hover:bg-gray-300'
      }
      key={timeslot}
      onClick={() => handleTimeslotSelect(timeslot)}>
      <div className="self-center w-full text-center font-medium leading-none">
        <p>
          <span className="text-md font-medium">
            {timeslot.substring(0, 5)}
          </span>
          <span className="text-xs mt-1">{timeslot.substring(5)}</span>
        </p>
      </div>
    </div>
  ));

const TimeslotColumn = ({
  handleTimeslotSelect,
  selectedTimeslot,
  filter,
  handleAllSlotChange,
  allSlot,
}) => {
  return (
    <div className="w-48 mr-5 timeSlot">
      <h3 className="w-full text-center text-sm font-bold text-gray-800 h-8 leading-loose">
        Timeslot
      </h3>
      {filter !== 'unassigned' && (
        <button
          className={
            allSlot
              ? 'w-full bg-gray-800 text-xs text-white font-medium py-1 px-2 rounded-lg mt-2'
              : 'w-full bg-gray-300 text-xs text-gray-700 font-medium py-1 px-2 rounded-lg mt-2'
          }
          type="button"
          onClick={handleAllSlotChange}>
          All
        </button>
      )}
      <div
        className="flex flex-col mt-2 overflow-scroll bg-white border border-gray-200 rounded-lg p-2"
        style={{ height: '70vh' }}>
        {renderTimeslots(selectedTimeslot, handleTimeslotSelect, allSlot)}
      </div>
    </div>
  );
};

export default TimeslotColumn;
