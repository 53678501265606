import React from "react";
import {string} from "prop-types";

import './card.scss';

const Card = ({title, children}) => (
    <div className="card">
        <h2 className="card__title">{title}</h2>
        <div className="card__content">
            { children }
        </div>
    </div>
);

Card.propTypes = {
    title: string.isRequired,
};

export default Card;
