import React from "react";

function UnsavedChanges({ handleNoClick, handleYesClick }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="modal-bg absolute inset-0 bg-gray-800 opacity-75"></div>
      <div className="modal relative bg-white p-6 rounded-lg shadow-lg w-64">
        <p className="mb-4">
          You have unsaved changes. Do you want to continue?{" "}
        </p>
        <div className="flex justify-around">
          <button
            onClick={handleNoClick}
            className="bg-gray-500 hover:bg-gray-300 text-white font-bold py-2 px-4 rounded mr-2"
          >
            Cancel
          </button>
          <button
            onClick={handleYesClick}
            className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded "
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default UnsavedChanges;
