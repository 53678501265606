import React, { useState } from "react"

const renderInfoPanel = (
    selected,
    selectedGroup,
    handleCreateGroup,
    handleReGroup,
    handleUngroup,
  ) => {
    if (!isEmpty(selected) && !isEmpty(selectedGroup)) {
      return (
        <>
          {renderPassengers(selectedGroup, 'group', handleUngroup)}
          {renderPassengers(selected, 'selected')}
  
          <button
            className="bg-gray-800 text-white text-sm px-3 py-2 rounded-md hover:bg-gray-700 mt-10"
            onClick={handleReGroup}
          >
            Re-Group Passengers {`(${selected.length + selectedGroup.length})`}
            <i className="fas fa-random ml-2" />
          </button>
        </>
      );
    }
  
    if (!isEmpty(selected)) {
      return (
        <>
          {renderPassengers(selected, 'selected')}
          <button
            className="bg-gray-800 text-white text-sm px-3 py-2 rounded-md hover:bg-gray-700 mt-10"
            onClick={handleCreateGroup}
          >
            Group Passengers
            <i className="fas fa-random ml-2" />
          </button>
        </>
      );
    }
  
    if (!isEmpty(selectedGroup)) {
      return renderPassengers(selectedGroup, 'group', handleUngroup);
    }
  };

  const GroupingMenu = () => {
    return(
        <div className="w-64 z-10 bg-white rounded-md shadow-md p-4 m-4">
            <h3 className="text-sm font-medium text-gray-700">
              Grouping Information
            </h3>
            {/* {renderInfoPanel(
              selected,
              selectedGroup,
              handleCreateGroup,
              handleReGroup,
              handleUngroup,
            )} */}
          </div>
    );
  }

  export default GroupingMenu