import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faTimesCircle, faUserMinus } from '@fortawesome/free-solid-svg-icons'

const Options = ({type, setShowOptions, setCancelTrip, setUngroupTrip, groupId, tripStatus, role, openInGoogleMaps, pageType}) => {

    const handleCancelTripClick = () => {
        // handle cancel trip click event here
        setShowOptions(false)
        setCancelTrip(true)
    }

    const handleViewTripClick = () => {
        setShowOptions(false);
        const currentUrl = window.location.href;
        const urlWithGroupId = `${currentUrl}/${groupId}`;
        window.open(urlWithGroupId, '_blank');
    }

    const handleOpenInGoogleMaps = () => {
        setShowOptions(false);
        openInGoogleMaps(groupId);
    }
    const handleUngroupAllClick = () => {
        setShowOptions(false)
        setUngroupTrip(true)
    }

    
    return(
        <div className='bg-white w-56 flex rounded shadow p-2 justify-between ' >
            {
                (type == 'ungrouped') &&
                <div className='w-full'>
                    <button className="flex rounded p-1 w-full space-x-2 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none  items-center"
                            onClick={handleCancelTripClick}        
                    >
                        <FontAwesomeIcon 
                            icon={faTimesCircle} className="text-gray-600 mx-1 text-sm" />
                        <span className="text-gray-600  text-sm">Cancel Trip</span>
                    </button>
                </div>
            }
            {
                (type == 'grouped') &&
                <div className='w-full'>
                   { 
                    (role != 'fleetmanager') &&
                    <button 
                            disabled={(tripStatus == 'complete') ? true : false}
                            className={`flex rounded p-1 w-full space-x-2 focus:bg-gray-200 focus:outline-none items-center ${(tripStatus == 'complete') ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-200"}`}
                            onClick={handleUngroupAllClick}        
                        >
                            <FontAwesomeIcon 
                                icon={faUserMinus} className="text-gray-600 mx-1 text-sm" />
                            <span className="text-gray-600  text-sm">Ungroup Passengers</span>
                        </button>
                    }
                    <hr className="my-1 mx-2" />
                    <button 
                        className="flex rounded p-1 w-full space-x-2 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none  items-center"
                        onClick={handleViewTripClick}
                    >
                        <FontAwesomeIcon 
                            icon={faEye} className="text-gray-600 mx-1 text-sm" />
                        <span className="text-gray-600  text-sm">View Trip</span>
                    </button>
                    <hr className="my-1 mx-2" />
                    {
                        (pageType == "assigned") &&
                        <button 
                            className="flex rounded p-1 w-full space-x-2 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none  items-center"
                            onClick={handleOpenInGoogleMaps}
                        >
                            <FontAwesomeIcon 
                                icon={faEye} className="text-gray-600 mx-1 text-sm" />
                            <span className="text-gray-600  text-sm">Open In Google Maps</span>
                        </button>
                    }
                </div>
            }

        </div>
    )

}

export default Options;