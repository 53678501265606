import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { 
    startOfMonth,
    endOfMonth,
    startOfWeek,
    endOfWeek,
    format,
    addDays,
    addMonths,
    isSameMonth,
    isSameDay
  } from 'date-fns';

const DatePickerDropDown = ({ handleCloseModal, selectedDate, setSelectedDate }) => {

    const [preliminaryDate, setPreliminaryDate] = useState(selectedDate ? selectedDate : new Date())

    const handleClear = () => {
        setSelectedDate(null)
        handleCloseModal()
    }

    const handleMonthChange = (value) => {
        setPreliminaryDate(addMonths(preliminaryDate, value));
    }

    function handleDateSelect(date) {
        setPreliminaryDate(date);
        setSelectedDate(date)
        handleCloseModal()
    }

    function handleDateSave(){
        handleCloseModal()
        setSelectedDate(preliminaryDate)
    }


      const renderDays = () => {
        const currentDate = preliminaryDate ? preliminaryDate : new Date();
        const monthStart = startOfMonth(currentDate);
        const monthEnd = endOfMonth(currentDate);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);
        const dateFormat = "d";
        const rows = [];
    
        let days = [];
        let day = startDate;
        let formattedDate = "";
        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, dateFormat);
                days.push({
                    date: day,
                    formattedDate,
                    isCurrentMonth: isSameMonth(day, currentDate),
                    isSelected: isSameDay(day, currentDate),
                });
                day = addDays(day, 1);
            }
            rows.push(days);
            days = [];
        }
        return rows.map((row, i) => {
            return (
                <div key={i} className="flex justify-between text-gray-800">
                    {row.map((day, j) => {
                        return (
                            <div
                                key={j}
                                className={`w-4 h-4 text-xs flex items-center justify-center rounded-full mx-1 ${
                                    !day.isCurrentMonth ? "text-gray-400" : "cursor-pointer"
                                } ${day.isSelected ? "bg-purple-400 text-white" : ""}`}
                                onClick={() => handleDateSelect(day.date)}
                            >
                                {day.formattedDate}
                            </div>
                        );
                    })}
                </div>
            );
        });
    };

    return (
        <div className="w-48 " >
            <div className="bg-white rounded-lg shadow-lg p-2" style={{userSelect: 'none'}}>
                <div className="flex items-center justify-between mb-2">
                    <FontAwesomeIcon icon={faChevronLeft} className="cursor-pointer ml-4 text-gray-700" onClick={() => handleMonthChange(-1)} data-testid={'next-month-button'}/>
                    <div className="text-gray-700 text-sm">{preliminaryDate ? format(preliminaryDate, 'MMMM yyyy') : format(new Date(), 'MMMM yyyy')}</div>
                    <FontAwesomeIcon icon={faChevronRight} className="cursor-pointer mr-4 text-gray-700" onClick={() => handleMonthChange(1)} data-testid={'prev-month-button'}/>
                </div>
                <hr className="my-2" />
                <div className="flex justify-between">
                    <div className="text-gray-600 text-xs font-medium w-4 h-4 flex items-center justify-center rounded-full mx-1">S</div>
                    <div className="text-gray-600 text-xs font-medium w-4 h-4 flex items-center justify-center rounded-full mx-1">M</div>
                    <div className="text-gray-600 text-xs font-medium w-4 h-4 flex items-center justify-center rounded-full mx-1">T</div>
                    <div className="text-gray-600 text-xs font-medium w-4 h-4 flex items-center justify-center rounded-full mx-1">W</div>
                    <div className="text-gray-600 text-xs font-medium w-4 h-4 flex items-center justify-center rounded-full mx-1">T</div>
                    <div className="text-gray-600 text-xs font-medium w-4 h-4 flex items-center justify-center rounded-full mx-1">F</div>
                    <div className="text-gray-600 text-xs font-medium w-4 h-4 flex items-center justify-center rounded-full mx-1">S</div>
                </div>
                <hr className="mt-1 mb-2" />
                {renderDays()}
                <hr className="my-1" />
                <div className="flex justify-between">
                    <button className="text-purple-400 hover:text-purple-600 text-xs px-2 py-0 rounded-full border border-purple-400 mr-2" onClick={handleClear}>Clear</button>
                    <button className="bg-purple-400 hover:bg-purple-600 text-xs text-white px-2 py-0 rounded-full ml-2" onClick={handleDateSave}>Done</button>
                </div>
            </div>
        </div>
    )
};

export default DatePickerDropDown;                