import { faBuilding, faCalendarAlt, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { useState, useEffect, useRef } from 'react';
import './loader_spinner.css'


/**
 *
 * @param {selectedCompanyAddress} selectedCompanyAddress - The selected company address
 * @param {setSelectedCompanyAddress} setSelectedCompanyAddress - The function to set the selected company address
 * @param {companyAddresses} companyAddresses - The company addresses
 * @param {addressLoading} addressLoading - The boolean to check if the company addresses are loading
 * @param {selectedEmployees} selectedEmployees - The selected employees (prevent click when length is 0)
 * @param {setUpdateState} setUpdateState - The function to set the update state to trigger useEffects when an address doesn't change values
 * @param {updateState} updateState - The update state to trigger useEffects when an address doesn't change values
 * @returns {JSX.Element} - The company address selection component
 */
const CompanyAddressSelection = ({ selectedCompanyAddress, setSelectedCompanyAddress, companyAddresses, addressLoading, selectedEmployees, setUpdateState, updateState }) => {
    const [viewOptions, setViewOptions] = useState(false)
    const dropdownRef = useRef(null);
    const boxRef = useRef(null)
    const companyAddressesSelection = [..."", ...companyAddresses]
    const toggleView = () => {
        setViewOptions(!viewOptions)
    }

    const handleSelect = (address) => {
        setViewOptions(false);

        if (selectedCompanyAddress === address) {
            const updatedState = updateState + 1;
            setUpdateState(updatedState);
        }

        // Check if address is "Clear" or empty
        if (address === "Clear" || !address) {
            setSelectedCompanyAddress({ name: "Select an Address" });
        } else {
            setSelectedCompanyAddress(address);
        }
    };


    useEffect(() => {
        // Function to handle the click outside the component
        const handleClickOutside = (event) => {
            if (dropdownRef.current && boxRef.current && !dropdownRef.current.contains(event.target) && !boxRef.current.contains(event.target)) {
                setViewOptions(false);
            }
        };

        // Add the event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <div className="address flex justify-between items-center bg-white border border-gray-200 text-gray-500 rounded-lg px-2 py-1 relative"
                onClick={(selectedEmployees?.length > 0) ? toggleView : null}
                style={{ userSelect: 'none' }}
                ref={boxRef}
            >
                <p className='text-gray-600 truncate' style={{ fontSize: "14px" }}>{selectedCompanyAddress ? selectedCompanyAddress.name : "Select an Address "}</p>
                <FontAwesomeIcon icon={faBuilding} className="text-gray-600" />
                {viewOptions && (
                    <div ref={dropdownRef}
                        className="absolute top-full left-0 bg-white rounded-lg shadow-md border border-gray-300 w-full z-40"
                        style={{ top: 'calc(100%)' }}
                    >
                        {
                            addressLoading &&
                            <>
                                <div className='w-full p-3 flex'> </div>
                                <div className="absolute overflow-hidden top-0 left-0 right-0 bottom-0 w-full h-full z-50 bg-transparent flex flex-col items-center justify-center">
                                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8 my-1"></div>
                                </div>
                                <div className='w-full p-3 flex'> </div>
                            </>
                        }
                        {!addressLoading &&
                            companyAddressesSelection
                                .sort((a, b) => {
                                    const nameA = a.name ? a.name.toUpperCase() : ""; // handle null/undefined
                                    const nameB = b.name ? b.name.toUpperCase() : ""; // handle null/undefined
                                    if (nameA < nameB) return -1;
                                    if (nameA > nameB) return 1;
                                    return 0;
                                })
                                .map((address, index) => (
                                    <div
                                        key={index}
                                        className="px-4 py-1 text-gray-600 font-normal cursor-pointer hover:bg-gray-100 truncate"
                                        style={{ fontSize: "14px", minHeight: "28px" }}
                                        onClick={() => {
                                            handleSelect(address);
                                        }}
                                    >
                                        {address.name ? address.name : "Clear"}
                                    </div>
                                ))}

                    </div>
                )}
            </div >
        </>
    )
}

export default CompanyAddressSelection;
