import React from 'react';

import Card from "./Card";

export default {
    title: 'Structure | Card',
};

export const EmptyCard = () => (
    <div style={{padding: '20px', backgroundColor: '#eeeeee', height: '500px'}}>
        <Card title="This is my title!">
        </Card>
    </div>
);

export const CardWithContent = () => (
    <div style={{padding: '20px', backgroundColor: '#eeeeee', height: '500px'}}>
        <Card title="This is my title!">
            <p>Here is some text</p>
            <p>I found some more text</p>
        </Card>
    </div>
);
