// TODO: Convert to mdx for better documentation
import React from 'react';
import { action } from '@storybook/addon-actions';
import Table from './Table';

import logoIconSrc from '../../../assets/images/logo.svg';

export default {
    title: 'Structure | Table',
    components: [Table],
};

const tableHeaders = [
    { Header: 'First Column', accessor: 'firstCol' },
    { Header: 'Centered Column', accessor: 'secondCol' },
    { Header: 'Bold data', accessor: 'thirdCol' },
];

const getRow = (firstCol, secondCol, thirdCol) => ({ firstCol, secondCol, thirdCol });

const smallRowData = [
    getRow('Hello', 'Table', 'Component!'),
    getRow(
        <img src={logoIconSrc} />,
        <strong>Event components can be rendered here</strong>,
        'Component!'
    ),
];


const headerModifiers = {
    secondCol: {
        center: true,
    },
};

const columnModifiers = {
    secondCol: {
        center: true
    },
    thirdCol: {
        strong: true,
    },
};

const largeRowData = Array(50).fill(0).map((_, index) => {
    return getRow(`Row ${index}`, 'Table', 'Component!');
});

export const WithColumns = () => (
    <div style={{padding: '20px'}}>
        <Table cols={tableHeaders} />
    </div>
);

export const WithColumnsAndData = () => (
    <div style={{padding: '20px'}}>
        <Table
            cols={tableHeaders}
            rowData={smallRowData}
        />
    </div>
);

export const WithPaginationAndCustomPageLength = () => (
    <div style={{padding: '20px'}}>
        <Table
            cols={tableHeaders}
            rowData={largeRowData}
            pageLength={5}
        />
    </div>
);

export const WithColumnFormatting = () => (
    <div style={{padding: '20px'}}>
        <Table
            cols={tableHeaders}
            rowData={largeRowData}
            headerModifiers={headerModifiers}
            columnModifiers={columnModifiers}
            pageLength={5}
        />
    </div>
);
