import React from 'react';

const CancelConfirm = ({ title, body, onNoClick, onYesClick }) => {
  return (
    <div style={{ width: "50%", height: "90%", maxWidth: "90%", zIndex: 15 }} className="flex items-center justify-center">
      <div className="bg-white rounded-md shadow-lg p-2" >
        <div>
          <div className="text-gray-800 mb-1">{title}</div>
          <div className="text-gray-500 text-sm font-light">{body}</div>
        </div>
        <div className="flex justify-between mt-1 border-gray-200">
          <button onClick={onNoClick} className="text-gray-700 rounded-xl bg-gray-200 px-6 py-1 ml-2 hover:text-gray-900 text-sm focus:outline-none">
            No
          </button>
          <button onClick={onYesClick} className="bg-gray-200 rounded-xl text-gray-700 hover:bg-gray-300 rounded px-6 py-1 mr-2 text-sm focus:outline-none">
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelConfirm;