import React, { useState, useEffect } from "react";

function ResponseMessage({ title, message }) {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    // Use a setTimeout to hide the component after 3 seconds
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 4000);

    // Clear the timer when the component unmounts
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return isVisible ? (
    <div
      className=" z-10"
      style={{ zIndex: 2 }}
    >
      <div className="border flex items-start rounded-lg absolute m-4 p-2 z-50 bottom-0 right-0 bg-white">
      {/* <i className="fas fa-check p-1 rounded-full bg-green-500 text-white mr-1 text-xxs"></i> */}
      <div>
      <h1 className="text-lg">{title}</h1>
      <p>{message}</p>
      </div>
    </div>
    </div>
  ) : null;
}

export default ResponseMessage;
