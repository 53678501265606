import React, { useState, useEffect, useRef } from "react";
import { faBusinessTime, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./loader_spinner.css";

const CompanySelection = ({
  companies,
  selectedCompany,
  handleSelectChange,
  teamsLoading,
}) => {
  const [viewOptions, setViewOptions] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const dropdownRef = useRef(null);
  const boxRef = useRef(null);

  const toggleView = () => {
    setViewOptions(!viewOptions);
  };

  const handleSelect = (company) => {
    const Event = { target: { value: company.id } };
    handleSelectChange(Event);
    setViewOptions(false);
  };

  const handleClickOutside = (event) => {
    if (
      boxRef.current &&
      dropdownRef.current &&
      !boxRef.current.contains(event.target) &&
      !dropdownRef.current.contains(event.target)
    ) {
      setViewOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredCompanies = companies.filter((company) =>
    company.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <div
        className="companySearch flex justify-between items-center bg-gray-200 rounded-lg w-full text-sm px-2 py-1 relative"
        onClick={() => toggleView()}
        style={{ userSelect: "none" }}
        ref={boxRef}
      >
        <div className="text-gray-600 font-small">
          {selectedCompany ? (
            selectedCompany.name
          ) : (
            <>
              <p className="flex justify-between text-gray-400 ml-1">
                {companies?.length > 0
                  ? `Select a company from ${companies?.length}`
                  : "No companies available"}
              </p>
            </>
          )}
        </div>

        {selectedCompany ? (
          <FontAwesomeIcon icon={faBusinessTime} className="text-gray-600" />
        ) : (
          <FontAwesomeIcon
            icon={faSearch}
            className="text-gray-600 cursor-pointer ml-2"
            onClick={(e) => {
              e.stopPropagation();
              toggleView();
            }}
          />
        )}

        {viewOptions && (
          <div
            ref={dropdownRef}
            className="absolute top-full left-0 bg-white rounded-lg shadow-md border dark-gray-scrollbar border-gray-300 w-full overflow-hidden z-40 "
            style={{ top: "calc(100%)", maxHeight: "200px", overflowY: "auto" }}
          >
            <div className={`w-full flex ${teamsLoading ? "p-5" : "p-2"}`}>
              <input
                type="text"
                className="border p-1 w-full rounded-lg"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onClick={(e) => e.stopPropagation()}
                autoFocus
              />
            </div>
            {teamsLoading && (
              <div className="absolute overflow-hidden top-0 left-0 right-0 bottom-0 w-full h-full z-50 bg-transparent flex flex-col items-center justify-center">
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8 my-1"></div>
              </div>
            )}

            {!teamsLoading &&
              filteredCompanies.map((company, index) => (
                <div
                  key={index}
                  className="px-4 py-1 text-gray-600 font-normal cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSelect(company)}
                >
                  {company?.name}
                </div>
              ))}
          </div>
        )}
      </div>
    </>
  );
};

export default CompanySelection;
