import React, {useState, useEffect} from 'react';
import Axios from 'axios';
import { Steps } from 'intro.js-react';
import { getFormattedDate } from '../../../tools/date.util';


const introBooking = () =>{

    const [trip, setTrip] =useState({
        id: "intro_trip",
        tag: "",
        region: "Alberton",
        timeslot: "08:00AM",
        full_name: "Thapelo Dlamini",

    })
    return(
        [
            <h2 key={"intro_date"} className="font-bold text-md text-gray-800 mt-5 introDate">
                Tue Jan 31 2023 (1)
            </h2>,
            <div
                className="flex bg-gray-200 border border-gray-300 rounded-lg h-13 p-2 mt-3 tripCardIntro"
                key={trip.id}
            >
                <div className="self-center w-3/12">
                    <div className="text-xs font-bold text-gray-800 mt-1">
                        {trip.tag}
                    </div>
                    <span className="text-sm mt-1">{trip.region}</span>
                    <span className="text-xs text-gray-600">{trip.timeslot}</span>
                </div>
                <div className="self-center w-2/12">
                    <div className="text-xs font-bold text-gray-800">
                        {trip.full_name}
                    </div>
                    <span className="text-xs">{trip.company_name}</span>
                </div>
                <a
                    className="self-center block appearance-none bg-green-500 px-2 py-1 rounded leading-tight font-medium text-xs text-white"
                >
                    View
                </a>
            </div>
        ]
    )
}

const renderTripContainer = (trips, filter, dateAll, allSlot, trained) => {
    const sortedTrips = mapTripsToFilter(trips, dateAll, allSlot);
    const tripArr = [];

    if(trained == false)
    {
        let introArr = introBooking();
        tripArr.push(introArr[0]);
        tripArr.push(introArr[1]);
    }


    for (const [date, trips] of Object.entries(sortedTrips)) {
        tripArr.push(
            <h2 key={date} className="font-bold text-md text-gray-800 mt-5">
                {date} ({trips.length})
            </h2>,
        );
        trips.forEach((trip) => {
            tripArr.push(
                <div
                    className="flex bg-gray-200 border border-gray-300 rounded-lg h-13 p-2 mt-3 tripCardIntro"
                    key={trip.id}
                >
                    <div className="self-center w-3/12">
                        <div className="text-xs font-bold text-gray-800 mt-1">
                            {trip.tag}
                        </div>
                        <span className="text-sm mt-1">{trip.region}</span>
                        <span className="text-xs text-gray-600">{trip.timeslot}</span>
                    </div>
                    <div className="self-center w-2/12">
                        <div className="text-xs font-bold text-gray-800">
                            {trip.full_name}
                        </div>
                        <span className="text-xs">{trip.company_name}</span>
                    </div>
                    <a
                        href={
                            filter === 'ungrouped'
                                ? '/trips/' + trip.id
                                : '/grouped_trips/' + trip.id
                        }
                        target="_blank"
                        className="self-center block appearance-none bg-green-500 px-2 py-1 rounded leading-tight font-medium text-xs text-white"
                    >
                        View
                    </a>
                </div>,
            );
        });
    }
    return tripArr;
};

const mapTripsToFilter = (trips, dateAll, allSlot) => {
    let sortedTrips = {};
    if (dateAll) {
        trips.forEach((trip) => {
            let date = Date.parse(trip.date);
            date = new Date(date);
            date = date.toDateString();
            if (sortedTrips.hasOwnProperty(date)) {
                sortedTrips[date].push(trip);
            } else {
                sortedTrips[date] = [trip];
            }
        });
        for (const [_date, trips] of Object.entries(sortedTrips)) {
            trips.sort((a, b) => {
                return (
                    Date.parse(
                        '1970/01/01 ' +
                        a.timeslot.slice(0, -2) +
                        ' ' +
                        a.timeslot.slice(-2),
                    ) -
                    Date.parse(
                        '1970/01/01 ' +
                        b.timeslot.slice(0, -2) +
                        ' ' +
                        b.timeslot.slice(-2),
                    )
                );
            });
        }
        const tmpSortedTrips = {};
        Object.keys(sortedTrips)
            .sort(function (a, b) {
                return new Date(a) - new Date(b);
            })
            .forEach(function (key) {
                tmpSortedTrips[key] = sortedTrips[key];
            });
        sortedTrips = tmpSortedTrips;
    }
    if ((allSlot && !dateAll) || (!allSlot && !dateAll)) {
        trips.forEach((trip) => {
            if (sortedTrips.hasOwnProperty(trip.timeslot)) {
                sortedTrips[trip.timeslot].push(trip);
            } else {
                sortedTrips[trip.timeslot] = [trip];
            }
        });
    }
    return sortedTrips;
};

const Bookings = ({
    fullDate,
    selectedTimeslot,
    selectedCompany,
    filter,
    dateAll,
    allSlot,
    trained
}) => {

    const [introState, setIntroState] = useState({
        stepsEnabled: !trained,
        initialStep: 0,
        options: {
          showProgress: true,
          showBullets: false,
          tooltipClass: 'etapathTooltip',
        },
        steps: [
            {
                element: '.groupTripsIntro',
                title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Trips",
                intro: "The trips page is where all the bookings for the client appear and where groupings are created by the user."
            },
            {
                element: '.selectCompany',
                title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Select Company",
                intro: "First select the company for the bookings to appear."
            },
            {
                element: '.dateTimeIntro',
                title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Select Date & Time",
                intro: "The date panel and timeslot panel are used to select days and timeslots that booked trips appear on.<br><br>  <span class='tooltip-note'><b><i class='fas fa-comment-dots'></i></b> The all button is selected as a default selection and will display all the trips for the month across all the timeslots</span>"
            },
            {
                element: '.bookingsIntro',
                title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Bookings",
                intro: "Once you have selected the day and timeslot, the trips for that day and timeslot will appear."
            },
            {
                element: '.introDate',
                title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Trip Information",
                intro: "The day, month and number of trips will appear here."
            },
        ]
    })
    
    const [trips, setTrips] = useState([]);

    const retrieveTrips = () => {
        let url = '/grouped_trips/ungrouped_trips';
        url += `?date=${getFormattedDate(fullDate)}`;
        !allSlot && (url += `&timeslot=${selectedTimeslot}`);
        url += `&company=${selectedCompany}`;
        url += `&all=${dateAll}`;
        Axios({
            method: 'get',
            url: url,
            headers: {Accept: 'application/json'},
        })
            .then((res) => {
                setTrips(res.data.trips);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        if (selectedCompany !== 'All Companies') {
            retrieveTrips();
        }
    }, [fullDate, selectedTimeslot, selectedCompany, filter, dateAll, allSlot]);

    return (
        <>
            <h2>Total: {trips.length}</h2>
            <div className='bookingsIntro'>
                {renderTripContainer(trips, filter, dateAll, allSlot, trained)}
            </div>
            <Steps
                enabled={introState.stepsEnabled}
                steps={introState.steps}
                initialStep={introState.initialStep}
                onExit={() => {}}
                options={introState.options}
            />
        </>
    );
};

export default Bookings;
