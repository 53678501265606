import React, { useState, useRef, useEffect } from "react";
import BookingsCard from "./Cards/BookingsCard";
import AdhocCard from "./Cards/AdhocCard";
import CancellationsCard from "./Cards/CancellationsCard";
import UnscheduledEmployeesCard from "./Cards/UnscheduledEmployeesCard";
import DateSelection from "./DateSelection";
import DaySelection from "./DaySelection";

/**
 *
 * @param {string} view The current (summary) view (day or week)
 * @param {function} setView The function to set the current (summary) view
 * @param {array} dateRanges The date ranges for the date selection component
 * @param {string} selectedWeek The selected week for the date selection component(summary)
 * @param {function} setWeek The function to set the selected week (summary)
 * @param {string} selectedDay The selected day for the date selection component (summary)
 * @param {function} setDay The function to set the selected day (summary)
 * @param {boolean} isLoadingSummary Whether the summary is loading
 * @param {function} setIsLoadingSummary The function to set whether the summary is loading
 * @param {array} summaryWeeklyData The weekly summary data
 * @param {array} summaryDailyData The daily summary data
 * @param {array} teamEmployees The employees for the selected team
 * @param {object} selectedTeam The selected team
 * @param {function} refresh The state to refresh the data via Use Effect
 * @returns {JSX.Element} The summary view component on the left side of the page, which contains different cards (more can be added)
 */

const BookingSummary = ({
  view,
  viewAdhoc,
  setViewAdhoc,
  setView,
  dateRanges,
  selectedWeek,
  setWeek,
  selectedDay,
  setDay,
  isLoadingSummary,
  setIsLoadingSummary,
  summaryWeeklyData,
  summaryAdhocData,
  summaryDailyData,
  teamEmployees,
  selectedTeam,
  refresh,
}) => {
  return (
    <div className="bg-white shadow-md rounded-lg w-full py-4 px-2 relative">
      {
        //Loader Spinner
        isLoadingSummary && (
          <>
            <div className="absolute top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 z-50 flex items-center justify-center rounded-lg">
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8 my-1"></div>
            </div>
          </>
        )
      }
      <h1 className="font-small text-gray-600">Booking Summary</h1>
      {
        //If a team is selected, you can toggle between daily view and weekly here
        teamEmployees?.length > 0 && (
          <>
            <div className="w-full flex space-x-3 justify-center items-center mt-4">
              {/* <button
                        className={`${
                            view === 'day' ? 'bg-purple-500 text-white' : 'bg-gray-200'
                        } text-sm py-2 px-4 rounded-lg mr-2 focus:outline-none`}
                        style={{width: '120px'}}
                        onClick={() => setView('day')}
                    >
                        Daily View
                    </button> */}
            </div>
            <div className="w-full flex justify-center items-center mt-4">
              <DateSelection
                dateRanges={dateRanges}
                selectedWeek={selectedWeek}
                setWeek={setWeek}
              />
            </div>
            {view === "day" && (
              <DaySelection
                selectedWeek={selectedWeek}
                selectedDay={selectedDay}
                setDay={setDay}
                pageType={"summary"}
              />
            )}
            {viewAdhoc !== "ad-hoc" && (
              <BookingsCard
                view={view}
                summaryWeeklyData={summaryWeeklyData}
                summaryDailyData={summaryDailyData}
                selectedDay={selectedDay}
                selectedWeek={selectedWeek}
              />
            )}
            {view == "ad-hoc" && selectedTeam && (
              <AdhocCard
                view={view}
                viewAdhoc={viewAdhoc}
                summaryAdhocData={summaryAdhocData}
                summaryDailyData={summaryDailyData}
                selectedDay={selectedDay}
                selectedWeek={selectedWeek}
              />
            )}

            {/* <UnscheduledEmployeesCard
              teamEmployees={teamEmployees}
              pageView={view}
              selectedDay={selectedDay}
              selectedWeek={selectedWeek}
              selectedTeam={selectedTeam}
              refresh={refresh}
              setIsLoadingSummary={setIsLoadingSummary}
            /> */}
            {/* <CancellationsCard
              teamEmployees={teamEmployees}
              view={view}
              selectedDay={selectedDay}
              selectedWeek={selectedWeek}
              selectedTeam={selectedTeam}
              refresh={refresh}
              setIsLoadingSummary={setIsLoadingSummary}
            /> */}
          </>
        )
      }
      {
        // If there are no employees on the team, show this message
        teamEmployees?.length === 0 && (
          <>
            <div className="w-full px-1 py-2 flex items-center justify-center font-thin text-gray-600">
              Select a team to see their information.
            </div>
          </>
        )
      }
    </div>
  );
};

export default BookingSummary;
