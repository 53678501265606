import React, { useState, useEffect } from "react";
import {
  getThisWeekDateRangeOption,
  getNextWeekDateRangeOption,
  getLastWeekDateRangeOption,
} from "../../tools/date.util";
import BookingSummary from "./ClientBookerComponents/BookingSummary";
import BookingView from "./ClientBookerComponents/BookingView";
import moment from "moment";
import Axios from "axios";
import "./ClientBookerComponents/custom_scrollbar.css";
import { ToastContainer } from 'react-toastify';
import { Steps } from 'intro.js-react';
/**
 * The parent component for the client booker page
 * @param {string} role the current user's role (manager, logistics_specialist, admin)
 * @returns {JSX.Element} The client booker summary and view components
 */

const ClientBooker = ({ role, user, isAllowedToEditCsb }) => {
  const dateRanges = [
    getThisWeekDateRangeOption(),
    getNextWeekDateRangeOption(),
    getLastWeekDateRangeOption(),
  ];

  //Summary Selectors and States
  const [summaryView, setSummaryView] = useState("day");
  const [summaryWeek, setSummaryWeek] = useState(getThisWeekDateRangeOption());
  const [summaryDay, setSummaryDay] = useState(moment().format("DD-MM-YYYY"));
  const [summaryWeeklyData, setSummaryWeeklyData] = useState([]);
  const [summaryAdhocData, setSummaryAdhocData] = useState([]);
  const [summaryDailyData, setSummaryDailyData] = useState([]);

  //Booking View Selectors
  const [bookingsView, setBookingsView] = useState("day");
  const [bookingsWeek, setBookingsWeek] = useState(
    getThisWeekDateRangeOption()
  );
  const [bookingsDay, setBookingsDay] = useState(moment().format("DD-MM-YYYY"));
  const [bookedTrips, setBookedTrips] = useState([]);
  const [weeklyBookedTrips, setWeeklyBookedTrips] = useState([]);

  //States to store the teams and company addresses
  const [teams, setTeams] = useState([]); // Used to store the teams of the selected company
  const [companyAddresses, setCompanyAddresses] = useState([]); // Used to store the addresses of the selected company
  const [selectedTeam, setSelectedTeam] = useState(null); // Used to store the selected team
  const [teamEmployees, setTeamEmployees] = useState([]); // Used to store the employees of the selected team

  // Used to determine if the booking time has passed
  const [timesUp, setTimesUp] = useState(false);

  //States to manage refreshes and reloads
  const [reloadTeams, setReloadTeams] = useState(0);
  const [refresh, setRefresh] = useState(false);

  //States that control loader spinners in the page
  const [isLoadingSummary, setIsLoadingSummary] = useState(false);
  const [bookingViewIsLoading, setBookingViewIsLoading] = useState(false);
  const [teamsLoading, setTeamsLoading] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);
  const [viewAdhoc, setViewAdhoc] = useState(false); // Used to determine if the adhoc trips should be displayed

  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);

  // Intro steps
  const [introState, setIntroState] = useState({
    stepsEnabled: false,
    initialStep: 0,
    options: {
      showProgress: true,
      showBullets: false,
      tooltipClass: 'etapathTooltip',
    },
    steps: [
      {
        element: '.booker',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Booking Schedule",
        intro: "Use this page to make and view team bookings, create ad-hocs or teams, or download and upload bookings."
      },
      {
        element: '.scheduler',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Scheduler",
        intro: "Use the scheduler to see and edit a detailed calender for your team's bookings."
      },
      {
        element: '.ad-hocs',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Ad-Hocs",
        intro: "Make ad-hoc bookings here."
      },
      {
        element: '.teams',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Teams",
        intro: "Create teams for a company."
      },
      {
        element: '.download',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Download",
        intro: "Download CSV schedules for employee bookings."
      },
      {
        element: '.upload',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Upload",
        intro: "Upload bookings to add to the schedule."
      },
      {
        element: '.companySearch',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Select a Company",
        intro: "Select a company and a team to get started."
      },
    ]
  })

  // Scheduler steps
  const [introScheduler, setIntroScheduler] = useState({
    stepsEnabled: false,
    initialStep: 0,
    options: {
      showProgress: true,
      showBullets: false,
      tooltipClass: 'etapathTooltip',
    },
    steps: [
      {
        element: '.teamSelect',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Team Search",
        intro: "Select a team from your company."
      },
      {
        element: '.weekSelect',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Week",
        intro: "Select a week to view in the schedule."
      },
      {
        element: '.bookingsSelect',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Make bookings",
        intro: "Make team bookings here."
      },
      {
        element: '.daySelect',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Date",
        intro: "Select a date to make a booking on."
      },
      {
        element: '.employeeSelect',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Employees",
        intro: "Select employees for your booking."
      },
      {
        element: '.timeslot',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Timeslot",
        intro: "Select a timeslot to make the bookings in."
      },
      {
        element: '.address',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Company Address",
        intro: "Select the company's address."
      },
      {
        element: '.addBooking',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Add Booking",
        intro: "Click to add your booking to the schedule."
      },
      {
        element: '.boundSelect',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Trip Type",
        intro: "Select the type of booking that you would like to see on the schedule.  <br><br> <span class='tooltip-note'><b><i class='fas fa-comment-dots'></i></b> Inbound trips are trips to work and outbound trips are trips home. </span>"
      },
      {
        element: '.employeeSort',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Sort Employees",
        intro: "Sort your employees in alphabetical order."
      },
      {
        element: '.reapplyBookings',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Reapply Bookings",
        intro: "Reapply bookings from last week to this week."
      },
      {
        element: '.confirmBookings',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Confirm",
        intro: "Don't forget to confirm your changes!"
      },
    ]
  })

  // Ad-hocs steps
  const [introAdhocs, setIntroAdhocs] = useState({
    stepsEnabled: false,
    initialStep: 0,
    options: {
      showProgress: true,
      showBullets: false,
      tooltipClass: 'etapathTooltip',
    },
    steps: [
      {
        element: '.employeeSelectAH',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Employees",
        intro: "Select employees for your ad-hoc booking."
      },
      {
        element: '.timeslotAH',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Timeslot",
        intro: "Select a timeslot to make the booking in."
      },
      {
        element: '.addressAH',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Company Address",
        intro: "Select the company's address."
      },
      {
        element: '.boundSelectAH',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Trip Type",
        intro: "Select the type of booking that you would like to make."
      },
      {
        element: '.confirmAH',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Confirm",
        intro: "Don't forget to confirm your changes!"
      },
    ]
  });

  // Teams steps
  const [introTeams, setIntroTeams] = useState({
    stepsEnabled: false,
    initialStep: 0,
    options: {
      showProgress: true,
      showBullets: false,
      tooltipClass: 'etapathTooltip',
    },
    steps: [],

    steps_create: [
      // Create Team
      {
        element: '.teamCreate',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Create a Team",
        intro: "Create a team with employees and managers that will be grouped together for bookings."
      },
      {
        element: '.companySearch',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Company",
        intro: "Select a company for the new team."
      },
      {
        element: '.newName',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Team Name",
        intro: "Enter a name for the new team."
      },
      {
        element: '.employeeSelect',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Employees",
        intro: "Select employees to add to the team."
      },
      {
        element: '.managerSelect',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Managers",
        intro: "Select managers to add to the team."
      },
      {
        element: '.createButton',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Managers",
        intro: "Click here to create your team!"
      },
      {
        element: '.teamEdit',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Edit a Team",
        intro: "Click here to edit a team's details."
      },
    ],

    steps_edit: [
    // Edit Team
      {
        element: '.teamCreate',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Create a Team",
        intro: "Click here to create a team."
      },
      {
        element: '.teamSelect',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Select a Team",
        intro: "Select the team that you want to edit."
      },
      {
        element: '.changeName',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Update Name",
        intro: "Change the team's name."
      },
      {
        element: '.employeeSelect',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Select Employees",
        intro: "Choose employees to add to the team."
      },
      {
        element: '.managerSelect',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Select Managers",
        intro: "Choose managers to add to the team."
      },
    ]
  });

   // Download steps
   const [introDownload, setIntroDownload] = useState({
    stepsEnabled: false,
    initialStep: 0,
    options: {
      showProgress: true,
      showBullets: false,
      tooltipClass: 'etapathTooltip',
    },
    steps: [
      {
        element: '.teamSelect',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Team Search",
        intro: "Select a team from your company."
      },
      {
        element: '.weekSelect',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Week",
        intro: "Select a week to view in the schedule."
      },
      {
        element: '.downloadCSV',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Download CSV",
        intro: "Generate a CSV schedule to download."
      },
      {
        element: '.daySelect',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Date",
        intro: "Select a date to make a booking on."
      },
      {
        element: '.employeeSelect',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Employees",
        intro: "Select employees for your booking."
      },
      {
        element: '.inboundTime',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Inbound Timeslot",
        intro: "Select a timeslot for the inbound trips. <br><br> <span class='tooltip-note'><b><i class='fas fa-comment-dots'></i></b> Inbound trips are trips to work. </span>"
      },
      {
        element: '.outboundTime',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Outbound Timeslot",
        intro: "Select a timeslot for the outbound trips. <br><br> <span class='tooltip-note'><b><i class='fas fa-comment-dots'></i></b> Outbound trips are trips home. </span>"
      },
      {
        element: '.address',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Company Address",
        intro: "Select the company's address."
      },
      {
        element: '.bookingsDownload',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Download",
        intro: "Click here to download your CSV."
      },
    ]
   });

   // Upload steps
   const [introUpload, setIntroUpload] = useState({
    stepsEnabled: false,
    initialStep: 0,
    options: {
      showProgress: true,
      showBullets: false,
      tooltipClass: 'etapathTooltip',
    },
    steps: [
      {
        element: '.chooseFile',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Select a File",
        intro: "Select a CSV file to upload bookings."
      },
      {
        element: '.fileUpload',
        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Upload",
        intro: "Click to upload your file."
      },
    ]
   })

  const managedTraining = (start) => {
    setIntroState((prevState) => ({
      ...prevState,
      stepsEnabled: start,
    }));
  };

  const managedScheduler = (start) => {
    setIntroScheduler((prevState) => ({
      ...prevState,
      stepsEnabled: start,
    }));
  };

  const managedAdhocs = (start) => {
    setIntroAdhocs((prevState) => ({
      ...prevState,
      stepsEnabled: start,
    }));
  };

  const managedTeams = (start, steps_tag = null) => {
    setIntroTeams((prevState) => ({
      ...prevState,
      stepsEnabled: start,
      steps: (steps_tag == null) ? [] : [...prevState[steps_tag]]
    }));
  };

  const managedDownload = (start) => {
    setIntroDownload((prevState) => ({
      ...prevState,
      stepsEnabled: start,
    }));
  };

  const managedUpload = (start) => {
    setIntroUpload((prevState) => ({
      ...prevState,
      stepsEnabled: start,
    }));
  };

  // Handle the company select change
  const handleSelectChange = (e) => {
    const selectedCompanyId = parseInt(e.target.value, 10);
    const selectedCompany = companies.find(
      (company) => company.id === selectedCompanyId
    );
    setSelectedCompany(selectedCompany);
    setSelectedTeam(null);
  };

  // Filter adhoc trips from the data returned from the API
  function filterAdhocTrips(data) {
    const filteredData = {
      this_week: {},
      past_week: {},
    };

    for (const day in data.this_week) {
      filteredData.this_week[day] = filterTrips(data.this_week[day]);
    }

    for (const day in data.past_week) {
      filteredData.past_week[day] = filterTrips(data.past_week[day]);
    }

    return filteredData;
  }

  // Filter the trips to only include adhoc trips
  function filterTrips(tripData) {
    const filteredTrips = {
      inbound: [],
      outbound: [],
    };

    for (const tripType in tripData) {
      const trips = tripData[tripType];
      if (Array.isArray(trips)) {
        const adhocTrips = trips.filter((trip) => trip.is_adhoc === true);
        filteredTrips[tripType] = adhocTrips;
      }
    }

    return filteredTrips;
  }

  // Get companies when role is admin
  useEffect(() => {
    Axios.get("/team_manager/get_companies", {
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        const data = response.data;
        setCompanies(data);
      })
      .catch((error) => {
        console.error("Error getting companies:", error);
      });
  }, []);

  useEffect(() => {
    if (role !== "admin" && role !== "company_admin" && role !== "logistics_specialist") {
      handleSelectChange({ target: { value: user.company_id } })
    };
  }, [companies]);

  // set selected company when role is employee
  useEffect(() => {
    if (role !== "admin" && role !== "company_admin" && role !== "logistics_specialist") {

      // get users team
      Axios.get("/team_manager/get_users_team", {
        params: {
          user_id: user.id,
        },
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) => {
          const data = response.data;
          setSelectedTeam(data);
          setTeamEmployees(data.employees);
        })
        .catch((error) => {
          console.error("Error getting teams:", error);
        });
    }
  }, [selectedCompany]);

  // get users teams
  useEffect(() => {
    // role !== "admin" && handleSelectChange({target: {value: user.company_id }});

    // get teams
    if (selectedCompany) {
      Axios.get("/team_manager/get_teams", {
        params: {
          company_id: selectedCompany?.id,
        },
        headers: {
          Accept: "application/json",
        },
      })

        .then((response) => {
          const data = response.data;
          setTeams(data);
          // select first team if data is not empty
          if (data?.length < 0) {
            setSelectedTeam(data[0]);
          }
        })
        .catch((error) => {
          console.error("Error getting teams:", error);
        });
    }
  }, [selectedCompany]);

  // Get the teams of the selected company, the applies for roles that can manage multiple Teams or Companies
  useEffect(() => {
    setTeamsLoading(true);
    Axios.get("/team_manager/get_teams", {
      params: {
        company_id: selectedCompany?.id,
      },
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        setTeams(response.data);
        setSelectedTeam(response.data.length > 0 ? response.data[0] : null);
        setTeamEmployees(response.data.length > 0 ? response.data[0].employees : []);
        setTeamsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching teams:", error);
        setTeamsLoading(false);
      });
  }, [reloadTeams, selectedCompany]);

  // Fetch the company address data
  useEffect(() => {
    setAddressLoading(true);
    if (selectedCompany?.id) {
      Axios.get("/team_manager/get_company_addresses", {
        headers: {
          Accept: "application/json",
        },
        params: {
          company_id: selectedCompany?.id,
        },
      })
        .then((response) => {
          const options = ["Clear", ...response.data]; //Add the Clear option to the list of addresses
          setCompanyAddresses(options);
          setAddressLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching company addresses:", error);
          setAddressLoading(false);
        });
    }
  }, [selectedTeam]);

  //Use effects that change the summary view when the booking view changes (daily vs weekly)
  useEffect(() => {
    setSummaryView(bookingsView);
  }, [bookingsView]);

  useEffect(() => {
    setSummaryWeek(bookingsWeek);
  }, [bookingsWeek]);

  useEffect(() => {
    setSummaryWeek(bookingsWeek);
    setSummaryDay(bookingsDay);
  }, [bookingsDay]);

  //Use effects that change the day when the week changes
  useEffect(() => {
    if (summaryWeek.label == getNextWeekDateRangeOption().label) {
      const monday = moment(summaryWeek?.value.split("|")[0], "DD-MM-YYYY");
      setSummaryDay(monday.format("DD-MM-YYYY"));
    } else if (summaryWeek.label == getThisWeekDateRangeOption().label) {
      setSummaryDay(moment().format("DD-MM-YYYY"));
    }
  }, [summaryWeek]);

  // get last week's data when the week changes
  useEffect(() => {
    if (summaryWeek.label == getLastWeekDateRangeOption().label) {
      const monday = moment(summaryWeek?.value.split("|")[0], "DD-MM-YYYY");
      setSummaryDay(monday.format("DD-MM-YYYY"));
    } else if (summaryWeek.label == getThisWeekDateRangeOption().label) {
      setSummaryDay(moment().format("DD-MM-YYYY"));
    }
  }, [summaryWeek]);


  useEffect(() => {
    if (bookingsWeek.label == getNextWeekDateRangeOption().label) {
      const monday = moment(bookingsWeek?.value.split("|")[0], "DD-MM-YYYY");
      setBookingsDay(monday.format("DD-MM-YYYY"));
    } else if (bookingsWeek.label == getThisWeekDateRangeOption().label) {
      setBookingsDay(moment().format("DD-MM-YYYY"));
    }
  }, [summaryWeek]);

  return (
    <div className="w-full">
      <div>
      <div className="flex flex-row-col">
        <h1 className="text-xl font-medium text-gray-700"> Team Manager</h1>
        {bookingsView!="ad-hocs" && bookingsView!="teams" && bookingsView!="download" && bookingsView!="upload" && selectedTeam == null && (
        <button
          className= 'text-gray-500 cursor-pointer hover:text-gray-900 ml-2'
          onClick={() => managedTraining(true)}
        >
          <i className="fas fa-question-circle"/>
        </button>)}
      </div>
      <p className="text-sm text-gray-500">{`Logged in as ${user.name} ${user.surname}`}</p>
    </div>
    <br/>

      {/* { <div className="w-1/4 mr-4">
        <BookingSummary
          view={summaryView}
          viewAdhoc={viewAdhoc}
          setViewAdhoc={setViewAdhoc}
          setView={setSummaryView}
          dateRanges={dateRanges}
          selectedWeek={summaryWeek}
          setWeek={setSummaryWeek}
          selectedDay={summaryDay}
          setDay={setSummaryDay}
          teams={teams}
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
          isLoadingSummary={isLoadingSummary}
          setIsLoadingSummary={setIsLoadingSummary}
          summaryWeeklyData={summaryWeeklyData}
          summaryAdhocData={summaryAdhocData}
          summaryDailyData={summaryDailyData}
          bookedTrips={bookedTrips}
          teamEmployees={teamEmployees}
          refresh={refresh}
        />
      </div> } */}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ width: "400px" }}
      />
      <div className="w-full">
        <BookingView
          companies={companies}
          setCompanies={setCompanies}
          selectedCompany={selectedCompany}
          handleSelectChange={handleSelectChange}
          view={bookingsView}
          viewAdhoc={viewAdhoc}
          setViewAdhoc={setViewAdhoc}
          setView={setBookingsView}
          dateRanges={dateRanges}
          selectedWeek={bookingsWeek}
          setWeek={setBookingsWeek}
          selectedDay={bookingsDay}
          setDay={setBookingsDay}
          teams={teams}
          setTeams={setTeams}
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
          setReloadTeams={setReloadTeams}
          reloadTeams={reloadTeams}
          companyAddresses={companyAddresses}
          setTimesUp={setTimesUp}
          timesUp={timesUp}
          teamEmployees={teamEmployees}
          setTeamEmployees={setTeamEmployees}
          teamsLoading={teamsLoading}
          setTeamsLoading={setTeamsLoading}
          addressLoading={addressLoading}
          setBookingViewIsLoading={setBookingViewIsLoading}
          bookingViewIsLoading={bookingViewIsLoading}
          role={role}
          refresh={refresh}
          setRefresh={setRefresh}
          bookedTrips={bookedTrips}
          summaryWeeklyData={summaryWeeklyData}
          summaryAdhocData={summaryAdhocData}
          summaryDailyData={summaryDailyData}
          setBookedTrips={setBookedTrips}
          weeklyBookedTrips={weeklyBookedTrips}
          setWeeklyBookedTrips={setWeeklyBookedTrips}
          user={user}
          trainingScheduler={managedScheduler}
          trainingAdhocs={managedAdhocs}
          trainingTeams={managedTeams}
          trainingDownload={managedDownload}
          trainingUpload={managedUpload}
          isAllowedToEditCsb={isAllowedToEditCsb}
        />
      </div>
        {/* JSX for training */}
        <Steps
          enabled={introState.stepsEnabled}
          steps={introState.steps}
          initialStep={introState.initialStep}
          onExit={() => managedTraining(false)}
          options={introState.options}
        />

        {/* // JSX for scheduler */}
        <Steps
          enabled={introScheduler.stepsEnabled}
          steps={introScheduler.steps}
          initialStep={introScheduler.initialStep}
          onExit={() => managedScheduler(false)}
          options={introScheduler.options}
        />

        {/* // JSX for adhocs */}
        <Steps
        enabled={introAdhocs.stepsEnabled}
        steps={introAdhocs.steps}
        initialStep={introAdhocs.initialStep}
        onExit={() => managedAdhocs(false)}
        options={introAdhocs.options}
        />

        {/* // JSX for teams */}
        <Steps
        enabled={introTeams.stepsEnabled}
        steps={introTeams.steps}
        initialStep={introTeams.initialStep}
        onExit={() => managedTeams(false)}
        options={introTeams.options}
        />

        {/* // JSX for download */}
        <Steps
        enabled={introDownload.stepsEnabled}
        steps={introDownload.steps}
        initialStep={introDownload.initialStep}
        onExit={() => managedDownload(false)}
        options={introDownload.options}
        />

        {/* // JSX for upload */}
        <Steps
        enabled={introUpload.stepsEnabled}
        steps={introUpload.steps}
        initialStep={introUpload.initialStep}
        onExit={() => managedUpload(false)}
        options={introUpload.options}
        />
    </div>
  );
};

export default ClientBooker;
