import React, { useEffect, useState } from 'react';
import Select from 'react-select';

const DynamicSelect = ({ props }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const ID = props.hiddenInputId + '_select';
  const options = props.resetOption
    ? [{ label: props.resetOption, value: null }, ...props.options]
    : props.options;

  useEffect(() => {
    if (props.value) {
      const _selectedValue = options.find(
        (option) => option.value === props.value
      );
      handleChange(_selectedValue);
    } else if (props.resetOption) {
      handleChange(options[0]);
    }
  }, []);

  const handleChange = (selectedOption) => {
    // Handle the selected option as needed
    setSelectedOption(selectedOption);

    // Update a hidden input field if provided
    if (props.hiddenInputId) {
      const hiddenInput = document.getElementById(props.hiddenInputId);
      if (hiddenInput) {
        hiddenInput.value = selectedOption ? selectedOption.value : '';
      }
    }

    // This is for the vehicles view to update the vehicle type field
    if (props.onCarSelected) {
      onCarSelected(selectedOption ? selectedOption.value : null);
    }

    // This is for the Book trip page to inject the Location and Destination to the other dropdowns
    if (props.setLocationDestination) {
      setLocationDestination(selectedOption ? selectedOption.value : null);
    }
  };

  return (
    <Select
      id={ID}
      key={ID}
      options={options}
      onChange={handleChange}
      value={selectedOption}
      placeholder={props.placeholder}
      styles={{
        control: (baseSyles, state) => ({
          ...baseSyles,
          borderColor: state.isFocused ? '#6b7280' : '#d1d5db',
          boxShadow: state.isFocused ? '0 0 0 1px #6b7280' : 'none',
          backgroundColor: props.backgroundColor
            ? props.backgroundColor
            : 'white',
          border: '1px solid #d1d5db',
          color: state.isFocused ? '#FFFFFF' : '#716C6E',
          borderRadius: '0.375rem',
          fontSize: '14px',
          lineHeight: '1.25rem',
          transition:
            'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
          boxSizing: 'border-box',
          width: 'full',
          paddingTop: '0.275rem',
          paddingBottom: '0.275rem',
          paddingLeft: '0.5rem',
          paddingRight: '0.25rem',
          display: 'flex',
          cursor: 'pointer',
        }),
        option: (baseSyles, state) => ({
          ...baseSyles,
          color: state.isSelected ? '#FFFFFF' : '#716C6E',
        }),
        singleValue: (baseSyles, state) => ({
          ...baseSyles,
          color: state.isSelected ? '#FFFFFF' : '#716C6E',
          '&:hover': {
            color: 'white',
            backgroundColor: '#4299E1',
          },
        }),
        indicatorSeparator: (baseSyles) => ({
          ...baseSyles,
          backgroundColor: props.backgroundColor
            ? props.backgroundColor
            : '#FFFFFF',
        }),
      }}
    />
  );
};

export default DynamicSelect;
