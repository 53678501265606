import React from "react"
import { isEmpty } from '../../../tools/helpers';
import Select from 'react-select';

const renderCompanyOptions = (companies) => {
  let companyOptions = []
  if (!isEmpty(companies)) {
    companyOptions = companies.map((company) => (
      {value: company.id, label: company.name || 'empty name', key: company.id}
    ));
  } 

  return [
    {value: "none", label: "Select company"},
  ...companyOptions
  ]
  
}



  const renderCompanyAddresses = (companyAddresses, allTrips, allGroups, allAsssignedGroups) => {
    let addressOptions = []
    if (!isEmpty(companyAddresses)) {
      addressOptions = companyAddresses.map((address, index) => (
        {value: address, label: address}
      ));
    }

    return [
      {value: "none", label: "Select Address"},
    ...addressOptions
    ]
  };

const customStyles = {
  control: (baseStyles) => ({
    ...baseStyles,
    appearance: 'none',
    width: '100%',
    backgroundColor: '#D1CFD4',
    borderRadius: '8px',
    border: 'none',
    fontSize: '12px',
    color: '#000000',
  }),
  dropdownIndicator: (baseStyles) => ({
    ...baseStyles,
    color: "#6E6C71"
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    color: '#000000',
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    color: '#000000',
  }),
};


const PassengerMenu = ({
    companies,
    addresses,
    setSelectedAddress,
    selectedAddress,
    setSelectedCompany,
    selectedCompanyRef,
    inboundTrips,
    setInboundTrips,
    outboundTrips,
    setOutboundTrips,
    setInboundGroupedTrips,
    setInboundAssignedGroupedTrips,
    setOutboundGroupedTrips,
    setOutboundAssignedGroupedTrips,
    setSelectedTrips,
    setSelectedGroup,
    setBound, 
    bound,
    allTrips,
    allGroups,
    allAsssignedGroups,
    setShowAssignedTrips,
    setShowGroupedTrips,
    showAssignedTrips,
    showGroupedTrips,
    role,
}) => {


    const handleCompanySelect = (e) => {
        const companyID = e.value;
        setSelectedCompany(companyID);
        setSelectedAddress('None');
        handleAddressChange({value: 'None'})
      };

    const handleAddressChange = (selectedAddress) => {
      const address = selectedAddress.value
      if (address === 'None') {
        setSelectedTrips([]);
        setSelectedGroup([]);
        setInboundTrips([]);
        setOutboundTrips([]);
        setInboundGroupedTrips([]);
        setOutboundGroupedTrips([]);
        setSelectedAddress(address);
        setBound('Inbound');
        return;
      } else {
        setSelectedAddress(address);
        if (allTrips.hasOwnProperty(address)) {
          setInboundTrips(allTrips[address].inbound);
          setOutboundTrips(allTrips[address].outbound);
        } else {
          setInboundTrips([]);
          setOutboundTrips([]);
        }
        if (allGroups.hasOwnProperty(address)) {
          setInboundGroupedTrips(allGroups[address].inbound);
          setOutboundGroupedTrips(allGroups[address].outbound);
        }else {
          setInboundGroupedTrips([]);
          setOutboundGroupedTrips([]);
        }

        if (allAsssignedGroups.hasOwnProperty(address)) {
          setInboundAssignedGroupedTrips(allAsssignedGroups[address].inbound);
          setOutboundAssignedGroupedTrips(allAsssignedGroups[address].outbound);
        } else {
          setInboundAssignedGroupedTrips([]);
          setOutboundAssignedGroupedTrips([]);
        }
      }
    };

    return (
        <div className="bg-white rounded-xl p-4 shadow-lg" >
            <div className='flow-root'>
                {/* Company Select */}
                <div className="inline-block relative w-34 h-6 text-xs pr-2 selectCompany">
                <Select
                  options={renderCompanyOptions(companies)}
                  styles={customStyles}
                  onChange={handleCompanySelect}
                  placeholder={"Select Company"}
                />
              </div>
                {/* Address Select */}
                <div className="inline-block relative w-48 h-6 text-xs pr-2 selectAddress">
                  <Select 
                    options={renderCompanyAddresses(addresses, allTrips, allGroups, allAsssignedGroups)}
                    styles={customStyles}
                    onChange={handleAddressChange}
                    placeholder={"Select Address"}
                    value={(selectedAddress == "None") ? {value: "None", label: "Select Address"} : {value: selectedAddress, label: selectedAddress}}
                  />
                </div>
                {/* Trip Type Select */}
                <div className="inline-block relative w-30 h-6 text-xs tripType">
                  <Select
                    options={[{value: "Inbound", label: "Inbound"},{value: "Outbound", label: "Outbound"}]}
                    styles={customStyles}
                    onChange={(e) => setBound(e.value)}
                    placeholder={"Inbound"}
                  />
                </div>
                {
                  (role != "fleetmanager") &&
                  <div className='flow-root mt-2 flex-row align-center justify-center filters'>
                    <div className={ showGroupedTrips ? "inline-block relative w-32 h-6 text-sm p-1 text-gray-800 content-center hover:text-gray-500 ": "inline-block relative w-32 h-6 text-sm p-1  text-gray-500 content-center hover:text-gray-800 "}
                        onClick={() => {setShowGroupedTrips(!showGroupedTrips)}}
                        style={{userSelect: 'none'}}>
                        <i id="show-hide-grouped"  className={ showGroupedTrips ? "fas fa-eye m-2" : "fas fa-eye-slash m-2"} ></i>
                        <div className={"align-middle inline-block pointer-events-none font-medium"}>Grouped</div>
                    </div>
                    <div className={ showAssignedTrips ? "inline-block relative w-32 h-6 text-sm p-1 text-gray-800 content-center hover:text-gray-500 ": "inline-block relative w-32 h-6 text-sm p-1  text-gray-500 content-center hover:text-gray-800 "}
                     onClick={()=>{setShowAssignedTrips(!showAssignedTrips)}}
                     style={{userSelect: 'none'}}>
                        <i id="show-hide-assigned" className={ showAssignedTrips ? "fas fa-eye m-2 " : "fas fa-eye-slash m-2"}></i>
                        <div className={"align-middle inline-block pointer-events-none font-medium"}>Assigned</div>
                    </div>
                  </div>
                }
            </div>
        </div>
    )
}

export default PassengerMenu