import React from 'react';

/**
 *
 * @param {string} title The title of the confirmation popup
 * @param {string} body The body of the confirmation popup
 * @param {function} onNoClick The function to close the popup
 * @param {function} onYesClick The function to confirm the action
 * @param {boolean} warning The boolean to determine if the popup is a warning (red) [can be used for conditional styling
 * @returns {JSX.Element} The confirmation popup component
 */
const ConfirmationPopup = ({ title, body, onNoClick, onYesClick, warning }) => {
  return (
    <div style={{ width: "45%", height: "90%", maxWidth: "90%" }} className="flex items-center justify-center">
      <div className="bg-white rounded-md shadow-lg p-4" >
        <div>
          <div className={` ${warning ? "font-bold text-orange-500" : "text-gray-800"} mb-1 text-center`}>{title}</div>
          <div className="text-gray-500 text-sm font-light">{body}</div>
        </div>
        <div className="flex justify-between mt-2 ">
          <button onClick={onNoClick} className="text-gray-700 rounded-md bg-gray-300 px-6 py-2 hover:text-gray-900 text-sm focus:outline-none">
            Cancel
          </button>
          <button onClick={onYesClick} className="bg-purple-500 rounded-md text-white hover:bg-purple-700 px-6 py-2  text-sm focus:outline-none">
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
