import React,{useState} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import DatePickerDropDown from './DatePickerDropDown';
const DatePickerInputBox = ({selectedDate, setSelectedDate}) => {

    const [isOpen, setIsOpen] = useState(false);


    const handleOpenModal = () => setIsOpen(true);
    const handleCloseModal = () => setIsOpen(false);

    const toggleDropdown = () => {
        if(isOpen){
          console.log(selectedDate)
          handleCloseModal()
        }else{
          handleOpenModal()  
        }
    
    }
    
    return (
        <>
            <div className="bg-gray-200 rounded-lg flex-1 px-2 py-1 flex items-center justify-between">
                <p className="text-gray-600 font-medium  inline-block">{selectedDate ? selectedDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }) :"Date"}</p> 
                <FontAwesomeIcon
                    data-testid={'calendar-icon'}
                    icon={faCalendarAlt}
                    className="text-gray-600 cursor-pointer"
                    onClick={toggleDropdown}
                />
            </div>
        {isOpen && (
            <div style={{ position: 'absolute', top: 'calc(100%-1.5rem)', left: "24px", zIndex: 20 }}>
                <DatePickerDropDown
                    handleCloseModal = {handleCloseModal} 
                    selectedDate = {selectedDate}
                    setSelectedDate = {setSelectedDate}
                />
            </div>
        )}
        </>
    )
}

export default DatePickerInputBox;