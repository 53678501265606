import React,{useEffect, useState} from "react";
import './tracking.scss'
import actionCable from 'actioncable'
const socketUrl = process.env.REACT_APP_SECURE_ACTION_CABLE_URL;
const CableApp = {};
CableApp.cable = actionCable.createConsumer(socketUrl);

let driverMarker = null;

const styleArray =  [
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e9e9e9',
            },
            {
                lightness: 17,
            },
        ],
    },
    {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e4e4e4',
            },
            {
                lightness: 20,
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#efefef',
            },
            {
                lightness: 17,
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#efefef',
            },
            {
                lightness: 29,
            },
            {
                weight: 0.2,
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
            {
                color: '#efefef',
            },
            {
                lightness: 18,
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
            {
                color: '#efefef',
            },
            {
                lightness: 16,
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e4e4e4',
            },
            {
                lightness: 21,
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
            {
                color: '#dedede',
            },
            {
                lightness: 21,
            },
        ],
    },
    {
        elementType: 'labels.text.stroke',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#ffffff',
            },
            {
                lightness: 16,
            },
        ],
    },
    {
        elementType: 'labels.text.fill',
        stylers: [
            {
                saturation: 36,
            },
            {
                color: '#333333',
            },
            {
                lightness: 40,
            },
        ],
    },
    {
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f2f2f2',
            },
            {
                lightness: 19,
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#fefefe',
            },
            {
                lightness: 20,
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#fefefe',
            },
            {
                lightness: 17,
            },
            {
                weight: 1.2,
            },
        ],
    },
];

const Tracking = ({
    coords,
    grouped_trip,
    origin,
    destination,
    destinations,
    trips,
    originCoords,
    destinationCoords,
    destinationsCoords,
    order,
    driver_id
}) =>{
    
    var map = null;
    var directionsDisplay = null;
    const [driverCoordinates, setDriverCoordinates] = useState({
        latitude: -26.1994,
        longitude: 27.9925
    });
    

    useEffect(()=>{
        // her get the grouped trip detsils
        var mapOptions = {
            center: {lat: coords.lat, lng: coords.long},
            zoom: 14,
            styles: styleArray
        };
        map = new google.maps.Map(document.getElementById('mapTracking'), mapOptions);
        directionsDisplay = new google.maps.DirectionsRenderer();
        directionsDisplay.setMap(map);
        directionsDisplay.setOptions({
            suppressMarkers: false,
            suppressInfoWindows: false
        });

        calculateRoute();
    },[]);

    useEffect(()=>{
        if(driverMarker != null)
        {
            driverMarker.setPosition({lat: driverCoordinates.latitude, lng: driverCoordinates.longitude});
        }
    },[driverCoordinates.latitude])

    useEffect(()=>{
        CableApp.cable.subscriptions.create
        (
            {
                channel: 'LocationsChannel',
                user_id: driver_id,
            },
            {
                received: async (driver_data) => {
                    console.log("received location");
                    if(map != null)
                    {
                        if(driverMarker  == null)
                        {
                            addDriverMarker(driver_data);
                        }
                        else{
                            setDriverCoordinates(driver_data.location);
                        }

                    }
                }
            }
        )
    },[])

    const calculateRoute = () => {

        var waypoints = [];
        if (order){
            order.split(',').forEach((id)=>{
            var selectedTrip = trips.filter(trip => id == trip[0]);
            if(selectedTrip.length != 0 && selectedTrip[0][1] !== origin && selectedTrip[0][1] !==  destination)
            {
                waypoints.push({
                location: selectedTrip[0][1],
                stopover: true
                });    
            }
            });
        }

        const request = {
            origin: origin,
            destination: destination,
            waypoints: waypoints,
            // optimizeWaypoints: true,
            travelMode: google.maps.TravelMode.DRIVING
        };
        const directionsService = new google.maps.DirectionsService();
        directionsService.route(request, function (result, status) {
            if (status === google.maps.DirectionsStatus.OK) {
                directionsDisplay.setDirections(result);
            }
        });
    }


    var locations_map = {
        [origin]: originCoords,
        [destination]: destinationCoords,
    }
    
    destinations.forEach((dest,i)=>{
        locations_map = { 
        ...locations_map, 
        [dest]:destinationsCoords[i]
        }
    });    
    
    const addDriverMarker = (driver_data) =>
    {
        driverMarker = new google.maps.Marker({
            position:{
            lat: driver_data.location.latitude,
            lng: driver_data.location.longitude
            },
            map: map,
            icon: 'http://maps.google.com/mapfiles/kml/pal4/icon54.png'
        });
        driverMarker._infowindow = new google.maps.InfoWindow({
            content: driver_data.name,
            shouldFocus: false
        });
        google.maps.event.addListener(driverMarker, 'click', function() {
            this._infowindow.open({map, shouldFocus: false}, this);
        });
        // open marker on load
        google.maps.event.trigger(driverMarker, 'click');
    }

    return(
        <div className="tracking">
            <div id="mapTracking" className="tracking_map"></div>
        </div>
    )
}
export default Tracking;