import React from "react";
import {action} from '@storybook/addon-actions';

import Dropdown from './Dropdown';
import Button from '../button/Button';

export default {
  title: 'Form Elements | Dropdown',
};

const menuItems = [
    {label: 'One', onClick: action('Menu Item One Clicked')},
    {label: 'Two', onClick: action('Menu Item Two Clicked')},
    {label: 'Three', onClick: action('Menu Item Three Clicked')}
];

export const DropdownWithMenuItems = () => (
    <div style={{padding: '40px'}}>
        <Dropdown menuItems={menuItems}>
            <Button>Click to open dropdown</Button>
        </Dropdown>
    </div>
);

export const DropdownWithCustomPlacement = () => (
    <div style={{padding: '40px'}}>
        <Dropdown menuItems={menuItems} placement='top-start'>
            <Button>Open top-start</Button>
        </Dropdown>
        <Dropdown menuItems={menuItems} placement='right'>
            <Button>Open right</Button>
        </Dropdown>
    </div>
);
