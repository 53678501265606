import { faCalendarAlt, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { useState, useEffect, useRef } from 'react';

/**
 * 
 * @param {tripType} tripType - The trip type selected by the user (Inbound or Outbound)
 * @param {setTripType} setTripType - The function to set the trip type
 */
const TripTypeSelection = ({tripType, setTripType}) => {
    const [viewOptions, setViewOptions] = useState(false)
    const tripTypes = ["Inbound", "Outbound"]
    const dropdownRef = useRef(null);
    const boxRef = useRef(null)

    const toggleView=()=>{
        setViewOptions(!viewOptions)
    }

    const handleSelect = (type) => {
        setViewOptions(false)
        setTripType(type)
    }

    useEffect(() => {
        // Function to handle the click outside the component
        const handleClickOutside = (event) => {
            if (dropdownRef.current && boxRef.current && !dropdownRef.current.contains(event.target) && !boxRef.current.contains(event.target)) {
                setViewOptions(false);
          }
        };
    
        // Add the event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);
    
        // Clean up the event listener when the component unmounts
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);   

    return(
        <>
            <div className="flex justify-between items-center w-1/3 bg-gray-200 text-gray-500 rounded-lg px-2 py-1 relative" 
                onClick={toggleView} 
                style={{zIndex: 0,userSelect: 'none'}}
                ref={boxRef}
            >
                <div className='text-gray-600' style={{fontSize: "14px"}}>{tripType ? tripType : "Trip Type"}</div>  
                <FontAwesomeIcon icon={faPaperPlane} className="text-gray-600" /> 
                {viewOptions && (
                    <div ref={dropdownRef}
                        className="absolute top-full left-0 bg-white rounded-lg shadow-md border border-gray-300 w-full overflow-hidden"
                        style={{ zIndex: 10, top: 'calc(100%)' }}
                    >
                        {tripTypes.map((type, index) => (
                        <div
                            key={index}
                            className="px-4 py-1 text-gray-600 font-normal cursor-pointer hover:bg-gray-100"
                            style={{fontSize: "14px"}}
                            onClick={() => { 
                                handleSelect(type);
                            }}
                        >
                            {type}
                        </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    )
}

export default TripTypeSelection;