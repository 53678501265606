import React, { useEffect, useRef, useState } from 'react';
import './bookings.scss';
import axios from 'axios';
import Button from '../button/Button';
import { ThreeDots } from 'react-loader-spinner';

export const UploadCsvBookings = ({ switchToBulkBookings }) => {
    const [file, setFile] = useState(null);

    const [success, setShowSuccess] = useState(false);
    const [failure, setShowFailure] = useState(false);
    const [skippedBookings, setSkippedBookings] = useState([]);
    const [invalidBookings, setInvalidBookings] = useState([]);
    const [inactiveBookings, setInactiveBookings] = useState([])
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(false);
    const fileRef = useRef(null);
    const [uploadInProgress, setUploadInProgress] = useState(false);

    const handleUpload = () => {

        setUploadButtonDisabled(false);
        setSkippedBookings([]);
        setInvalidBookings([]);
        setInactiveBookings([]);

        const formData = new FormData();
        formData.append('bookings_file', file, file.name);

        axios.post('/csv_bookings/upload_csv.json', formData)
        .then((res) => {
            const { saved_trips, skipped_bookings, invalid_bookings, inactive_bookings } = res.data;

            if (skipped_bookings.length !== 0) {
                setSkippedBookings(skipped_bookings);
            }
            if (inactive_bookings.length !== 0) {
                setInactiveBookings(inactive_bookings);
            }
            if (invalid_bookings.length !== 0) {
                setInvalidBookings(invalid_bookings);
            }
            if (skipped_bookings.length === 0 && inactive_bookings.length === 0
                && invalid_bookings.length === 0 && saved_trips.length !== 0) {
                const { company_id, date } = saved_trips[0];
                setTimeout(() => {
                    switchToBulkBookings({ company_id, date });
                }, 4000);
            }
            if (saved_trips.length !== 0) {
                setShowSuccess(true);
                setTimeout(() => {
                    setShowSuccess(false);
                }, 4000);
            }
        })
        .catch((err) => {
            setShowFailure(true);
        })
        .finally(() => {
            setUploadButtonDisabled(false);
            setFile(null);
            fileRef.current.value = ''
        });

        if (!uploadInProgress) {
            setUploadInProgress(false);
            setUploadButtonDisabled(false);
            setFile(null);
            fileRef.current.value = ''
        }
    }

    const handleChange = (event) => {
        if (event.target.files.length > 0){
            setFile(event.target.files[0]);
        } else {
            setFile(null);
            fileRef.current.value = '';
        }
    }

    function removeDuplicates(array, property) {
        const uniqueMap = new Map();
        const uniqueArray = [];

        array.forEach(item => {
            const key = item[property];
            if (!uniqueMap.has(key)) {
                uniqueMap.set(key, item);
                uniqueArray.push(item);
            }
        });

        return uniqueArray;
    }

    const InactiveBookingsFiltered = removeDuplicates(inactiveBookings, 'id');
    const InvalidBookingsFiltered= removeDuplicates(invalidBookings, 'id');
    const skippedBookingFiltered = removeDuplicates(skippedBookings,'id');

    return (
        <div className="bookings">
            <h1 className="bookings__title">Upload Bookings</h1>
            {success && <h3 style={{ color: 'green', textAlign: 'center', marginBottom: '30px' }}>Trip(s) were created successfully!</h3>}
            {failure && <h3 style={{ color: 'red', textAlign: 'center', marginBottom: '30px' }}>Failed to process your request!</h3>}
            {
                (skippedBookings.length != 0) &&
                <div role="alert" className="rounded-xl border border-gray-100 bg-white p-4">
                    <div className="flex items-start gap-4">
                        <span className="text-red-600">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                            </svg>

                        </span>

                        <div className="flex-1">
                            <strong className="block font-medium text-gray-900"> Duplicate bookings</strong>

                            <p className="mt-1 text-sm text-gray-700">
                                {`For :`}
                                {skippedBookingFiltered.map((user) => {
                                    return `${user.name} ${user.surname}, `;
                                }).join('')}
                            </p>
                        </div>

                        <button onClick={() => setSkippedBookings([])} className="text-gray-500 transition hover:text-gray-600">


                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="h-6 w-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>

            }
            {
                (inactiveBookings.length != 0) &&
                <div role="alert" className="rounded-xl border border-gray-100 bg-white p-4">
                    <div className="flex items-start gap-4">
                        <span className="text-red-600">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                            </svg>

                        </span>

                        <div className="flex-1">
                            <strong className="block font-medium text-gray-900"> Inactive users</strong>

                            <p className="mt-1 text-sm text-gray-700">
                                {`For: `}
                                {InactiveBookingsFiltered.map((user) => {
                                    return `${user.name} ${user.surname}, `;
                                }).join('')}
                            </p>
                        </div>

                        <button onClick={() => setInactiveBookings([])} className="text-gray-500 transition hover:text-gray-600">


                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="h-6 w-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            }
            {
                (invalidBookings.length != 0) &&

                <div role="alert" className="rounded-xl border border-gray-100 bg-white p-4">
                    <div className="flex items-start gap-4">
                        <span className="text-red-600">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                            </svg>

                        </span>

                        <div className="flex-1">
                            <strong className="block font-medium text-gray-900"> Invalid users</strong>

                            <p className="mt-1 text-sm text-gray-700">
                                {`For: `}
                                {InvalidBookingsFiltered.map((user) => {
                                    return `${user.name} ${user.surname}, `;
                                }).join('')}
                            </p>
                        </div>

                        <button onClick={() => setInvalidBookings([])} className="text-gray-500 transition hover:text-gray-600">


                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="h-6 w-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            }
            {
                <div className="bookings__filters_upload mt-6">
                    <input
                        type="file"
                        ref={fileRef}
                        onChange={handleChange}
                    />

                    <div>
                        {
                            <Button 
                                className={(uploadButtonDisabled || !file) ? 'enable' : ''}
                                onClick={() => handleUpload()}
                                disabled = { (uploadButtonDisabled || !file || uploadInProgress) }
                            >
                                Upload file
                                { !file && (
                                    <div className='tooltip-uploads'>{"Please select a file."}</div>
                                )}
                            </Button>
                        }
                    </div>

                </div>
            }

            {
                uploadButtonDisabled &&
                <div className='flex flex-row w-full h-full absolute top-0 left-0 justify-center items-center bg-gray-200 bg-opacity-50'>
                    <ThreeDots
                        type="ThreeDots" 
                        color="#9B999E" 
                        height="100" 
                        width="100"
                    />
                </div>
            }

        </div>
    )

}
export default UploadCsvBookings;
