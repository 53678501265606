import React, { useState } from "react";

/**
 *
 * @param {handleTabSelect} handleTabSelect - The function to handle the bound selection
 * @param {tripType} tripType - The trip type
 * @param {inboundCountDaily} inboundCountDaily - The inbound count daily
 * @param {outboundCountDaily} outboundCountDaily - The outbound count daily
 * @returns {JSX.Element} - The bound selector component
 */

const BoundSelector = ({
  handleTabSelect,
  tripType,
  inboundCountDaily,
  outboundCountDaily,
}) => {
  const [inboundButtonText, setInboundButtonText] = useState("Inbound");
  const [outboundButtonText, setOutboundButtonText] = useState("Outbound");

  const handleOptionSelect = (selectedType) => {
    handleTabSelect(selectedType);
  };

  return (
    <>
      <div className="boundSelect flex gap-2 p-0 m-0">
        <button
          className={`${tripType === "inbound"
            ? "bg-green-200 text-green-500"
            : "text-gray-500 hover:bg-green-100 hover:text-green-500 focus:bg-green-200"
            } flex w-full rounded-md justify-between items-center px-0 pl-1 py-0 text-sm focus:outline-none`}
          onMouseEnter={() => setInboundButtonText("Trips to work")}
          onMouseLeave={() => setInboundButtonText("Inbound")}
          onClick={() => handleOptionSelect("inbound")}
        >
          {inboundButtonText}{" "}
          <span className="bg-green-500 rounded px-1 flex items-center text-white text-xs">
            {inboundCountDaily}
          </span>
        </button>

        <button
          className={`${tripType === "outbound"
            ? "bg-orange-200 text-orange-500"
            : "text-gray-500 hover:bg-orange-100 hover:text-orange-500 focus:bg-orange-200"
            } flex w-full rounded-md justify-between items-center px-0 pl-1 py-0 text-sm focus:outline-none`}
          onMouseEnter={() => setOutboundButtonText("Trips to home")}
          onMouseLeave={() => setOutboundButtonText("Outbound")}
          onClick={() => handleOptionSelect("outbound")}
        >
          {outboundButtonText}
          <span className="bg-orange-500 rounded px-1 text-white flex items-center text-xs">
            {outboundCountDaily}
          </span>
        </button>
      </div>
    </>
  );
};

export default BoundSelector;
