import React, {useState, useEffect} from 'react';
import Axios from 'axios';
import Select from 'react-select'
import { createRef } from 'react';
import { Steps } from 'intro.js-react';
import { getFormattedDate } from '../../../tools/date.util';

const mapGroupsToFilter = (groups, dateAll, allSlot) => {
    let sortedGroups = {};
    if (dateAll) {
        groups.forEach((group) => {
            let date = Date.parse(group.date);
            date = new Date(date);
            date = date.toDateString();
            if (sortedGroups.hasOwnProperty(date)) {
                sortedGroups[date].push(group);
            } else {
                sortedGroups[date] = [group];
            }
        });
        for (const [_date, groups] of Object.entries(sortedGroups)) {
            groups.sort((a, b) => (a.timeslot > b.timeslot ? 1 : -1));
        }
        const tmpSortedGroups = {};
        Object.keys(sortedGroups)
            .sort(function (a, b) {
                return new Date(a) - new Date(b);
            })
            .forEach(function (key) {
                tmpSortedGroups[key] = sortedGroups[key];
            });
        sortedGroups = tmpSortedGroups;
    }
    if ((allSlot && !dateAll) || (!allSlot && !dateAll)) {
        groups.forEach((group) => {
            if (sortedGroups.hasOwnProperty(group.timeslot)) {
                sortedGroups[group.timeslot].push(group);
            } else {
                sortedGroups[group.timeslot] = [group];
            }
        });
    }
    return sortedGroups;
};

const introTrip = () =>{

    return (
        <div
            className="flex bg-gray-200 border border-gray-300 rounded-lg h-13 p-2 mt-3 tripCardIntro"
            key={"introTrip"}
        >
            <div className="self-center w-3/12">
                <div className="text-xs font-bold text-gray-800 mt-1">20220901_21:30PM_09347458</div>
                <span className="text-sm mt-1">Rosebank</span>
                <span className="text-xs text-gray-600">(1)</span>
                <span className="text-xs text-gray-600">21:30PM</span>
            </div>
            <div className="self-center w-2/12 text-center">
                <div className="text-xs mt-1">Start Time</div>
                <div className="text-s">21:00PM</div>
            </div>
            <div className="self-center w-3/12">
                <div className="text-xs mt-1">Etapath</div>
                <div className="text-xs mt-1">
                    <span>Toyota</span>&nbsp;
                    <span>Camry</span> - (<span>000000</span>)
                </div>
            </div>
            <form
                className="w-4/12 text-xs mt-1 flex"
                onSubmit={(e) =>{}}
            >
                <span>{''}</span>
                <div className="self-center mr-2 w-40">
                    <div className="inline-block relative w-full text-xs">
                        {
                            <div className = { 'assignDriver' } >
                                <Select
                                    name="driver_name"
                                    className = { "w-full px-2 py-1 rounded-lg" } 
                                    placeholder = {"Assign Driver"}
                                    isSearchable
                                    options={[
                                        { label: `Driver Intro0`, value: "introDriver0" },
                                        { label: `Driver Intro1`, value: "introDriver1" },
                                        { label: `Driver Intro2`, value: "introDriver2" },
                                    ]}
                                    closeMenuOnSelect={true} 
                                />
                            </div>
                        }
                    </div>
                </div>
                <div className = {"flex content-center updateTrip"} >
                    <button
                        className="cursor-pointer bg-transparent font-medium p-1 border border-gray-700 text-gray-700 rounded hover:bg-gray-700 hover:text-white text-xs updateTrip"
                        onClick={(e)=>{
                            e.preventDefault()
                        }}
                    >
                        Update
                    </button>
                </div>
            </form>
            <a
                href="/grouped_trips/1/show_intro"
                className= "viewGroup self-center block appearance-none bg-green-500 px-2 py-1 rounded leading-tight font-medium text-xs text-white viewGroup"
                target="_blank"

            >
                View
            </a>
        </div>
    );
}

const renderGroupsContainer = (
    groups,
    filter,
    drivers,
    retrieveAssignedGroups,
    retrieveUnassignedGroups,
    dateAll,
    allSlot,
    groupRefs,
    introState,
    trained
) => {
    const sortedGroups = mapGroupsToFilter(groups, dateAll, allSlot);
    const groupArr = [];
    if(introState.stepsEnabled == true && (trained != null || trained != false))
    {
        groupArr.push(introTrip());
    }


    for (const [date, groups] of Object.entries(sortedGroups)) {
        groupArr.push(
            <h2 key={date} className="font-bold text-md text-gray-800 mt-5">
                {date} ({groups.length})
            </h2>,
        );

        groups.forEach((group) => {
            groupArr.push(
                <div
                    className="flex bg-gray-200 border border-gray-300 rounded-lg h-13 p-2 mt-3"
                    key={group.id}
                >
                    <div className="self-center w-3/12">
                        <div className="text-xs font-bold text-gray-800 mt-1">
                            {group.tag}
                        </div>
                        <span className="text-sm mt-1">{group.region}</span>
                        <span className="text-xs text-gray-600">({group.trip_count})</span>
                        <span className="text-xs text-gray-600">{group.timeslot}</span>
                    </div>
                    <div className="self-center w-2/12 text-center">
                        <div className="text-xs mt-1">Start Time</div>
                        <div className="text-s">{group.start_time || 'N/A'}</div>
                    </div>
                    <div className="self-center w-3/12">
                        <div className="text-xs mt-1">{group.name}</div>
                        <div className="text-xs mt-1">
                            <span>{group.make}</span>&nbsp;
                            <span>{group.model}</span> - (<span>{group.reg_number}</span>)
                        </div>
                    </div>
                    <form
                        className="w-4/12 text-xs mt-1 flex"
                        onSubmit={(e) =>
                            updateDriver(
                                e,
                                group,
                                retrieveAssignedGroups,
                                retrieveUnassignedGroups,
                            )
                        }
                    >
                        <span>{group.driver_name || ''}</span>
                        <div className="self-center mr-2 w-40">
                            <div className="inline-block relative w-full text-xs">
                                {
                                    <div className = '' >
                                        <Select
                                            name="driver_name"
                                            className = { "w-full px-2 py-1 rounded-lg" } 
                                            ref={groupRefs[group.id]}
                                            placeholder = {"Assign Driver"}
                                            isSearchable
                                            options={getDriverOptions(group,drivers)}
                                            closeMenuOnSelect={true} 
                                        />
                                    </div>
                                }
                                
                            </div>
                        </div>
                        <div className = "flex content-center" >
                            <button
                                type="submit"
                                className="cursor-pointer bg-transparent font-medium p-1 border border-gray-700 text-gray-700 rounded hover:bg-gray-700 hover:text-white text-xs updateTrip"
                            >
                                Update
                            </button>
                        </div>
                    </form>
                    <a
                        href={
                            filter === 'ungrouped'
                                ? '/trips/' + group.id
                                : '/grouped_trips/' + group.id
                        }
                        target="_blank"
                        className= "self-center block appearance-none bg-green-500 px-2 py-1 rounded leading-tight font-medium text-xs text-white viewGroup"
                    >
                        View
                    </a>
                </div>
            );
        });
    }
    return groupArr;
};

const filterDrivers = (group, drivers) => {
    const driversList = [...drivers];
    const date = group.date.split('T')[0];
    if (!date) {
        return driversList;
    }

    return driversList.filter((driver) => {
        if (!driver.selections) {
            return true;
        }

        const dateSelection = driver.selections[date];
        if (!dateSelection) {
            return true;
        }

        return !dateSelection.includes(group.timeslot);
    });
};

const renderDriverOptions = (group, drivers) => {
    const filteredDrivers = filterDrivers(group, drivers);
    return filteredDrivers.map((driver) => {
        return (
            <option value={driver.id} key={driver.id}>
                {driver.username} {driver.usersurname}
            </option>
        );
    });
};

const getDriverOptions = (group, drivers) =>{
    const filteredDrivers = filterDrivers(group, drivers);
    return filteredDrivers.map((driver) => {
        return (
            { label: `${driver.username} ${driver.usersurname}`, value: driver.id }
        );
    });
}

const updateDriver = (
    e,
    group,
    retrieveAssignedGroups,
    retrieveUnassignedGroups,
) => {
    e.preventDefault();
    Axios({
        method: 'put',
        url: `/grouped_trips/${group.id}/assign_vehicle`,
        headers: {Accept: 'application/json'},
        data: {
            vehicle: e.target.driver_name.value,
        },
    })
        .then((_res) => {
            retrieveAssignedGroups();
            retrieveUnassignedGroups();
        })
        .catch((err) => console.log(err));
};

const Assign = ({
    fullDate,
    selectedTimeslot,
    selectedCompany,
    filter,
    dateAll,
    allSlot,
    trained
}) => {

    const [introState, setIntroState] = useState({
        stepsEnabled: false,
        initialStep: 0,
        options: {
            showProgress: true,
            showBullets: false,
            tooltipClass: 'etapathTooltip',
        },
        steps: [
            {
            element: '.groupTripsIntro',
            title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Grouped Trips",
            intro: "All of your client's trips are displayed here. <br><br>  <span class='tooltip-note'><b><i class='fas fa-comment-dots'></i></b> Trips will appear with passengers already grouped together</span>"
            },
            {
            element: '.selectCompany',
            title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Select Company",
            intro: "Select your client's company name here to display all the client's grouped trips."
            },
            {
            element: '.dateTimeIntro',
            title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Select Date and Time",
            intro: "You can filter trips for the specific day and timeslot here."
            },
            {
            element: '.unassignedTrips',
            title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Unassigned Trips",
            intro: "Trips yet to be assigned to drivers are displayed here under the unassigned filter."
            },
            {
            element: '.tripCardIntro',
            title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Trip Card",
            intro: "Each card shows the essential details of each trip. When reviewing the card you can see the following details: <ul style='font-size: 0.9rem;list-style: disc;margin-left: 16px;'><li>The location of the first passenger</li><li>The amount of passengers in the trip</li><li>The first pick up time</li><li>The company's and client's names.</li></ul>"
            },
            {
            element: '.assignDriver',
            title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Assign a Driver",
            intro: "Search and assign your driver here. Selecting update will assign the driver to the trip. <br><br> <span class='tooltip-note'><b><i class='fas fa-comment-dots'></i></b> When a driver is assigned, a notification will be sent to the passengers notifying them of the trip details and a notification will be sent to the driver notifying him/her of the trip details</span>",
            position: 'left'
            },
            {
            element: '.updateTrip',
            title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Update Trip",
            intro: "When a driver is assigned, the trip card will move into the assigned filter. The following additional details will appear:<ul style='font-size: 0.9rem;list-style: disc;margin-left: 16px;'><li>The trip tag</li><li>The vehicle type and registration number</li><li>The assigned driver's name</li></ul>"
            },
            {
            element: '.viewGroup',
            title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> View Grouped Trip Details",
            intro: "Select view to see the Grouped Trip Details page."
            }

        ]
    })
                    
    const [assignedGroups, setAssignedGroups] = useState([]);
    const [unassignedGroups, setUnassignedGroups] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [assignmentToggle, setAssignmentToggle] = useState('Unassigned');

    const [groupRefs, setGroupRefs] = useState({});

    const retrieveAssignedGroups = () => {
        let url = '/grouped_trips';
        url += `?date=${getFormattedDate(fullDate)}`;
        !allSlot && (url += `&timeslot=${selectedTimeslot}`);
        url += `&company=${selectedCompany}`;
        url += `&all=${dateAll}`;
        url += '&filter=assigned';
        Axios({
            method: 'get',
            url: url,
            headers: {Accept: 'application/json'},
        })
            .then((res) => {
                setAssignedGroups(res.data.grouped_trips);
            })
            .catch((err) => console.log(err));
    };

    const retrieveUnassignedGroups = () => {
        let url = '/grouped_trips';
        url += `?date=${getFormattedDate(fullDate)}`;
        !allSlot && (url += `&timeslot=${selectedTimeslot}`);
        url += `&company=${selectedCompany}`;
        url += `&all=${dateAll}`;
        url += '&filter=unassigned';
        Axios({
            method: 'get',
            url: url,
            headers: {Accept: 'application/json'},
        })
        .then((res) => {
            createGroupRefs(res.data.grouped_trips);
            setUnassignedGroups(res.data.grouped_trips);
            if(introState.stepsEnabled == false && (trained == null || trained == false))
            {
                introState.stepsEnabled = true;
                setIntroState(introState);
            }
            /*else if (introState.stepsEnabled == false && (trained == null || trained == false))
            {
                introState.stepsEnabled = true;
                
                introState.steps = [
                    {
                        element: '.groupTripsIntro',
                        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Grouped Trips",
                        intro: "All of your client's trips are displayed here. <br><br>  <span class='tooltip-note'><b><i class='fas fa-comment-dots'></i></b> Trips will appear with passengers already grouped together</span>"
                    },
                    {
                        element: '.selectCompany',
                        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Select Company",
                        intro: "Select your client's company name here to display all the client's grouped trips."
                    },
                    {
                        element: '.dateTimeIntro',
                        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Select Date and Time",
                        intro: "You can filter trips for the specific day and timeslot here."
                    },
                    {
                        element: '.unassignedTrips',
                        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Unassigned Trips",
                        intro: "Trips yet to be assigned to drivers are displayed here under the unassigned filter."
                    },
                    {
                        element: '.updateAll',
                        title: "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Update Trips",
                        intro: "Click to update all trips."
                    },
                ];
                setIntroState(introState);
            }*/
        })
        .catch((err) => console.log(err));
    };


    const createGroupRefs = (groups) =>{
        let groupRefs = {};

        groups.forEach((group)=>{
            groupRefs[group.id] = createRef();
        });

        setGroupRefs(groupRefs);
    }

    // get drivers

    useEffect(() => {
        Axios({
            method: 'get',
            url: `/vehicles/drivers?company=${selectedCompany}`,
            headers: {Accept: 'application/json'},
        })
        .then((res) => {
            setDrivers(res.data);
        })
        .catch((err) => console.log(err));
    }, [assignedGroups, unassignedGroups]);

    // Call API to reset group state when dependencies change

    useEffect(() => {
        if (selectedCompany !== 'All Companies') {
            retrieveAssignedGroups();
            retrieveUnassignedGroups();
        }
    }, [fullDate, selectedTimeslot, selectedCompany, filter, allSlot]);

    // Toggle between assigned and unassigned groups

    const handleAssignmentToggle = (e) => {
        if (e.target.innerHTML === 'Assigned') {
            setAssignmentToggle('Assigned');
        } else {
            setAssignmentToggle('Unassigned');
        }
    };

    const bulkUpdateDrivers = () =>{
        let tripsToAssign = [];

        Object.keys(groupRefs).forEach((key)=>{
            tripsToAssign.push({
                group: key,
                vehicle: groupRefs[key].current?.getValue()[0]?.value
            })
        });

        Axios({
            method: 'post',
            url: `/grouped_trips/bulk_assign_vehicles`,
            headers: {Accept: 'application/json'},
            data: {
                trips_to_assign: tripsToAssign
            }
        })
        .then((_res) => {
            retrieveAssignedGroups();
            retrieveUnassignedGroups();
        })
        .catch((err) => console.log(err));
    }

    return (
        <>
            <div className='flex w-full justify-between'>
                <div>
                    <button
                        className={
                            assignmentToggle === 'Assigned'
                                ? 'text-xs text-gray-800 font-medium border-b-2 border-gray-800 mr-2 mt-10 assignedTrips'
                                : 'text-xs text-gray-800 font-medium border-b-2 border-transparent mr-2 mt-10 assignedTrips'
                        }
                        type="button"
                        onClick={handleAssignmentToggle}
                    >
                        Assigned
                    </button>
                    <button
                        className={
                            assignmentToggle === 'Unassigned'
                                ? 'text-xs text-gray-800 font-medium border-b-2 border-gray-800 mt-10 unassignedTrips'
                                : 'text-xs text-gray-800 font-medium border-b-2 border-transparent mt-10 unassignedTrips'
                        }
                        type="button"
                        onClick={handleAssignmentToggle}
                    >
                        Unassigned
                    </button>
                </div>
                {
                    (assignmentToggle === 'Unassigned') &&
                    <button
                        className="cursor-pointer bg-transparent font-medium p-1 border border-gray-700 text-gray-700 rounded hover:bg-gray-700 hover:text-white text-xs updateAll"
                        type="button"
                        onClick={bulkUpdateDrivers}
                    >
                        Update All
                    </button>
                }
            </div>

            <h2>
                Total: {assignmentToggle === 'Assigned' ? assignedGroups.length : unassignedGroups.length}
            </h2>
            {assignmentToggle === 'Assigned' ? (
                <div className=''>
                    {
                        renderGroupsContainer(
                            assignedGroups,
                            filter,
                            drivers,
                            retrieveAssignedGroups,
                            retrieveUnassignedGroups,
                            dateAll,
                            allSlot,
                            groupRefs,
                            introState,
                            trained
                        )
                    }
                </div>
            ) : (
                <div className=''>
                    {
                        renderGroupsContainer(
                            unassignedGroups,
                            filter,
                            drivers,
                            retrieveAssignedGroups,
                            retrieveUnassignedGroups,
                            dateAll,
                            allSlot,
                            groupRefs,
                            introState,
                            trained
                        ).map((item)=> item)
                    }
                </div>
            )}
            <Steps
                enabled={introState.stepsEnabled}
                steps={introState.steps}
                initialStep={introState.initialStep}
                onExit={() => {}}
                options={introState.options}
            />
        </>
    );
};

export default Assign;
