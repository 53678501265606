function companyClosingTime(currentTimeStr, closingTimeStr) {
    // Get current time
    const currentTime = new Date();

    // Parse closing time
    const closingTimeParts = closingTimeStr.split(':');
    const closingHour = parseInt(closingTimeParts[0], 10);
    const closingMinute = parseInt(closingTimeParts[1], 10);

    // Set closing time date object with current date
    const closingDateTime = new Date();
    closingDateTime.setHours(closingHour, closingMinute, 0, 0);

    // Parse current time string
    const currentTimeParts = currentTimeStr.split(':');
    const currentHour = parseInt(currentTimeParts[0], 10);
    const currentMinute = parseInt(currentTimeParts[1], 10);

    // Set current time date object with current date
    currentTime.setHours(currentHour, currentMinute, 0, 0);

    // Compare current time with closing time
    return currentTime > closingDateTime;
}

export default companyClosingTime;
// Example usage:
// console.log(companyClosingTime("14:00", "17:00")); // Output: false (assuming it's before 5:00 PM)
