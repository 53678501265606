import axios from 'axios';
import React, { cloneElement, Component, createRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';

import {
  getClassNames,
  isEmpty,
  isEmptyStr,
  isNull,
  isUndefined,
} from '../../tools/helpers';

import Table from '../table/Table';
import Button from '../button/Button';
import BasicSelect from '../select/BasicSelect';
import Card from '../card/Card';
import Input from '../input/Input';

import './bulk-bookings.scss';

const renderButtonLabel = (loading) =>
  loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Book Trips';

class BulkBookings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      timeslots: [],
      employees: [],
      employeeData: [],
      tableHeaders: [
        { Header: 'Employee', accessor: 'employee' },
        { Header: 'Date', accessor: 'date' },
        { Header: 'Pickup', accessor: 'pickup' },
        { Header: 'Dropoff', accessor: 'dropoff' },
        { Header: 'Depart/Arrive', accessor: 'tripType' },
        { Header: 'Timeslot', accessor: 'timeslot' },
        { Header: 'Trip Time', accessor: 'time' },
        { Header: '', accessor: 'action' },
      ],
    };
  }

  componentDidMount() {
    this.fetchTimeslots();
    this.fetchEmployees();
  }

  componentDidUpdate(prevProps, prevState) {
    if (isEmpty(prevState.employees) && !isEmpty(this.state.employees)) {
      this.setState(
        (prevState) => ({
          ...prevState,
          rowData: [this.emptyRow(0)],
        }),
        () => {
          if ($('[type="date"]').prop('type') != 'date') {
            $('[type="date"]').datepicker();
          }
        }
      );
    }
  }

  fetchTimeslots = async () => {
    const response = await axios('/bulk_bookings/time_slots', {
      headers: { Accept: 'application/json' },
    });

    const { data } = response;
    this.mapTimeslots(data);
  };

  fetchEmployees = async () => {
    const response = await axios('/bulk_bookings/employees', {
      headers: { Accept: 'application/json' },
    });

    const { data } = response;
    this.mapEmployees(data);
  };

  mapEmployees = (data) => {
    const mappedEmployees = [];
    for (let item of data) {
      mappedEmployees.push({
        label: `${item.name} ${item.surname}`,
        value: item.id,
      });
    }

    this.setState((prevState) => ({
      ...prevState,
      employees: data,
      employeeData: mappedEmployees,
    }));
  };

  mapTimeslots = (data) => {
    const mappedTimeslots = [];
    for (let item of data) {
      mappedTimeslots.push({
        label: `${item[0]}`,
        value: `${item[0]}`,
      });
    }

    this.setState((prevState) => ({
      ...prevState,
      timeslots: mappedTimeslots,
    }));
  };

  getRow = (
    employee,
    date,
    pickup,
    dropoff,
    tripType,
    timeslot,
    time,
    distance,
    action,
    index
  ) => ({
    employee,
    date,
    pickup,
    dropoff,
    tripType,
    timeslot,
    time,
    distance,
    action,
    index,
  });

  emptyRow = (index) => {
    const { employeeData, timeslots } = this.state;
    // JavaScript is updating the employeeData field by reference, so a clone
    // ensures different data sets are used for each row.
    const clonedData = JSON.parse(JSON.stringify(employeeData));

    const today = new Date();
    const min = today.toISOString().split('T')[0];
    today.setDate(today.getDate() + 10);
    const max = today.toISOString().split('T')[0];

    const clonedTimeslots = [...timeslots];
    return this.getRow(
      //  Employee
      <BasicSelect
        name={index}
        dropdownRef={createRef()}
        placeholder="Select Employee"
        items={clonedData}
        onChange={this.changeEmployee}
      />,
      //  Date
      <Input
        type="date"
        inputRef={createRef()}
        min={min}
        max={max}
        placeholder="yyyy/mm/dd"
      />,
      //  Pickup
      <BasicSelect
        dropdownRef={createRef()}
        placeholder="Select Pickup"
        items={[]}
        onChange={() => {
          this.calculateDistance();
          this.getTripType();
        }}
      />,
      //  Dropoff
      <BasicSelect
        dropdownRef={createRef()}
        placeholder="Select Dropoff"
        items={[]}
        onChange={() => {
          this.calculateDistance();
          this.getTripType();
        }}
      />,
      // Trip Type
      <Input disabled inputRef={createRef()} />,
      //Timeslot
      <BasicSelect
        items={clonedTimeslots}
        dropdownRef={createRef()}
        // onChange={this.selectShiftTime}
      />,
      //  Time
      <Input disabled inputRef={createRef()} />,
      0,
      <div className="bookings__table-actions">
        <Button name={index} onClick={(e) => this.removeRow(index, e)}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
        <Button name={index} onClick={(e) => this.copyRow(index, e)}>
          <FontAwesomeIcon icon={faCopy} />
        </Button>
      </div>,
      index
    );
  };

  getTripType = () => {
    const { rowData, selectedRow } = this.state;

    const row = rowData[selectedRow];
    const selectedPickup = row.pickup.props.dropdownRef.current.value;
    const selectedDropoff = row.dropoff.props.dropdownRef.current.value;

    const id = row.employee.props.dropdownRef.current.value;
    const employee = this.state.employees.find((emp) => emp.id == id);

    const company_addresses = employee.company_addresses;

    if (
      employee.address_primary === selectedPickup &&
      company_addresses.includes(selectedDropoff)
    ) {
      row.tripType = cloneElement(row.tripType, {
        ...row.tripType.props,
        value: 'Arrive-by',
      });

      const newData = [...rowData];
      this.setState((prevState) => ({
        ...prevState,
        rowData: newData,
      }));
    } else {
      row.tripType = cloneElement(row.tripType, {
        ...row.tripType.props,
        value: 'Leave-at',
      });

      const newData = [...rowData];
      this.setState((prevState) => ({
        ...prevState,
        rowData: newData,
      }));
    }
  };

  removeRow = (count, e) => {
    e.stopPropagation();
    const { rowData } = this.state;
    const newData = rowData.filter((item) => item.index !== count);
    this.setState((prevState) => ({
      ...prevState,
      rowData: newData,
    }));
  };

  copyRow = (count, e) => {
    e.stopPropagation();
    const { rowData } = this.state;
    const row = rowData.find((item) => item.index === count);
    if (!row) {
      return;
    }

    const clonedRow = this.emptyRow(rowData.length);
    clonedRow.employee = cloneElement(row.employee, {
      ...row.employee.props,
      name: rowData.length,
      dropdownRef: createRef(),
    });
    clonedRow.date = cloneElement(row.date, {
      ...row.date.props,
      inputRef: createRef(),
    });
    clonedRow.pickup = cloneElement(row.pickup, {
      ...row.pickup.props,
      dropdownRef: createRef(),
    });
    clonedRow.dropoff = cloneElement(row.dropoff, {
      ...row.dropoff.props,
      dropdownRef: createRef(),
    });
    clonedRow.timeslot = cloneElement(row.timeslot, {
      ...row.timeslot.props,
      dropdownRef: createRef(),
    });
    clonedRow.time = cloneElement(row.time);
    clonedRow.distance = row.distance;

    this.setState(
      (prevState) => ({
        ...prevState,
        rowData: [...rowData, clonedRow],
      }),
      () => {
        if ($('[type="date"]').prop('type') != 'date') {
          $('[type="date"]').datepicker();
        }

        const newRow = this.state.rowData.pop();
        if (!!newRow.employee.props.dropdownRef?.current) {
          newRow.employee.props.dropdownRef.current.value =
            row.employee.props.dropdownRef?.current?.value;
        }
        if (!!newRow.date.props.inputRef?.current) {
          newRow.date.props.inputRef.current.value =
            row.date.props.inputRef?.current?.value;
        }
        if (!!newRow.pickup.props.dropdownRef?.current) {
          newRow.pickup.props.dropdownRef.current.value =
            row.pickup.props.dropdownRef?.current?.value;
        }
        if (!!newRow.dropoff.props.dropdownRef?.current) {
          newRow.dropoff.props.dropdownRef.current.value =
            row.dropoff.props.dropdownRef?.current?.value;
        }
        if (!!newRow.timeslot.props.dropdownRef?.current) {
          newRow.timeslot.props.dropdownRef.current.value =
            row.timeslot.props.dropdownRef?.current?.value;
        }

        this.setState({
          rowData: [...this.state.rowData, newRow],
        });
      }
    );
  };

  calculateDistance = async () => {
    const { rowData, selectedRow } = this.state;

    const row = rowData[selectedRow];
    const selectedPickup = row.pickup.props.dropdownRef.current.value;
    const selectedDropoff = row.dropoff.props.dropdownRef.current.value;

    if (!selectedDropoff || !selectedPickup) {
      return;
    }

    await this.useGoogleMaps(selectedPickup, selectedDropoff, row, rowData);
  };

  useGoogleMaps = async (location, destination, row, rowData) => {
    const request = {
      origin: location,
      destination,
      travelMode: 'DRIVING',
    };
    const directionsService = new google.maps.DirectionsService();
    await directionsService.route(request, async (result, status) => {
      if (status == 'OK') {
        const distance = result.routes[0].legs[0].distance.value / 1000;
        let seconds = 600; // padding time
        seconds += distance * 54; // 53.4s per km avg
        const time = Math.ceil(seconds / 60);

        row.distance = distance;
        row.time = cloneElement(row.time, {
          ...row.time.props,
          value: `${time} min`,
        });

        const newData = [...rowData];
        this.setState((prevState) => ({
          ...prevState,
          rowData: newData,
        }));
      }
    });
  };

  changeEmployee = (e) => {
    const { target } = e;
    const id = target.value;
    const index = target.name;
    const employee = this.state.employees.find((emp) => emp.id == id);
    const { rowData, selectedRow } = this.state;

    const items = [];
    if (
      !isNull(employee.address_primary) &&
      !isUndefined(employee.address_primary)
    ) {
      items.push({
        label: `(HOME) ${employee.address_primary}`,
        value: employee.address_primary,
      });
    }

    employee.company_addresses.forEach((add) => {
      items.push({
        label: `(WORK) ${add}`,
        value: add,
      });
    });

    const row = rowData.find((aRow) => aRow.index == index);
    row.pickup = cloneElement(row.pickup, {
      ...row.pickup.props,
      items,
    });
    row.dropoff = cloneElement(row.dropoff, {
      ...row.dropoff.props,
      items,
    });

    const newData = [...rowData];
    this.setState((prevState) => ({
      ...prevState,
      rowData: newData,
    }));
  };

  addNewRow = () => {
    const { rowData } = this.state;
    rowData.push(this.emptyRow(rowData.length));

    this.setState(
      (prevProps) => ({
        ...prevProps,
        message: {},
        rowData: [...rowData],
      }),
      () => {
        if ($('[type="date"]').prop('type') != 'date') {
          $('[type="date"]').datepicker();
        }
      }
    );
  };

  setRowSelection = (selectedRow) => {
    console.log('setting selection', selectedRow);
    this.setState((prevState) => ({
      ...prevState,
      selectedRow,
    }));
  };

  bookTrips = async () => {
    this.setState({ loading: true });
    const { rowData } = this.state;

    const bookings = [];
    for (let row of rowData) {
      const employee = row.employee.props.dropdownRef.current.value;
      const pickup = row.pickup.props.dropdownRef.current.value;
      const dropoff = row.dropoff.props.dropdownRef.current.value;
      const timeslot = row.timeslot.props.dropdownRef.current.value;

      const dateRef = row.date.props.inputRef;
      const date = dateRef.current.value;

      if (this.isInvalid(employee, pickup, dropoff, timeslot, date)) {
        this.setState((prevState) => ({
          ...prevState,
          loading: false,
          message: {
            text: 'There is a booking without all the necessary data',
            type: 'error',
          },
        }));
        return;
      }

      const estimate = row.time.props.inputRef?.current?.value;
      let location = this.removeText(pickup, '(HOME) ');
      location = this.removeText(location, '(WORK) ');

      let destination = this.removeText(dropoff, '(HOME) ');
      destination = this.removeText(destination, '(WORK) ');

      bookings.push({
        user_id: employee,
        date,
        timeslot,
        location,
        destination,
        distance: row.distance,
        estimate,
      });
    }

    await this.processBookings(bookings);
  };

  removeText = (value, text) => {
    return value.replace(text, '');
  };

  processBookings = async (bookings) => {
    if (this.state.submitted) {
      return;
    }

    this.setState(
      (prevState) => ({
        ...prevState,
        submitted: true,
      }),
      async () => {
        let message = 'Trips have been created successfully!';
        let type = 'success';
        let newData = [];
        try {
          await axios('/bulk_bookings/process', {
            method: 'POST',
            data: { trips: bookings },
            headers: { Accept: 'application/json' },
          });
        } catch (e) {
          message = 'Failed to create the trips';
          type = 'error';
          newData = [...this.state.rowData];
        }

        this.setState((prevState) => ({
          ...prevState,
          submitted: false,
          loading: false,
          rowData: newData,
          message: {
            text: message,
            type,
          },
        }));
      }
    );
  };

  isInvalid = (employee, pickup, dropoff, timeslot, date) => {
    return (
      isUndefined(employee) ||
      isUndefined(pickup) ||
      isUndefined(dropoff) ||
      isUndefined(timeslot) ||
      isUndefined(date) ||
      isEmptyStr(date)
    );
  };

  findSelectedValue = (element) => {
    return element.props.menuItems
      ? element.props.menuItems.find((item) => item.selected)
      : undefined;
  };

  render() {
    const { rowData, tableHeaders, message, submitted, loading } = this.state;
    return (
      <Card title="Bulk Bookings">
        <div>
          Total:{' '}
          {isUndefined(this.state.rowData) ? '0' : this.state.rowData.length}
        </div>
        <div className="bookings">
          {message && (
            <h3
              className={getClassNames('bookings__message', {
                [message.type]: true,
              })}>
              {message.text}
            </h3>
          )}
          <div className="bookings__table">
            <Table
              headerModifiers={{
                distance: {
                  hidden: true,
                },
              }}
              cols={tableHeaders}
              rowData={rowData}
              setRowSelection={(e, index) => this.setRowSelection(e, index)}
            />
          </div>
          <div className="bookings__sub-actions">
            <Button hollow expand onClick={this.addNewRow}>
              Add Another Trip
            </Button>
          </div>
          <div className="bookings__actions">
            <Button onClick={this.bookTrips} disabled={submitted}>
              {renderButtonLabel(loading)}
            </Button>
          </div>
        </div>
      </Card>
    );
  }
}

export default BulkBookings;
