import React, { useRef, useState, useEffect } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import Options from './Options'

const OptionsButton = ({type, setCancelTrip, setUngroupTrip, groupId, tripStatus, role, openInGoogleMaps, pageType}) => {
    const [showOptions, setShowOptions] = useState(false);
    const optionsButtonRef = useRef();

    const handleOptionsClick = () => {
      setShowOptions(!showOptions);
    };


    return(
        <div style={{ position: 'relative', zIndex: 0 }}>
            <button 
                data-testid={'options-btn'}
                ref={optionsButtonRef}
                onClick={handleOptionsClick}
                className="max-w-30 h-5 max-h-20 ml-2 w-30 px-2 py-1 bg-gray-100 hover:bg-gray-200 text-gray-700 font-bold rounded-full border border-gray-700 flex items-center justify-center focus:outline-none">
                <FontAwesomeIcon icon={faEllipsisH} size={'xs'} />
            </button>
            {
                showOptions && (
                <div style={{ position: 'absolute', right: 0, top: '1.5rem' }}>
                     <Options
                         type={type}
                         setShowOptions = {setShowOptions}
                         setCancelTrip = {setCancelTrip}
                         setUngroupTrip = {setUngroupTrip}
                         groupId = {groupId}
                         tripStatus={tripStatus}
                         role = {role}
                         openInGoogleMaps={openInGoogleMaps}
                         pageType={pageType}
                     />
                </div>
                )}
        </div>
    )

}

export default OptionsButton;