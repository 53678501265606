import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronCircleDown,
  faChevronCircleUp,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import UngroupedPassengerCard from './UngroupedPassengerCard';
import GroupedTripCard from './GroupedTripCard';
import AssignedTripCard from './AssignedTripCard';
import AddTripDialouge from './AddTripDialouge';
import TimePicker from './TimePicker';
import DatePickerInputBox from './DatePickerInputBox';
import {
  getFormattedDateDisplay,
  getFormattedTimeSlot,
} from '../../../tools/date.util';
import CancelConfirm from './CancelConfirm';
import moment from 'moment';
import Axios from 'axios';
import Select from 'react-select';

const renderUngroupedPassengers = (
  tripsList,
  setRefresh,
  focusPin,
  handleMarkerHighlight
) => {
  // Create a copy of the tripsList array
  const tripsCopy = tripsList.slice();
  // Group trips by date and timeslot
  const groupedTrips = tripsCopy.reduce((acc, trip) => {
    const compositeKey = `${getFormattedDateDisplay(trip.date)}-${trip.timeslot
      }`;

    if (!acc[compositeKey]) {
      acc[compositeKey] = [];
    }
    acc[compositeKey].push(trip);
    return acc;
  }, {});

  // Sort composite keys in ascending order
  const sortedKeys = Object.keys(groupedTrips).sort((a, b) => {
    let dateA = moment(a.split('-')[0], 'DD/MM/YYYY');
    let timeslotA = moment(getFormattedTimeSlot(a.split('-')[1]), 'HH:mmA');

    let dateB = moment(b.split('-')[0], 'DD/MM/YYYY');
    let timeslotB = moment(getFormattedTimeSlot(b.split('-')[1]), 'HH:mmA');

    let compare = (a, b) => (a > b) - (a < b);
    return compare(dateA, dateB) || compare(timeslotA, timeslotB);
  });

  // Render the list of ungrouped passengers (bookings)
  return (
    <div
      className="h-full mt-4 pr-2 pb-8 overflow-y-auto"
      style={{ maxHeight: '400px' }}>
      {sortedKeys.map((key) => {
        const tripsForCompositeKey = groupedTrips[key];
        const numTrips = tripsForCompositeKey.length;
        const [date, timeslot] = key.split('-');
        return (
          <div key={key} className="w-full my-2 border-b-2">
            <h2 className="text-lg text-gray-700 ml-2 font-bold">
              {date} - {timeslot} [{numTrips}]
            </h2>
            {tripsForCompositeKey.map((trip) => (
              <UngroupedPassengerCard
                key={trip.id}
                id={trip.id}
                date={trip.date}
                timeslot={trip.timeslot}
                region={trip.region}
                full_name={trip.full_name}
                setRefresh={setRefresh}
                focusPin={focusPin}
                lat={
                  trip.trip_type === 'inbound'
                    ? trip.location_latitude
                    : trip.destination_latitude
                }
                long={
                  trip.trip_type === 'inbound'
                    ? trip.location_longitude
                    : trip.destination_longitude
                }
                handleMarkerHighlight={handleMarkerHighlight}
                trip={trip}
              />
            ))}
          </div>
        );
      })}
    </div>
  );
};

const renderUngroupedPassengersWithDate = (
  tripsList,
  setRefresh,
  focusPin,
  handleMarkerHighlight
) => {
  // Create a copy of the tripsList array
  const tripsCopy = tripsList.slice();
  // Group trips by timeslot
  const groupedTrips = tripsCopy.reduce((acc, trip) => {
    if (!acc[trip.timeslot]) {
      acc[trip.timeslot] = [];
    }
    acc[trip.timeslot].push(trip);
    return acc;
  }, {});

  // Sort timeslots in ascending order
  const sortedDates = Object.keys(groupedTrips).sort((a, b) => {
    let timeslotA = moment(getFormattedTimeSlot(a), 'hh:mmA');
    let timeslotB = moment(getFormattedTimeSlot(b), 'hh:mmA');

    let compare = (a, b) => (a > b) - (a < b);
    return compare(timeslotA, timeslotB);
  });
  // Render the list of grouped passengers
  return (
    <div
      className="h-full mt-4 pr-2 pb-8 overflow-y-auto"
      style={{ maxHeight: '400px' }}>
      {sortedDates.map((timeslot) => {
        const tripsForTimeslot = groupedTrips[timeslot];
        const numTrips = tripsForTimeslot.length;
        return (
          <div key={timeslot} className="w-full my-2 border-b-2">
            <h2 className="text-lg text-gray-700 ml-2 font-bold">
              {timeslot}
              {' - '}[{numTrips}]
            </h2>
            {groupedTrips[timeslot].map((trip) => (
              <UngroupedPassengerCard
                key={trip.id}
                id={trip.id}
                date={trip.date}
                timeslot={trip.timeslot}
                region={trip.region}
                full_name={trip.full_name}
                setRefresh={setRefresh}
                focusPin={focusPin}
                lat={
                  trip.trip_type == 'inbound'
                    ? trip.location_latitude
                    : trip.destination_latitude
                }
                long={
                  trip.trip_type == 'inbound'
                    ? trip.location_longitude
                    : trip.destination_longitude
                }
                handleMarkerHighlight={handleMarkerHighlight}
                trip={trip}
              />
            ))}
          </div>
        );
      })}
    </div>
  );
};

const renderGroupedTrips = (
  groupedTrips,
  handleUngroup,
  setTripsToUngroupUnassigned,
  tripsToUngroupUnassigned,
  drivers,
  updateDriver,
  focusPin,
  assignAllDrivers,
  getGroupLabel,
  handleMarkerHighlight,
  handleGroupMarkerHighlight,
  setFocusPins,
  setRefresh,
  bound,
  role,
  selectedCompany,
  companies,
  assignAllFleets,
  updateFleet,
  merchantFleets,
  fleets,
  openInGoogleMaps,
  pageType,
  assignType,
  handleUngroupAllTrips
) => {
  let labelIndex = -1;
  const groups = Object.entries(groupedTrips).map(([groupId, trips]) => [
    groupId,
    trips,
  ]);
  const groupedTripsByDateTime = {};
  groups.forEach(([groupId, trips]) => {
    trips.forEach((trip) => {
      const formattedDateTime =
        getFormattedDateDisplay(trip.date) + ' - ' + trip.timeslot;
      if (!groupedTripsByDateTime[formattedDateTime]) {
        groupedTripsByDateTime[formattedDateTime] = {};
      }
      if (!groupedTripsByDateTime[formattedDateTime][groupId]) {
        groupedTripsByDateTime[formattedDateTime][groupId] = [];
      }
      groupedTripsByDateTime[formattedDateTime][groupId].push(trip);
    });
  });

  // Sort composite keys in ascending order
  const sortedKeys = Object.keys(groupedTripsByDateTime).sort((a, b) => {
    let dateA = moment(a.split('-')[0].trim(), 'DD/MM/YYYY');
    let timeslotA = moment(
      getFormattedTimeSlot(a.split('-')[1].trim()),
      'HH:mmA'
    );

    let dateB = moment(b.split('-')[0].trim(), 'DD/MM/YYYY');
    let timeslotB = moment(
      getFormattedTimeSlot(b.split('-')[1].trim()),
      'HH:mmA'
    );

    let compare = (a, b) => (a > b) - (a < b);
    return compare(dateA, dateB) || compare(timeslotA, timeslotB);
  });

  return (
    <div
      className="h-full mt-4 pr-2 pb-8 overflow-y-auto"
      style={{ maxHeight: '400px' }}>
      {sortedKeys.map((datetime) => {
        const tripsByGroup = groupedTripsByDateTime[datetime];
        const tripCount = Object.keys(tripsByGroup).length;
        labelIndex = -1; // Reset the labelIndex to -1 for each datetime
        return (
          <div key={datetime} className="my-1 border-b-2">
            <h2 className="text-lg text-gray-700 ml-2 font-bold">
              {datetime} [{tripCount}]
            </h2>
            {Object.entries(tripsByGroup).map(([groupId, trips]) => {
              labelIndex++;
              return (
                <div key={groupId} className="w-full my-2">
                  <GroupedTripCard
                    label={getGroupLabel(labelIndex)}
                    setTripsToUngroup={setTripsToUngroupUnassigned}
                    tripsToUngroup={tripsToUngroupUnassigned}
                    trips={trips}
                    groupId={groupId}
                    handleUngroup={handleUngroup}
                    drivers={drivers}
                    updateDriver={updateDriver}
                    focusPin={focusPin}
                    assignAllDrivers={assignAllDrivers}
                    handleMarkerHighlight={handleMarkerHighlight}
                    handleGroupMarkerHighlight={handleGroupMarkerHighlight}
                    setFocusPins={setFocusPins}
                    setRefresh={setRefresh}
                    dateTime={datetime}
                    bound={bound}
                    role={role}
                    companies={companies}
                    selectedCompany={selectedCompany}
                    assignAllFleets={assignAllFleets}
                    updateFleet={updateFleet}
                    merchantFleets={merchantFleets}
                    fleets={fleets}
                    openInGoogleMaps={openInGoogleMaps}
                    pageType={pageType}
                    assignType={assignType}
                    handleUngroupAllTrips = {handleUngroupAllTrips}
                  />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

const renderAssignedTrips = (
  groupTripSet,
  setTripsToUngroupAssigned,
  tripsToUngroupAssigned,
  viewAllPassengers,
  handleUngroup,
  drivers,
  updateDriver,
  companies,
  focusPin,
  assignAllDrivers,
  getGroupLabel,
  handleMarkerHighlight,
  handleGroupMarkerHighlight,
  setIsLoadingPage,
  setRefresh,
  setFocusPins,
  bound,
  selectedCompany,
  role,
  assignAllFleets,
  updateFleet,
  merchantFleets,
  fleets,
  openInGoogleMaps,
  pageType,
  assignType,
  handleUngroupAllTrips
) => {
  let labelIndex = -1;

  const groupedTripsByDateTime = {};

  groupTripSet.forEach((item) => {
    const { group_id, tripDetails, trips } = item;
    const { date, timeslot } = tripDetails;

    const formattedDateTime = getFormattedDateDisplay(date) + ' - ' + timeslot;
    if (!groupedTripsByDateTime[formattedDateTime]) {
      groupedTripsByDateTime[formattedDateTime] = [];
    }
    groupedTripsByDateTime[formattedDateTime].push({
      group_id,
      tripDetails,
      trips,
    });
  });
  // Sort composite keys in ascending order
  const sortedKeys = Object.keys(groupedTripsByDateTime).sort((a, b) => {
    let dateA = moment(a.split('-')[0].trim(), 'DD/MM/YYYY');
    let timeslotA = moment(
      getFormattedTimeSlot(a.split('-')[1].trim()),
      'HH:mmA'
    );

    let dateB = moment(b.split('-')[0].trim(), 'DD/MM/YYYY');
    let timeslotB = moment(
      getFormattedTimeSlot(b.split('-')[1].trim()),
      'HH:mmA'
    );

    let compare = (a, b) => (a > b) - (a < b);
    return compare(dateA, dateB) || compare(timeslotA, timeslotB);
  });

  return (
    <div
      className="h-full mt-4 pr-2 pb-8 overflow-y-auto"
      style={{ maxHeight: '400px' }}>
      {sortedKeys.map((datetime) => {
        const trips = groupedTripsByDateTime[datetime];
        const tripCount = Object.keys(trips).length;
        labelIndex++;
        return (
          <div key={datetime} className=" my-1 border-b-2">
            <h2 className="text-lg text-gray-700 ml-2 font-bold">
              {datetime} [{tripCount}]
            </h2>
            {trips.map((item) => {
              const { group_id, tripDetails, trips } = item;
              return (
                <div key={group_id} className="w-full my-2">
                  <AssignedTripCard
                    group_trip_id={group_id}
                    setTripsToUngroupAssigned={setTripsToUngroupAssigned}
                    tripsToUngroupAssigned={tripsToUngroupAssigned}
                    tripDetails={tripDetails}
                    trips={trips}
                    viewAllPassengers={viewAllPassengers}
                    handleUngroup={handleUngroup}
                    drivers={drivers}
                    companies={companies}
                    updateDriver={updateDriver}
                    focusPin={focusPin}
                    assignAllDrivers={assignAllDrivers}
                    getGroupLabel={getGroupLabel}
                    labelIndex={labelIndex}
                    handleMarkerHighlight={handleMarkerHighlight}
                    handleGroupMarkerHighlight={handleGroupMarkerHighlight}
                    setIsLoadingPage={setIsLoadingPage}
                    setRefresh={setRefresh}
                    setFocusPins={setFocusPins}
                    bound={bound}
                    selectedCompany={selectedCompany}
                    role={role}
                    assignAllFleets={assignAllFleets}
                    updateFleet={updateFleet}
                    merchantFleets={merchantFleets}
                    fleets={fleets}
                    openInGoogleMaps={openInGoogleMaps}
                    pageType={pageType}
                    groupedTrips={groupTripSet}
                    assignType={assignType}
                    handleUngroupAllTrips={handleUngroupAllTrips}
                  />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

const TripMenu = ({
  setIsLoadingPage,
  tripsList,
  bound,
  selectedDate,
  retrieveAssignedGroups,
  setSelectedDate,
  selectedCompany,
  timeSlot,
  setTimeSlot,
  groupedTrips,
  pageType,
  setPageType,
  handleUngroup,
  drivers,
  updateDriver,
  groupTripSet,
  searchQuery,
  setSearchQuery,
  setRefresh,
  companies,
  selectedAddress,
  focusPin,
  handleMarkerHighlight,
  handleGroupMarkerHighlight,
  setFocusPins,
  role,
  merchantFleets,
  fleets,
  updateFleet,
  setSelectedFleetFilter,
  selectedFleetFilter,
  fleetFilterOptions,
  openInGoogleMaps,
  handleUngroupAllTrips
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [addTripPressed, setAddTripPressed] = useState(false);
  const [viewAllPassengers, setViewAllPassengers] = useState(false);
  const [overlayH, setOverlayH] = useState('366px');
  const [assignAllDrivers, setAssignAllDrivers] = useState(false);
  const [confirmAssignAllDrivers, setConfirmAssignAllDrivers] = useState(false);
  const [tripsToUngroupAssigned, setTripsToUngroupAssigned] = useState([]);
  const [tripsToUngroupUnassigned, setTripsToUngroupUnassigned] = useState([]);

  const [assignAllFleets, setAssignAllFleets] = useState(false);
  const [confirmAssignAllFleets, setConfirmAssignAllFleets] = useState(false);

  const [assignType, setAssignType] = useState('driver');

  const cardRef = useRef(null);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const handleAssignAllDrivers = () => {
    setConfirmAssignAllDrivers(false);
    setAssignAllDrivers(!assignAllDrivers);
  };

  const handleAssignAllFleets = () => {
    setConfirmAssignAllFleets(false);
    setAssignAllFleets(!assignAllFleets);
  };

  const getCurrentTrips = () => {
    if (pageType == 'ungrouped') {
      return tripsList;
    } else if (pageType == 'grouped') {
      const allTrips = [];
      Object.entries(groupedTrips).map(([groupId, trips]) =>
        allTrips.push(...trips)
      );
      return allTrips;
    } else if (pageType == 'assigned') {
      const allTrips = [];
      groupTripSet.forEach((item) => {
        const { group_id, tripDetails, trips } = item;
        allTrips.push(...trips);
      });
      return allTrips;
    }
  };

  const getAllTrips = () => {
    const allTrips = [];

    allTrips.push(...tripsList);

    Object.entries(groupedTrips).forEach(([groupId, trips]) => {
      allTrips.push(...trips);
    });

    groupTripSet.forEach((item) => {
      const { group_id, tripDetails, trips } = item;
      allTrips.push(...trips);
    });

    return allTrips;
  };

  const getGroupLabel = (labelIndex) => {
    const labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    if (labelIndex < labels.length) {
      return labels[labelIndex];
    } else {
      const index = Math.floor(labelIndex / labels.length) - 1;
      let _customLabel = labels[index];
      const remainder = labelIndex % labels.length;
      _customLabel += labels[remainder];
      return _customLabel;
    }
  };
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleTabChange = (pageType) => {
    setPageType(pageType);
    setIsExpanded(true);
  };

  useEffect(() => {
    if (cardRef.current) {
      const cardHeight = cardRef.current.offsetHeight;
      // Use cardHeight for rendering your overlay or performing any other operations
      setOverlayH(cardHeight);
    }
  }, [isExpanded, addTripPressed]);

  useEffect(() => {
    getCurrentTrips();
  }, [pageType]);

  useEffect(() => {
    setAddTripPressed(false);
    setAssignAllDrivers(false);
    setConfirmAssignAllDrivers(false);
    setAssignAllFleets(false);
    setConfirmAssignAllFleets(false);
  }, [selectedCompany, selectedAddress]);

  const updatedTripsArray = groupTripSet.filter((item) => {
    const groupedAt = new Date(item.tripDetails.grouped_at);
    const communicatedAt = new Date(item.tripDetails.communicated_at);
    const timeDifferenceInSeconds = (groupedAt - communicatedAt) / 1000; // Convert to seconds

    // Return data where time difference is greater than 3 seconds and not negative
    return timeDifferenceInSeconds > 3;
  });

  const handleComms = async () => {
    try {
      setIsLoadingPage(true);

      if (updatedTripsArray && updatedTripsArray.length > 0) {
        for (const i of updatedTripsArray) {
          await Axios.post(
            '/grouped_trips/group_updated_comms',
            {
              id: i.group_id,
            },
            {
              headers: { Accept: 'application/json' },
            }
          );
        }
      }
      setIsLoadingPage(false);
    } catch (error) {
      console.error(`Failed to create adhoc trips`, error);
      setIsLoadingPage(false);
    }
    retrieveAssignedGroups();
  };

  const handleUngroupAllAssigned = () => {
    tripsToUngroupAssigned.forEach((trip) => {
      handleUngroup(null, trip);
    });

    setTripsToUngroupAssigned([]);
  };

  const handleUngroupAllUnassigned = () => {
    tripsToUngroupUnassigned.forEach((trip) => {
      handleUngroup(null, trip);
    });

    setTripsToUngroupUnassigned([]);
  };

  useEffect(() => {
    setAssignType((merchantFleets == true && role != 'fleetmanager') ? 'fleet' : 'driver')
  }, [merchantFleets])

  return (
    <div
      className="bg-white rounded-lg p-4 shadow-lg overflow-visible"
      ref={cardRef}
      style={{
        height: isExpanded ? 'auto' : 'auto',
        transition: 'height 2s',
        width: '600px',
        minHeight: isExpanded ? '200px' : '',
      }}>
      {/* HEADER */}
      <div className="flex justify-between items-center w-612px">
        {/* TABS */}
        <div className="tripStatus flex space-x-3">
          {role != 'fleetmanager' && (
            <button
              data-testid="btn-ungrouped-page"
              className={`flex items-center focus:outline-none pl-1 inline-block font-medium text-gray-800 ${pageType === 'ungrouped'
                  ? 'rounded-lg bg-purple bg-purple-200'
                  : ''
                }`}
              onClick={() => {
                handleTabChange('ungrouped');
              }}>
              <div>Bookings</div>
              <div
                data-testid="div-ungrouped-count"
                className={`ml-2 rounded-lg px-1 py-0 text-white text-sm ${pageType === 'ungrouped' ? 'bg-purple-700' : 'bg-gray-700'
                  }`}>
                {tripsList.length}
              </div>
            </button>
          )}
          <button
            data-testid="btn-grouped-page"
            className={`flex items-center focus:outline-none pl-1 inline-block font-medium text-gray-800 ${pageType === 'grouped' ? 'rounded-lg bg-purple bg-purple-200' : ''
              }`}
            onClick={() => {
              handleTabChange('grouped');
            }}>
            <div>Unassigned</div>
            <div
              data-testid="div-grouped-count"
              className={`ml-2 rounded-lg px-1 py-0 text-white text-sm ${pageType === 'grouped' ? 'bg-purple-700' : 'bg-gray-700'
                }`}>
              {Object.keys(groupedTrips).length}
            </div>
          </button>
          <button
            data-testid="btn-assigned-page"
            className={`flex items-center focus:outline-none pl-1 inline-block font-medium text-gray-800 ${pageType === 'assigned'
                ? 'rounded-lg bg-purple bg-purple-200'
                : ''
              }`}
            onClick={() => {
              handleTabChange('assigned');
            }}>
            <div>Assigned</div>
            <div
              data-testid="div-assigned-count"
              className={`ml-2 rounded-lg px-1 py-0 text-white text-sm ${pageType === 'assigned' ? 'bg-purple-700' : 'bg-gray-700'
                }`}>
              {groupTripSet.length}
            </div>
          </button>
        </div>
        {isExpanded &&
          pageType == 'ungrouped' &&
          selectedDate &&
          timeSlot &&
          selectedCompany != 'All Companies' &&
          selectedAddress != 'None' && (
            <button
              data-testid="btn-add-trip"
              onClick={() => {
                setAddTripPressed(true);
              }}
              className="inline-block mx-5 bg-green-500 rounded-full px-3 py-1 text-white text-sm shadow-sm focus:outline-none focus:bg-green-600">
              + Add Trip
            </button>
          )}
        {isExpanded && pageType == 'grouped' && (
          <div className="flex ">
            <button
              onClick={() => {
                if (role != 'fleetmanager' && merchantFleets == true && assignType == 'fleet') {
                  setConfirmAssignAllFleets(true);
                } else {
                  setConfirmAssignAllDrivers(true);
                }
              }}
              className="inline-block mx-2 bg-green-500 rounded-full px-2 py-1 text-white text-sm shadow-sm focus:outline-none focus:bg-green-600">
              Update All
            </button>

            {tripsToUngroupUnassigned?.length > 0 && (
              <button
                onClick={() => handleUngroupAllUnassigned()}
                className="inline-block mx-2 border border-green-500 text-black rounded-full px-2 ml-2 py-1 text-sm shadow-sm focus:outline-none focus:border-orange-500">
                Ungroup All
              </button>
            )}
          </div>
        )}
        {/* Bulk Update all Trips */}
        {isExpanded && pageType == 'assigned' && (
          <div className="flex ">
            {tripsToUngroupAssigned?.length > 0 && (
              <button
                onClick={() => handleUngroupAllAssigned()}
                className="inline-block mx-2 border border-green-500 text-black rounded-full px-2 ml-2 py-1 text-sm shadow-sm focus:outline-none focus:border-orange-500">
                Ungroup All
              </button>
            )}

            {updatedTripsArray.length > 0 && (
              <button
                onClick={handleComms}
                className="inline-block mr-3 bg-green-500 rounded-full px-3 py-1 text-white text-sm shadow-sm focus:outline-none focus:bg-green-600">
                Save All
              </button>
            )}
          </div>
        )}
        {!isExpanded && (
          <div className="inline-block ml-5 text-gray-600">
            <FontAwesomeIcon
              icon={isExpanded ? faChevronCircleUp : faChevronCircleDown}
              className="text-white-600 font-lg"
              onClick={toggleExpansion}
            />
          </div>
        )}
      </div>
      {/* Sub Header */}
      <div className="selectDateTime flex justify-between items-center mt-4">
        <div className="flex w-full space-x-3">
          <div className="flex-1">
            <DatePickerInputBox
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          </div>
          <div className="flex-1">
            <TimePicker
              timeSlot={timeSlot}
              setTimeSlot={setTimeSlot}
              trips={getCurrentTrips()}
            />
          </div>
          <div className="flex-1">
            <div className="bg-gray-200 rounded-lg flex px-2 py-1 items-center justify-between">
              <input
                type="text"
                className="w-full text-gray-600 font-medium inline-block bg-transparent placeholder-gray-600 outline-none"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <FontAwesomeIcon icon={faSearch} className="text-gray-600" />
            </div>
          </div>
        </div>
      </div>
      {/* Tally sub header */}
      { selectedAddress != 'None' && role != 'fleetmanager' && (
        <div className='flex flex-row justify-end w-full gap-4 mt-1'>
        {
            (pageType == 'grouped') &&
            <Select
                className="w-1/2"
                placeholder={"Placeholder"}
                options={fleetFilterOptions}
                onChange={(value)=>{
                    setSelectedFleetFilter(value)
                }}
                value = {selectedFleetFilter}
            />
        }
        {
            (merchantFleets == true) && (pageType == 'grouped' || pageType == 'assigned') &&
            <div className='flex flex-row justify-end w-1/2'>
                <button
                    className={`flex items-center focus:outline-none p-1 inline-block font-medium text-gray-800 h-fit ${assignType === 'fleet' ? 'rounded-lg bg-purple bg-purple-200' : ''}`}
                    onClick={() => setAssignType("fleet")}
                >Assign fleets</button>
                <button
                    className={`flex items-center focus:outline-none p-1 inline-block font-medium text-gray-800 h-fit ${assignType === 'driver' ? 'rounded-lg bg-purple bg-purple-200' : ''}`}
                    onClick={() => setAssignType("driver")}
                >Assign drivers</button>
            </div>
        }
    </div>
      )}
      {/* Body */}
      {isExpanded &&
        pageType == 'ungrouped' &&
        (selectedDate
          ? renderUngroupedPassengersWithDate(
            tripsList,
            setRefresh,
            focusPin,
            handleMarkerHighlight
          )
          : renderUngroupedPassengers(
            tripsList,
            setRefresh,
            focusPin,
            handleMarkerHighlight
          ))}
      {isExpanded &&
        pageType == 'grouped' &&
        renderGroupedTrips(
          groupedTrips,
          handleUngroup,
          setTripsToUngroupUnassigned,
          tripsToUngroupUnassigned,
          drivers,
          updateDriver,
          focusPin,
          assignAllDrivers,
          getGroupLabel,
          handleMarkerHighlight,
          handleGroupMarkerHighlight,
          setFocusPins,
          setRefresh,
          bound,
          role,
          selectedCompany,
          companies,
          assignAllFleets,
          updateFleet,
          merchantFleets,
          fleets,
          openInGoogleMaps,
          pageType,
          assignType,
          handleUngroupAllTrips
        )}
      {isExpanded &&
        pageType == 'assigned' &&
        renderAssignedTrips(
          groupTripSet,
          setTripsToUngroupAssigned,
          tripsToUngroupAssigned,
          viewAllPassengers,
          handleUngroup,
          drivers,
          updateDriver,
          companies,
          focusPin,
          assignAllDrivers,
          getGroupLabel,
          handleMarkerHighlight,
          handleGroupMarkerHighlight,
          setIsLoadingPage,
          setRefresh,
          setFocusPins,
          bound,
          selectedCompany,
          role,
          assignAllFleets,
          updateFleet,
          merchantFleets,
          fleets,
          openInGoogleMaps,
          pageType,
          assignType,
          handleUngroupAllTrips
        )}
      {/* Footer */}
      {isExpanded && (
        <div className="w-full h-4 my-4 pb-4 flex justify-end">
          <div className="mt-1 text-gray-600">
            <FontAwesomeIcon
              icon={isExpanded ? faChevronCircleUp : faChevronCircleDown}
              className="text-white-600 font-xl"
              onClick={toggleExpansion}
            />
          </div>
        </div>
      )}
      {/* {Add Trip Pop up} */}
      {addTripPressed && (
        <>
          <div
            className="absolute m-4 pb-4 top-0 rounded-lg left-0 w-full h-full bg-black opacity-50 z-5"
            style={{ maxHeight: overlayH, maxWidth: '600px' }}
            onClick={() => setAddTripPressed(false)}></div>
          <div
            className="absolute m-4 top-0 rounded-lg left-0 w-full h-full flex items-center justify-center"
            style={{ maxHeight: '776px', maxWidth: '600px' }}>
            <AddTripDialouge
              onNoClick={() => {
                setAddTripPressed(false);
              }}
              onYesClick={() => {
                setAddTripPressed(false);
              }}
              companyId={selectedCompany}
              address={selectedAddress}
              date={selectedDate}
              timeslot={timeSlot}
              bound={bound}
              setIsLoadingPage={setIsLoadingPage}
              setRefresh={setRefresh}
              currentTrips={getAllTrips()}
            />
          </div>
        </>
      )}
      {confirmAssignAllDrivers && (
        <>
          <div
            className="absolute top-0 left-0 w-full h-full bg-black opacity-50 z-5"
            onClick={() => setConfirmAssignAllDrivers(false)}></div>
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
            <CancelConfirm
              title={'Assign All Drivers'}
              body={
                'You are about to assign all selected drivers and send out communications. Are you sure?'
              }
              onNoClick={() => {
                setConfirmAssignAllDrivers(false);
              }}
              onYesClick={() => {
                handleAssignAllDrivers();
              }}
            />
          </div>
        </>
      )}
      {confirmAssignAllFleets && (
        <>
          <div
            className="absolute top-0 left-0 w-full h-full bg-black opacity-50 z-5"
            onClick={() => setConfirmAssignAllFleets(false)}></div>
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
            <CancelConfirm
              title={'Assign All Drivers'}
              body={
                'You are about to assign all selected fleets. Are you sure?'
              }
              onNoClick={() => {
                setConfirmAssignAllFleets(false);
              }}
              onYesClick={() => {
                handleAssignAllFleets();
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default TripMenu;
