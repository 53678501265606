import React, { useState, useRef, useEffect } from "react";
import {
  faChevronDown,
  faTimesCircle, faXmark, faUserPlus, faTrashCan
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import "./custom_scrollbar.css";

/**
 *
 * @param {showDropdown} showDropdown - The dropdown to show
 * @param {setShowDropdown} setShowDropdown - The function to set the dropdown to show
 * @param {availableEmployees} availableEmployees - The available employees
 * @param {selectedEmployees} selectedEmployees - The selected employees
 * @param {setSelectedEmployees} setSelectedEmployees - The function to set the selected employees
 * @param {excludeSelected} excludeSelected - The boolean to exclude the selected employees from the dropdown
 * @param {timesUp} timesUp - The boolean to check if the times are up
 * @param {setShowAdhoc} setShowAdhoc - The function to set the show adhoc
 * @param {selectedDay} selectedDay - The selected day
 * @returns {JSX.Element} - The employee multi-select component
 */
const EmployeeMultiSelect = ({
  showDropdown,
  setShowDropdown,
  availableEmployees,
  selectedEmployees,
  setSelectedEmployees,
  excludeSelected,
  timesUp,
  setShowAdhoc,
  selectedDay,
}) => {
  const [empMultiSelectView, setEmpMultiSelectView] = useState(false); //Employee MultiSelect View dropdown function
  const [empSearchQuery, setEmpSearchQuery] = useState(""); //Search Query in dropdown
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  const dropdownRef = useRef(null);
  const boxRef = useRef(null);

  //Search funtionality in dropdown
  const handleSearchInputChange = (event) => {
    setEmpSearchQuery(event.target.value);
  };

  const handleClearAll = () => {
    setSelectedEmployees([]); // Clear all selected employees
  };

  const filteredEmployeeSelect = filteredEmployees?.filter((employee) => {
    return (
      employee.name.toLowerCase().includes(empSearchQuery.toLowerCase()) ||
      employee.surname.toLowerCase().includes(empSearchQuery.toLowerCase())
    );
  });

  const handleEmployeeSelect = (e, employee) => {
    e.stopPropagation();
    if (selectedEmployees.includes(employee)) {
      setSelectedEmployees(
        selectedEmployees?.filter((item) => item.id !== employee.id)
      );
    } else {
      setSelectedEmployees([...selectedEmployees, employee]);
    }
  };

  //Check for adhoc bookings
  const isToday = () => {
    const now = moment();
    const selectedDate = moment(selectedDay, "DD-MM-YYYY");
    return now.isSame(selectedDate, "day");
  };

  //Selection method that checks for adhocs on the daily table
  const handleEmployeeMultiSelectToggle = (e) => {
    e.stopPropagation();
    if (timesUp && isToday()) {
      setShowAdhoc(true);
      setEmpMultiSelectView(false);
    }
    if (empMultiSelectView) {
      setEmpMultiSelectView(false);
    } else {
      setEmpMultiSelectView(true);
      setShowDropdown("employee");
    }
  };

  // Function to handle "Select All" button click
  const handleSelectAll = () => {
    if (selectedEmployees.length === filteredEmployees.length) {
      // If all are already selected, deselect all
      setSelectedEmployees([]);
    } else {
      // Otherwise, select all filtered employees
      setSelectedEmployees([...filteredEmployees]);
    }
  };

  //Prevents the multi-selects from being open at the same time
  useEffect(() => {
    if (showDropdown == "manager") {
      setEmpMultiSelectView(false);
    }
  }, [showDropdown]);

  //Loads the new avaiableEmployees when it changes
  useEffect(() => {
    setEmployees(availableEmployees);
  }, [availableEmployees]);

  //Remove selected employees from employee list
  useEffect(() => {
    if (excludeSelected) {
      const filteredEmployees = employees?.filter(
        (employee) => !selectedEmployees.includes(employee)
      );
      setFilteredEmployees(filteredEmployees);
    } else {
      setFilteredEmployees(employees);
    }
  }, [employees, selectedEmployees]);

  useEffect(() => {
    // Function to handle the click outside the component
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        boxRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !boxRef.current.contains(event.target)
      ) {
        setEmpMultiSelectView(false);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="employeeSelect w-full bg-white border border-gray-200 rounded-lg">
        {/* No Employees available to add to team */}
        {employees?.length === 0 && (
          <div className="w-full bg-white rounded-lg flex items-center justify-between focus:outline-none relative p-1">
            <span className="ml-1 text-gray-600" style={{ fontSize: "14px" }}>
              No Employees Available.
            </span>
          </div>
        )}

        {/* Employees available to add to team */}
        {employees?.length !== 0 && (
          <div
            className="w-full bg-white rounded-lg flex items-center justify-between focus:outline-none relative p-1"
            onClick={(e) => {
              handleEmployeeMultiSelectToggle(e);
            }}
            style={{ userSelect: "none" }}
            ref={boxRef}
          >
            <span className="employeeSelect ml-1 text-gray-600" style={{ fontSize: "14px" }}>
              {selectedEmployees?.length == 0
                ? `Employees Available : ${filteredEmployeeSelect?.length}`
                : `${selectedEmployees?.length} selected employees`}
            </span>
            <FontAwesomeIcon
              icon={faUserPlus}
              size="xs"
              className="text-gray-600 mr-1"
            />
            {empMultiSelectView && (
              <div
                ref={dropdownRef}
                className="absolute top-full left-0 bg-white shadow-sm w-full dark-gray-scrollbar overflow-hidden p-1 z-40 rounded-md"
                style={{
                  top: "calc(100%)",
                  maxHeight: "200px",
                  overflowY: "auto",
                }}
              >

                <div className="flex justify-between items-center px-2">
                  <button
                    onClick={() => handleSelectAll()}
                    className="text-xs text-purple-500 p-1 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500"
                  >
                    Select All
                  </button>

                  <button
                    onClick={handleClearAll}
                    className="text-xs text-gray-600"
                  >
                    <FontAwesomeIcon icon={faTrashCan} className="text-red-400 " />
                  </button>

                </div>


                <input
                  type="text"
                  className="px-2 py-1 text-sm bg-gray-200 text-gray-600 w-full "
                  placeholder="Search..."
                  value={empSearchQuery}
                  onChange={handleSearchInputChange}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  autoFocus={true}
                />
                <div
                  className="pb-1 bg-white overflow-y-auto dark-gray-scrollbar"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  {filteredEmployeeSelect?.map((employee) => (
                    <div
                      key={employee.id}
                      className={`block px-2 py-1 text-xs text-gray-600 bg-white hover:bg-purple-500 hover:text-white cursor-pointer ${selectedEmployees.includes(employee)
                        ? "bg-purple-200 text-gray-600"
                        : ""
                        }`}
                      onClick={(e) => handleEmployeeSelect(e, employee)}
                    >
                      {employee.name} {employee.surname}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default EmployeeMultiSelect;
