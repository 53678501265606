import React from 'react';
import moment from 'moment';

import {getClassNames} from '../../tools/helpers';

import './tabs.scss';

const Tab = ({ item, onClick, selected }) => (
  <div className={ getClassNames('tab', { selected }) } onClick={ onClick }>
    { moment(item.value, 'DD-MM-YYYY').format('D MMM YYYY (ddd)')}
  </div>
);

const Tabs = ({ items = [], onClick, selected }) => (
  <div className="tabs">
    { items.map((item) =>
      <Tab
        key={ item.value }
        item={ item }
        onClick={ () => onClick(item.value) }
        selected={ item.value === selected }
      />
    ) }
  </div>
);

export default Tabs;
