import React, { useState, useRef, useEffect } from "react";
import EmployeeMultiSelect from "./EmployeeMultiSelect";
import AdhocBoundSelector from "./AdhocBoundSelector";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TimeslotPicker from "./TimeslotPicker";
import CompanyAddressSelection from "./CompanyAddressSelection";
import "./tooltip.css";
import axios from "axios";
import moment from "moment";
import { showToast } from "../../toasts";

/**
 *
 * @param {function} onNoClick The function to close the dialog
 * @param {array} teamEmployees The employees of the selected team
 * @param {string} selectedDay The selected day for the adhoc booking
 * @param {array} companyAddresses The company addresses of the selected team
 * @param {boolean} addressLoading The state to determine if the company addresses are still loading
 * @param {function} setRefresh The function to refresh the page
 * @param {boolean} refresh The state to determine if the page should be refreshed
 * @returns {JSX.Element} The adhoc booking dialog component
 */
const AdhocView = ({
  setWeeklyChangesMade,
  cleanUpBookings,
  setCleanUpBookings,
  teamEmployees,
  onNoClick,
  summaryAdhocData,
  selectedDay,
  selectedTeam,
  companyAddresses,
  addressLoading,
  setRefresh,
  refresh,
  reloadTeams,
  setReloadTeams,
  isAllowedToEditCsb,
  bookingTimeRange,
  openingTime,
  closingTime
}) => {
  const [showDropdown, setShowDropdown] = useState("");
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [timeslot, setTimeslot] = useState(null);
  const [address, setAddress] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");

  const [bound, setBound] = useState(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tripToCancel, setTripToCancel] = useState(null); // Store the trip ID
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);
  const [adhocTrips, setAdhocTrips] = useState([]); // Store the adhoc trips

  const AdhocData = summaryAdhocData?.this_week;

  const allTrips = [];

  if (AdhocData) {
    Object.keys(AdhocData).forEach((day) => {
      AdhocData[day].inbound.forEach((trip) => {
        allTrips.push({ ...trip, day, tripType: "Inbound" });
      });

      AdhocData[day].outbound.forEach((trip) => {
        allTrips.push({ ...trip, day, tripType: "Outbound" });
      });
    });

    // Sort the trips by date
    allTrips.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
    });
  }

  const handleCancelation = (tripId) => {
    // Set the trip ID and open the modal
    setTripToCancel(tripId);
    setCancelModalOpen(true);
  };

  const handleConfirmCancelation = () => {
    setIsLoading(true);
    axios({
      method: "delete",
      url: `/trips/${tripToCancel}`,
      headers: { Accept: "application/json" },
    })
      .then((response) => {
        setCleanUpBookings(!cleanUpBookings);
        setRefresh(!refresh);
        setWeeklyChangesMade(false);
        // setWeeklyChangesMade(false);
        showToast("success", "Trip cancelled successfully");
        setIsLoading(false);
        setTripToCancel(null); // Reset the trip ID
        setCancelModalOpen(false); // Close the modal
        fetchAdhocTrips();
      })
      .catch((error) => {
        console.error("Trip cancellation failed", error);
        showToast("error", "Error cancelling trip");
        setIsLoading(false);
        setTripToCancel(null); // Reset the trip ID
        setCancelModalOpen(false); // Close the modal
      });
  };

  const bookingViewRef = useRef(null);
  const adHocRef = useRef(null);

  const toggleTooltip = () => {
    setTooltipVisible(!tooltipVisible);
  };

  const handleRemoveEmployee = (employee) => {
    setSelectedEmployees(
      selectedEmployees.filter((item) => item.id !== employee.id)
    );
  };
  // Function to check if the time of the adhoc trip is for tomorrow (a time that has already passed is selected)
  const isTomorrow = () => {
    const now = moment();
    const optionTime = moment(timeslot, "hh:mmA");
    //if the timeslot selected is before the current time, then it is for tomorrow
    return optionTime.isBefore(now);
  };

  //Function to send a request to the backend to create the adhoc trip
  const confirmAdhoc = () => {
    if (
      selectedEmployees.length > 0 &&
      timeslot &&
      address &&
      bound &&
      isAllowed()
    ) {
      const userIds = selectedEmployees.map((employee) => employee.id);
      const data = {
        user_ids: userIds,
        is_tomorrow: isTomorrow(),
        timeslot: timeslot,
        address: address.name,
        bound: bound,
      };
      setIsLoading(true);
      // Make the POST request to create the adhoc trips
      axios
        .post("/team_manager/create_adhoc_trip", data)
        .then((response) => {
          // Handle the response if needed

          // onNoClick();
          // setReloadTeams(reloadTeams + 1);
          setCleanUpBookings(!cleanUpBookings);
          setRefresh(!refresh);
          setWeeklyChangesMade(false);

          setAddress({
            name: "Select Address",
          });
          setTimeslot(null);
          setSelectedEmployees([]);
          setBound(null);
          showToast("success", "Adhoc trip created successfully");
          fetchAdhocTrips();
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error creating team:", error);
          showToast("error", "Error creating adhoc trip");
          // Handle the error if needed
          setRefresh(!refresh);
          setIsLoading(false);
        });
    }
  };

  const showToolTip = () => {
    if (selectedEmployees.length == 0) {
      return "Please select the employees you want to book for.";
    } else if (!timeslot) {
      return "Please select a timeslot to book for.";
    } else if (!address) {
      return "Please select a company address to book for.";
    } else if (!bound) {
      return "Please select a bound.";
    }
  };

  //Check if all required data is present
  const isAllowed = () => {
    if (timeslot == null) {
      return false;
    }
    if (selectedEmployees.length == 0) {
      return false;
    }
    if (address == null || address == "") {
      return false;
    }
    if (bound == null) {
      return false;
    }
    return true;
  };

  const filteredTrips = adhocTrips.filter((trip) => {
    const searchString =
      trip.full_name +
      trip.day +
      trip.location +
      trip.destination +
      trip.timeslot +
      trip.status +
      trip.tripType;
    return searchString.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Modal component
  const ConfirmationModal = ({ isOpen, onCancel, onConfirm }) => {
    if (!isOpen) {
      return null;
    }

    return (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="modal-bg absolute inset-0 bg-gray-800 opacity-75"></div>
        <div className="modal relative bg-white p-6 rounded-lg shadow-lg w-64">
          <p className="pb-2">Are you sure you want to cancel this trip?</p>
          <button
            onClick={onCancel}
            className="bg-gray-500 px-4 py-2 rounded hover:bg-gray-300 mr-2 text-white"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="bg-purple-500 px-4 py-2 rounded hover:bg-purple-700 text-white"
          >
            Confirm
          </button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    // Function to handle the click outside the adhoc component
    const handleClickOutside = (event) => {
      if (
        adHocRef.current &&
        bookingViewRef.current &&
        !adHocRef.current.contains(event.target) &&
        !bookingViewRef.current.contains(event.target)
      ) {
        onNoClick();
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // useEffect to fetch adhoc trips
  const fetchAdhocTrips = () => {
    axios({
      method: "get",
      url: `/team_manager/get_adhoc_trips`,
      headers: { Accept: "application/json" },
      params: {
        team_id: selectedTeam?.team_id,
        date: selectedDay,
      },
    })
      .then((response) => {
        setAdhocTrips(response?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Adhoc trips fetch failed", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchAdhocTrips();
  }, [selectedTeam]);

  return (
    <div ref={bookingViewRef}>
      <div ref={adHocRef}>
        <div className="p-4 bg-gray-200 bg-opacity-50 rounded my-4" style={{ whiteSpace: 'pre-wrap' }}>
          <h1 className="text-gray-600 text-lg py-2">
            Adhoc Bookings for{" "}
            <span className=" font-medium text-purple-500">
              {selectedTeam?.team_name}
            </span>{" "}
          </h1>
          <h3 className="text-gray-600 mb-1 text-sm">Date - {selectedDay} </h3>
          <p className="text-gray-500 text-sm">
            Adhoc bookings outside the normal schedule can be made here.
            Additional charges may apply.{" "}
            <br/>
            <span className="text-gray-700 italic text-sm">
              Any ad-hoc bookings for timeslots before the current time, will be
              made for tomorrow at that time.
            </span>
          </p>
        </div>

        {isAllowedToEditCsb && (
          <>
            <div className="w-full my-1">
              <EmployeeMultiSelect
                showDropdown={showDropdown}
                setShowDropdown={setShowDropdown}
                availableEmployees={teamEmployees}
                selectedEmployees={selectedEmployees}
                setSelectedEmployees={setSelectedEmployees}
                excludeSelected={true}
              />
            </div>

            {selectedEmployees.length > 0 && (
              <div className="w-full mt-4">
                <div className="flex flex-wrap justify-center items-center overflow-y-auto">
                  {selectedEmployees.map((employee) => (
                    <div
                      className="flex items-center justify-between bg-gray-200 text-gray-700 p-2 rounded-full m-1 "
                      style={{ height: "26px" }}
                    >
                      <p key={employee.id} className="text-sm">
                        {employee.name} {employee.surname}
                      </p>
                      <button
                        onClick={() => handleRemoveEmployee(employee)}
                        className="px-2 cursor-pointer "
                      >
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="ml-2 text-gray-500"
                        />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className="flex w-full my-2 justify-between space-x-1">
              <div className="w-1/3">
                <TimeslotPicker
                  timeSlot={timeslot}
                  setTimeSlot={setTimeslot}
                  selectedDay={selectedDay}
                  selectedEmployees={selectedEmployees}
                  isAdhoc={true}
                  bookingTimeRange={bookingTimeRange}
                  openingTime={openingTime}
                  closingTime={closingTime}
                />
              </div>
              <div className="w-1/3">
                <CompanyAddressSelection
                  selectedCompanyAddress={address}
                  setSelectedCompanyAddress={setAddress}
                  companyAddresses={companyAddresses}
                  addressLoading={addressLoading}
                  selectedEmployees={selectedEmployees}
                />
              </div>
              <div className="w-1/3">
                <AdhocBoundSelector
                  tripType={bound}
                  handleTabSelect={setBound}
                />
              </div>
            </div>

            <div className="flex justify-end mt-4 border-gray-200">
              <button
                onClick={isAllowed() ? confirmAdhoc : () => {}}
                className={`px-6 py-1 text-white rounded-xl flex align-center justify-center relative focus:outline-none ${
                  !isAllowed()
                    ? "cursor-not-allowed bg-gray-300"
                    : "cursor-pointer bg-purple-500 hover:bg-purple-600"
                } `}
                onMouseEnter={toggleTooltip}
                onMouseLeave={toggleTooltip}
                style={{ zIndex: 11 }}
              >
                Confirm
                {tooltipVisible && !isAllowed() && (
                  <div className="tooltip w-full" style={{ zIndex: 11 }}>
                    {showToolTip()}
                  </div>
                )}
              </button>
              {isLoading && (
                <>
                  <div className="absolute top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 z-50 flex items-center justify-center rounded-lg">
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8 my-1"></div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>

      {adhocTrips && adhocTrips.length > 0 && (
        <div className="pb-2 pt-4 w-1/3">
          <input
            type="text"
            placeholder="Search..."
            className="w-full px-3 py-1 text-sm border rounded-md"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
      )}

      {filteredTrips.length > 0 ? (
        <>
          <div className="bg-white shadow-md rounded mt-2 mb-6">
            <div className="max-h-80 overflow-y-auto">
              <table className="min-w-max w-full table-auto">
                <thead>
                  <tr className="bg-gray-200 text-gray-600 text-sm leading-normal">
                    <th className="py-3 px-6 text-left">Name</th>
                    <th className="py-3 px-6 text-left">Day</th>
                    <th className="py-3 px-6 text-left">Location</th>
                    <th className="py-3 px-6 text-left">Time Slot</th>
                    <th className="py-3 px-6 text-left">Status</th>
                    <th className="py-3 px-6 text-left">Trip Type</th>
                    <th className="py-3 px-6 text-left"></th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 text-xs font-light">
                  {filteredTrips?.map((trip) => (
                    <tr key={trip.id}>
                      <td className="py-3 px-6 text-left">{trip.full_name}</td>
                      <td className="py-3 px-6 text-left">
                        {trip.date.split("T")[0]}
                      </td>
                      <td className="py-3 px-6 text-left">{trip.location}</td>
                      <td className="py-3 px-6 text-left">{trip.timeslot}</td>
                      <td className="py-3 px-6 text-left">{trip.status}</td>
                      <td className="py-3 px-6 text-left">{trip.trip_type}</td>
                      <td className="py-3 px-6 text-left">
                        {trip.status == "pending" && (
                          <button
                            className="bg-red-400 hover:bg-red-600 p-2 rounded text-white"
                            onClick={() => handleCancelation(trip.id)}
                            title="Cancel Trip"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                              />
                            </svg>
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <div className="p-4 bg-white shadow-md rounded my-6">
          <p className="text-gray-600 text-sm text-center">No adhoc trips.</p>
        </div>
      )}

      <ConfirmationModal
        isOpen={isCancelModalOpen}
        onCancel={() => {
          setTripToCancel(null);
          setCancelModalOpen(false);
        }}
        onConfirm={handleConfirmCancelation}
      />
    </div>
  );
};

export default AdhocView;
