import React, { useState, useRef, useEffect } from "react";
import EmployeeMultiSelect from "./EmployeeMultiSelect";
import ManagerMultiSelect from "./ManagerMultiSelect";
import {
  faTimesCircle,
  faChevronDown,
  faSearch,
  faBusinessTime,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import CompanySelection from "./CompanySelection";
import { showToast } from "../../toasts";

const CreateTeamDialogue = ({
  companies,
  userCompany,
  role,
  handleCloseDialogue,
  reloadTeams,
  setReloadTeams,
  teamsLoading,
  handleSelectChange,
  selectedCompany,
  handleTabClick
}) => {
  const [newTeamName, setNewTeamName] = useState("");
  const [availableEmployees, setAvailableEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [availableManagers, setAvailableManagers] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [isCompanyDropdownOpen, setIsCompanyDropdownOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState("");
  const [searchFilter, setSearchFilter] = useState("");

  const allowTeamCreation =
    newTeamName.trim() === "" || selectedEmployees.length === 0;

  const handleChangeTeamName = (event) => {
    setNewTeamName(event.target.value);
  };

  const handleRemoveManager = (manager) => {
    setSelectedManagers(
      selectedManagers.filter((item) => item.id !== manager.id)
    );
  };

  const handleRemoveEmployee = (employee) => {
    setSelectedEmployees(
      selectedEmployees.filter((item) => item.id !== employee.id)
    );
  };

  const handleCreateTeam = () => {
    if (newTeamName.trim() !== "" && selectedEmployees.length > 0) {
      const manager_user_ids =
        selectedManagers.map((manager) => manager.id) || [];
      const employee_user_ids = selectedEmployees.map(
        (employee) => employee.id
      );
      const data = {
        company_id:
          role !== "admin" && role !== "company_admin" && role !== "logistics_specialist"
            ? userCompany?.id
            : selectedCompany.id,
        team_name: newTeamName,
        manager_user_ids: manager_user_ids,
        employee_user_ids: employee_user_ids,
      };
      setIsLoading(true);
      Axios.post("/team_manager/create_team", data)
        .then((response) => {
          showToast("success", "Team created successfully");
          setReloadTeams(reloadTeams + 1);
          setIsLoading(false);
          handleCloseDialogue();
          handleTabClick('createTeamTab')
        })
        .catch((error) => {
          showToast("error", "Error creating team");
          console.error("Error creating team:", error.response.data.error);
          setReloadTeams(reloadTeams + 1);
          setIsLoading(false);
        });
    }
  };

  const modalRef = useRef(null);

  const handleSelectChange2 = (company) => {
    setSelectedCompany(company.id);
    setCompanyName(company.name);
    setIsCompanyDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickAway = (e) => {
      if (
        isCompanyDropdownOpen &&
        !modalRef.current.contains(e.target) &&
        !e.target.classList.contains("company-dropdown")
      ) {
        setIsCompanyDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickAway);

    return () => {
      document.removeEventListener("click", handleClickAway);
    };
  }, [isCompanyDropdownOpen]);

  useEffect(() => {
    if ((selectedCompany !== null && selectedCompany !== "") || userCompany) {
      setIsLoading(true);
      Axios({
        method: "get",
        url: "/team_manager/get_employees",
        headers: { Accept: "application/json" },
        params: {
          company_id:
            role !== "admin" && role !== "company_admin" && role !== "logistics_specialist"
              ? userCompany?.id
              : selectedCompany?.id,
        },
      })
        .then((res) => {
          setAvailableEmployees(res.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching employees:", error);
          setIsLoading(false);
        });
    }
  }, [selectedCompany]);

  useEffect(() => {
    if ((selectedCompany !== null && selectedCompany !== "") || userCompany) {
      setIsLoading(true);
      Axios({
        method: "get",
        url: "/team_manager/get_managers",
        headers: { Accept: "application/json" },
        params: {
          company_id:
            role !== "admin" && role !== "company_admin" && role !== "logistics_specialist"
              ? userCompany?.id
              : selectedCompany.id,
        },
      })
        .then((res) => {
          setAvailableManagers(res.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching managers:", error);
          setIsLoading(false);
        });
    }
  }, [selectedCompany]);


  return (
    <div className="py-4">

      <div className="flex pt-4">
        <div className="w-1/2">
          {role === "admin" || role === "company_admin" || role === "logistics_specialist" ? (
            <div className="mb-4">
              <CompanySelection
                selectedCompany={selectedCompany}
                companies={companies}
                handleSelectChange={handleSelectChange}
                teamsLoading={teamsLoading}
              />
            </div>
          ) : (
            <div>
              <h2>Company : {userCompany?.name}</h2>
            </div>
          )}
        </div>

        <div className="p-2"></div>

        <div className="newName w-1/2">
          <input
            type="text"
            value={newTeamName}
            placeholder="Enter a new Team Name"
            onChange={handleChangeTeamName}
            className="w-full text-sm border rounded-lg py-1 border-gray-300 text-gray-600 px-3"
          />
        </div>
      </div>

      <div className="mb-4"></div>

      <div className="flex flex-wrap mb-4">
        <div className="w-full md:w-1/2 md:pr-2">
          <EmployeeMultiSelect
            showDropdown={showDropdown}
            setShowDropdown={setShowDropdown}
            availableEmployees={availableEmployees}
            selectedEmployees={selectedEmployees}
            setSelectedEmployees={setSelectedEmployees}
            excludeSelected={true}
          />

          {selectedEmployees.length > 0 && (
            <div
              className="my-4 overflow-y-scroll dark-gray-scrollbar rounded bg-gray-200 p-1 pt-2 px-2"
              style={{ maxHeight: "200px" }}
            >
              <div className="w-full ">
                {selectedEmployees.map((employee) => (
                  <div
                    key={employee.id}
                    className="flex justify-between items-center bg-white text-sm text-gray-700 rounded-md pl-2 w-full py-1 mb-2 relative"
                  >
                    <span>
                      {employee.name} {employee.surname}
                    </span>
                    <button
                      className="text-white cursor-pointer border-red-600 bg-red-600 hover:bg-red-400 rounded-md rounded-l-none px-2 py-1 absolute right-0 top-0 flex justify-center items-center h-full"
                      onClick={() => handleRemoveEmployee(employee)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 text-white "
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </button>
                  </div>

                ))}
              </div>
            </div>
          )}
        </div>



        <div className="w-full md:w-1/2 pl-2">
          <ManagerMultiSelect
            showDropdown={showDropdown}
            setShowDropdown={setShowDropdown}
            availableManagers={availableManagers}
            selectedManagers={selectedManagers}
            setSelectedManagers={setSelectedManagers}
          />

          {selectedManagers.length > 0 && (
            <div
              className="my-4 overflow-y-auto dark-gray-scrollbar rounded bg-gray-200 p-1 pt-2 px-2"
              style={{ maxHeight: "200px" }}
            >
              <div className="w-full  ">
                {selectedManagers.map((manager) => (
                  <div
                    key={manager.id}
                    className="flex justify-between items-center text-sm bg-white text-gray-700 rounded-md pl-2 w-full py-1 mb-2 relative"
                  >
                    <span>
                      {manager.name} {manager.surname}
                    </span>

                    <button
                      className="text-white cursor-pointer border-red-600 bg-red-600 hover:bg-red-400 rounded-md rounded-l-none px-2 py-1 absolute right-0 top-0 flex justify-center items-center h-full"
                      onClick={() => handleRemoveManager(manager)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 text-white "
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>


      </div>
      <div className="w-full my-6 flex justify-center">
        <button
          className={`createButton ${allowTeamCreation
            ? "bg-gray-300 text-gray-500 cursor-not-allowed"
            : "bg-purple-500 text-white"
            } text-sm px-4 py-2 rounded-lg focus:outline-none`}
          disabled={allowTeamCreation}
          onClick={() => handleCreateTeam()}
        >
          {allowTeamCreation ? "Team creation not complete" : "Create Team"}
        </button>
      </div>

      {isLoading && (
        <div className="absolute top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 z-50 flex items-center justify-center rounded-lg">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8 my-1"></div>
        </div>
      )}
    </div>
  );
};

export default CreateTeamDialogue;
