import {
  faPeopleGroup,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";

import React, { useState, useEffect, useRef } from "react";

import "./loader_spinner.css";

/**
 *
 * @param {teams} teams - The teams of the company
 * @param {selectedTeam} selectedTeam - The selected team
 * @param {setSelectedTeam} setSelectedTeam - The function to set the selected team
 * @param {setTeamEmployees} setTeamEmployees - The function to set the employees of the selected team
 * @param {reloadTeams} reloadTeams - The boolean to reload the teams
 * @param {setReloadTeams} setReloadTeams - The function to set the boolean to reload the teams
 * @param {teamsLoading} teamsLoading - The boolean to check if the teams are loading
 * @param {role} role - The role of the current user (admin, company_admin, manager, logistics_specialist)
 * @returns {JSX.Element} - The team selection dropdown component
 */
const TeamSelection = ({
  selectedCompany,
  teams,
  selectedTeam,
  setSelectedTeam,
  setTeamEmployees,
  reloadTeams,
  setReloadTeams,
  teamsLoading,
  role,
  user,
}) => {
  const [viewOptions, setViewOptions] = useState(false);
  const [createNewTeamView, setCreateNewTeamView] = useState(false);
  const [editTeamView, setEditTeamView] = useState(false);
  const [availableEmployees, setAvailableEmployees] = useState([]);

  const dropdownRef = useRef(null);
  const boxRef = useRef(null);

  const toggleView = () => {
    // e.stopPropagation();
    fetchEmployees();
    setViewOptions(!viewOptions);
  };

  const handleSelect = (team) => {
    setViewOptions(false);
    setSelectedTeam(team);
    setTeamEmployees(team.employees);
  };

  const fetchEmployees = () => {
    Axios({
      method: "get",
      url: "/team_manager/get_employees",
      params: {
        company_id: selectedCompany?.id,
      },
      headers: { Accept: "application/json" },
    })
      .then((res) => {
        setAvailableEmployees(res.data);
      })
      .catch((error) => {
        console.error("Error fetching employees:", error);
      });
  };

  const handleCloseDialogue = () => {
    // e.stopPropagation();
    setCreateNewTeamView(false);
    setEditTeamView(false);
    setViewOptions(false);
  };

  //You can open the edit team dialog here.

  const handleClickOutside = (event) => {
    if (
      boxRef.current &&
      dropdownRef.current &&
      !boxRef.current.contains(event.target) &&
      !dropdownRef.current.contains(event.target)
    ) {
      setViewOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (selectedTeam) {
      // function to only show the current user data from the team when

      handleSelect(selectedTeam);
    }
  }, [selectedTeam]);

  //  useeffect to filter the employees and only show the current user data from the team when the user is an employee
  useEffect(() => {
    if (role === "employee") {
      const filteredEmployees = selectedTeam?.employees?.filter(
        (employee) => employee.id === user.id
      );
      setTeamEmployees(filteredEmployees);
    }
  }, [selectedTeam]);

  return (
    <>
      {role === "employee" ? (
        <>
          <h1 className="flex text-gray-600 justify-between items-center bg-gray-200 rounded-lg w-full text-sm px-2 py-1 relative">
            Team Name : {selectedTeam?.team_name}
          </h1>
        </>
      ) : (
        <div
          className="teamSelect flex justify-between items-center bg-gray-200 rounded-lg w-full text-sm px-2 py-1 relative "
          onClick={() => {
            toggleView();
          }}
          style={{ userSelect: "none" }}
          ref={boxRef}
        >
          <div className="text-gray-600 font-small">
            {selectedTeam ? (
              selectedTeam?.team_name
            ) : (
              <>
                <p className="flex justify-between text-gray-400 ml-1">
                  {teams?.length > 0
                    ? `Select a team from ${teams?.length} team(s)`
                    : "No teams available"}
                </p>
              </>
            )}
          </div>
          <FontAwesomeIcon icon={faPeopleGroup} className="text-gray-600" />
          {viewOptions && (
            <div
              ref={dropdownRef}
              className="absolute top-full left-0 bg-white rounded-lg shadow-md border border-gray-300 w-full overflow-hidden  z-40"
              style={{ top: "calc(100%)" }}
            >
              <div className={`w-full flex ${teamsLoading ? "p-5" : "p-2"}`}>
                {" "}
              </div>
              {teamsLoading && (
                <>
                  <div className="absolute overflow-hidden top-0 left-0 right-0 bottom-0 w-full h-full z-50 bg-transparent flex flex-col items-center justify-center">
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8 my-1"></div>
                  </div>
                </>
              )}
              <div className="px-4 text-gray-500 font-semibold">
                Employees with no teams {""}: {availableEmployees?.length}
              </div>
              {!teamsLoading &&
                teams
                  .sort((a, b) => a.team_name.localeCompare(b.team_name))
                  .map((team, index) => (
                    <div
                      key={index}
                      className="px-4 py-1 text-gray-600 font-normal cursor-pointer hover:bg-gray-100"
                      onClick={() => {
                        handleSelect(team);
                      }}
                    >
                      <h2 className="flex justify-between">
                        {team.team_name}{" "}
                        <span className="font-semibold">({team?.employees?.length})</span>
                      </h2>
                    </div>
                  ))}
            </div>
          )}


        </div>
      )}
    </>
  );
};

export default TeamSelection;
