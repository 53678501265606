import React, { useRef, useState, useEffect } from 'react';
import Axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisH, faLocationCrosshairs, faClock, faMapPin, faSearchLocation, faBullseye } from '@fortawesome/free-solid-svg-icons'
import OptionsButton from './OptionsButton';
import CancelConfirm from './CancelConfirm';



const UngroupedPassengerCard = ({id, date, timeslot, region, full_name, setRefresh, focusPin, long, lat, trip, handleMarkerHighlight}) => {

    const [cancelTrip, setCancelTrip] = useState(false)

    const handleCancelation = (tripId) => {
        Axios({
            method: 'delete',
            url: `/trips/${tripId}`,
            headers: { Accept: 'application/json' },
          })
            .then((response) => {
              // Handle successful cancellation
              setCancelTrip(false)
              setRefresh()
            })
            .catch((error) => {
              // Handle error response
              console.error('Trip cancellation failed', error);
            });
    }

    const handleFocusClick = () => {
        focusPin(lat, long)
        handleMarkerHighlight(id)
    }

    return(
        <div className="w-full bg-gray-100 rounded-xl overflow-hidden mb-4 shadow-md relative border border-gray-200">
            <div className="grid grid-cols-3 p-2 ">
                <div className="col-span-2">
                    <h2 className="text-lg text-gray-700 font-bold">{full_name}</h2>
                </div>
                <div className="flex items-center justify-end col-span-1">
                    <button onClick={handleFocusClick}
                        className="max-w-30 h-5 max-h-20 w-30 px-2 py-1 bg-gray-100 hover:bg-gray-200 text-gray-700 font-bold rounded-full border border-gray-700 flex items-center justify-center focus:outline-none">
                        <FontAwesomeIcon icon={faBullseye} size={'xs'} />
                    </button>
                    <OptionsButton type={'ungrouped'} setCancelTrip={setCancelTrip}/>
                </div>
                <hr className="my-2 col-span-3" />
                <div className="col-span-3">
                    <div className='text-gray-700 700 w-full'>
                        <div className='w-5 min-w-5 items-center justify-center inline-block'><FontAwesomeIcon icon={faClock}/></div>
                        <div className='w-30 inline-block'>{timeslot}</div>
                    </div>
                    <div className='text-gray-700 w-full'>
                        <div className='w-4 min-w-4 items-center justify-center inline-block ml-1'><FontAwesomeIcon icon={faMapPin}/></div>
                        <div className='w-30 inline-block'>{region}</div>
                    </div>
                </div>
                {
                cancelTrip &&
                <>
                    <div
                        className="absolute top-0 left-0 w-full h-full bg-black opacity-50 z-5"
                        onClick={() => setCancelTrip(false)}
                    ></div>
                    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                        <CancelConfirm
                            title={"Cancel Trip"}
                            body = {"This will cancel the trip. Are you sure?"}
                            onNoClick = {()=> {setCancelTrip(false)}}
                            onYesClick= {()=>{handleCancelation(id)}}
                        />
                    </div>
                </>
            }
            </div>
        </div>
    )
}

export default UngroupedPassengerCard
